import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { useRouteTo } from '../../../../../hooks/useRouteTo';

const Puller = styled(Box)(() => ({
  width: 98,
  height: 8,
  backgroundColor: '#0000001A',
  borderRadius: '30px',
  position: 'absolute',
  bottom: 0,
  left: 'calc(50% - 15px)',
}));

const PublishForm = ({ toggleDrawer }) => {
  const routeTo = useRouteTo();
  const [formData, setFormData] = useState({
    publish: '',
  });

  const handleSubmition = async (e) => {
    e.preventDefault();

    if (formData.publish === 'PUBLISH')
      routeTo(
        `${appRoutes.superAdmin.root}${appRoutes.superAdmin.tools.toolsNotificationsandAlerts}`,
      );
  };

  const handleResize = () => {};
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Box
      sx={{
        minHeight: '252px',
        backgroundColor: 'white',
        padding: '40px 67px 20px 67px',
      }}
    >
      <Box
        sx={{
          minHeight: '276px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            minHeight: '185px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: '24px',
            gap: '10px',
            width: '50%',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontWeight: '600',
              fontSize: '39px',
              marginBottom: '24px',
            }}
            variant="h1"
            component="h1"
          >
            Publish Confirmation
          </Typography>
          <Box
            sx={{
              width: '100%',
              minHeight: '56px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: '15px',
            }}
          >
            <Box
              sx={{
                paddingX: '3px',
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  width: '160px',
                  fontSize: '16px',
                }}
              >
                Enter Publish
              </span>

              <TextField
                variant="outlined"
                id="outlined-basic"
                type="email"
                name="email"
                size="medium"
                label="Enter 'PUBLISH' to complete"
                value={formData.publish}
                fullWidth
                sx={{
                  '.css-1in441m': {
                    width: '10.1rem',
                  },
                }}
                onChange={(e) =>
                  setFormData({ ...formData, publish: e.target.value })
                }
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginBottom: '24px',
            height: '35px',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              width: '211px',
              display: 'flex',
              gap: '27px',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                position: 'relative',
                letterSpacing: '-0.3px',
                lineHeight: '19px',
                padding: '8px 18px',
                textAlign: 'center',
                fontSize: '14px',
                color: 'rgba(3, 7, 30, 0.8)',
                fontFamily: "'Open Sans'",
                borderRadius: '3px',
                width: '42px',
                overflow: 'hidden',
              }}
              onClick={toggleDrawer('bottom', false)}
            >
              Cancel
            </Button>

            <Button
              onClick={handleSubmition}
              style={{
                letterSpacing: '-0.3px',
                lineHeight: '19px',
                borderRadius: '5px',
                backgroundColor: '#711fff',
                padding: '8px 18px',
                textAlign: 'center',
                fontSize: '14px',
                color: '#fff',
                width: '100%',
                fontFamily: "'Open Sans'",
                textTransform: 'capitalize',
              }}
            >
              Publish
            </Button>
          </div>
        </Box>
        <Puller onClick={toggleDrawer('bottom', false)} />
      </Box>
    </Box>
  );
};

export default function Publish({ children }) {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
    >
      <PublishForm toggleDrawer={toggleDrawer} />
    </Box>
  );

  return (
    <React.Fragment key={'bottom'}>
      <span onClick={toggleDrawer('bottom', true)}>{children}</span>
      <Drawer
        anchor={'bottom'}
        open={state['bottom']}
        onClose={toggleDrawer('bottom', false)}
      >
        {list('bottom')}
      </Drawer>
    </React.Fragment>
  );
}

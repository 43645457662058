import { Alert } from '@mui/material';
import SampleCSV from './SampleCSV';
import { useRouteTo } from '../../../hooks/useRouteTo';

function FirstStep({ setStep, screenHeight }) {
  const routeTo = useRouteTo();
  return (
    <div
      className=""
      style={{
        height: screenHeight - 170,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '2rem',
      }}
    >
      <section className="row">
        <h3 className="txtCenter">Importing People</h3>
        <p className="txtCenter initialParagraph">
          Here you can import existing contacts from other CRM systems, or
          files.
        </p>
      </section>
      <section className="row">
        <article className="uploadBox">
          <div className="white-box" />
          <h3 className="txtCenter txtSM">File upload</h3>
          <p className="txtCenter initialParagraph txtESM">
            Please be sure to have a .csv file with headers ready to import
          </p>
          <button
            type="button"
            className="btn btnSm"
            onClick={() => setStep(2)}
          >
            Start
          </button>
        </article>
      </section>
      <Alert severity="info" action={<SampleCSV />}>
        You can download a sample CSV file :
      </Alert>
      <section className="AlLeft">
        <h3 className="sub-heading">Import History</h3>
        <p className="fstxt">
          Nothing to show. When ever you do an import, it will show you the
          history of the import here.
        </p>
      </section>
      <div className="AlRight dd-import-group">
        {' '}
        <button
          type="button"
          className="btninCc alRight"
          onClick={() => routeTo(-1)}
        >
          Cancel Upload
        </button>
      </div>
    </div>
  );
}
export default FirstStep;

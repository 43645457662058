import React from 'react';
import './Dropdown.css';
import { appRoutes } from '../../appRoutes';
import { useRouteTo } from '../../hooks/useRouteTo';

export default function Dropdown({
  showModal,
  setShowModal,
  updateInnerNetNetTabs,
}) {
  const handleModal = () => {
    setShowModal(!showModal);
  };
  const routeTo = useRouteTo();
  return (
    <ul className="menu">
      <li className="menu-item popupChildren" onClick={handleModal}>
        New Opportunity
      </li>
      <li
        className="menu-item LastChild popupChildren"
        onClick={(e) => {
          e.preventDefault();
          updateInnerNetNetTabs('New Job Estimate', appRoutes.newJobEstimate);
          setShowModal(false);
          routeTo(appRoutes.newJobEstimate);
        }}
      >
        New Estimate
      </li>
    </ul>
  );
}

import React, { useEffect, useState, memo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Slide from 'react-reveal/Slide';
import { PiInfo } from 'react-icons/pi';
import Bounce from 'react-reveal/Bounce';

import {
  Grid,
  Box,
  TextField,
  Paper,
  Alert,
  Popover,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Skeleton from '@mui/material/Skeleton';

import format from 'date-fns/format';
import dayjs from 'dayjs';
import RichTextEditor from '../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';

import { ENDPOINT, getTimeTracking } from '../Opportunity/Redux/oppor';
import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import moreIco from './assets/moreIco.png';
import { getQuickTask } from '../Projects/proRedux';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg';

import { PreLoader1 } from '../../Componenets/Misc';

const sx = {
  fontFamily: 'Open Sans',
  fontSize: '1.2rem',
  fontWeight: '600',
  py: 0,
  px: { xs: 0.5, md: 2 },
};

const formateDate = (date) => format(date, 'iii, MMM do');

function GenericHeader() {
  return (
    <TableHead>
      <TableRow sx={{ height: '30px !important' }}>
        <TableCell align="left" sx={{ width: '50%', height: '45px', py: 0 }}>
          <p
            className="tcell"
            style={{ fontWeight: 600, fontSize: '16px', color: '#03071E99' }}
          >
            Task Name
          </p>
        </TableCell>
        <TableCell align="left" sx={{ width: '50%', py: 0 }}>
          <p
            className="tcell"
            style={{ fontWeight: 600, fontSize: '16px', color: '#03071E99' }}
          >
            Description
          </p>
        </TableCell>
        <TableCell align="left" sx={{ py: 0, backgroundColor: '#F8F9FA' }}>
          <p
            className="tcell"
            style={{ fontWeight: 600, fontSize: '16px', color: '#03071E99' }}
          >
            Date
          </p>
        </TableCell>
        <TableCell align="left" sx={{ py: 0, backgroundColor: '#F8F9FA' }}>
          <p
            className="tcell"
            style={{ fontWeight: 600, fontSize: '16px', color: '#03071E99' }}
          >
            Time
          </p>
        </TableCell>
        <TableCell
          align="left"
          sx={{ py: 0, backgroundColor: '#F8F9FA', minWidth: '150px' }}
        >
          <p
            className="tcell"
            style={{ fontWeight: 600, fontSize: '16px', color: '#03071E99' }}
          >
            Time Tracked
          </p>
        </TableCell>
        <TableCell align="right" sx={{ py: 0 }}>
          <div className="tcell" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

function ThisTime({
  access_token,
  dispatch,
  setAlertModal,
  handleAlertOpen,
  handleAlertClose,
  // allTasks, // ? Analyze if this is needed.
  item,
  // index, // ? Analyze if this is needed.
  tsk,
  funcAlert,
}) {
  const [taskName, setTaskNamne] = useState(
    tsk?.task_id ? tsk?.task_id?.task_name : '',
  );
  const [startTime, setStartTime] = useState(
    dayjs(tsk?.time_tracking_start || ''),
  );
  const [startTime1, setStartTime1] = useState(
    dayjs(tsk?.time_tracking_start || ''),
  );
  const [EndTime, setEndTime] = useState(dayjs(tsk?.time_tracking_stop || ''));
  const [EndTime1, setEndTime1] = useState(
    dayjs(tsk?.time_tracking_stop || ''),
  );
  const [dayDate, setDayDate] = useState(dayjs(tsk?.date || item?.date));

  const diffInMilliseconds = EndTime - startTime;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);

  const [timeAvrage, setTimeAvrage] = useState(
    `${hours < 10 && hours >= 0 ? 0 : ''}${hours}:${minutes < 10 && minutes >= 0 ? 0 : ''}${minutes}`,
  );

  const [avgHours, avgMinutes] =
    timeAvrage && timeAvrage.split(':').map(Number);

  const endTime = new Date(startTime);

  // * Add 3 hours and 20 minutes to the end time.
  endTime.setHours(endTime.getHours() + avgHours);
  endTime.setMinutes(endTime.getMinutes() + avgMinutes);

  // * this is popover.
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [anchorElDescription, setAnchorElDescription] = useState(null);

  const [html, setHtml] = useState(
    tsk?.note && tsk?.note
      ? tsk?.note === '<p><br></p>'
        ? '<p></p>'
        : tsk?.note
      : '<p></p>',
  );

  const handleClickDescriptionPopop = (event) => {
    setAnchorElDescription(event.currentTarget);
  };

  const handleCloseDescriptionPopop = async () => {
    setAnchorElDescription(null);

    try {
      const res = await fetch(
        `${ENDPOINT}/api/project/task/timetracking/update/${tsk.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify({
            note: html,
          }),
        },
      );

      if (res.ok) {
        dispatch(getTimeTracking({ access_token }));
      } else {
        setAlertModal('Not Set Discription');
        handleAlertOpen();
        setTimeout(() => {
          handleAlertClose();
        }, 3000);
      }
    } catch (error) {
      // ! Handle network errors or any other exceptions.
    }
  };

  const openDescription = Boolean(anchorElDescription);
  const idDescription = openDescription ? 'simple-popover' : undefined;

  const [showTableRow, SetShowTableRow] = useState(true);

  const [openLink, setOpenLink] = useState(false);

  const [isSureToDelete, setIsSureToDelete] = useState(false);
  const [isDeletionLoading, setIsDeletionLoading] = useState(false);

  const handleDeleteThisTime = async () => {
    handleClose();

    setIsDeletionLoading(true);
    const res = await fetch(
      `${ENDPOINT}/api/project/task/timetracking/delete/${tsk.id}/`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );
    if (res.ok) {
      setIsDeletionLoading(false);
      dispatch(getTimeTracking({ access_token }));
      funcAlert('Successfully Deleted', true);
      SetShowTableRow(false);
    } else {
      setIsDeletionLoading(false);
    }
  };

  return (
    <>
      {showTableRow && (
        <TableRow
          hover
          sx={{
            height: '40px',
          }}
        >
          <TableCell
            align="left"
            sx={{
              ...sx,
              width: '542px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }}
          >
            <TextField
              id="standard-basic"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              value={taskName}
              onChange={(newValue) => setTaskNamne(newValue.target.value)}
              fullWidth
              sx={{
                '& .MuiInputBase-root:hover': {
                  borderBottom: 'none',
                  outline: 'none',
                  minWidth: '130px',
                },
                '& .MuiInputBase-root': {
                  height: '18px',
                  minWidth: '130px',
                },
                '& .MuiOutlinedInput-input': {
                  padding: '0px !important',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#03071E',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& ..css-v4u5dn-MuiInputBase-root-MuiInput-root:after': {
                  border: 'none',
                },
              }}
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{
              ...sx,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }}
          >
            <Typography
              sx={{ color: 'lightgray', fontSize: '13px' }}
              aria-describedby={idDescription}
              variant="contained"
              onClick={handleClickDescriptionPopop}
            >
              {html === '<p></p>' ? (
                '........................................................'
              ) : (
                <Typography
                  dangerouslySetInnerHTML={{ __html: html }}
                  sx={{
                    maxWidth: '150px',
                    maxHeight: '20px',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#03071E',
                  }}
                />
              )}
            </Typography>
            <Popover
              id={idDescription}
              open={openDescription}
              anchorEl={anchorElDescription}
              onClose={handleCloseDescriptionPopop}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  borderRadius: '8px',
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: '9px',
                  maxWidth: '768px',
                  minHeight: '170px',
                  maxHeight: 'auto',
                  overflowY: 'auto',
                  scrollbarWidth: 'none',
                }}
              >
                <RichTextEditor
                  open={openLink}
                  setOpen={setOpenLink}
                  setHtml={setHtml}
                  html={html}
                />
              </Box>
            </Popover>
          </TableCell>
          <TableCell align="left" sx={{ ...sx, backgroundColor: '#F8F9FA' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                format="MM-DD-YYYY"
                sx={{
                  '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                    {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '95px !important',
                      height: '25px',
                    },
                  '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                    marginRight: '0px',
                    position: 'absolute',
                    borderRadius: '0px !important',
                    width: '95px !important',
                    height: '25px',
                  },
                  '& .MuiInputAdornment-root': {
                    position: 'absolute',
                    top: '10px',
                    left: '-15px',
                    borderRadius: '0px',
                    width: '95px',
                  },

                  '& .MuiSvgIcon-root': {
                    display: 'none',
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                    position: 'relative',
                  },
                  '& .MuiOutlinedInput-input': {
                    width: '85px',
                    height: '15px',
                    padding: '0px',
                    borderBottom: '2px dotted lightgray',
                    color: '#03071E',
                    fontSize: '16px',
                    fontWeight: '400',
                    mt: 0.4,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    outline: 'none !important',
                    border: 'none !important',
                    width: '80px',
                    height: '70px',
                  },
                }}
                value={dayDate}
                onChange={async (newValue) => {
                  setDayDate(newValue);

                  try {
                    const res = await fetch(
                      `${ENDPOINT}/api/project/task/timetracking/update/${tsk.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          date: newValue,
                        }),
                      },
                    );

                    if (res.ok) {
                      dispatch(getTimeTracking({ access_token }));
                    }
                  } catch (error) {
                    // ! Handle network errors or any other exceptions.
                  }
                }}
              />
            </LocalizationProvider>
          </TableCell>
          <TableCell align="left" sx={{ ...sx, backgroundColor: '#F8F9FA' }}>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                  maxTime={EndTime}
                  onAccept={async (e) => {
                    const diffInMilliseconds = EndTime - dayjs(e);
                    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
                    const hours = Math.floor(diffInSeconds / 3600);
                    const minutes = Math.floor((diffInSeconds % 3600) / 60);

                    try {
                      const res = await fetch(
                        `${ENDPOINT}/api/project/task/timetracking/update/${tsk.id}/`,
                        {
                          method: 'PUT',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                          body: JSON.stringify({
                            time_tracking_start: e,
                            time_tracking_stop: EndTime1,
                          }),
                        },
                      );

                      if (res.ok) {
                        dispatch(getTimeTracking({ access_token }));
                        setTimeAvrage(
                          `${hours < 10 && hours >= 0 ? 0 : ''}${hours}:${minutes < 10 && minutes >= 0 ? 0 : ''}${minutes}`,
                        );
                        setStartTime1(e);
                      } else {
                        setAlertModal('End Time Can not be before start time.');
                        handleAlertOpen();
                        setTimeout(() => {
                          handleAlertClose();
                        }, 3000);
                        setStartTime(startTime1);
                      }
                    } catch (error) {
                      // ! Handle network errors or any other exceptions.
                    }
                  }}
                  sx={{
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '80px !important',
                        height: '25px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '80px !important',
                      height: '25px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '10px',
                      left: '-15px',
                      borderRadius: '0px',
                      width: '80px',
                    },

                    '& .MuiSvgIcon-root': {
                      display: 'none',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '72px',
                      height: '15px',
                      padding: '0px',
                      borderBottom: '2px dotted lightgray',
                      color: '#00B28F',
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      outline: 'none !important',
                      border: 'none !important',
                      width: '80px',
                      height: '70px',
                    },
                  }}
                />

                <Typography sx={{ mx: 1 }}>-</Typography>
                <DesktopTimePicker
                  value={EndTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                  minTime={startTime}
                  onAccept={async (e) => {
                    const diffInMilliseconds = dayjs(e) - startTime;
                    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
                    const hours = Math.floor(diffInSeconds / 3600);
                    const minutes = Math.floor((diffInSeconds % 3600) / 60);

                    try {
                      const res = await fetch(
                        `${ENDPOINT}/api/project/task/timetracking/update/${tsk.id}/`,
                        {
                          method: 'PUT',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                          body: JSON.stringify({
                            time_tracking_start: startTime1,
                            time_tracking_stop: e,
                          }),
                        },
                      );

                      if (res.ok) {
                        dispatch(getTimeTracking({ access_token }));
                        setTimeAvrage(
                          `${hours < 10 && hours >= 0 ? 0 : ''}${hours}:${minutes < 10 && minutes >= 0 ? 0 : ''}${minutes}`,
                        );
                        setEndTime1(e);
                      } else {
                        setAlertModal('End Time Can not be before start time.');
                        handleAlertOpen();
                        setTimeout(() => {
                          handleAlertClose();
                        }, 3000);

                        setEndTime(EndTime1);
                      }
                    } catch (error) {
                      // ! Handle network errors or any other exceptions.
                    }
                  }}
                  sx={{
                    '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                      {
                        marginRight: '0px',
                        position: 'absolute',
                        borderRadius: '0px !important',
                        width: '80px !important',
                        height: '25px',
                      },
                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '80px !important',
                      height: '25px',
                    },
                    '& .MuiInputAdornment-root': {
                      position: 'absolute',
                      top: '10px',
                      left: '-15px',
                      borderRadius: '0px',
                      width: '80px',
                    },

                    '& .MuiSvgIcon-root': {
                      display: 'none',
                    },
                    '& .MuiInputBase-root': {
                      width: '100%',
                      position: 'relative',
                    },
                    '& .MuiOutlinedInput-input': {
                      width: '72px',
                      height: '15px',
                      padding: '0px',
                      borderBottom: '2px dotted lightgray',
                      color: ' #00B28F',
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      outline: 'none !important',
                      border: 'none !important',
                      width: '80px',
                      height: '70px',
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          </TableCell>
          <TableCell sx={{ ...sx, backgroundColor: '#F8F9FA', width: '80px' }}>
            <Box>
              <TextField
                variant="outlined"
                autoComplete=""
                name="card_expiry"
                id="card_expiry"
                onAccept={() => {}}
                onBlur={async () => {
                  try {
                    const res = await fetch(
                      `${ENDPOINT}/api/project/task/timetracking/update/${tsk.id}/`,
                      {
                        method: 'PUT',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                          time_tracking_start: startTime,
                          time_tracking_stop: new Date(endTime),
                          tracked_time: timeAvrage,
                          tracked_time_hours: timeAvrage,
                        }),
                      },
                    );

                    if (res.ok) {
                      dispatch(getTimeTracking({ access_token }));
                      setEndTime(dayjs(endTime));
                    }
                  } catch (error) {
                    // ! Handle network errors or any other exceptions.
                  }
                }}
                value={timeAvrage}
                onChange={(e) => {
                  setTimeAvrage(e.target.value);
                }}
                sx={{
                  '& .MuiInputBase-root:hover': {
                    borderBottom: 'none',
                    width: '50px',
                    outline: 'none',
                  },

                  '& .MuiInputBase-root': {
                    width: '50px',
                    height: '18px',
                    padding: '0px',
                    color: ' #008066 !important',
                    fontWeight: '700',
                    fontSize: '16px',
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '0px !important',
                    borderBottom: '2px dotted lightgray ',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& ..css-v4u5dn-MuiInputBase-root-MuiInput-root:after': {
                    border: 'none',
                  },
                }}
              />
            </Box>
          </TableCell>
          <TableCell align="right" sx={sx}>
            <>
              <img
                className="icon-sm"
                src={moreIco}
                alt="dots"
                style={{ cursor: 'pointer' }}
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '8px',
                    boxShadow: 'none',
                  },
                }}
              >
                <Box
                  sx={{
                    width: '150px',
                    border: '1px solid #03071E33',
                    borderRadius: '8px',
                    padding: '0.5rem',
                  }}
                >
                  <Box
                    aria-describedby={id}
                    sx={{
                      display: isSureToDelete ? 'none' : 'flex',
                      fontSize: '18px',
                      cursor: 'pointer',
                      borderRadius: 2,
                      border: '1px solid #d32f2f',
                      padding: '0.5rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#d32f2f',
                      '&:hover': {
                        backgroundColor: '#d32f2f',
                        color: '#ffffff',
                      },
                    }}
                    onClick={() => setIsSureToDelete(true)}
                  >
                    Delete
                  </Box>
                </Box>
              </Popover>
            </>
          </TableCell>
        </TableRow>
      )}

      {isSureToDelete && (
        <section
          className="loading"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 999999,
          }}
        >
          <Slide bottom>
            <Box
              sx={{
                width: '500px',
                height: '130px',
                backgroundColor: 'rgba(255,255,255)',
                border: '1px solid #d9d9d9',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '8px',
                padding: '0.68rem 1rem',
              }}
            >
              <p
                className="fstxt"
                style={{ textAlign: 'center', marginTop: '.3rem' }}
              >
                Are you sure you want to Delete this time entry?
              </p>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '75%',
                  marginTop: '1rem',
                }}
              >
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => setIsSureToDelete(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  style={{ background: isDeletionLoading && '#FA2D23' }}
                  className="netDanger-btn"
                  onClick={handleDeleteThisTime}
                >
                  {isDeletionLoading ? (
                    <Box
                      sx={{
                        width: '21px',
                        height: '21px',
                        margin: '0rem 0.7rem',
                      }}
                    >
                      <PreLoader1 />
                    </Box>
                  ) : (
                    'Delete'
                  )}{' '}
                </button>
              </Box>
            </Box>
          </Slide>
        </section>
      )}
    </>
  );
}

function ThisWeek({
  funcAlert,
  data,
  // setSelected, // ? Analyze if this is needed.
  // newTasks, // ? Analyze if this is needed.
  access_token,
  // screenHeight, // ? Analyze if this is needed.
}) {
  const allTasks = useSelector((state) => state.projects.tasks);

  const totalWeekHours =
    Array.isArray(data.thisWeek) && data.thisWeek.length
      ? data.thisWeek.reduce((total, week) => {
          return (
            total +
            week.tasks.reduce((subTotal, task) => {
              const trackedTimeParts = task.tracked_time.split(':');
              const hours = parseInt(trackedTimeParts[0], 10);
              const minutes = parseInt(trackedTimeParts[1], 10);

              // eslint-disable-next-line no-param-reassign
              subTotal += hours + minutes / 60;

              return subTotal;
            }, 0)
          );
        }, 0)
      : 0.0;

  const totalWH = Math.floor(totalWeekHours);
  const totalWM = Math.round((totalWeekHours - totalWH) * 60);
  const formattedTotalTime = `${totalWH.toString().padStart(2, '0')}:${totalWM.toString().padStart(2, '0')}`;

  const dispatch = useDispatch();

  const [alertopen, setAlertOpen] = useState(false);
  const [alertModal, setAlertModal] = useState('');

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Grid item container direction="column">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#f8f8f8',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          padding: '18px 21px',
          marginBottom: '2rem',
          fontFamily: 'Open Sans',
          fontSize: '1.5rem',
          fontWeight: '700',
        }}
      >
        <div>This Week</div>
        <div>Week Total: {formattedTotalTime}</div>
      </Box>
      {Array.isArray(data.thisWeek) && data.thisWeek.length ? (
        data.thisWeek.map((item, index) => {
          const totalTime = item.tasks.reduce((total, acc) => {
            const trackedTimeParts = acc.tracked_time.split(':');
            const hours = parseInt(trackedTimeParts[0], 10);
            const minutes = parseInt(trackedTimeParts[1], 10);

            // eslint-disable-next-line no-param-reassign
            total += hours + minutes / 60;

            return total;
          }, 0);
          const totalHours = Math.floor(totalTime);
          const totalMinutes = Math.round((totalTime - totalHours) * 60);
          const formattedTotalTime = `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;

          return (
            <>
              <AlertDialog
                handleAlertClose={handleAlertClose}
                handleAlertOpen={handleAlertOpen}
                alertopen={alertopen}
                alertModal={alertModal}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#fff',
                  border: '1px solid #d9d9d9',
                  padding: '10px 35px',
                  marginTop: index === 0 ? 0 : '2rem',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  borderBottom: index !== 0 && '1px solid #d9d9d9',
                }}
              >
                <h5 style={{ fontSize: '20px' }} className="fstxt">
                  {isToday(new Date(item.date)) && 'Today'}
                  {isYesterday(new Date(item.date)) && 'Yesterday'}
                  {!isToday(new Date(item.date)) &&
                    !isYesterday(new Date(item.date)) &&
                    formateDate(new Date(item.date))}
                </h5>
                <p style={{ fontSize: '20px' }} className="fstxt">
                  {formattedTotalTime}
                </p>
              </Box>

              <TableContainer
                component={Paper}
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  border: index === 0 ? '1px solid #d9d9d9' : '1px solid #fff',
                  borderTop: 'none',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  padding: '0px 25px',
                  overflowY: 'hidden',
                  overflowX: 'auto',
                }}
              >
                <Table aria-label="simple table">
                  <GenericHeader />
                  <TableBody>
                    {item.tasks.map((tsk) => (
                      <ThisTime
                        key={tsk.id}
                        access_token={access_token}
                        dispatch={dispatch}
                        setAlertModal={setAlertModal}
                        handleAlertOpen={handleAlertOpen}
                        handleAlertClose={handleAlertClose}
                        allTasks={allTasks}
                        item={item}
                        index={index}
                        tsk={tsk}
                        funcAlert={funcAlert}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          );
        })
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            width: '100%',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderTop: 'none',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <div
            style={{ padding: '44px 23px', width: '100%', textAlign: 'center' }}
          >
            <Alert severity="info">No Tasks Available for This Week</Alert>
          </div>
        </TableContainer>
      )}
    </Grid>
  );
}

function LastWeek({ funcAlert, data, access_token }) {
  const dispatch = useDispatch();

  const allTasks = useSelector((state) => state.projects.tasks);

  const totalWeekHours =
    Array.isArray(data.lastWeek) && data.lastWeek.length
      ? data.lastWeek.reduce((total, week) => {
          return (
            total +
            week.tasks.reduce((subTotal, task) => {
              const trackedTimeParts = task.tracked_time.split(':');
              const hours = parseInt(trackedTimeParts[0], 10);
              const minutes = parseInt(trackedTimeParts[1], 10);

              // eslint-disable-next-line no-param-reassign
              subTotal += hours + minutes / 60;

              return subTotal;
            }, 0)
          );
        }, 0)
      : 0.0;

  const totalWH = Math.floor(totalWeekHours);
  const totalWM = Math.round((totalWeekHours - totalWH) * 60);
  const formattedTotalTime = `${totalWH.toString().padStart(2, '0')}:${totalWM.toString().padStart(2, '0')}`;

  const [alertopen, setAlertOpen] = useState(false);
  const [alertModal, setAlertModal] = useState('');

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Grid item container direction="column">
      <div
        style={{
          border: '1px solid #9152FF',
          marginBottom: '2rem',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#f8f8f8',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          padding: '18px 21px',
          marginBottom: '2rem',
          fontFamily: 'Open Sans',
          fontSize: '1.5rem',
          fontWeight: '700',
          color: '#9152FF',
        }}
      >
        <div>Older</div>
        <div>Total: {formattedTotalTime}</div>
      </Box>
      {Array.isArray(data.lastWeek) && data.lastWeek.length ? (
        data.lastWeek.map((item, index) => {
          const totalTime = item.tasks.reduce((total, acc) => {
            const trackedTimeParts = acc.tracked_time.split(':');
            const hours = parseInt(trackedTimeParts[0], 10);
            const minutes = parseInt(trackedTimeParts[1], 10);

            // eslint-disable-next-line no-param-reassign
            total += hours + minutes / 60;

            return total;
          }, 0);
          const totalHours = Math.floor(totalTime);
          const totalMinutes = Math.round((totalTime - totalHours) * 60);
          const formattedTotalTime = `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;

          return (
            <>
              <AlertDialog
                handleAlertClose={handleAlertClose}
                handleAlertOpen={handleAlertOpen}
                alertopen={alertopen}
                alertModal={alertModal}
              />
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#fff',
                  border: '1px solid #d9d9d9',
                  padding: '10px 35px',
                  marginTop: index === 0 ? 0 : '0.5rem',
                  borderTopLeftRadius: '8px',
                  borderTopRightRadius: '8px',
                  borderBottom: index !== 0 && '1px solid #d9d9d9',
                }}
              >
                <h5 style={{ fontSize: '20px' }} className="fstxt">
                  {isToday(new Date(item.date)) && 'Today'}
                  {isYesterday(new Date(item.date)) && 'Yesterday'}
                  {!isToday(new Date(item.date)) &&
                    !isYesterday(new Date(item.date)) &&
                    formateDate(new Date(item.date))}
                </h5>
                <p style={{ fontSize: '20px' }} className="fstxt">
                  {formattedTotalTime}
                </p>
              </Box>

              <TableContainer
                component={Paper}
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  borderTop: 'none',
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px',
                  padding: '0px 25px',
                  overflowY: 'hidden',
                  overflowX: 'auto',
                }}
              >
                <Table
                  aria-label="simple table"
                  sx={{ overflow: 'hidden !important' }}
                >
                  <GenericHeader />
                  <TableBody>
                    {item.tasks.map((tsk) => (
                      <ThisTime
                        key={tsk.id}
                        access_token={access_token}
                        dispatch={dispatch}
                        setAlertModal={setAlertModal}
                        handleAlertOpen={handleAlertOpen}
                        handleAlertClose={handleAlertClose}
                        allTasks={allTasks}
                        item={item}
                        index={index}
                        tsk={tsk}
                        funcAlert={funcAlert}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          );
        })
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            width: '100%',
            backgroundColor: '#fff',
            border: '1px solid #d9d9d9',
            borderTop: 'none',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          <div
            style={{ padding: '44px 23px', width: '100%', textAlign: 'center' }}
          >
            <Alert severity="info">No Tasks Avalible for This Week</Alert>
          </div>
        </TableContainer>
      )}
    </Grid>
  );
}

function MyTime({ setSelected, screenHeight }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);

  // this is alert modal
  const today = new Date();
  const [showAlert, setShowAlert] = useState();
  const [msg, setMsg] = useState('');
  const [heading, setHeading] = useState(true);
  const funcAlert = (mes, head) => {
    setMsg(mes);
    setHeading(head);
    setShowAlert(true);
  };

  useEffect(() => {
    dispatch(getQuickTask({ access_token }));
  }, []);

  const user = useSelector((state) => state.userData.userData);
  const StateTimeTracking = useSelector(
    (state) => state?.oppertunity?.timetracking,
  );
  const timetracking = StateTimeTracking;
  const tasksByDate = {};
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);

  if (Array.isArray(timetracking)) {
    // eslint-disable-next-line
    timetracking.forEach((item) => {
      if (item.tracked_time_hours === null) {
        return null;
      }

      const createdAt = new Date(item.date && item.date);
      const startDate = new Date(item.time_tracking_start);
      const dateKey = createdAt
        ? createdAt.toDateString()
        : startDate.toDateString();

      if (!tasksByDate[dateKey]) {
        tasksByDate[dateKey] = [];
      }

      tasksByDate[dateKey].push(item);
    });
  }

  const startOfThisWeek = new Date(now);
  startOfThisWeek.setDate(now.getDate() - now.getDay());
  startOfThisWeek.setHours(0, 0, 0, 0);

  const endOfLastWeek = new Date(startOfThisWeek);
  endOfLastWeek.setDate(startOfThisWeek.getDate() - 1);

  const thisWeekTasks = [];
  const lastWeekTasks = [];

  // eslint-disable-next-line no-unused-expressions
  Array.isArray(Object.keys(tasksByDate)) &&
    Object.keys(tasksByDate).forEach((dateKey) => {
      const tasks = tasksByDate[dateKey];
      const date = new Date(dateKey);
      const formattedDate = date.toDateString();

      if (date.getTime() === yesterday.getTime()) {
        thisWeekTasks.push({
          date: 'Yesterday',
          tasks,
        });
      } else if (date >= startOfThisWeek) {
        thisWeekTasks.push({
          date: formattedDate,
          tasks,
        });
      } else if (date <= endOfLastWeek) {
        lastWeekTasks.push({
          date: formattedDate,
          tasks,
        });
      }
    });

  const result = {
    thisWeek: thisWeekTasks.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      /* eslint-disable */
      if (dateA > today && dateB > today) {
        return dateB - dateA;
      } else if (dateA <= today && dateB <= today) {
        return dateB - dateA;
      } else if (dateA > today && dateB <= today) {
        return -1;
      } else if (dateA <= today && dateB > today) {
        return 1;
      } else if (dateA.toDateString() === today.toDateString()) {
        return -1;
      } else if (dateB.toDateString() === today.toDateString()) {
        return 1;
      } else if (dateA - today === -86400000 && dateB - today === -86400000) {
        return dateA - dateB;
      } else {
        return 0; // * Shouldn't happen with the criteria, but return 0 for consistency.
      }
      /* eslint-enable */
    }),
    lastWeek: lastWeekTasks,
  };

  if (Array.isArray(lastWeekTasks)) {
    // * Sort the lastWeekTasks array by date in descending order
    lastWeekTasks.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  }

  const data = result;

  const userTasks =
    Array.isArray(StateTimeTracking) && StateTimeTracking.length
      ? StateTimeTracking.filter((tsk) =>
          tsk.assignee_task?.some(
            (ele) => Number(ele.assignee_user) === Number(user.id),
          ),
        )
      : [];

  const [show, setShow] = useState(false);
  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'My Time');
  });

  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setIsReady(true);
  }, [StateTimeTracking]);

  return isReady ? (
    (Array.isArray(data.thisWeek || data.lastWeek) && data.thisWeek.length) ||
    data.lastWeek.length ? (
      <Bounce left>
        <section className="oppor-section">
          <div className="oppor-row">
            <div className="oppor-col">
              <h3 className="fstxtPro">My Time</h3>
              <img className="icon-sm" src={Dots} alt="dots" />
              <img className="icon-sm" src={Gear} alt="gear" />
              <p>Filters</p>
              <img
                alt=""
                src={helpVid}
                onClick={() => {
                  setShow(true);
                }}
                style={{
                  color: '#a6a6a6',
                  marginLeft: '10px',
                  width: '28px',
                  height: '28px',
                  cursor: 'pointer',
                }}
              />
            </div>
          </div>
          {showAlert && (
            <AlertShowComponent
              setShowAlert={setShowAlert}
              msg={msg}
              setMsg={setMsg}
              heading={heading}
              setHeading={setHeading}
            />
          )}
          <Box
            sx={{
              minHeight: screenHeight - 160,
              maxHeight: screenHeight - 160,
              width: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: '2rem',
            }}
          >
            <ThisWeek
              funcAlert={funcAlert}
              data={data}
              newTasks={userTasks}
              setSelected={setSelected}
              access_token={access_token}
              screenHeight={screenHeight}
            />
            {Array.isArray(data.lastWeek) && data.lastWeek.length ? (
              <LastWeek
                funcAlert={funcAlert}
                newTasks={userTasks}
                data={data}
                access_token={access_token}
              />
            ) : null}
          </Box>
          <HelpVideo
            show={show}
            setShow={setShow}
            welcomeChapters={welcomeChapters}
          />
        </section>
      </Bounce>
    ) : (
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">My Time</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
            <p>Filters</p>
            <img
              alt=""
              src={helpVid}
              onClick={() => {
                setShow(true);
              }}
              style={{
                color: '#a6a6a6',
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <section className="myCenter" style={{ height: screenHeight - 155 }}>
          <article className="box-txt">
            <h3 className="txtCenter">No Time yet</h3>
            <p className="txtCenter">
              All Your Time Tracking Would Appear Here!
            </p>
          </article>
        </section>
        <HelpVideo
          show={show}
          setShow={setShow}
          welcomeChapters={welcomeChapters}
        />
      </section>
    )
  ) : (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '4rem 1rem',
      }}
    >
      <Skeleton
        sx={{ background: '#f0f0f0' }}
        variant="rounded"
        width="100%"
        height={80}
        animation="wave"
      />
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((n) => {
        return (
          <Skeleton
            key={n}
            sx={{ background: '#f0f0f0' }}
            variant="rounded"
            width="100%"
            height="40px"
            animation="wave"
          />
        );
      })}
    </Box>
  );
}

export default memo(MyTime);

function AlertDialog(props) {
  /* eslint-disable */
  return (
    <Dialog
      open={props.alertopen}
      onClose={props.handleAlertClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        align="center"
        sx={{ color: '#FF6827' }}
        id="alert-dialog-title"
      >
        <Box>
          <PiInfo
            style={{ fontWeight: 'bolder', marginRight: '5px', fontSize: '20' }}
          />
          {'warning!'}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center" id="alert-dialog-description">
          <span style={{ fontWeight: 'bold', color: 'black' }}>Message :</span>{' '}
          {props.alertModal}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF6827',
            '&:hover': { backgroundColor: '#FF6827' },
          }}
          onClick={props.handleAlertClose}
          autoFocus
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
  /* eslint-enable */
}

function AlertShowComponent({
  // showAlert, // ? Analyze if this is needed.
  setShowAlert,
  msg,
  setMsg,
  heading,
  setHeading,
}) {
  setTimeout(() => {
    setShowAlert(false);
    setMsg('');
    setHeading(true);
  }, 3000);

  return (
    <Box
      sx={{
        width: { xs: '90%', sm: '75%', md: '60%' },
        height: { xs: '200px', sm: '250px', lg: '300px' },
        padding: '2rem',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '98%',
          height: '98%',
          backgroundColor: 'rgba(255,255,255)',
          border: '1px solid #d9d9d9',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          padding: '0.68rem 1rem',
          justifyContent: 'space-around',
          boxShadow: '2px 2px 6px 0px #D9D9D9',
        }}
      >
        <p
          style={{
            fontSize: '1.1rem',
            fontWeight: '500',
            fontFamily: 'Open Sans, sans-serif',
            color: heading ? '#db8000' : 'red',
            alignItems: 'center',
          }}
        >
          <InfoOutlinedIcon
            SX={{
              color: heading ? '#db8000' : 'red',
              fontSize: '22px',
              marginRight: '10px',
            }}
          />{' '}
          {heading ? 'Delete Your TimeTracking' : 'ERROR'}
        </p>
        <p
          style={{
            color: '#232125',
            letterSpacing: '-0.3px',
            textAlign: 'center',
            fontSize: '1rem',
            fontFamily: "'Open sans', sans-serif",
            fontWeight: '500',
          }}
        >
          {msg}
        </p>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            margin: '0 auto',
          }}
        />
      </Box>
    </Box>
  );
}

import React from 'react';
import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import './netnetU.css';
import { Link, useParams } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SchoolIcon from '@mui/icons-material/School';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SummaryImage from './assets/Frame 794.png';
import { useSelector } from 'react-redux';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import { useState } from 'react';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';

const LeftCourses = ({ chap }) => {
  const [showLessons, setShowLessons] = useState(false)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const routeTo = useRouteTo();
  const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <h6 className="chapter-heading" >{chap.title}</h6>
        {showLessons ?
          <ExpandLessOutlinedIcon sx={{ cursor: 'pointer', marginLeft: '3rem' }} onClick={
            () => {
              setShowLessons(!showLessons)
            }
          } />
          :
          <KeyboardArrowDownOutlinedIcon sx={{ cursor: 'pointer', marginLeft: '3rem' }} onClick={
            () => {
              setShowLessons(!showLessons)
            }
          } />
        }

      </div>
      {showLessons ? <p style={{ color: '#964ed4' }} dangerouslySetInnerHTML={{ __html: chap?.description ?? '' }}></p> : null}
      {showLessons ?
        <ul className="chapter-list" style={{ width: '90%', }}>
          {chap?.lessons?.map((lesson) => {
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                <li className="chapter-item">
                  <NetNetCheckBox checked={checkboxChecked}
                    onChange={() => setCheckboxChecked(!checkboxChecked)} />
                  <PlayCircleIcon
                    sx={{ width: '1.5rem', height: '1.5rem' }}
                    className="chapter-play"
                    onClick={() =>
                      routeTo(
                        `${appRoutes.lessonDetail}/${chap?.related_course}/${chap?.id}/${lesson?.id}`
                      )
                    }
                  />
                  <span className="chapter-name" >{lesson?.title}</span>

                </li>
                <p style={{ fontWeight: 'bold', color: '#000', }}>{formatMinutes(lesson?.duration)}</p>
              </Box>
            );
          })}
        </ul>
        : null
      }
    </>
  )
}

const lessonDetail = () => {
  const { courseId, chapterId, lessonId } = useParams();
  const course = useSelector((state) => state.NetNetU.courses);

  const targetCourse = course.filter((item) => item.id == courseId)[0];

  const targetChapter = targetCourse.chapters.filter(
    (item) => item.id == chapterId
  )[0];

  const targetLesson = targetChapter.lessons.filter(
    (item) => item.id == lessonId
  )[0];

  return (
    <>
      <div className="course-header">
        <div className="course-row">
          <div className="col-top">
            <h1 className="course-title">Net Net U</h1>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
          </div>
          <div className="col-bottom">
            <Link to={appRoutes.netnetU}>NeNet U</Link>
            <ChevronRightIcon />
            <Link to={`${appRoutes.courseDetail}/${courseId}`}>{targetCourse?.title}</Link>
            <ChevronRightIcon />
            <Link to={``}>{targetChapter?.title}</Link>
            <ChevronRightIcon />
            <Link to={``}>{targetLesson?.title}</Link>
          </div>
        </div>
      </div>
      <div className="lesson-body" style={{ padding: '10px 15px' }}>
        <div style={{ padding: "7px", height:"100%" }} className="body-left">
          <div style={{ width:"100%" }} >


            <h4 style={{ fontWeight: 600, fontSize: "14px" }} >{targetChapter.title} </h4>
            <h3 style={{ fontSize: "30px", fontWeight: 700, marginTop: "5px", letterSpacing: "1px" }} >{targetLesson.title}</h3>
            <div className="span-group">
              <span>
                <SchoolIcon sx={{ marginRight: '10px', fontWeight: 200 }} /> Beginner
              </span>
              <span>
                <AccessTimeIcon sx={{ marginRight: '10px' }} />
                00:0{targetLesson?.duration}:00
              </span>
            </div>
            <p
              style={{ paddingBottom: '20px' }}
              dangerouslySetInnerHTML={{
                __html: targetLesson?.description ?? '',
              }}
            ></p>
            <div>

            </div>
            <iframe
              style={{ borderRadius: '10px', width:"100%"}}
              src={targetLesson?.video}
              width={"553px"}
              height={"363px"}
            />
          </div>
          <h5>Introduction</h5>
          <img src={SummaryImage} alt='' />
          <button className="enroll" style={{ marginTop: '20px' }}>
            Download Course Assests
          </button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", }} className="body-right">
          <div style={{ borderRadius: "9px", padding: "16px", background: "#ffffff", height: "60%", overflow: "auto" }} >
            <h3 style={{ width: "100%", borderBottom: "1px solid black", paddingTop: "10px" }} >Course Outline</h3>
            <div className="chapter-loop">
              {course.map((chap) => {
                return (
                  <>
                    <LeftCourses chap={chap} />

                  </>
                );
              })}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default lessonDetail;

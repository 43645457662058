import { useState } from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';

import { useSelector, useDispatch } from 'react-redux';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Avatar, Grid, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { getPersons } from '../ContactRedux';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import RichTextEditor from '../../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

function ContactModal({
  show,
  setShow,
  setCurrentTarget,
  setCurrentPerson,
  currentPerson,
  id,
  screenHeight,
  setFormData,
  formData,
  updateInnerNetNetTabs,
}) {
  const routeTo = useRouteTo();
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [value, setValue] = useState('Notes');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => setShow(false);

  const fetchData = async () => {
    try {
      const res = await fetch(`${ENDPOINT}/api/contact/company/detail/${id}/`, {
        method: 'GET',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        setCurrentTarget(data);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  const [html, setHtml] = useState('');

  const [btn, setbtn] = useState();
  const check = (e) => {
    setbtn(e);
  };

  const send_Note = async (e) => {
    // eslint-disable-next-line
    if (e.shiftKey) {
    } else if (e.key === 'Enter') {
      setHtml('');
      btn?.editor?.commands.clearContent();
      const res = await fetch(`${ENDPOINT}/api/contact/note/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          contact_note: currentPerson.id,
          note: html,
        }),
      });
      if (res.ok) {
        const resGet = await fetch(
          `${ENDPOINT}/api/contact/detail/${currentPerson.unique_id}/`,
          {
            method: 'GET',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (resGet.ok) {
          const dataGet = await resGet.json();
          setCurrentPerson(dataGet);
          fetchData();
        }
      }
      dispatch(getPersons({ access_token }));
    }
  };

  return currentPerson ? (
    <Offcanvas show={show} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        {/* eslint-disable-next-line */}
        <button
          type="button"
          className="close-modal"
          onClick={() => setShow(false)}
        />
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          height: screenHeight - 350,
          overflowY: 'hidden',
        }}
      >
        <section
          className="modal-Header"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: '0 1.24rem',
          }}
        >
          <article
            className="companyHeader"
            style={{ display: 'flex', gap: '0.8rem' }}
          >
            <Avatar
              src={`${ENDPOINT}/${currentPerson.avatar}`}
              sx={{ width: 80, height: 80 }}
              alt={currentPerson.first_name}
            />
            <div className="dFlex col-md-6">
              <h3
                className="fstxtPro noPad"
                style={{ textTransform: 'capitalize' }}
              >
                {currentPerson.first_name} {currentPerson.last_name}
              </h3>
              <h3 className="specialTxT noPad">
                {currentPerson.title ? currentPerson.title : ''}
              </h3>
            </div>
          </article>
          <button
            type="button"
            className="btninC"
            onClick={() => {
              // eslint-disable-next-line
              if (['unique_id' in currentPerson]) {
                routeTo(`${appRoutes.personDetail}/${currentPerson.unique_id}`);
                updateInnerNetNetTabs(
                  'Person Detail',
                  `${appRoutes.personDetail}/${currentPerson.unique_id}`,
                );
              }
            }}
          >
            Go to Contact
          </button>
        </section>
        <section
          className="modal-Body"
          style={{ display: 'flex', gap: '0.74rem', width: '100%' }}
        >
          <section className="info" style={{ flex: '0.6' }}>
            <Grid container style={{ padding: '0 1.24rem' }}>
              <Grid item xs={12} sm={6}>
                <article className="col">
                  <h3 className="sub-heading">Info</h3>
                  <div className="MUI-box-in-CC">
                    <span>Email</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      disabled
                    />
                  </div>
                  <div className="MUI-box-in-CC">
                    <span>Facebook</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.facebook_url}
                      disabled
                    />
                  </div>
                  <div className="MUI-box-in-CC">
                    <span>Type</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.contact_type}
                      disabled
                    />
                  </div>
                  <div className="MUI-box-in-CC">
                    <span>Tags</span>
                    <Box
                      className="tagBox"
                      sx={{ display: 'flex', gap: '1rem' }}
                    >
                      {currentPerson.tags
                        ? currentPerson.tags.split(',').map((item) => (
                            <span key={item} className="tag">
                              {item}
                            </span>
                          ))
                        : null}
                    </Box>
                  </div>
                </article>
              </Grid>
              <Grid item xs={12} sm={6}>
                <article
                  className="col"
                  style={{
                    marginTop: '42px',
                  }}
                >
                  <div className="MUI-box-in-CC">
                    <span>Mobile</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.mobile_num}
                      disabled
                    />
                  </div>
                  <div className="MUI-box-in-CC">
                    <span>LinkedIn</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.linkedin_url}
                      disabled
                    />
                  </div>
                  <div className="MUI-box-in-CC">
                    <span>Twitter</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.twitter_url}
                      disabled
                    />
                  </div>
                  <div className="MUI-box-in-CC">
                    <span>Instagram</span>
                    <input
                      type="text"
                      className="mui-In-CC"
                      value={currentPerson.instagram_url}
                      disabled
                    />
                  </div>
                </article>
              </Grid>
            </Grid>
          </section>
          <section
            className="Tab-System"
            style={{ flex: '0.6', height: '500px' }}
          >
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="Notes" label="Notes" />
              </Tabs>
            </Box>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              sx={{
                padding: '0 2rem',
                paddingBottom: '10px',
                height: screenHeight - 200,
              }}
            >
              {value === 'Notes' && (
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  justifyContent="space-between"
                >
                  <List
                    sx={{
                      width: '100%',
                      height: '290px',
                      overflowY: 'auto',
                    }}
                  >
                    {currentPerson.contact_note &&
                    currentPerson.contact_note.length > 0 ? (
                      currentPerson.contact_note.map((item) => (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar
                                alt={item.created_by.first_name}
                                src={`${ENDPOINT}/${item.created_by.avatar}`}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: 'bold',
                                    fontFamily: 'Open Sans',
                                    fontSize: '1rem',
                                  }}
                                >
                                  {item.created_by.first_name}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  sx={{
                                    color: '#03071E',
                                    fontSize: '14px',
                                  }}
                                  variant="subtitle2"
                                  dangerouslySetInnerHTML={{
                                    __html: item.note,
                                  }}
                                />
                              }
                            />
                          </ListItem>
                          <Divider
                            component="li"
                            sx={{
                              paddingLeft: '2.25%',
                              paddingRight: '2.25%',
                            }}
                          />
                        </>
                      ))
                    ) : (
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      >
                        No Notes
                      </Typography>
                    )}
                  </List>
                  <Grid item sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'end',
                      }}
                    >
                      <section
                        className="editorNoteContacts px-2 pt-1 mb-2 rounded"
                        onKeyDown={send_Note}
                      >
                        <Box sx={{ height: '150px', overflow: 'hidden' }}>
                          <RichTextEditor
                            setHtml={setHtml}
                            html={html}
                            check={check}
                            auto_focus={false}
                          />
                        </Box>
                      </section>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </section>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  ) : (
    0
  );
}
export default ContactModal;

import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import filterIcon from "./assets/sort 1.svg";
import React, { useState, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import './notificationAndAlerts.css'
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAndAlerts } from "./notificationReducer";
import { appRoutes } from "../../../../../appRoutes";


const Header = () => {
    return (
      <div
        style={{
          position: "relative",
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: " 1% 1.38% 1% 1.38%",
          boxSizing: "border-box",
          textAlign: "center",
          fontSize: "29px",
          color: "#03071e",
          fontFamily: "'Open Sans'",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "20px",
          }}
        >
          <b style={{ position: "relative", lineHeight: "100%" }}>
            Notifications
          </b>
  
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "left",
              fontSize: "14px",
              color: "rgba(3, 7, 30, 0.4)",
            }}
          >
            <div
              style={{
                borderRadius: "4px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "18px",
                  height: "18px",
                  overflow: "hidden",
                  flexShrink: "0",
                }}
                alt=""
                src={filterIcon}
              />
              <div
                style={{
                  position: "relative",
                  letterSpacing: "-0.3px",
                  lineHeight: "19px",
                }}
              >
                Filters
              </div>
            </div>
          </div>
        </div>
  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "5px 0",
            fontSize: "14px",
            color: "#fff",
          }}
        >
          <Link to={`${appRoutes.superAdmin.root}${appRoutes.superAdmin.tools.toolsNotificationNew}`}>
            <Button
              sx={{
                width: 108,
                height: 35,
                textTransform: "capitalize",
                fontWeight: "300",
                backgroundColor: "#711FFF",
                lineHeight: "19px",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#711DDD",
                },
              }}
              variant="contained"
            >
              + New
            </Button>
          </Link>
        </div>
      </div>
    );
  };


const NotificationAndAlerts = ({screenHeight}) => {

    const dispatch = useDispatch();
    const access_token = useSelector((state) => state.auth.user.access);
     
     useEffect(() => {
         const meta = {
             access_token,
           };
       dispatch(getNotificationsAndAlerts(meta))

     }, [dispatch, access_token]);
        
    return (
        <>
           <Header />
    <section className="oppor-section">
      <sections className="myCenter" style={{height: screenHeight - 120}}>
        <article className="box-txt">
          <h3 className="txtCenter"style={{marginBottom: "6px"}}>Notification And Alerts </h3>
          <p className="txtCenter">Coming Soon!</p>
        </article>
      </sections>
    </section>
        </>
    )

}


export default NotificationAndAlerts;
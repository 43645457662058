import { useEffect, useState, React } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ProgressBar } from 'react-bootstrap';

import {
  Grid,
  Box,
  Typography,
  TextField,
  Avatar,
  Alert,
  Autocomplete,
  Popper,
  Button,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GridViewIcon from '@mui/icons-material/GridView';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers';

import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  calculateReduction,
  calculateTotalNet,
} from '../../Opportunity/estSteps/FifthStep';
import { PreLoader1, DashedField } from '../../../Componenets/Misc';
import { getVariant } from '../ProjectUtility';
import { calculateTotalDurationObtained } from '../QuickTasks';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { getProjects } from '../proRedux';

import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

dayjs.extend(utc);

const ccri = {
  color: '#03071E',
  fontSize: '0.85rem',
  fontWeight: '400',
  paddingLeft: '25px',
  paddingRight: '50px',
  textAlign: 'justify',
};

const spri = {
  color: '#03071E',
  fontSize: '.90rem',
  fontWeight: '700',
  letterSpacing: '-0.3px',
};

function MarkComplete({ setSureComplete, currentTarget, setIsTyping }) {
  const dispatch = useDispatch();
  const routeTo = useRouteTo();

  const [finishDate, setFinishData] = useState(null);
  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    if (currentTarget?.finish_date) {
      setFinishData(
        dayjs(
          new Date(
            new Date(currentTarget.finish_date).setDate(
              new Date(currentTarget.finish_date).getDate() - 1,
            ),
          ).toISOString(),
        ),
      );
    }
  }, []);

  const handleCompletion = async () => {
    const res = await fetch(
      `${ENDPOINT}/api/project/update/${currentTarget.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'complete',
          finish_date: finishDate
            ? new Date(
                new Date(finishDate).setDate(
                  new Date(finishDate).getDate() + 1,
                ),
              ).toISOString()
            : currentTarget.finish_date,
        }),
      },
    );

    if (res.ok) {
      dispatch(getProjects({ access_token }));
      setIsTyping(false);
      setSureComplete(false);
      routeTo(appRoutes.jobs);
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '850px',
          height: '300px',
          background: 'rgba(241, 243, 245, 0.50)',
          padding: '2rem',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '95%',
            height: '95%',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
            justifyContent: 'space-around',
            boxShadow: '2px 2px 6px 0px #D9D9D9',
          }}
        >
          <p
            style={{
              fontSize: '0.9rem',
              fontWeight: '600',
              fontFamily: 'Open Sans, sans-serif',
              color: '#4300b8',
            }}
          >
            Marking Job as complete
          </p>
          <p className="fstxt">
            Please Select the date for the completion of the Job.{' '}
          </p>
          <Box
            sx={{
              width: '80%',
              backgroundColor: '#d4e8fb',
              borderRadius: '8px',
              padding: '1rem 1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '65px',
            }}
          >
            <p className="baseTypography2">Finish Date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DD/MM/YYYY"
                inputFormat="dd-MM-yyyy"
                value={
                  finishDate ||
                  (currentTarget.finish_date
                    ? dayjs(currentTarget.finish_date)
                    : '')
                }
                onChange={(newValue) => {
                  setFinishData(newValue);
                }}
                sx={{
                  borderColor: '#d4e8fb',
                  marginTop: '5px',
                  width: '53%',
                  height: '50px',
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                    {
                      color: '#711FFF',
                    },
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                    {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '50px',
                    },
                  '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                    marginRight: '0px',
                    position: 'absolute',
                    borderRadius: '0px !important',
                    width: '100% !important',
                    height: '50px',
                  },
                  '& .MuiInputAdornment-root': {
                    position: 'absolute',
                    top: '22px',
                    left: '-6px',
                    borderRadius: '0px',
                    width: '100%',
                  },
                  '& .MuiSvgIcon-root': {
                    position: 'absolute',
                    right: '10px',
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                    position: 'relative',
                  },
                  '& .MuiOutlinedInput-input': {
                    width: '100%',
                    height: '50px',
                    padding: '0px',
                    color: 'black',
                    marginLeft: '10px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    width: '100%',
                    height: '50px',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '&.Mui-error fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureComplete(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={handleCompletion}
            >
              Complete
            </button>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

function MarkArchieve({ setSureArchive, currentTarget }) {
  const dispatch = useDispatch();
  const routeTo = useRouteTo();

  const [val, setVal] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);

  const handleCompletion = async () => {
    if (!val) {
      return;
    }

    if (val.toLocaleLowerCase() !== 'archive') {
      return;
    }

    const res = await fetch(
      `${ENDPOINT}/api/project/update/${currentTarget.id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          status: 'archived',
        }),
      },
    );

    if (!res.ok) {
      setSureArchive(false);
      return;
    }

    dispatch(getProjects({ access_token }));
    setSureArchive(false);
    routeTo(appRoutes.jobs);
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Box
        sx={{
          width: '850px',
          height: '300px',
          background: 'rgba(241, 243, 245, 0.50)',
          padding: '2rem',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '95%',
            height: '95%',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '0.68rem 1rem',
            justifyContent: 'space-around',
            boxShadow: '2px 2px 6px 0px #D9D9D9',
          }}
        >
          <p
            style={{
              fontSize: '0.9rem',
              fontWeight: '600',
              fontFamily: 'Open Sans, sans-serif',
              color: '#4300b8',
            }}
          >
            ARCHIVE Job
          </p>
          <p className="fstxt" style={{ width: '90%', textAlign: 'center' }}>
            Are you sure you want to Archive this Job? Your Job will become
            inactive and the billing will also stop.
          </p>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <p className="fstxt">
              Please type the word &quot;ARCHIVE&quot; and click the Archive
              button
            </p>
            <Box
              sx={{
                width: '60%',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '65px',
              }}
            >
              <DashedField
                value={val}
                onChange={(e) => setVal(e.target.value)}
                label="Archive"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '90%',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureArchive(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="create-btn"
              onClick={handleCompletion}
              disabled={!val}
            >
              Archive
            </button>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

function MileStones({ dileverable, formData }) {
  const [data, setData] = useState([
    {
      id: 'Summary',
      contentHead: 'Summary',
      contentPara: formData.description ? (
        // eslint-disable-next-line
        <p dangerouslySetInnerHTML={{ __html: formData.description }} />
      ) : null,
    },

    {
      id: 'DELIVERABLES',
      contentHead: 'Deliverables',
      deliverables: dileverable,
    },
    {
      id: 'Pricing',
      contentHead: 'PRICING',
      tableHeads: [
        {
          one: 'Deliverable',
          two: 'Subtotal',
          three: 'FED Taxes',
          four: 'NGO Discount',
          five: 'Total',
        },
      ],
      tableData: dileverable,
      tableSubTotal: 'SUB TOTAL',
    },
    {
      hardCostSub: 'SUB TOTAL',
      hardCostHeads: [
        'Hard Costs',
        'Subtotal',
        'Multiplier',
        'Discount',
        'Total',
      ],
      hardCostData: [],
    },
    {
      id: 'paymentTerms',
      contentHead: 'Payment Terms',

      contentPara: `We bill using a progressive invoicing method with no deposit required for this Job.
    Progressive Invoicing occurs two times per month on the 1st and 16th for the previous periods.
    A time log report will be provided with the invoice for the total hours accrued for that period. All
    invoices are due and payable Net 10 from issuance.
    
    RH bills for the actual time incurred, should the hours be less than the estimate.
    The hours are billed at a standard rate of $185/hour. Changes in scope or understanding may
    affect the completeness of deliverables and/or extend the effort and may result in additional
    costs to Client.`,
      isEditing: false,
    },

    {
      id: 'conditionsTerms',

      contentHead: 'Terms and Conditions',
      contentPara: `This Job Order presented by Right Here Interactive, LLC. (“RHI”) to Client represents the full and complete understanding of the deliverables.  Modifications or additions to the deliverables listed in this Job Order are subject to additional charges (“Change Orders”).  RHI warrants the deliverables will be free from mechanical defect caused solely by RHI for a period of Thirty (30) days from the date of delivery.  If material defects are found, RHI shall be responsible for correcting them without unreasonable delay, at their expense. This shall be the exclusive warranty available to Client.  This warranty is considered nullified, void or otherwise not valid if any other party than those authorized by RHI in any way access or modify the deliverables or any components in part or whole without the express written consent of RHI. Client acknowledges that RHI does not warrant any third-party software licensed, purchased or by any other means acquired for use in the creation of the deliverables, nor that software will remain active, available, updated or registered for more than Thirty (30) days.  RHI does not warrant servers to which the deliverables are run on, stored or hosted.  

        For the period during and One (1) year after the completion of this Job Order, neither party shall directly or indirectly employ or contract to any employees, contractors or freelancers of the other party without the prior written approval of the other party.  Client does hereby allow RHI to use their name, URL(s), taglines, logo(s) and/or quotes for promotional purposes. 
        
        Client acknowledges that RHI must allocate resources over a period of time to complete the deliverables and that the scheduling of those resources creates costs for RHI. If after the commencement of work, Client creates a delay in RHI’s work effort for more than Ten (10) consecutive days for any reason, the job will be considered “Stalled” and RHI may temporarily remove it from production.  Once Client is able to resume the Job Order and is approved by RHI, Client will pay in advance any remaining balance that brings them to Ninety Five Percent (95%) of the sum total of the Job Order and any Change Orders, plus a Reinstatement Fee of Seven and a Half Percent (7.5%) of the Job Order total.  Once the amounts are paid in full, production will resume with a new schedule issued by RHI.
        
        If this Job Order is terminated for any reason, Client agrees that no refunds will be given to Client for any payments made to RHI up to the date of termination. Upon termination, RHI will issue a final invoice for the fees in excess of payments made by Client, which at the discretion of RHI will be determined either by hours incurred at a rate of One Hundred Fifty Dollars ($150) per hour or by the value of the deliverables completed, plus Change Orders fees, plus a Cancellation Fee of Twenty Percent (20%) of the Job Order total.  The total amount of the final invoice plus payments already made by Client will not exceed the sum total of the Job Order, plus Change Orders, plus if applicable the Reinstatement Fee. Client agrees they will pay the invoice in full within Ten (10) days.  
        `,
      isEditing: false,
    },
    {
      id: 'acceptance',
      contentHead: 'ACCEPTANCE',
      contentPara: (
        <p style={{ display: 'flex', marginTop: '15px', marginBottom: '10px' }}>
          <b>THE UNDERSIGNED CLIENT,</b> does hereby accept snd execute this Job
          Order on the date set forth herein.
        </p>
      ),
      clientName: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Name ___________________________________________________________
        </p>
      ),
      clientSign: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Signature ___________________________________________________________
        </p>
      ),
      clientTittle: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Title ___________________________________________________________
        </p>
      ),
      clientDate: (
        <p style={{ paddingTop: '20px', fontFamily: 'Open Sans' }}>
          Date ___________________________________
        </p>
      ),
    },
  ]);

  const [items, setItems] = useState(data);
  const onDragEnd = (result, items, setItems) => {
    if (!result.destination) {
      return;
    }

    const itemsCopy = Array.from(items);
    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, reorderedItem);

    setItems(itemsCopy);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditDescription = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    setData(newData);
  };

  const handleDescriptionChange = (index, event) => {
    const newData = [...data];
    newData[index].contentPara = event.target.value;
    setData(newData);
  };

  const handleSaveDescription = (index) => {
    const newData = [...data];
    newData[index].isEditing = false;
    setData(newData);
  };
  const [openCon, setOpenCon] = useState(false);

  return (
    <Box>
      <DragDropContext
        id="divToPrint"
        onDragEnd={(result) => onDragEnd(result, items, setItems)}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <Grid
              item
              container
              direction="column"
              sx={{ gap: '1rem' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Box
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          backgroundColor: '#fff',
                        }}
                      >
                        <Typography style={{ display: 'flex' }} sx={spri}>
                          {' '}
                          {item.contentHead ? (
                            <DragIndicatorIcon
                              sx={{ fontSize: '1.2rem', color: 'gray' }}
                            />
                          ) : null}{' '}
                          {item.contentHead?.toUpperCase()}{' '}
                          {item.id === 'paymentTerms' ? (
                            <Box>
                              <BorderColorIcon
                                sx={{
                                  fontSize: '15px',
                                  color: 'GrayText',
                                  marginLeft: '1rem',
                                  marginBottom: '5px',
                                }}
                                onClick={handleClick}
                              />
                              <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleEditDescription(index);
                                    handleClose();
                                  }}
                                  sx={{
                                    fontSize: '12px',
                                    color: 'gray',
                                    gap: '5px',
                                    borderBottom: '1px solid gray',
                                  }}
                                >
                                  <BorderColorIcon fontSize="12px" /> Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  sx={{
                                    fontSize: '12px',
                                    color: 'gray',
                                    gap: '5px',
                                  }}
                                >
                                  {' '}
                                  <GridViewIcon fontSize="12px" />
                                  Template 1
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  sx={{
                                    fontSize: '12px',
                                    color: 'gray',
                                    gap: '5px',
                                  }}
                                >
                                  <GridViewIcon fontSize="12px" />
                                  Template 2
                                </MenuItem>
                              </Menu>
                            </Box>
                          ) : null}
                          {item.id === 'conditionsTerms' ? (
                            <>
                              <BorderColorIcon
                                fontSize="12px"
                                sx={{
                                  marginLeft: '10px',
                                  color: 'gray',
                                  border: '1px solid green',
                                }}
                                onClick={() => setOpenCon(!openCon)}
                              />
                              {openCon ? (
                                <div
                                  style={{
                                    position: 'relative',
                                    width: '250px',
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor: '#fff',
                                      padding: '15px',
                                      position: 'absolute',
                                      top: '0',
                                      left: '-20px',
                                      boxShadow:
                                        '2px 2px 6px rgba(0, 0, 0, 0.6)',
                                    }}
                                  >
                                    <p
                                      onClick={() => {
                                        handleEditDescription(index);
                                        setOpenCon(false);
                                      }}
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: '13px',
                                        borderBottom: '1px solid black',
                                        cursor: 'pointer',
                                        color: 'GrayText',
                                      }}
                                    >
                                      {' '}
                                      <BorderColorIcon fontSize="12px" /> Edit
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: '13px',
                                        cursor: 'pointer',
                                        color: 'GrayText',
                                      }}
                                    >
                                      {' '}
                                      <GridViewIcon fontSize="12px" /> Template
                                      1
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: 'normal',
                                        fontSize: '13px',
                                        cursor: 'pointer',
                                        color: 'GrayText',
                                      }}
                                    >
                                      {' '}
                                      <GridViewIcon fontSize="12px" /> Template
                                      2
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </Typography>
                        <Typography sx={ccri}>
                          {item.isEditing ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <TextField
                                id="outlined-multiline-flexible"
                                multiline
                                maxRows={4}
                                sx={{ width: '1000px' }}
                                value={item.contentPara}
                                onChange={(event) =>
                                  handleDescriptionChange(index, event)
                                }
                              />{' '}
                              <button
                                type="button"
                                className="create-btn"
                                onClick={() => handleSaveDescription(index)}
                              >
                                Save
                              </button>
                            </div>
                          ) : (
                            item.contentPara
                          )}
                        </Typography>
                        <Typography sx={ccri}>{item.clientName}</Typography>
                        <Typography sx={ccri}>{item.clientSign}</Typography>
                        <Typography sx={ccri}>{item.clientTittle}</Typography>
                        <Typography sx={ccri}>{item.clientDate}</Typography>

                        {item.timeLinePic ? (
                          <img
                            alt=""
                            src={item.timeLinePic}
                            width="90%"
                            height="400px"
                            style={{ paddingLeft: '25px', marginTop: '10px' }}
                          />
                        ) : (
                          <Typography sx={ccri}>{item.timeLinePic}</Typography>
                        )}

                        <ul>
                          {Array.isArray(item.deliverables) &&
                          item.deliverables.length
                            ? item.deliverables.map((nestedItem) => (
                                <li
                                  key={`${item.id}-${nestedItem.deliverable_name}`}
                                >
                                  <u
                                    style={{
                                      color: '#03071E',
                                      fontFamily: 'Open Sans',
                                      fontSize: '0.75rem',
                                      fontWeight: '400',
                                      textUnderlinePosition: 'under',
                                      padding: '0px',
                                      marginTop: '15px',
                                    }}
                                  >
                                    {nestedItem.deliverable_name}
                                  </u>
                                  <p
                                    style={{
                                      color: '#03071E',
                                      fontFamily: 'Open Sans',
                                      fontSize: '0.75rem',
                                      fontWeight: '400',
                                      padding: '0px',
                                    }}
                                  >
                                    {nestedItem.title ? nestedItem.title : null}
                                  </p>
                                </li>
                              ))
                            : null}
                        </ul>
                        <table
                          style={{
                            width: '90%',
                            marginLeft: '20px',
                            borderCollapse: 'collapse',
                          }}
                        >
                          <thead>
                            {Array.isArray(item.tableHeads) &&
                            item.tableHeads.length
                              ? item.tableHeads.map((header) => (
                                  <tr key={header.two}>
                                    <th
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                      }}
                                    >
                                      {header.one}
                                    </th>
                                    <th
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                      }}
                                    >
                                      {header.two}
                                    </th>

                                    {item.tableData &&
                                    item.tableData[0].deliverable_multiplier
                                      ? item.tableData[0].deliverable_multiplier
                                        ? item.tableData[0].deliverable_multiplier.map(
                                            (v) => {
                                              return (
                                                v.visible && (
                                                  <th
                                                    style={{
                                                      border: '1px solid black',
                                                      padding: '8px',
                                                    }}
                                                  >
                                                    {' '}
                                                    {v.label}{' '}
                                                  </th>
                                                )
                                              );
                                            },
                                          )
                                        : null
                                      : null}
                                    <th
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                      }}
                                    >
                                      {header.five}
                                    </th>
                                  </tr>
                                ))
                              : null}
                          </thead>

                          <tbody>
                            {Array.isArray(item.tableData) &&
                            item.tableData.length
                              ? item.tableData.map((tableItem) => (
                                  <tr
                                    key={tableItem.deliverable_name}
                                    style={{
                                      border: '1px solid black',
                                      padding: '8px',
                                      color: '#000',
                                      height: '50px',
                                    }}
                                  >
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        width: '200px',
                                      }}
                                    >
                                      {tableItem.deliverable_name}
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      ${tableItem.user_price}
                                    </td>
                                    {tableItem.deliverable_multiplier
                                      ? Array.isArray(
                                          tableItem.deliverable_multiplier,
                                        ) &&
                                        tableItem.deliverable_multiplier.length
                                        ? tableItem.deliverable_multiplier.map(
                                            (veed) => {
                                              return (
                                                veed.visible && (
                                                  <td
                                                    style={{
                                                      border: '1px solid gray',
                                                      padding: '8px',
                                                      textAlign: 'end',
                                                    }}
                                                  >
                                                    {veed.rate}%
                                                    <p
                                                      style={{
                                                        fontSize: '10px',
                                                        color: 'GrayText',
                                                      }}
                                                    >
                                                      {`${veed.operation} $${tableItem.user_price * (veed.rate / 100)}`}
                                                    </p>
                                                  </td>
                                                )
                                              );
                                            },
                                          )
                                        : null
                                      : null}
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      $
                                      {tableItem.deliverable_multiplier
                                        ? calculateReduction(
                                            tableItem.deliverable_multiplier,
                                            Number(tableItem.user_price),
                                          ).toFixed(2)
                                        : tableItem.netTotal}
                                    </td>
                                  </tr>
                                ))
                              : null}
                            {item.tableSubTotal ? (
                              <tr
                                style={{
                                  border: '1px solid black',
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  height: '50px',
                                }}
                              >
                                <td
                                  style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    width: '200px',
                                  }}
                                >
                                  {item.tableSubTotal}
                                </td>

                                {/* eslint-disable-next-line */}
                                <td></td>

                                {item.tableData &&
                                item.tableData[0].deliverable_multiplier
                                  ? item.tableData[0].deliverable_multiplier
                                    ? item.tableData[0].deliverable_multiplier.map(
                                        (v) => {
                                          // eslint-disable-next-line
                                          return v.visible && <td> </td>;
                                        },
                                      )
                                    : null
                                  : null}
                                <td
                                  style={{ textAlign: 'end', padding: '8px' }}
                                >
                                  ${' '}
                                  {calculateTotalNet(
                                    dileverable.map((item) => ({
                                      ...item,
                                      netTotal: item.user_price,
                                    })),
                                  )}
                                </td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>

                        <table
                          style={{
                            width: '90%',
                            marginLeft: '20px',
                            borderCollapse: 'collapse',
                          }}
                        >
                          <thead>
                            <tr>
                              {Array.isArray(item.hardCostHeads) &&
                              item.hardCostHeads.length
                                ? item.hardCostHeads.map((header) => (
                                    <th
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                      }}
                                      key={header.two}
                                    >
                                      {header}
                                    </th>
                                  ))
                                : null}
                            </tr>
                          </thead>

                          <tbody>
                            {Array.isArray(item.hardCostData) &&
                            item.hardCostData.length
                              ? item.hardCostData.map((costItem) => (
                                  <tr
                                    key={costItem.hardcost_label}
                                    style={{
                                      border: '1px solid black',
                                      padding: '8px',
                                      fontSize: '13px',
                                      height: '50px',
                                    }}
                                  >
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        width: '200px',
                                      }}
                                    >
                                      {costItem.hardcost_label}
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      ${costItem.price_to_customer}
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      {costItem.multiplier
                                        ? costItem.multiplier
                                        : '0'}
                                      %
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      {' '}
                                      {costItem.discount
                                        ? costItem.discount
                                        : '0'}
                                      %
                                    </td>
                                    <td
                                      style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        textAlign: 'end',
                                      }}
                                    >
                                      ${costItem.price_to_customer}
                                    </td>
                                  </tr>
                                ))
                              : null}
                            {item.hardCostSub ? (
                              <tr
                                style={{
                                  border: '1px solid black',
                                  fontSize: '18px',
                                  fontWeight: 'bold',
                                  height: '50px',
                                }}
                              >
                                <td
                                  style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    width: '200px',
                                  }}
                                >
                                  {item.hardCostSub}
                                </td>

                                <td aria-label="-" />
                                <td aria-label="-" />
                                <td aria-label="-" />

                                <td
                                  aria-label="-"
                                  style={{
                                    border: '1px solid black',
                                    padding: '8px',
                                    textAlign: 'end',
                                  }}
                                />
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

const act = {
  color: '#711fff',
  fontWeight: '500',
  fontSize: '1.2rem',
};

const inAct = {
  color: 'gray',
  fontWeight: '500',
  fontSize: '1.2rem',
};

function ViewSwitcher({ view, setView }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        marginBottom: '2rem',
      }}
    >
      <button
        type="button"
        className="noBorder"
        style={view === 'general' ? act : inAct}
        onClick={() => setView('general')}
      >
        General Settings
      </button>
      <button
        type="button"
        className="noBorder"
        style={view === 'team' ? act : inAct}
        onClick={() => setView('team')}
      >
        Team Members
      </button>

      <button
        type="button"
        className="noBorder"
        style={view === 'client_info' ? act : inAct}
        onClick={() => setView('client_info')}
      >
        Client Info
      </button>

      <button
        type="button"
        className="noBorder"
        style={view === 'estimate' ? act : inAct}
        onClick={() => setView('estimate')}
      >
        Estimate
      </button>
    </Box>
  );
}

function IntroRow({ user, currentDate }) {
  const companyData = {
    name: `${user.first_name} ${user.last_name}`,
    company_name: 'AliTech Solutions SMC.',
    company_address: 'Office No 3, Second Floor Saphire Technology Park',
    company_city: 'Jamshoro, Sindh',
  };

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ marginTop: '1.5rem' }}
    >
      <IntroCol companyData={companyData} currentDate={currentDate} />
      <CulminationCol />
    </Grid>
  );
}

function IntroCol({ companyData, currentDate }) {
  const { name, company_name, company_address, company_city } = companyData;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Typography sx={{ ...ccri, margin: '1rem, 0' }}>{currentDate}</Typography>

      <Typography sx={ccri} style={{ marginTop: '10px' }}>
        {name}
      </Typography>
      <Typography sx={ccri}>{company_name}</Typography>
      <Typography sx={ccri}>{company_address}</Typography>
      <Typography sx={{ ...ccri, paddingBottom: '50px' }}>
        {company_city}
      </Typography>
    </Box>
  );
}

function CulminationCol() {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
    >
      <Typography
        sx={{
          color: '#03071E',
          fontSize: '22px',
          fontWeight: '700',
        }}
        className="fstxt"
      >
        Estimate No #10111
      </Typography>
      <Typography sx={ccri}>Version 1</Typography>
    </Box>
  );
}

function Estimate({ dileverable, formData, setDeliverable }) {
  const currentDate = moment().format('DD MMM, YYYY');
  const user = useSelector(
    (state) => state.userData?.userData ?? { first_name: '', last_name: '' },
  );

  return (
    <Grid container justifyContent="center">
      <Grid
        id="divToPrint"
        item
        container
        xl={9}
        lg={9}
        md={9}
        sm={9}
        direction="column"
        alignItems="flex-start"
        sx={{
          backgroundColor: '#fff',
          padding: '0.88rem 1rem',
          border: '1px solid #d9d9d9',
          borderRadius: '2px',
        }}
      >
        <IntroRow currentDate={currentDate} user={user} />
        <MileStones
          formData={formData}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
        />
      </Grid>
    </Grid>
  );
}

const transition = 'all 0.3s ease-in-out';
const fontFamily = 'Open Sans';

function AcDate({
  formData,
  setDeliverable,
  dileverable,
  setFormData,
  setIsTyping,
}) {
  const [active, setActive] = useState(false);
  const [value1, setValue1] = useState([null, null]);

  const formDataTargetStart =
    formData && formData.target_start && formData.target_start?.endsWith('Z')
      ? formData.target_start.split('T')
      : null;

  const formDataFinishDate =
    formData.project_type !== 'retainer' &&
    formData &&
    formData.finish_date &&
    formData.finish_date.endsWith('Z')
      ? formData.finish_date.split('T')
      : null;

  const [dateRange, setDateRange] = useState();

  const handleDateRange = (v) => {
    const utcDate = dayjs(v).utc().toISOString();
    setDateRange(utcDate);
  };

  const handleClose = () => {
    setValue1([null, null]);
    setDateRange(false);
  };

  const access_token = useSelector((state) => state.auth.user.access);
  const [endOrStart, setEndOrStart] = useState();

  async function handleRangeChange() {
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;

    const res = await fetch(`${ENDPOINT}/api/project/update/${formData.id}/`, {
      method: 'PUT',
      headers: {
        Authorization: `JWT ${access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...shallowCopy,
        target_start:
          endOrStart === 'start'
            ? dayjs(dateRange).toISOString()
            : formData?.target_start,
        finish_date:
          endOrStart === 'end'
            ? dayjs(dateRange).toISOString()
            : formData?.finish_date,

        project_deliverable: dileverable?.map((item) => {
          const durationInMilliseconds =
            new Date(item.deliverable_enddate) -
            new Date(item.deliverable_startdate);
          const deliverable_duration =
            durationInMilliseconds / (24 * 60 * 60 * 1000);

          const dynamicChange =
            new Date(item.deliverable_startdate) -
            new Date(formData.target_start);
          const dynamic_duration = dynamicChange / (24 * 60 * 60 * 1000);

          // eslint-disable-next-line
          delete item.deliverable_multiplier;
          // eslint-disable-next-line
          delete item.task_deliverable;

          return {
            ...item,
            deliver_id: item.id,

            deliverable_startdate:
              endOrStart === 'start'
                ? new Date(
                    new Date(dateRange).setDate(
                      new Date(dateRange).getDate() + dynamic_duration,
                    ),
                  ).toISOString()
                : item.deliverable_startdate,
            deliverable_enddate:
              endOrStart === 'start'
                ? new Date(
                    new Date(dateRange).setDate(
                      new Date(dateRange).getDate() +
                        dynamic_duration +
                        deliverable_duration,
                    ),
                  ).toISOString()
                : item.deliverable_enddate,
            deliverable_worktype: item.deliverable_worktype.map((wtt) => ({
              ...wtt,
              hours: wtt.hours ? Number(wtt.hours) : null,
            })),
          };
        }),
      }),
    });

    if (res.ok) {
      const resGet = await fetch(
        `${ENDPOINT}/api/project/detail/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );

      const dataGet = await resGet.json();
      if (!resGet.ok) {
        return;
      }

      setFormData(dataGet);
      setValue1(dateRange);
      setDateRange(false);

      if (
        dataGet &&
        Array.isArray(dataGet.deliverable_project) &&
        dataGet.deliverable_project.length
      ) {
        setDeliverable(dataGet.deliverable_project);
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        gap: '0.4rem',
        height: { xl: '230px', lg: '200px', md: '190px', sm: '190px' },
        justifyContent: 'space-between',
        width: { xl: '80%', lg: '95%', md: '97%', sm: '98%' },
        position: 'relative',
      }}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Box
        sx={{
          backgroundColor: active ? '#008067' : '#00b28f',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '1.2rem 0rem',
          borderRadius: '4px',
          textAlign: 'center',
          transition: '0.5s ease',
          fontWeight: '600',
          flexDirection: 'column',
          width: '100%',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          color: '#fff',
          gap: '.6rem',
        }}
      >
        <DateRangeIcon sx={{ fill: '#fff', fontSize: '2rem' }} />
        <p style={{ color: '#fff', fontSize: '1.1rem' }}>TIMELINE</p>
      </Box>

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '10px',
          width: '100%',
          justifyContent: 'space-around',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: '5px',
            backgroundColor: '#d4e8fb',
            padding: '0.88rem',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: '#000',
              fontSize: { xl: '14px', lg: '13px', md: '12px', sm: '12px' },
            }}
          >
            Start Date
          </Typography>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <CalendarMonthOutlinedIcon
              sx={{
                cursor: 'pointer',
                fontSize: { xl: '25px', lg: '23px', md: '21px', sm: '20px' },
              }}
              onClick={() => {
                setDateRange(
                  formData.target_start
                    ? dayjs(formData.target_start)
                    : new Date(),
                );
                setValue1(null);
                setIsTyping(true);
                setEndOrStart('start');
              }}
            />
            <Typography
              sx={{
                marginTop: '0px',
                fontWeight: 'normal',
                color: '#000',
                fontSize: { xl: '14px', lg: '13px', md: '12px', sm: '12px' },
              }}
            >
              {formDataTargetStart && formDataTargetStart[0]
                ? dayjs(formDataTargetStart).format('MMM DD, YYYY')
                : ''}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: '5px',
            backgroundColor: '#d4e8fb',
            padding: '0.88rem',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: '#000',
              fontSize: { xl: '14px', lg: '13px', md: '12px', sm: '12px' },
            }}
          >
            Finish Date
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <CalendarMonthOutlinedIcon
              sx={{
                cursor: 'pointer',
                fontSize: { xl: '25px', lg: '23px', md: '21px', sm: '20px' },
              }}
              onClick={() => {
                setValue1(null);
                setIsTyping(true);
                setDateRange(
                  formData.finish_date
                    ? dayjs(formData.finish_date)
                    : new Date(),
                );
                setEndOrStart('end');
              }}
            />

            <Typography
              sx={{
                marginTop: '0px',
                fontWeight: 'normal',
                color: '#000',
                fontSize: { xl: '14px', lg: '13px', md: '12px', sm: '12px' },
              }}
            >
              {formData.project_type !== 'retainer' &&
              formDataFinishDate &&
              formDataFinishDate[0]
                ? dayjs(formDataFinishDate).format('MMM DD, YYYY')
                : ''}
            </Typography>
          </div>
        </div>
      </div>
      {!value1 && (
        <Box
          sx={{
            position: 'fixed' /* Stay in place */,
            zIndex: 99 /* Sit on top */,
            paddingTop: '100px' /* Location of the box */,
            left: 0,
            top: 0,
            width: '100%' /* Full width */,
            height: '100%' /* Full height */,
            overflow: 'auto' /* Enable scroll if needed */,
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '99999',
              background: '#fff',
            }}
          >
            <Box
              align="center"
              sx={{
                width: 'auto',
                height: 'auto',
                px: '30px',
                display: { xs: 'block', md: 'flex' },
              }}
            >
              <Box sx={{ mx: 1, my: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateCalendar
                    value={dayjs(dateRange)}
                    onChange={(newValue) => handleDateRange(newValue)}
                    sx={{
                      '& .css-10wpov9-MuiTypography-root ': {
                        fontWeight: '700 !important',
                      },
                      '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval':
                        {
                          color: '#03071E !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                        backgroundColor: '#310085 !important',
                        color: 'white !important',
                        borderRadius: '0px !important',
                      },
                      '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                        {
                          backgroundColor: '#711FFF !important',
                          color: 'white !important',
                          borderRadius: '0px !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                        {
                          backgroundColor: '#711FFF !important',
                          color: 'white !important',
                          borderRadius: '0px !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected':
                        {
                          backgroundColor: '#711FFF !important',
                          color: 'white !important',
                          borderRadius: '0px !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                        {
                          backgroundColor: '#310085 !important',
                          color: 'white !important',
                          opacity: 'inherit !important',
                          borderRadius: '0px',
                          border: 'none !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                        {
                          backgroundColor: '#711FFF !important',
                          color: 'white !important',
                          borderRadius: '0px !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                        {
                          backgroundColor: '#310085 !important',
                          color: '#e569db !important',
                          borderRadius: '0px !important',
                          fontSize: '16px !important',
                          fontWeight: 500,
                        },
                      '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)':
                        {
                          borderRight: 'none !important',
                        },
                      '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                        flexDirection: 'column !important',
                      },

                      '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                        {
                          color: 'inherit',
                          fontSize: '16px !important',
                          opacity: 'inherit',
                          fontWeight: 500,
                        },
                      '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                        {
                          backgroundColor: 'inherit',
                          color: 'black !important',
                          borderRadius: '50%',
                          border: '1px solid gray',
                          fontSize: '16px',
                          fontWeight: 500,
                        },
                      '& .MuiTypography-subtitle1': {
                        color: ' #03071E !important',
                        fontSize: '16px',
                        fontWeight: 700,
                      },
                      '& .MuiDayCalendar-weekDayLabel': {
                        color: ' #03071ECC !important',
                        fontSize: '16px',
                        fontWeight: 400,
                      },
                      '& .MuiSvgIcon-fontSizeInherit': {
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '50%',
                      },
                      '& .MuiPickersDay-today': {
                        backgroundColor: !dateRange && '#711FFF',
                        color: !dateRange && 'white',
                        borderRadius: !dateRange && '0px',
                        fontSize: !dateRange && '16px',
                        fontWeight: !dateRange && 500,
                      },
                      '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                        {
                          backgroundColor: '#711FFF',
                          color: 'white',
                          borderRadius: '0px',
                          fontSize: '16px',
                          fontWeight: 500,
                        },
                      '& .css-gtjfra-MuiDateRangePickerDay-root': {
                        backgroundColor: '#310085',
                        color: 'white !important',
                        borderRadius: '0px',
                        fontSize: '16px',
                        fontWeight: 500,
                      },
                      '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                        backgroundColor: '#310085',
                        color: 'white !important',
                        borderRadius: '0px',
                        fontSize: '16px',
                        fontWeight: 500,
                      },
                    }}
                  />
                </LocalizationProvider>

                <Box
                  sx={{
                    display: 'flex',
                    my: 2,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box align="right" sx={{ display: 'flex', gap: '10px' }}>
                    <Button
                      variant="text"
                      sx={{
                        textDecoration: 'inherit',
                        color: 'black',
                        width: { xs: 'auto', lg: '100px' },
                        mr: 1,
                        ml: 1,
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        textDecoration: 'inherit',
                        backgroundColor: '#711FFF',
                        width: { xs: 'auto', lg: '100px' },
                        mr: 1,
                        '&:hover': {
                          backgroundColor: '#711FFF',
                        },
                      }}
                      onClick={() => {
                        handleRangeChange();
                      }}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function ProComplete({ setSureComplete, currentTarget }) {
  const color = '#0B88F9';
  const [active, setActive] = useState(false);

  const end =
    currentTarget.project_type !== 'retainer' &&
    currentTarget &&
    currentTarget.finish_date &&
    currentTarget.finish_date.endsWith('Z')
      ? currentTarget.finish_date.split('T')
      : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        gap: '0.4rem',
        height: { xl: '230px', lg: '200px', md: '190px', sm: '190px' },
        justifyContent: 'space-between',
        width: { xl: '80%', lg: '95%', md: '97%', sm: '98%' },
      }}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => setSureComplete(true)}
    >
      <Box
        sx={{
          backgroundColor: active ? '#112789' : color,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: {
            xl: '1rem 0.6rem',
            lg: '.7rem .3rem',
            md: '.5rem .2rem',
            sm: '.3rem .1rem',
          },
          borderRadius: '8px',
          transition: '0.5s ease',
          width: '100%',
        }}
      >
        <DoneOutlineIcon sx={{ fill: '#5EE690', marginBottom: '15px' }} />
        <Typography
          sx={{
            fontSize: '1.1rem',
            fontWeight: '600',
            color: '#fff',
            paddingBottom: '1rem',
          }}
        >
          Job IS COMPLETE
        </Typography>
        <Box
          sx={{
            width: '100%',
            listStyle: 'disc',
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #fff',
            padding: '0.72rem',
            textTransform: 'uppercase',
            color: '#fff',
            fontSize: { xl: '12px', lg: '10px', md: '9px', sm: '9px' },
          }}
        >
          <li>Job status gets marked as complete</li>
          <li>You would be able to see all Job insights</li>
          <li>You would be still paying for it</li>
        </Box>
      </Box>
      <Box
        sx={{
          transition,
          backgroundColor: '#E9ECEF',
          padding: '0.88rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '4px',
          width: '100%',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          fontSize: { xl: '12px', lg: '10px', md: '9px', sm: '9px' },
        }}
      >
        {currentTarget.finish_date && end
          ? ` YOUR ACTUAL END DATE WAS SET AT ${(end && end[0]
              ? dayjs(end[0]).format('MMMM DD, YYYY')
              : ''
            ).toUpperCase()}`
          : 'NO END DATE WAS SELECTED.'}
      </Box>
    </Box>
  );
}

function ProArchieve({ setSureArchive }) {
  const color = '#711fff';
  const [active, setActive] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        height: { xl: '230px', lg: '200px', md: '190px', sm: '190px' },
        justifyContent: 'space-between',
        width: { xl: '80%', lg: '95%', md: '97%', sm: '98%' },
      }}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onClick={() => setSureArchive(true)}
    >
      <Box
        sx={{
          backgroundColor: active ? '#4d14b1' : color,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: { xl: '1.44rem 0.6rem', lg: '.7rem .3rem' },
          borderRadius: '8px',
          height: '100%',
          transition: '0.5s ease',
        }}
      >
        <ArchiveIcon
          sx={{
            fill: '#fff',
            marginBottom: '10px',
            height: '35px',
            width: '35px',
          }}
        />
        <Typography
          sx={{
            fontSize: '1.1rem',
            fontWeight: '600',
            textTransform: 'uppercase',
            color: '#fff',
            paddingBottom: '1rem',
          }}
        >
          archive job
        </Typography>
        <Box
          sx={{
            width: '100%',
            listStyle: 'disc',
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #fff',
            padding: '0.88rem',
            textTransform: 'uppercase',
            color: '#fff',
            fontSize: { xl: '12px', lg: '10px', md: '9px', sm: '9px' },
          }}
        >
          <li>Job status gets marked as complete & archived</li>
          <li>You would be able to see only kpi&apos;s and report </li>
          <li>you would NOT BE paying for it</li>
        </Box>
      </Box>
    </Box>
  );
}

function GeneralSettings({
  formData,
  setFormData,
  setDeliverable,
  dileverable,
  setIsTyping,
}) {
  const [sureComplete, setSureComplete] = useState(false);
  const [sureArchive, setSureArchive] = useState(false);

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ marginTop: '4rem', height: '100vh' }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            padding: {
              xl: '0.88rem 1.88rem',
              lg: '0.78rem 1.68rem',
              md: '0.68rem 1rem',
              sm: '0.58rem .58rem',
            },
            border: '1.5px solid #d9d9d9',
            borderRadius: '8px',
            flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'row' },
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            sx={{
              width: '33%',
              borderRight: '.5px solid #d9d9d9',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AcDate
              formData={formData}
              setFormData={setFormData}
              setDeliverable={setDeliverable}
              dileverable={dileverable}
              setIsTyping={setIsTyping}
            />
          </Box>
          <Box sx={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
            <ProComplete
              setSureComplete={setSureComplete}
              currentTarget={formData}
            />
          </Box>

          <Box sx={{ width: '33%', display: 'flex', justifyContent: 'center' }}>
            <ProArchieve
              setSureArchive={setSureArchive}
              currentTarget={formData}
            />
          </Box>
        </Grid>
      </Grid>
      {sureComplete && (
        <MarkComplete
          setSureComplete={setSureComplete}
          currentTarget={formData}
          setIsTyping={setIsTyping}
        />
      )}

      {sureArchive && (
        <MarkArchieve
          setSureArchive={setSureArchive}
          currentTarget={formData}
        />
      )}
    </>
  );
}

function AddTeam({ setFormData, formData, userTeam }) {
  const team = useSelector((state) => state?.userTeam?.team?.member ?? []);
  const teamOptions = team.filter(
    (mem) => !userTeam.some((ta) => Number(ta.id) === mem.id),
  );

  const access_token = useSelector((state) => state.auth.user.access);
  const finalTeamOpts =
    Array.isArray(teamOptions) && teamOptions.length
      ? teamOptions.filter((ele) => ele.first_name !== 'First Name')
      : [];

  const [newMembers, setNewMembers] = useState();
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  }, [error]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!newMembers || newMembers.length === 0) {
      setError('Please select new members');
    } else {
      setLoading(true);
      const res = await fetch(
        `${ENDPOINT}/api/project/update/${formData.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            members: newMembers.map((eachMem) => eachMem.email),
          }),
        },
      );
      const data = await res.json();

      if (!res.ok) {
        Object.entries(data).forEach(([key, value]) => {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            setError(value);
            setLoading(false);
          }
        });
      } else {
        const resGet = await fetch(
          `${ENDPOINT}/api/project/detail/${formData?.unique_id}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${access_token}`,
            },
          },
        );
        const dataGet = await resGet.json();
        if (!resGet.ok) {
          return;
        }

        setFormData(dataGet);
        setNewMembers();
        setLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#fff',
        padding: '1.88rem 1.44rem',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#f8f9fa',
          borderRadius: '8px',
          padding: '0.88rem 0.44rem',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontWeight: '600',
              fontSize: '1.2rem',
              color: '#000',
            }}
          >
            Add Your Team Members
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontWeight: '400',
              fontSize: '1rem',
            }}
          >
            Add Your Team Members for this Job
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <SearchIcon style={{ curser: 'pointer' }} />
          <Autocomplete
            multiple
            options={finalTeamOpts}
            // eslint-disable-next-line
            PopperComponent={(props) => (
              <Popper
                {...props}
                sx={{
                  overflowY: 'none', // Enable vertical scrolling
                  scrollbarColor: 'transparent transparent', // For Firefox
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },

                  // Styles for Microsoft Edge
                  '&::-ms-scrollbar': {
                    width: 0,
                    background: 'transparent transparent',
                  },
                }}
              />
            )}
            getOptionLabel={(option) => option.first_name}
            noOptionsText="You dont have any team members available outside of this project"
            value={newMembers}
            onChange={(_, newVal) => setNewMembers(newVal)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Team Members"
                variant="standard"
                color="secondary"
                InputProps={{
                  ...params.InputProps,
                  style: { width: 300 },
                }}
              />
            )}
          />
        </Box>
      </Box>
      {error && <Alert severity="info">{error}</Alert>}
      <button
        type="button"
        className="create-btn"
        style={{ alignSelf: 'flex-end', margin: '1rem 0' }}
        onClick={handleSubmit}
      >
        {loading ? (
          <Box sx={{ width: '30px', height: '30px', margin: '0rem 0.1rem' }}>
            <PreLoader1 />
          </Box>
        ) : (
          'Add'
        )}{' '}
      </button>
    </Box>
  );
}

function AddColab({ formData }) {
  const colab = useSelector((state) => state?.Misc?.collabs ?? []);
  const colabOptions = colab.filter((mem) =>
    Array.isArray(formData.collaborator) && formData.collaborator.length
      ? !formData.collaborator.includes(mem.id)
      : true,
  );

  const finalColabOptions =
    Array.isArray(colabOptions) && colabOptions.length
      ? colabOptions
          .map((opt) => ({
            ...opt.collab,
          }))
          .filter((agn) => agn.first_name !== 'first_name')
      : [];

  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [newMembers, setNewMembers] = useState();
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  }, [error]);

  const handleSubmit = async () => {
    if (!Array.isArray(newMembers) || !newMembers.length) {
      setError('Please select new collaborators');
    } else {
      const res = await fetch(
        `${ENDPOINT}/api/project/update/${formData.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            collaborators: newMembers.map((eachMem) => eachMem.id),
          }),
        },
      );
      const data = await res.json();
      if (!res.ok) {
        // eslint-disable-next-line
        Object.entries(data).forEach(([_, value]) => {
          setError(value);
        });
      } else {
        dispatch(getProjects({ access_token }));
      }
    }
  };

  return (
    <Box
      sx={{
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        backgroundColor: '#fff',
        padding: '1.88rem 1.44rem',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#f2f9ff',
          borderRadius: '8px',
          padding: '0.88rem 0.44rem',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontWeight: '600',
              fontSize: '1.2rem',
              color: '#023fe3',
            }}
          >
            Add Collaborators
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontWeight: '400',
              fontSize: '1rem',
            }}
          >
            Add Your New Collaborator for this job
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <SearchIcon style={{ curser: 'pointer' }} />
          <Autocomplete
            multiple
            options={finalColabOptions}
            getOptionLabel={(option) => option.first_name}
            noOptionsText="You dont have any Collaborators available outside of this job"
            onChange={(_, newVal) => setNewMembers(newVal)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Collaborator"
                variant="standard"
                color="secondary"
                InputProps={{
                  ...params.InputProps,
                  style: { width: 300 },
                }}
              />
            )}
          />
        </Box>
      </Box>
      <button
        type="button"
        className="create-btn"
        style={{ alignSelf: 'flex-end', margin: '1rem 0' }}
        onClick={handleSubmit}
      >
        Add
      </button>
    </Box>
  );
}

function LeadBox({ Leads }) {
  return Array.isArray(Leads) && Leads.length ? (
    <>
      {[Leads[0]].map((item) => (
        <Box
          key={item.id}
          sx={{
            display: 'flex',
            alingItems: 'center',
            justifyContent: 'space-between',
            p: '1.44rem 1.88rem',
            borderRadius: '8px',
            border: '1px solid #711fff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#D2B8FF',
                borderRadius: '8px',
                p: '1rem',
                color: '#000',
                textAlign: 'center',
                m: '0 1rem',
              }}
            >
              LEAD
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Avatar src={item.avatar} alt={item.first_name} />
              <Typography sx={{ fontFamily, color: '#03071E' }}>
                {item.first_name}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  ) : (
    <Alert severity="info" sx={{ width: '100%' }}>
      {' '}
      No Lead Available{' '}
    </Alert>
  );
}

function TeamSettings({ formData, setFormData }) {
  const userTeam = formData.project_subteam[0].members;
  const tasks = useSelector((state) => state.projects.tasks).filter(
    (item) => item?.task_project === formData.id,
  );
  const Leadds = userTeam.filter((ele) => ele.is_lead);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
      sx={{ width: '100%', m: '1rem 0', gap: '1rem' }}
    >
      <AddTeam
        formData={formData}
        userTeam={userTeam}
        setFormData={setFormData}
      />
      <Grid item sx={{ m: '3rem 0', width: '100%' }}>
        <LeadBox Leads={Leadds} />
      </Grid>

      <Grid
        item
        container
        direction="column"
        sx={{ m: '0rem', width: '100%', gap: '1rem' }}
      >
        {userTeam.map((item) => {
          const userTasks = tasks.filter((tsk) =>
            tsk.assignee_task?.some(
              (ele) => Number(ele.assignee_user) === Number(item.id),
            ),
          );

          const userHours = userTasks.reduce((acc, ech) => {
            const hrs = ech.assignee_task.find(
              (user) => user.assignee_user === Number(item.id),
            )?.hours;

            return acc + Number(hrs);
          }, 0);

          const usersTimeTracked = userTasks.filter((ele) =>
            ele.time_tracking.some(
              (trackedTime) => trackedTime.created_by.id === Number(item.id),
            ),
          );

          const userObt = usersTimeTracked.reduce((acc, trk) => {
            const get = calculateTotalDurationObtained(trk.time_tracking);
            return acc + get;
          }, 0);

          return (
            <Box
              key={item.id}
              sx={{
                display: 'flex',
                alingItems: 'center',
                justifyContent: 'space-between',
                p: '1.44rem 1.88rem',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '14%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    width: '150px',
                  }}
                >
                  <Avatar src={item.avatar} alt={item.first_name} />
                  <Typography sx={{ fontFamily, color: '#03071E' }}>
                    {item.first_name}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '80%',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#5EE690',
                    borderRadius: '8px',
                    p: '1rem',
                    color: '#000',
                    textAlign: 'center',
                  }}
                >
                  MEMBER
                </Box>
                <button
                  type="button"
                  // eslint-disable-next-line
                  variant="success"
                  className="btn-task"
                  style={{ margin: '0 1rem' }}
                >
                  <span className="number-of-task">
                    {Array.isArray(userTasks) && userTasks.length
                      ? userTasks.length
                      : '0'}
                  </span>{' '}
                  Tasks
                </button>
                <div className="inner-summery">
                  <span className="span">Level of Effort(LOE)</span>
                  <ProgressBar
                    sx
                    now={Math.round(userObt)}
                    className={getVariant(
                      Math.round(userObt),
                      Math.round(userHours),
                      'hour',
                    )}
                    min={0}
                    max={Math.round(userHours)}
                  />

                  <span className="span">
                    {Math.round(userObt)}
                    <i>/</i>
                    {Math.round(userHours)}
                  </span>
                </div>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
}

function CollabSettings({ formData, setFormData }) {
  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      sx={{ width: '100%', m: '1rem 0' }}
    >
      <AddColab formData={formData} setFormData={setFormData} />
    </Grid>
  );
}

function Settings({
  formData,
  setFormData,
  dileverable,
  setDeliverable,
  setIsTyping,
}) {
  const [view, setView] = useState('general');
  const access_token = useSelector((state) => state.auth.user.access);
  return (
    <Grid
      sx={{
        backgroundColor: '#fff',
        border: '1px solid #d9d9d9',
        padding: '1rem 1.5rem',
        borderRadius: '8px',
      }}
      container
      direction="column"
    >
      <ViewSwitcher view={view} setView={setView} />
      {view === 'general' && (
        <GeneralSettings
          access_token={access_token}
          formData={formData}
          setFormData={setFormData}
          setDeliverable={setDeliverable}
          dileverable={dileverable}
          setIsTyping={setIsTyping}
        />
      )}
      {view === 'team' && (
        <TeamSettings formData={formData} setFormData={setFormData} />
      )}
      {view === 'collab' && (
        <CollabSettings formData={formData} setFormData={setFormData} />
      )}

      {view === 'estimate' && (
        <Estimate
          formData={formData}
          dileverable={dileverable}
          setDeliverable={setDeliverable}
        />
      )}
    </Grid>
  );
}
export default Settings;

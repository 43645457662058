import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Drawer from '@mui/material/Drawer';

import { PreLoading } from '../People/EditContact';
import defaultCompany from '../assets/defaultCompany.png';
import { getCompanies } from '../ContactRedux';
import addressIcon from '../assets/AddressIcon.png';
import telegram from '../assets/telegram.png';
import Phone from '../assets/Phone.png';
import CreateTask from '../../Projects/CreateTask';
import ContactModal from '../People/Contact_Model';
import RichTextEditor from '../../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import Modal from '../../../Componenets/Modal/Modal';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

function Card({
  currentTarget,
  setCurrentTarget,
  state,
  setState,
  updateInnerNetNetTabs,
}) {
  const [html, setHtml] = useState('<p></p>');
  const [anchor] = useState('left');
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    opportunity_type: 'project_opportunity',
    payment_terms: 'weekly',
    expected_value: '',
    company: '',
    contact: currentTarget ? currentTarget.id : '',
    target_start: '',
    finish_date: '',
    budget: '',
    period: '1',
    connverted_to_project: false,
    opportunity_status: 'active',
  });
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    setFormData({
      name: '',
      description: '',
      opportunity_type: 'project_opportunity',
      payment_terms: 'weekly',
      expected_value: '',
      company: '',
      contact: currentTarget ? currentTarget.id : '',
      target_start: '',
      finish_date: '',
      budget: '',
      period: '1',
      connverted_to_project: false,
      opportunity_status: 'active',
    });
    setHtml('');
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const routeTo = useRouteTo();
  const [counter, setCounter] = useState(0);

  return (
    <>
      <Grid
        item
        sx={{
          width: '100%',
        }}
      >
        <Box className="card">
          <div className="row">
            <div className="col card-row">
              <img src={telegram} alt="telegram" />
              <p
                style={{ cursor: 'pointer' }}
                className="cardTxt"
                onClick={() => {
                  // Open the URL in a new tab
                  window.open(currentTarget.company_url, '_blank');
                }}
              >
                {currentTarget.company_url
                  ? currentTarget.company_url
                  : 'Not Available'}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col card-row">
              <img src={Phone} alt="Phone" />
              <p className="cardTxt">
                {currentTarget.office_num
                  ? currentTarget.office_num
                  : 'Not Available '}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col card-row">
              <img src={addressIcon} alt="Address" />
              <p className="cardTxt">
                {currentTarget.street ? currentTarget.street : 'Not Available '}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="btnGroups">
              <button
                type="button"
                className="det-btn"
                onClick={() => {
                  routeTo(
                    `${appRoutes.editCompany}/${currentTarget.unique_id}`,
                  );
                  updateInnerNetNetTabs(
                    'Edit Company',
                    `${appRoutes.editCompany}/${currentTarget.unique_id}`,
                  );
                }}
              >
                Edit Company
              </button>
              {['bottom'].map((anchor) => (
                <>
                  <button
                    type="button"
                    className="det-btn"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    New Task
                  </button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    <CreateTask
                      anchor={anchor}
                      toggleDrawer={toggleDrawer}
                      currentCompany={currentTarget}
                      state={state}
                      setState={setState}
                      counter={counter}
                      setCounter={setCounter}
                    />
                  </Drawer>
                </>
              ))}

              <button
                type="button"
                className="det-btn"
                onClick={() => setShowModal(true)}
                id="opp-btn"
              >
                New Opportunity
              </button>
            </div>
          </div>
        </Box>
      </Grid>
      {showModal && (
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              maxHeight: '82vh !important',
            },
          }}
          anchor={anchor}
          open={showModal}
          onClose={closeModal}
        >
          <Modal
            html={html}
            setHtml={setHtml}
            formData={formData}
            setFormData={setFormData}
            showModal={showModal}
            setShowModal={setShowModal}
            currentCompany={currentTarget}
            setCurrentCompany={setCurrentTarget}
          />
        </Drawer>
      )}
    </>
  );
}

function AtGlance({ currentTarget }) {
  return (
    <Grid
      item
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          height: '158px',
          borderRadius: '6px',
          border: '0.9px solid #d9d9d9',
          backgroundColor: '#f8f9fa',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem 12px',
        }}
      >
        <h3>At a glance</h3>
        <p className="fstxt">{currentTarget.description ?? ''}</p>
      </Box>
    </Grid>
  );
}

function CompanyInfo({ formData }) {
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      sx={{
        width: '100%',
        border: '0.9px solid #d9d9d9',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
      }}
    >
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Business Information</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.%',
              }}
            >
              <span>ID</span>
              <Box
                sx={{
                  width: '44%',
                  color: 'rgba(0,0,0,0.4)',
                  overflow: 'hidden',
                }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.unique_id ? formData.unique_id : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.3%',
              }}
            >
              <span>Company</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography> {formData.name ? formData.name : ''}</Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Office Telephone</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.office_num ? formData.office_num : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Web Address</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>{formData?.company_url || ''}</Typography>
              </Box>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Profile Information</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Type</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.company_type ? formData.company_type : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>NAICS Industry</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.industry
                    ? formData.industry
                    : (formData.Industry ?? '')}
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Address</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Address 1</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.street ? formData.street : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Address 2</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.street2 ? formData.street2 : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>City</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography> {formData.city ? formData.city : ''}</Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>State / Province</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography> {formData.state ? formData.state : ''}</Typography>
              </Box>
            </Box>

            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Zip / Postal Code</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.address_zip ? formData.address_zip : ''}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Country</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography>
                  {' '}
                  {formData.country ? formData.country : ''}
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </Grid>
      <Grid
        item
        sx={{
          padding: '1rem 12px',
          width: '100%',
        }}
      >
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Social Media</h3>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>LinkedIn</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(formData.linkedin_url, '_blank');
                  }}
                >
                  {' '}
                  {formData.linkedin_url}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>X</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(formData.twitter_url, '_blank');
                  }}
                >
                  {' '}
                  {formData.twitter_url}
                </Typography>
              </Box>
            </Box>
            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Instagaram</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(formData.instagram_url, '_blank');
                  }}
                >
                  {' '}
                  {formData.instagram_url}
                </Typography>
              </Box>
            </Box>

            <Box
              className="df-r-j-c-gp-5 MobileDFR autoWidth"
              sx={{
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              <span>Facebook</span>
              <Box
                sx={{ width: '44%', overflow: 'hidden' }}
                className="OppCreate"
              >
                <Typography
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open(formData.facebook_url, '_blank');
                  }}
                >
                  {' '}
                  {formData.facebook_url}
                </Typography>
              </Box>
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

function Tags({ currentTarget }) {
  return (
    <Grid
      container
      sx={{
        padding: '1rem 12px',
        border: '0.9px solid #d9d9d9',
        backgroundColor: '#f8f9fa',
        borderRadius: '10px',
        height: '128px',
      }}
    >
      <Grid item xs={12}>
        <div className="gs-10">
          <div className="colgs-10p90q">
            <h3 className="sub-title">Tags</h3>

            <Box
              className="tagBox"
              sx={{
                display: 'flex',
                gap: '1rem',
                paddingTop: '1.37%',
                paddingBottom: '1.37%',
              }}
            >
              {currentTarget.tags
                ? currentTarget.tags.split(',').map((item) => (
                    <span key={item} className="tag">
                      {item}
                    </span>
                  ))
                : null}
            </Box>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
function TabSystem({
  currentTarget,
  setCurrentTarget,
  screenHeight,
  updateInnerNetNetTabs,
}) {
  const [currentPerson, setCurrentPerson] = useState({});
  const access_token = useSelector((state) => state.auth.user.access);
  const [value, setValue] = useState('Notes');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const [html, setHtml] = useState('');
  const [btn, setbtn] = useState();

  const check = (e) => {
    setbtn(e);
  };

  const send_Note = async (e) => {
    // eslint-disable-next-line
    if (e.shiftKey) {
    } else if (e.key === 'Enter') {
      e.preventDefault();
      setHtml('');
      btn?.editor?.commands.clearContent();
      const res = await fetch(`${ENDPOINT}/api/contact/note/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
        body: JSON.stringify({
          company_note: currentTarget.id,
          note: html,
        }),
      });
      if (res.ok) {
        const resGet = await fetch(
          `${ENDPOINT}/api/contact/company/detail/${currentTarget.unique_id}/`,
          {
            method: 'GET',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (resGet.ok) {
          const dataGet = await resGet.json();
          setCurrentTarget(dataGet);
        }
        dispatch(getCompanies({ access_token }));
      }
    }
  };

  function DateForm(originalDateString) {
    const date = new Date(originalDateString);
    // Define months array for abbreviation
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    // Get the month abbreviation and format the day and year
    const formattedDate = `On ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    return formattedDate;
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          paddingRight: '10px',
          paddingLeft: '10px',
          paddingTop: '3.36%',
          paddingBottom: '3.36%',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            borderBottom: '2px solid #A0A2AA',

            '& .MuiTabs-indicator': {
              backgroundColor: '#000',
            },

            '& .MuiTab-textColorSecondary.Mui-selected': {
              color: '#711fff',
              borderBottom: '2px solid #5B5A5A',

              fontWeight: 'bold',
              fontFamily: 'Open Sans',
            },

            '& .MuiTab-textColorSecondary': {
              color: 'rgba(3, 7, 30, 0.4)',
              fontFamily: 'Open Sans',
            },

            '& .MuiTabs-flexContainer': {
              justifyContent: 'space-between',
            },
          }}
        >
          <Tab
            value="Notes"
            label={
              currentTarget.company_note
                ? `Notes (${currentTarget.company_note.length})`
                : 'Notes'
            }
          />
          <Tab
            value="People"
            label={
              Array.isArray(currentTarget.company_contacts)
                ? `People (${currentTarget.company_contacts.length})`
                : 'People'
            }
          />
          <Tab
            value="Tasks"
            label={
              Array.isArray(currentTarget.company_task)
                ? `Tasks (${currentTarget.company_task.length})`
                : 'Tasks'
            }
          />
          <Tab
            value="Opportunities"
            label={
              Array.isArray(currentTarget.company_opportunity)
                ? `Sales (${currentTarget.company_opportunity.length})`
                : 'Sales'
            }
          />
          <Tab value="Projects" label="Projects" />
        </Tabs>
      </Box>
      <Grid item container alignItems="flex-start" sx={{}}>
        {value === 'Notes' && (
          <Grid item direction="column" xs={12} justifyContent="space-between">
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
                marginTop: '-1rem',
              }}
            >
              {currentTarget.company_note &&
              currentTarget.company_note.length > 0 ? (
                currentTarget.company_note.map((item) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{ alignItems: 'center' }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          alt={item.created_by.first_name}
                          src={`${ENDPOINT}/${item.created_by.avatar}`}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                            }}
                          >
                            {`${item.created_by.first_name} ${item.created_by.last_name}`}
                          </Typography>
                        }
                      />
                      <Typography sx={{ color: 'rgba(3, 7, 30, 0.4)' }}>
                        {DateForm(item.created_at)}
                      </Typography>
                    </ListItem>
                    <Box sx={{ padding: '0rem 1.24rem' }}>
                      <Typography
                        sx={{
                          color: '#03071E',
                          fontSize: '14px',
                        }}
                        variant="subtitle2"
                        dangerouslySetInnerHTML={{ __html: item.note }}
                      />
                    </Box>
                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                >
                  No Notes
                </Typography>
              )}
            </List>
            <Grid item sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'end',
                  px: '10px',
                }}
              >
                <section
                  className="editorNoteContacts px-2 pt-1 mb-2 rounded"
                  onKeyDown={send_Note}
                >
                  <Box sx={{ height: '150px', overflow: 'hidden' }}>
                    <RichTextEditor
                      setHtml={setHtml}
                      html={html}
                      check={check}
                      auto_focus={false}
                    />
                  </Box>
                </section>
                <button
                  type="button"
                  className="create-btn"
                  id="c-btn"
                  style={{
                    marginLeft: '10px',
                    marginBottom: '10px',
                  }}
                  onClick={async (e) => {
                    if (html.length > 0) {
                      setLoading(true);
                      e.preventDefault();
                      setHtml('');
                      btn?.editor?.commands.clearContent();
                      const res = await fetch(
                        `${ENDPOINT}/api/contact/note/create/`,
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                          body: JSON.stringify({
                            company_note: currentTarget.id,
                            note: html,
                          }),
                        },
                      );
                      setLoading(false);
                      if (res.ok) {
                        const resGet = await fetch(
                          `${ENDPOINT}/api/contact/company/detail/${currentTarget.unique_id}/`,
                          {
                            method: 'GET',
                            headers: {
                              Authorization: `JWT ${access_token}`,
                              'Content-Type': 'application/json',
                            },
                          },
                        );

                        if (resGet.ok) {
                          const dataGet = await resGet.json();
                          setCurrentTarget(dataGet);
                        }
                      }
                      dispatch(getCompanies({ access_token }));
                    }
                  }}
                >
                  {loading ? (
                    <i className="bx bx-loader-alt bx-spin" />
                  ) : (
                    'Save'
                  )}
                </button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* People Tab Detail */}
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 1rem',
        }}
      >
        {value === 'People' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
              }}
            >
              {currentTarget.company_contacts &&
              currentTarget.company_contacts.length > 0 ? (
                currentTarget.company_contacts.map((employee) => (
                  <>
                    <ListItem
                      sx={{
                        marginTop: '2.25%',
                        marginBottom: '2.25%',
                        width: '100%',
                        flexGrow: 1,
                        paddingLeft: '-1rem',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setCurrentPerson(employee);
                        setShow(true);
                      }}
                    >
                      <Grid justifyContent="space-evenly" container spacing={1}>
                        <Grid item xs={1}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '',
                            }}
                          >
                            <Avatar>{employee.first_name[0]}</Avatar>
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '',
                            }}
                          >
                            {employee.first_name} {employee.last_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'normal',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '',
                            }}
                          >
                            |
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#023FE3',
                            }}
                          >
                            {employee.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2rem',
                        marginRight: '2rem',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  There are no people associated with this company yet...
                </Typography>
              )}
            </List>
          </Grid>
        )}
      </Grid>

      {/* Tasks Tab Detail */}
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }}
      >
        {value === 'Tasks' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
              }}
            >
              <ListItem alignItems="flex-start">
                <Box
                  className="autoWidth"
                  sx={{
                    border: '1px solid #d9d9d9',
                    paddingTop: '0.66%',
                    paddingBottom: '0.66%',
                    paddingLeft: '2.7%',
                    paddingRight: '2.7%',
                    marginTop: '-1rem',
                    marginBottom: '1rem',
                    borderRadius: '8px',
                    background: '#FCFCFC',
                  }}
                >
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                          paddingLeft: '2.7%',
                          paddingRight: '2.7%',
                        }}
                      >
                        Tasks
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              {currentTarget.company_task &&
              currentTarget.company_task.length > 0 ? (
                currentTarget.company_task.map((item) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        paddingLeft: '5%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                            }}
                          >
                            {item.task_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                              textTransform: 'uppercase',
                            }}
                          >
                            {item.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                >
                  You don&quot;t have any tasks for this Company yet, you can
                  always use the &quot;+ New Task&quot; button to create one.
                </Typography>
              )}
            </List>
          </Grid>
        )}
      </Grid>

      {/* Opportunities Tab Detail */}
      <Grid
        item
        container
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '0 2rem',
        }}
      >
        {value === 'Opportunities' && (
          <Grid
            item
            container
            direction="column"
            xs={12}
            justifyContent="space-between"
          >
            <List
              sx={{
                width: '100%',
                height: '510px',
                overflowY: 'auto',
              }}
            >
              <ListItem alignItems="flex-start">
                <Box
                  className="autoWidth"
                  sx={{
                    border: '1px solid #d9d9d9',
                    paddingTop: '0.66%',
                    paddingBottom: '0.66%',
                    paddingLeft: '2.18%',
                    paddingRight: '2.18%',
                    marginTop: '-1rem',
                    marginBottom: '1rem',
                    borderRadius: '8px',
                    background: '#FCFCFC',
                  }}
                >
                  <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Opportunities
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Open Sans',
                          fontSize: '1rem',
                          color: '#711FFF',
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>

              {currentTarget.company_opportunity &&
              currentTarget.company_opportunity.length > 0 ? (
                currentTarget.company_opportunity.map((item) => (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      sx={{
                        marginTop: '2.25%',
                        marginBottom: '2.25%',
                      }}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color: '#310085',
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Open Sans',
                              fontSize: '1rem',
                              color:
                                item.opportunity_status !== 'lost'
                                  ? '#310085'
                                  : 'red',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item.opportunity_status !== 'lost'
                              ? item.opportunity_type
                              : item.opportunity_status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <Divider
                      component="li"
                      sx={{
                        marginLeft: '2.25%',
                        marginRight: '2.25%',
                      }}
                    />
                  </>
                ))
              ) : (
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                >
                  Ooops... You don&apos;t have any Opportunities for this
                  company yet...
                </Typography>
              )}
            </List>
          </Grid>
        )}
      </Grid>
      {show && (
        <ContactModal
          show={show}
          setShow={setShow}
          setCurrentTarget={setCurrentTarget}
          currentPerson={currentPerson}
          setCurrentPerson={setCurrentPerson}
          id={currentTarget.id}
          currentTarget={currentTarget}
          screenHeight={screenHeight}
          updateInnerNetNetTabs={updateInnerNetNetTabs}
        />
      )}
    </>
  );
}

function CompanyDet({ screenHeight, updateInnerNetNetTabs }) {
  const { id } = useParams();
  const unique_id = id;
  const routeTo = useRouteTo();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [formData, setFormData] = useState({});
  const [currentTarget, setCurrentTarget] = useState({});

  const access_token = useSelector((item) => item.auth.user.access);
  const [state, setState] = React.useState({
    bottom: false,
  });
  const ourTarget = currentCompanies.find(
    (item) => item.unique_id === unique_id,
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${ENDPOINT}/api/contact/company/detail/${ourTarget.unique_id}/`,
          {
            method: 'GET',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (!res.ok) {
          routeTo(appRoutes.companies);
          updateInnerNetNetTabs('Company', appRoutes.companies);
        } else {
          const data = await res.json();
          setFormData(data);
          setCurrentTarget(data);
          if (data) {
            setIsDataLoading(false);
          }
        }
        // eslint-disable-next-line
      } catch (error) {}
    };

    if (ourTarget) {
      fetchData();
    }
  }, []);

  return (
    <div style={{ height: '100%' }}>
      {isDataLoading && ourTarget && <PreLoading />}
      <section className="oppor-section5">
        <div className="oppor-row fixed">
          <article
            className="Center"
            style={{
              position: 'realitve',
              marginBottom: '5.72%',
            }}
          >
            <ArrowBackIcon
              sx={{
                position: 'absolute',
                bottom: '32px',
                left: '0px',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => {
                updateInnerNetNetTabs('Company', appRoutes.companies);
                routeTo(appRoutes.companies);
              }}
            />
            <Avatar
              sx={{ width: 80, height: 80 }}
              src={currentTarget.avatar ? currentTarget.avatar : defaultCompany}
              alt={currentTarget.name}
            />

            <div className="dFlex col-md-6">
              <h3 className="fstxtPro noPad">{currentTarget.name}</h3>
              <div
                className="dFlex"
                style={{
                  gap: '0.5rem',
                }}
              >
                <h3 className="fstxt">
                  {currentTarget.city ? `${currentTarget.city} ` : ''}{' '}
                  {currentTarget.state ? currentTarget.state : ''}
                </h3>{' '}
              </div>
            </div>
          </article>
        </div>
        <Box
          sx={{
            maxHeight: screenHeight - 285,
            overflow: 'auto',
            border: '1px solid #d9d9d9',
            padding: '1.5rem 1.5rem',
            position: 'relative',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            background: '#fff',
            marginBottom: '22px',
          }}
        >
          <Grid
            container
            sx={{
              width: '100%',
              gap: '1rem',
            }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={4.91}
              lg={4.91}
              xl={4.91}
              direction="column"
              alignItems="flex-end"
              sx={{
                gap: '1rem',
              }}
            >
              <Card
                currentTarget={currentTarget}
                id={unique_id}
                setCurrentTarget={setCurrentTarget}
                setState={setState}
                state={state}
                updateInnerNetNetTabs={updateInnerNetNetTabs}
              />
              <AtGlance currentTarget={currentTarget} />
              <CompanyInfo formData={formData} setFormData={setFormData} />
              <Tags currentTarget={currentTarget} />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6.84}
              lg={6.84}
              xl={6.84}
              container
              direction="column"
              sx={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                height: { xs: '780px', md: '766px', lg: '770px', xl: '820px' },
              }}
            >
              <TabSystem
                currentTarget={currentTarget}
                setCurrentTarget={setCurrentTarget}
                screenHeight={screenHeight}
                updateInnerNetNetTabs={updateInnerNetNetTabs}
              />
            </Grid>
          </Grid>
        </Box>
      </section>
    </div>
  );
}
export default CompanyDet;

import { Grid, Box, Alert, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../Assets/MobileLogo.png';
import checkEmailSvg from '../Assets/Check-email.svg';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';
import { appRoutes } from '../../../appRoutes';

function MailSent({ setErr, setSucc, succ, err, setErrMessage }) {
  const newUser = useSelector((state) => state.auth.newUser.newUser);

  const firstName = newUser.first_name;
  const Useremail = newUser.email;
  const obj = {
    email: Useremail,
  };
  const handelResend = async (e) => {
    e.preventDefault();
    const response = await fetch(`${ENDPOINT}/auth/users/resend_activation/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      setErr(true);
      setErrMessage('Something went wrong, please try again later');
    } else {
      setSucc(true);
    }
  };

  useEffect(() => {
    if (err) {
      setTimeout(() => {
        setErr(false);
      }, 3000);
    }

    if (succ) {
      setTimeout(() => {
        setSucc(false);
      }, 5000);
    }
  }, [err, succ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.55rem',
        margin: 'auto 0',
        width: {
          xl: '60%',
          lg: '65%',
          md: '70%',
          xs: '90%',
        },
      }}
    >
      <h2 className="sub-heading sentSuc fsMedium">Verification Mail Sent</h2>
      <p className="fstxt">
        Great work{' '}
        <span
          style={{ color: '#03071E', fontWeight: 'bolder', fontSize: '16px' }}
        >
          {firstName}.
        </span>
        , a verification email has been sent to {Useremail}. Please check out
        for it and click the verify my email link in the mail. If you can&apos;t
        find it in your inbox or spam, check your spam or junk mail.
      </p>
      <article className="dFlex">
        <p className="fstxt left" style={{ marginBottom: '0px' }}>
          Didn&apos;t see email?{' '}
        </p>
        <button type="button" className="link noBorder" onClick={handelResend}>
          Re-send Email
        </button>
      </article>
      <Link
        className="link2"
        exact
        to={appRoutes.login}
        style={{
          textDecoration: 'none',
          color: '711fff',
          textAlign: 'center',
        }}
      >
        {' '}
        Back to Login{' '}
      </Link>
      {succ && (
        <Alert severity="success" sx={{ width: '100%' }}>
          An Activation Link was resent to the provided email address.
        </Alert>
      )}
      {err && (
        <Alert severity="error" sx={{ width: '100%' }}>
          Failed to resent activation Link.
        </Alert>
      )}
    </Box>
  );
}

function Onbording() {
  const [err, setErr] = useState(false);
  const [succ, setSucc] = useState(false);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      container
      component="main"
      justifyContent={screenSize < 900 ? 'center' : null}
      sx={{ height: screenSize < 900 ? '78vh' : '100vh' }}
    >
      {screenSize >= 900 && (
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: '#47d1fc',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: { xs: '70%', lg: '60%', xl: '50%', xxl: '50%' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} width="100%" height="auto" alt="Mobile Logo" />
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                }}
              >
                Putting Profits Into Your Workflow Tools!
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - opportunities & sales
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - project, retainer & task management
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - time tracking
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - team communications
              </Typography>
            </Box>
            <img
              src={checkEmailSvg}
              alt="checkmail SVG"
              width="100%"
              height="auto"
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.55rem',
          display: 'flex',
          justifyContent: { sm: 'center' },
          overflowY: 'auto',
        }}
        direction="column"
        alignItems="center"
      >
        <MailSent setErr={setErr} setSucc={setSucc} succ={succ} err={err} />
      </Grid>
    </Grid>
  );
}
export default Onbording;

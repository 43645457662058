/* eslint-disable camelcase */
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

function AllSet() {
  const first_name = 'John';
  const routeTo = useRouteTo();
  const handleRedirect = () => {
    routeTo(appRoutes.rootRoute);
  };

  return (
    <section className="AxisCenter">
      <article className="box-txt">
        <h2 className="sub-heading">You&apos;re All Set!</h2>
        <p className="fstxt">
          Congratulations {first_name}. Your Business has been setup and your
          dashboard has been created. If you invited any team members, they have
          been mailed invites.
        </p>
        <button type="button" className="btn" onClick={handleRedirect}>
          Take me to Dashboard
        </button>
      </article>
    </section>
  );
}
export default AllSet;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import Shake from 'react-reveal/Shake';

import Popup from 'reactjs-popup';

import {
  MdOutlineCalendarMonth,
  MdEdit,
  MdControlPointDuplicate,
  MdOutlineDeleteForever,
  MdOutlinePlaylistRemove,
} from 'react-icons/md';
import { LuServer } from 'react-icons/lu';

import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Skeleton from '@mui/material/Skeleton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Backdrop,
  Box,
  Tooltip,
  Grid,
  Typography,
  Autocomplete,
  Drawer,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import RepeatIcon from '@mui/icons-material/Repeat';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ModalMui from '@mui/material/Modal';
import { DataGridPro } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import Offcanvas from 'react-bootstrap/Offcanvas';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import PreLoader, { PreLoader1 } from '../../Componenets/Misc';
import { FindIcon } from '../Projects/_module';
import icon_colab from './assets/icon_colab.png';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import helpVid from './assets/Section_Help-idle.svg';
import { payment, conditions } from './NewEstimate';
import moreIco from './assets/moreIco.png';
import './Opp.css';
import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import Dropdown from '../../Componenets/Dropdown/Dropdown';
import Modal from '../../Componenets/Modal/Modal';
import {
  getEstimates,
  getOpportunity,
  storeStep,
  ENDPOINT,
} from './Redux/oppor';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';

const fontFamily = 'Open Sans';

function InfoCreate({ setShowDrop, setShowModal, updateInnerNetNetTabs }) {
  const routeTo = useRouteTo();
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        padding: '2.44rem 2.88rem',
        gap: '2rem',
        width: 'fit-content',
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          background: '#310085',
          padding: '1.44rem 1.88rem',
          color: '#fff',
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontStyle: '1rem',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowDrop(false);
          setShowModal(true);
        }}
      >
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: '600',
          }}
        >
          Start A
        </Typography>
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.5rem',
            fontWeight: '600',
            marginBottom: '1rem',
          }}
        >
          New Opportunity
        </Typography>
        <li>It will not include pricing</li>
        <li>It will not include financial reporting</li>
        <li>It will also not include overall estimate</li>
        <li>Suitable for internal or short projects</li>
      </Box>
      <Box
        sx={{ width: '1.5px', backgroundColor: '#d9d9d9', height: '150px' }}
      />
      <Box
        sx={{
          background: '#5fcea8',
          padding: '1.44rem 1.88rem',
          color: '#fff',
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontStyle: '1rem',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onClick={() => {
          updateInnerNetNetTabs('New Job Estimate', appRoutes.newJobEstimate);
          routeTo(appRoutes.newJobEstimate);
        }}
      >
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.1rem',
            fontWeight: '600',
          }}
        >
          Get an
        </Typography>
        <Typography
          sx={{
            fontFamily,
            color: '#fff',
            fontSize: '1.5rem',
            fontWeight: '600',
            marginBottom: '1rem',
          }}
        >
          Estimate
        </Typography>
        <li>It will include pricing</li>
        <li>It will include financial reporting</li>
        <li>Suitable for long term projects</li>
        <li>Suitable for internal and external projects</li>
      </Box>
    </Grid>
  );
}

function checkCollaborators(collaborators, user) {
  // eslint-disable-next-line
  for (let i = 0; i < collaborators.length; i++) {
    const collaborator = collaborators[i];
    if (collaborator.collab_detail === user.id) {
      return true;
    }
  }
  return false;
}

function PrevBox({ setArgs, args, searchQuery, setSearchQuery }) {
  return (
    <Box
      sx={{
        backgroundColor: '#f2f2f2',
        borderRadius: '8px 0px 0 0',
        borderTopRightRadius: '8px',
        height: '70px',
        width: '100%',
        border: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.5rem 1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '40%',
        }}
      >
        <FormControl
          sx={{
            width: '300px',
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            sx={{
              '& .MuiSelect-select:focus': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
              },
              '& .MuiSelect-select:hover': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
              },
              '& .MuiSelect-select': {
                backgroundColor: '#711fff',
                color: '#fff',
                outline: '1px solid #711fff',
                border: '1px solid #711fff',
                padding: '10px 32px 10px 10px',
              },
              '& .MuiSelect-icon': {
                color: '#fff',
              },
              '& .MuiSelect-selectMenu': {
                color: '#fff',
              },
            }}
            value={args}
            onChange={(e) => setArgs(e.target.value)}
          >
            <MenuItem value="all">All Opportunities and Estimates</MenuItem>
            <MenuItem value="Project Opportunity">
              Project Opportunities
            </MenuItem>
            <MenuItem value="Retainer Opportunity">
              Retainer Opportunities
            </MenuItem>
            <MenuItem value="Project Estimate">Project Estimates</MenuItem>
            <MenuItem value="Retainer Estimate">Retainer Estimates</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          minWidth: '10%',
          maxWidth: '30%',
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <FindIcon setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      </Box>
    </Box>
  );
}

function SureLost({ setSureLost, currentTarget }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    if (currentTarget.isOpa) {
      const response = await fetch(
        `${ENDPOINT}/api/opportunity/update/${currentTarget.idForRequest}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            opportunity_status: 'lost',
          }),
        },
      );

      if (response.ok) {
        setSureLost(false);
      }
    } else {
      const res = await fetch(
        `${ENDPOINT}/api/opportunity/update/estimate/${currentTarget.idForRequest}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            estimate_status: 'lost',
            title: currentTarget.name,
            budget: currentTarget.budget ?? 0,
          }),
        },
      );
      if (!res.ok) {
        return;
      }
      setSureLost(false);
      setLoading(false);
      dispatch(getEstimates({ access_token }));
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '500px',
            height: '150px',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '1.48rem 1rem',
          }}
        >
          <p className="fstxt">
            Are You Sure You want to Mark this Opportunity as Lost? It will
            still show up in your sales reporting.{' '}
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureLost(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{ background: loading && '#FF6827' }}
              className="netWarn-btn"
              onClick={() => handleDelete()}
            >
              {loading ? (
                <Box
                  sx={{ width: '23px', height: '23px', margin: '0rem 0.6rem' }}
                >
                  <PreLoader1 />
                </Box>
              ) : (
                'Lost'
              )}{' '}
            </button>
          </Box>
        </Box>
      </Slide>
    </section>
  );
}

function SureDuplicate({ setSureDuplicate, currentTarget, myArr2 }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const currentUser = useSelector((state) => state.userData.userData);
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const resGet = await fetch(
      `${ENDPOINT}/api/opportunity/estimate/${currentTarget.id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      },
    );
    const getData = await resGet.json();

    const formData = { ...getData };
    let duplicateNumber = 1;
    let newTitle = `${formData.title} - Duplicate ${duplicateNumber}`;

    // eslint-disable-next-line
    while (myArr2.some((item) => item.title === newTitle)) {
      // eslint-disable-next-line
      duplicateNumber++;
      newTitle = `${formData.title} - Duplicate ${duplicateNumber}`;
    }

    delete formData.work_type;
    delete formData.id;
    delete formData.collaborator;

    if (resGet.ok) {
      const res = await fetch(`${ENDPOINT}/api/opportunity/create-estimate/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body:
          formData.type === 'project'
            ? JSON.stringify({
                ...formData,
                title: newTitle,
                budget: Number(
                  parseFloat(formData?.budget).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  }) || 0,
                ),
                est_startdate:
                  formData.est_start_date ||
                  new Date(
                    new Date().setDate(new Date().getDate() + 14),
                  ).toISOString(),
                est_enddate: formData.est_finish_date || null,
                members:
                  Array.isArray(formData.subteam_estimate) &&
                  formData.subteam_estimate.length
                    ? formData.subteam_estimate[0].members.map(
                        (item) => item.email,
                      )
                    : [],
                estimate_deliverable:
                  Array.isArray(formData.deliverable) &&
                  formData.deliverable.length
                    ? formData.deliverable.map((item) => ({
                        deliverable_name: item.deliverable_name,
                        loe_price: item.loe_price ?? 0,
                        description: item.description || '',
                        task_deliverable:
                          Array.isArray(item.task_deliverable) &&
                          item.task_deliverable.length
                            ? item.task_deliverable.map((tsk) => ({
                                task_name: tsk.task_name,
                                task_worktypes: Array.isArray(tsk.task_worktype)
                                  ? tsk.task_worktype.map((tskWtt) => ({
                                      id: tskWtt.id,
                                      name: tskWtt.name,
                                      hours: tskWtt.hours || 0,
                                    }))
                                  : [],
                              }))
                            : [],
                        deliverable_multiplier: Array.isArray(
                          item.deliverable_multiplier,
                        )
                          ? item.deliverable_multiplier.map((multi) => ({
                              label: multi.label,
                              rate: multi.rate,
                              operation: multi.operation,
                            }))
                          : [],
                        user_price: item.user_price || 0,
                        deliverable_startdate: item.deliverable_startdate,
                        deliverable_enddate: item.deliverable_enddate,
                        deliverable_worktype: Array.isArray(
                          item.deliverable_worktype,
                        )
                          ? item.deliverable_worktype.map((wtt) => ({
                              name: wtt.name,
                              hours: wtt.hours || 0,
                              id: wtt.id,
                            }))
                          : [],
                      }))
                    : [],
                estimate_hardcost: Array.isArray(formData.estimate_hardcost)
                  ? formData.estimate_hardcost.map((est) => ({
                      hardcost_label: est.hardcost_label,
                      our_price: est.our_price,
                      price_to_customer: est.price_to_customer,
                      hardcost_margin: est.hardcost_margin,
                    }))
                  : [],
                estimate_worktype:
                  Array.isArray(formData.deliverable) &&
                  formData.deliverable.length
                    ? formData.deliverable[0].deliverable_worktype.map(
                        (item) => ({
                          id: Number(item.id),
                          hours: item.hours || 0,
                        }),
                      )
                    : Array.isArray(getData?.work_type) &&
                      getData?.work_type.length &&
                      getData?.work_type.map((item) => ({
                        id: Number(item.id),
                        hours: 0,
                      })),
              })
            : JSON.stringify({
                ...formData,
                estimate_deliverable: [],
                est_startdate: formData.est_start_date
                  ? formData.est_start_date
                  : new Date(
                      new Date().setDate(new Date().getDate() + 14),
                    ).toISOString(),
                est_enddate: formData.est_finish_date
                  ? formData.est_finish_date
                  : null,
                members:
                  Array.isArray(formData.members) && formData.members.length
                    ? formData.members
                    : [currentUser.email],
                estimate_worktype: formData.work_type.map((item) => ({
                  id: item.id,
                  hours: item.hours ?? 0,
                })),
              }),
      });

      const data = await res.json();
      // eslint-disable-next-line
      const res2 = await fetch(
        `${ENDPOINT}/api/opportunity/create/estimate/termsconditions/`,
        {
          method: 'POST',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            terms_conditions: conditions,
            estimate_id: data.id,
            template_id: 1,
          }),
        },
      );
      // eslint-disable-next-line
      const res3 = await fetch(
        `${ENDPOINT}/api/opportunity/create/estimate/paymentterms/`,
        {
          method: 'POST',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            payment_terms: payment,
            estimate_id: data.id,
            template_id: 1,
          }),
        },
      );
      if (res.ok) {
        dispatch(getEstimates({ access_token }));
        setSureDuplicate(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '500px',
            height: '150px',
            backgroundColor: 'rgba(255,255,255)',
            border: '1px solid #d9d9d9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
            padding: '1.48rem 1rem',
          }}
        >
          <p className="fstxt">
            Are You Sure You want to Mark this Opportunity as Duplicate? It will
            still show up in your sales reporting.{' '}
          </p>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setSureDuplicate(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              style={{ background: loading && '#FF6827', height: '46px' }}
              className="netWarn-btn"
              onClick={() => handleDelete()}
            >
              {loading ? (
                <Box
                  sx={{ width: '30px', height: '30px', margin: '0rem 0.6rem' }}
                >
                  <PreLoader1 />
                </Box>
              ) : (
                'Duplicate'
              )}{' '}
            </button>
          </Box>
        </Box>
      </Slide>
    </section>
  );
}

function SureDeleteOpp({ setSureDelete, currentTarget }) {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [error, setError] = useState(false);
  const handleDelete = async () => {
    if (type === 'delete') {
      setLoading(true);
      if (currentTarget.isOpa) {
        const response = await fetch(
          `${ENDPOINT}/api/opportunity/delete/${currentTarget.idForRequest}/`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );
        if (response.ok) {
          dispatch(getOpportunity({ access_token }));
          setSureDelete(false);
          setType('');
        }
      } else {
        const response = await fetch(
          `${ENDPOINT}/api/opportunity/delete/estimate/${currentTarget.idForRequest}/`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );
        if (response.ok) {
          dispatch(getEstimates({ access_token }));
          setSureDelete(false);
          setType('');
          setLoading(false);
        }
      }
    } else {
      setError('Incorrect Spelling');
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <section
      className="loading"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Slide bottom>
        <Box
          sx={{
            width: '850px',
            height: '320px',
            background: 'rgba(241, 243, 245, 0.50)',
            padding: '2rem',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '95%',
              height: '95%',
              backgroundColor: 'rgba(255,255,255)',
              border: '1px solid #d9d9d9',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px',
              padding: '0.68rem 1rem',
              justifyContent: 'space-around',
              boxShadow: '2px 2px 6px 0px #D9D9D9',
              gap: '10px',
            }}
          >
            <p
              style={{
                fontSize: '0.9rem',
                fontWeight: '600',
                fontFamily: 'Open Sans, sans-serif',
                color: 'red',
              }}
            >
              DELETE {currentTarget.isOpa ? 'OPPORTUNITY' : 'ESTIMATE'}
            </p>
            <p
              className="fstxt"
              style={{ width: '80%', textAlign: 'center', fontWeight: '400' }}
            >
              Are you sure you want to Delete this{' '}
              {currentTarget.isOpa ? 'Opportunity' : 'Estimate'}? Your Estimate
              will be Deleted and the billing will also stop.
            </p>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p className="fstxt" style={{ fontWeight: '400' }}>
                Please type the word &quot;DELETE&quot; and click the Delete
                button
              </p>
              <Box
                sx={{
                  width: '40%',
                  borderRadius: '8px',
                  padding: '1rem 1.1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '65px',
                }}
              >
                <TextField
                  value={type}
                  variant="standard"
                  onChange={(e) => setType(e.target.value.toLocaleLowerCase())}
                  placeholder="Delete"
                  sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                      fontSize: '1rem',
                      fontFamily: 'Open Sans',
                      fontWeight: '500',
                      color: '#000000',
                      textAlign: 'center',
                    },
                    '& .MuiInput-underline:before': {
                      borderBottom: '1px dashed #000000',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottom: '1px dashed #000000',
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: '1px dashed #000000',
                    },
                  }}
                />
              </Box>
              {error && (
                <Shake>
                  <p style={{ color: 'red' }}>{error}</p>
                </Shake>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '50%',
                margin: '0 auto',
              }}
            >
              <button
                type="button"
                className="cancel-btn"
                onClick={() => setSureDelete(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                style={{
                  fontFamily:
                    "'Neue Haas Display Pro', 'Open Sans', sans-serif",
                  fontWeight: '500',
                  backgroundColor: type ? '#cd0b4c' : '#f99f9f',
                  borderRadius: '5px',
                  color: 'var(--white-100)',
                  border: 'none',
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontSize: '1rem',
                  padding: '0rem 2rem',
                  height: '38px',
                }}
                onClick={handleDelete}
                disabled={!type}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.1rem',
                    }}
                  >
                    <PreLoader1 />
                  </Box>
                ) : (
                  'Delete'
                )}{' '}
              </button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </section>
  );
}

function Opportunity({
  setShowDrop,
  showDrop,
  showModal,
  setShowModal,
  setConvertToEst,
  screenHeight,
  updateInnerNetNetTabs,
}) {
  const [args, setArgs] = useState('all');
  const [makeNew, setMakeNew] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);
  const dispatch = useDispatch();
  const myArr = useSelector((state) => state.oppertunity.opportunites);
  const myArr2 = useSelector((state) => state.oppertunity.estimates);
  const routeTo = useRouteTo();
  const [megaLoading, setMegaLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterBase, setFilterBase] = useState(['retainer', 'project']);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [IsError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [currentTarget, setCurrentTarget] = useState({});
  const [sureLost, setSureLost] = useState(false);
  const [sureDuplicate, setSureDuplicatet] = useState(false);
  const [sureDelete, setSureDelete] = useState(false);

  const meta = {
    access_token,
  };

  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  const [projects, setProjects] = useState([]);
  const [filterOptions, setFilterOptions] = useState(false);

  useEffect(() => {
    dispatch(storeStep(1));
    setConvertToEst(null);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getEstimates(meta));
      dispatch(getOpportunity(meta));
    };

    fetchData();
  }, [showModal, sureDelete]);

  const handleCreation = () => {
    setMakeNew(!makeNew);
  };

  document.addEventListener('click', (e) => {
    if (e.target.id !== 'c-btn') {
      setMakeNew(false);
    }
  });

  useEffect(() => {
    setIsError(false);
    setError('');
  }, [megaLoading]);

  useEffect(() => {
    if (Array.isArray(myArr) && Array.isArray(myArr2)) {
      const myArr3 = myArr2?.filter(
        (item) => item.estimate_status === 'active',
      );
      const merger = [...myArr, ...myArr3];
      setProjects(merger);
    }
  }, [myArr, myArr2]);

  const [ModalConversion, setModalConversion] = useState(false);
  const handleCloseModalConversion = () => setModalConversion(false);
  const [html, setHtml] = useState('<p></p>');

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    opportunity_type: 'project_opportunity',
    payment_terms: 'weekly',
    expected_value: '',
    company: '',
    contact: currentTarget ? currentTarget.id : '',
    target_start: '',
    finish_date: '',
    budget: '',
    period: '1',
    connverted_to_project: false,
    opportunity_status: 'active',
  });
  const [detailData, setDetailData] = useState();
  const closeModal = () => {
    setShowModal(false);
    if (setDetailData) {
      setDetailData(false);
    }
    setFormData({
      name: '',
      description: '',
      opportunity_type: 'project_opportunity',
      payment_terms: 'weekly',
      expected_value: '',
      company: '',
      contact: currentTarget ? currentTarget.id : '',
      target_start: '',
      finish_date: '',
      budget: '',
      period: '1',
      connverted_to_project: false,
      opportunity_status: 'active',
    });
    setHtml('');
  };

  const user = useSelector((state) => state.userData.userData);

  const rows = projects
    .filter((item) => item?.opportunity_status !== 'archieved')
    .sort((a, b) => {
      // Assuming 'created_at' is a property in your project objects
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      // Sort in descending order (newest created first)
      return dateB - dateA;
    })
    .map((item) => {
      return {
        idForRequest: item.id,
        id: item.unique_id ? item.unique_id : item.id,
        contact: item?.contact,
        name: item.title ? item.title : item.name ? item.name : '-----',
        opportunity_type: item.opportunity_type
          ? item.opportunity_type === 'project_opportunity'
            ? 'Project Opportunity'
            : 'Retainer Opportunity'
          : item.type && item.type === 'project'
            ? 'Project Estimate'
            : 'Retainer Estimate',
        company:
          currentCompanies.find((c) => c.id === parseInt(item.company, 10))
            ?.name ?? '---------',
        target_start: item.target_start,
        budget: item.actual
          ? item.actual
          : item.opportunity_type
            ? item.budget
            : 0,
        month_to_month: item.month_to_month ? item.month_to_month : null,
        target_end: item.finish_date,
        formatedStartDate: item.target_start
          ? `${months[item.target_start.split('-')[1]]} ${
              item.target_start.split('-')[2]
            }, ${item.target_start.split('-')[0]}`
          : false,

        formatedEndDate: item.target_end
          ? `${new Date(item.target_end).getDate()} ${
              months[new Date(item.target_end).getMonth() + 1]
            } ${new Date(item.target_end).getFullYear()}`
          : false,
        duration:
          (item?.opportunity_type || item.type) &&
          (item.opportunity_type === 'retainer_opportunity' ||
            item.type === 'retainer')
            ? Number(item.duration)
            : item.target_start && item.finish_date
              ? Math.floor(
                  (new Date(item.finish_date) - new Date(item.target_start)) /
                    (1000 * 60 * 60 * 24),
                )
              : false,
        est_startdate: item.est_startdate
          ? moment(item.est_startdate).format('D MMM, YYYY')
          : '',
        est_enddate: item.est_enddate
          ? moment(item.est_enddate).format('D MMM, YYYY')
          : '',
        isOpa: 'name' in item,
        unique_id: item.unique_id || item.id,
        collaborator: item.collaborator,
        is_retainer: item.type === 'retainer',
        description: item.description ? item.description : '',
      };
    });

  const [searchQuery, setSearchQuery] = useState('');
  const filteredRow =
    rows && rows.filter((item) => item.opportunity_type === args);
  const rows2 = args === 'all' ? rows : filteredRow;
  const filteredSearch =
    rows2 &&
    rows2.filter((item) =>
      item.name.toLowerCase().startsWith(searchQuery.toLowerCase()),
    );
  const rows3 = searchQuery ? filteredSearch : rows2;

  const total =
    Array.isArray(rows3) && rows3.length
      ? rows3.reduce((total, acc) => {
          const isMonth =
            acc?.month_to_month === null &&
            (acc?.opportunity_type === 'Retainer Opportunity' ||
              acc?.opportunity_type === 'Retainer Estimate');
          const duration = Number(acc?.duration) || 1;
          const budget = Number(acc?.budget) || 0;
          return total + (isMonth ? duration * budget : budget);
        }, 0)
      : 0;

  const DurationData = (lastDate, firstDate) => {
    const newStartDate = new Date(firstDate);
    const newEndDate = new Date(lastDate);
    const firstDateImMs = newStartDate.getTime();
    const secoundDateINMs = newEndDate.getTime();
    const msDiff = secoundDateINMs - firstDateImMs;
    const daysInMs = 24 * 60 * 60 * 1000;
    const daysDifference = Math.round(msDiff / daysInMs);
    return daysDifference;
  };

  const [RealData, setRealData] = useState(null);
  // eslint-disable-next-line
  const [ConvertingData, setConvertingData] = useState();
  const [startDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();

  const [deliverables, setDeliverables] = useState(null);
  const handleOpenModalConversion = async (id) => {
    setModalConversion(true);
    const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
    });
    const getData = await resGet.json();
    setConvertingData(getData);

    const modifiedCode = {
      ...getData,
      deliverable: getData?.deliverable?.map((items) => ({
        ...items,
        duration: DurationData(
          items?.deliverable_enddate,
          items?.deliverable_startdate,
        ),
      })),
    };
    setRealData(modifiedCode);
    setDeliverables(modifiedCode?.deliverable);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      align: 'left',
      flex: 1.8,
      renderHeader: () => (
        <div className="tableHeader" style={{ padding: '0 0 0 1rem' }}>
          Name
        </div>
      ),
      renderCell: (params) => {
        const amIColab =
          Array.isArray(params.row.collaborator) &&
          params.row.collaborator.length
            ? checkCollaborators(params.row.collaborator, user)
            : false;

        return (
          <div
            className="td"
            style={{ padding: '0 0 0 1rem' }}
            onClick={async (e) => {
              e.preventDefault();
              if (params.row.isOpa) {
                const data = myArr.find(
                  (item) => item.id === params.row.idForRequest,
                );

                if (data) {
                  setDetailData(data);
                  setShowModal(true);
                }
              } else {
                const path = amIColab
                  ? `${appRoutes.collaborationDetail}/${params.row.unique_id}`
                  : `${appRoutes.estimateDetail}/${params.row.id}`;
                updateInnerNetNetTabs('Estimate Detail', path);
                routeTo(path);
              }
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'opportunity_type',
      headerName: 'Type',
      width: 300,
      flex: 1.8,
      renderHeader: () => <div className="tableHeader">Type</div>,
      renderCell: (params) => {
        const amIColab =
          Array.isArray(params.row.collaborator) &&
          params.row.collaborator.length
            ? checkCollaborators(params.row.collaborator, user)
            : false;

        return !amIColab ? (
          <div className="td">{params.value}</div>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <div className="td">{params.value}</div>
            <Tooltip
              title="You are a Collaborator on this Estimate"
              placement="bottom"
            >
              <img src={icon_colab} alt="Colaborator" />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'company',
      headerName: 'Company',
      flex: 1.8,
      renderHeader: () => <div className="tableHeader">Company</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'target_start',
      headerName: 'Target Start',
      flex: 1.8,
      renderHeader: () => <div className="tableHeader">Target Start</div>,
      renderCell: (params) => (
        <div className="td">
          {params.row.est_startdate ||
            (params.row.formatedStartDate
              ? `${params.row.formatedStartDate.split('T')[0]},${params.row.formatedStartDate.split(',')[1]}`
              : '------')}
        </div>
      ),
    },
    {
      field: 'budget',
      headerName: 'Budget',
      flex: 1.2,
      renderHeader: () => (
        <div style={{ height: '50px' }}>
          <div style={{ height: '50px' }} className="tableHeader">
            $ Total{' '}
          </div>{' '}
          <p
            style={{
              marginTop: '-2.5rem',
              textAlign: 'center',
              height: '20px',
              fontSize: '12px',
              color: '#000',
            }}
          >
            ${' '}
            {parseFloat(total).toLocaleString('en-US', {
              maximumFractionDigits: 0,
            })}
          </p>
        </div>
      ),
      renderCell: (params) => (
        <div
          className="td"
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {params.value ? (
            params.row.opportunity_type === 'Project Estimate' ||
            params.row.opportunity_type === 'Project Opportunity' ? (
              `$${params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            ) : params.row.month_to_month === null ? (
              <div>
                {`$${(
                  params.value *
                  Number(params.row.duration === 0 ? 1 : params.row.duration)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                <div className="date">
                  {`$${params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / Month`}
                </div>
              </div>
            ) : (
              `$${params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / Monthly`
            )
          ) : (
            '---'
          )}
        </div>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      align: 'right',

      flex: 0.5,
      sortable: false,
      filterable: false,
      renderHeader: () => (
        <div className="tableHeader" style={{ padding: '0 1rem 0 0' }} />
      ),
      renderCell: (params) => (
        <div className="td" style={{ padding: '0 1rem 0 0' }}>
          <Popup
            trigger={
              <button type="button" className="noBorder">
                <img src={moreIco} alt="more" />
              </button>
            }
            position="right center"
          >
            <ul className="popup" id={params.id}>
              <li
                onClick={async () => {
                  if (params?.row?.isOpa) {
                    const data = myArr.find(
                      (item) => item.id === params.row.idForRequest,
                    );

                    if (data) {
                      setDetailData(data);
                      setShowModal(true);
                    } else {
                      // eslint-disable-next-line
                      return;
                    }
                  } else {
                    updateInnerNetNetTabs(
                      'Estimate Detail',
                      `${appRoutes.estimateDetail}/${params.row.unique_id}`,
                    );
                    routeTo(
                      `${appRoutes.estimateDetail}/${params.row.unique_id}`,
                    );
                  }
                }}
                className="popupChildren"
              >
                <MdEdit style={{ marginRight: '5px' }} />
                Edit
              </li>
              <li
                className="popupChildren"
                onClick={() => {
                  if (!params.row.isOpa) {
                    handleOpenModalConversion(params.row.id);
                  } else if (params.row.isOpa) {
                    setConvertToEst(params.row);
                    updateInnerNetNetTabs(
                      'New Job Estimate',
                      appRoutes.newJobEstimate,
                    );
                    routeTo(appRoutes.newJobEstimate);
                  }
                }}
              >
                <LuServer style={{ marginRight: '5px' }} />

                {loading ? (
                  <i className="bx bx-loader-alt bx-spin" />
                ) : params.row.isOpa ? (
                  'Convert to Estimate'
                ) : (
                  'Activate Job'
                )}
              </li>
              <li
                className="popupChildren"
                onClick={async () => {
                  setCurrentTarget(params.row);
                  setSureDuplicatet(true);
                }}
              >
                <MdControlPointDuplicate style={{ marginRight: '5px' }} />{' '}
                Duplicate
              </li>
              <li
                className="popupChildren"
                onClick={() => {
                  setCurrentTarget(params.row);
                  setSureLost(true);
                }}
              >
                <MdOutlinePlaylistRemove style={{ marginRight: '5px' }} /> Lost
              </li>
              <li
                className="popupChildren LastChild"
                onClick={() => {
                  setCurrentTarget(params.row);
                  setSureDelete(true);
                }}
                style={{ color: 'red' }}
              >
                <MdOutlineDeleteForever
                  style={{ marginRight: '5px', color: 'red' }}
                />{' '}
                Delete
              </li>
            </ul>
          </Popup>
        </div>
      ),
    },
  ];
  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.map((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'Sales');
  });
  const [show, setShow] = useState(false);

  // this is start or end date

  const [steps, setSteps] = useState(0);

  const [expended, setExpended] = useState(null);
  const handleExpendRow = (index) => {
    setExpended(expended === index ? null : index);
  };

  const userData = useSelector((state) => state.userData.userData);

  const IncreaseDuration = (index) => {
    const addingDate = {
      ...RealData,
      est_startdate: RealData.est_startdate + 1,
    };
    setRealData(addingDate);

    setRealData((prevData) => {
      const newData = { ...prevData };
      newData.deliverable = [...prevData.deliverable];

      if (
        newData.deliverable[index].deliverable_enddate !== null &&
        newData.deliverable[index].deliverable_enddate !== null
      ) {
        newData.deliverable[index] = {
          ...newData.deliverable[index],
          duration: newData.deliverable[index].duration + 1,
          deliverable_enddate: dayjs(
            newData.deliverable[index].deliverable_enddate,
          )
            .add(1, 'day')
            .toISOString(),
        };
      }

      return newData;
    });
  };

  const DecreaseDuration = (index) => {
    setRealData((prevData) => {
      const newData = { ...prevData };
      newData.deliverable = [...prevData.deliverable];

      if (
        newData.deliverable[index].deliverable_enddate !== null &&
        newData.deliverable[index].deliverable_enddate !== null
      ) {
        newData.deliverable[index] = {
          ...newData.deliverable[index],
          duration: newData.deliverable[index].duration - 1,
          deliverable_enddate: dayjs(
            newData.deliverable[index].deliverable_enddate,
          )
            .subtract(1, 'day')
            .toISOString(),
        };
      }

      return newData;
    });
  };

  const TotalHours = (worktypes) => {
    const workHours = worktypes?.map((items) => {
      return items.hours;
    });

    const totalHours = workHours?.reduce((sum, hours) => sum + hours, 0);

    return totalHours;
  };

  const handleNextButton = () => {
    setSteps(1);
    const startDateObject = dayjs(startDate).toISOString();
    const endDateObject = dayjs(EndDate).toISOString();

    if (!Number.isNaN(startDateObject) && !Number.isNaN(endDateObject)) {
      const newConvertingData = {
        ...RealData,
        startDate: new Date(
          startDateObject.getTime() -
            startDateObject.getTimezoneOffset() * 60000,
        ).toISOString(),
        endDate: new Date(
          endDateObject.getTime() - endDateObject.getTimezoneOffset() * 60000,
        ).toISOString(),
      };
      setRealData(newConvertingData);
    }
  };

  const handleConvert = async () => {
    const shallowCopy = {
      ...RealData,
    };
    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;
    const condition =
      new Date(shallowCopy.est_enddate) < new Date(shallowCopy.est_startdate);
    const eststart = new Date(
      shallowCopy.est_startdate.split('T')[0],
    ).toISOString();
    const res = await fetch(
      `${ENDPOINT}/api/opportunity/update/estimate/${RealData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...shallowCopy,
          est_startdate: eststart,
          est_enddate:
            condition && !shallowCopy.est_enddate
              ? null
              : new Date(shallowCopy.est_enddate).toISOString(),
          estimate_status: 'won',
          month_to_month: RealData?.month_to_month ?? null,
          duration: RealData?.duration ?? 0,
          estimate_deliverable: shallowCopy.deliverable?.map((item) => {
            // eslint-disable-next-line
            delete item.task_deliverable;
            // eslint-disable-next-line
            delete item.deliverable_multiplier;
            return {
              ...item,
              deliverable_dependency: item.deliverable_dependency
                ? item.deliverable_dependency
                : null,
              deliver_id: item.id,
              deliverable_enddate: item.deliverable_enddate,
              deliverable_startdate: item.deliverable_startdate,
              deliverable_worktype: item.deliverable_worktype.map((wtt) => ({
                ...wtt,
                hours: wtt.hours ? Number(wtt.hours) : null,
              })),
            };
          }),
        }),
      },
    );

    if (res.ok) {
      dispatch(getEstimates({ access_token }));
      try {
        const response = await fetch(
          `${ENDPOINT}/api/opportunity/convert/estimate/${RealData.id}/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              title: RealData.title,
              converted_to_project: true,
              budget: RealData.budget ? RealData.budget : 0,
              type: RealData.type,
              status: 'active',
              creation_wizard_completed: true,
            }),
          },
        );
        const data = await response.json();
        if (!response.ok) {
          setIsError(true);
          setError(data.name);
          setLoading(false);
        } else {
          setLoading(false);
          setTimeout(() => {
            updateInnerNetNetTabs('Projects', appRoutes.jobs);
            routeTo(appRoutes.jobs);
          }, 1000);
        }
      } catch (err) {
        setIsError(true);
      }
    }
  };

  const [selected, setSelected] = useState({ deliverable_name: 'None' });

  const filteredOptions = (data) => {
    // Check if data is defined before filtering
    if (data) {
      const deliverableOptions = RealData?.deliverable.filter(
        (item) => item?.deliverable_name !== data?.deliverable_name,
      );

      // Add the "None" option at the beginning
      const optionsWithNone = [
        { deliverable_name: 'None' }, // You can customize the value as needed
        ...deliverableOptions,
      ];

      return optionsWithNone;
    }

    return [];
  };

  const [whiteLoading, setWhiteLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWhiteLoading(true);
    });
  }, [projects]);

  return (
    <>
      {showModal && (
        <Box
          onClick={() => setShowModal(false)}
          sx={{
            backgroundColor: 'transparent',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            zIndex: '1',
            top: 0,
            left: 0,
          }}
        />
      )}
      <div>
        <ModalMui
          open={ModalConversion}
          onClose={handleCloseModalConversion}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {steps === 0 ? (
            <Box
              align="center"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height: '94%',
                bgcolor: '#F2EBFF',
                borderRadius: 24,
                boxShadow: 24,
                px: 3,
                pt: 2,
                pb: 8,
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{
                  fontSize: '20px',
                  letterSpacing: '3px',
                  fontWeight: 600,
                  color: '#711FFF',
                  m: 3,
                }}
              >
                Activiting Project | Step 1 Or 2
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 10,
                  width: '98%',
                  height: '95%',
                  pt: 6,
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                  overflow: 'auto',
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{
                    fontSize: '27px',
                    fontWeight: 100,
                    wordSpacing: '3px',
                    letterSpacing: '4px',
                    color: 'Black',
                  }}
                >
                  Congratulations {userData?.first_name}
                </Typography>
                <Typography
                  id="modal-modal-title"
                  sx={{ color: '#00B28F', mt: 1 }}
                >
                  Lets get your project activated first, Please tell us the
                  start date
                </Typography>

                <Box
                  sx={{
                    backgroundColor: '#00B28F',
                    borderRadius: 2,
                    width: { xs: '300px', md: '415px' },
                    mt: 6,
                    py: 5,
                  }}
                >
                  <MdOutlineCalendarMonth
                    style={{ fontSize: '22px', color: 'white' }}
                  />
                  <Typography
                    id="modal-modal-title"
                    sx={{ fontsize: '21px', color: 'white' }}
                  >
                    Timeline
                  </Typography>
                </Box>
                <>
                  <Box
                    sx={{
                      backgroundColor: '#d0f1f7',
                      borderRadius: 2,
                      width: { xs: '300px', md: '400px' },
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 3,
                      px: 2,
                    }}
                  >
                    <Typography id="modal-modal-title" sx={{ color: 'black' }}>
                      Start Date
                    </Typography>
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(RealData?.est_startdate)}
                          onChange={(newValue) => {
                            setRealData({
                              ...RealData,
                              est_startdate: new Date(newValue).toISOString(),
                            });
                            setStartDate(newValue);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              mr: 2,
                            },
                            '& .MuiInputBase-root': {
                              width: '150px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              outline: 'none !important',
                              border: 'none !important',
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Box>
                  {RealData?.type === 'retainer' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        padding: '1.56rem 1rem',
                        backgroundColor: '#fff',

                        borderRadius: '8px',
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#f2f5f5',
                          width: { xs: '300px', md: '400px' },
                          padding: '10px 10px',
                          borderRadius: '10px',
                        }}
                      >
                        <p style={{ textAlign: 'start', fontSize: '17px' }}>
                          *Select your Term (Duration)
                        </p>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '15px',
                            marginTop: '10px',
                          }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <TextField
                              placeholder="____  ____  ____"
                              value={RealData?.duration}
                              onChange={(e) => {
                                setRealData({
                                  ...RealData,
                                  duration: e.target.value,
                                });
                              }}
                              style={{
                                width: 100,
                                backgroundColor: '#fff',
                                borderStartStartRadius: '10px',
                                borderEndStartRadius: '10px',
                              }}
                              color="secondary"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    border: 'none',
                                  },
                                  '&:hover fieldset': {
                                    border: 'none',
                                  },
                                  '&.Mui-focused fieldset': {
                                    border: 'none',
                                  },

                                  '&.Mui-error fieldset': {
                                    borderColor: 'red',
                                  },

                                  '&.Mui-disabled fieldset': {
                                    border: 'none',
                                  },
                                },
                                '& .MuiFormLabel-root': {
                                  color: '#03071e66',
                                },
                                '& .MuiFormLabel-root.Mui-focused': {
                                  color: '#5a0bdf',
                                },
                                '& .MuiFormLabel-root.Mui-error': {
                                  color: 'red',
                                },
                              }}
                              type="number"
                            />
                            <Box
                              sx={{
                                width: '80px',
                                backgroundColor: '#e6e6e6',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '0 10px',
                                borderStartEndRadius: '10px',
                                borderEndEndRadius: '10px',
                              }}
                            >
                              <p>Month(s)</p>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: '#ebeded',
                              width: { xl: '40%', lg: '50%', md: '50%' },
                              display: 'flex',
                              padding: '10px 10px',
                              gap: { xl: '15px', lg: '6px', md: '5px' },
                              borderRadius: '10px',
                              alignItems: 'center',
                            }}
                          >
                            <TextField
                              type="checkbox"
                              color="secondary"
                              sx={{
                                outline: 'none',
                                width: '20px',
                                height: '20px',
                              }}
                              onChange={() =>
                                setRealData({
                                  ...RealData,
                                  month_to_month: 'month',
                                })
                              }
                            />
                            <RepeatIcon sx={{ cursor: 'pointer' }} />

                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: { xl: '16px', lg: '9px' },
                              }}
                            >
                              Month to Month
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: '#d0f1f7',
                        borderRadius: 2,
                        width: { xs: '300px', md: '400px' },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 3,
                        px: 2,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        sx={{ color: 'black' }}
                      >
                        End Date
                      </Typography>
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={dayjs(RealData?.est_enddate)}
                            onChange={(newValue) => {
                              setRealData({
                                ...RealData,
                                est_enddate: new Date(newValue).toISOString(),
                              });
                              setEndDate(newValue);
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                mr: 2,
                              },
                              '& .MuiInputBase-root': {
                                width: '150px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                outline: 'none !important',
                                border: 'none !important',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  )}
                </>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 8,
                    pb: 4,
                    width: { xs: '300px', md: '340px' },
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleCloseModalConversion}
                    sx={{
                      borderColor: '#711FFF',
                      color: '#711FFF',
                      width: '100px',
                      '&:hover': { borderColor: '#711FFF' },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => handleNextButton()}
                    sx={{
                      backgroundColor: '#711FFF',
                      color: 'white',
                      width: '100px',
                      '&:hover': { backgroundColor: '#711FFF' },
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              align="center"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height: '94%',
                bgcolor: '#F2EBFF',
                borderRadius: 24,
                boxShadow: 24,
                px: 3,
                pt: 2,
                pb: 1,
                '@media screen and (max-width: 1605px)': {
                  width: '95%',
                },
                '@media screen and (max-width: 1168px)': {
                  width: '98%',
                },
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{
                  fontSize: '20px',
                  letterSpacing: '3px',
                  fontWeight: 600,
                  color: '#711FFF',
                  m: 3,
                }}
              >
                Activiting Project | Step 2 Of 2
              </Typography>
              <Box
                sx={{
                  '@media screen and (max-width: 1605px)': {
                    width: '95%',
                    height: '88%',
                  },
                  '@media screen and (max-height: 730px)': {
                    width: '95%',
                    height: '84%',
                  },
                  backgroundColor: 'white',
                  borderRadius: 10,
                  width: '99%',
                  height: '88%',
                  mb: 4,
                  pt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                }}
              >
                <Box sx={{ overflow: 'auto', height: '100%' }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontSize: '27px',
                      fontWeight: 100,
                      wordSpacing: '3px',
                      letterSpacing: '4px',
                      color: 'Black',
                    }}
                  >
                    Awesome {userData?.first_name}
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    sx={{ color: '#00B28F', m: 3 }}
                  >
                    Based on your anticipated start date and the durations you
                    previously set for this project, here is the anticipated
                    timeline for the project. Please confirm this is accurate or
                    feel free to make any changes before you confirm
                  </Typography>
                  <TableContainer
                    sx={{
                      padding: '10px',
                      height: '525px',
                    }}
                  >
                    <Table>
                      <TableBody sx={{}}>
                        {RealData &&
                          RealData?.deliverable?.map((data, index) => {
                            return (
                              <TableRow
                                key={data?.deliverable_name}
                                sx={{
                                  width: '100%',
                                  mb: 3,
                                  transition: '0.3s ease',
                                  height: expended === index ? '250px' : '50px',
                                }}
                              >
                                <TableCell sx={{ width: '290px' }}>
                                  <Grid
                                    container
                                    display="flex"
                                    justifyContent="start"
                                    textAlign="start"
                                    alignItems="center"
                                    fontWeight={700}
                                    minHeight="55px"
                                    fontSize="14.9px"
                                    color="#310085"
                                    sx={{
                                      '@media screen and (max-width: 1890px)': {
                                        fontSize: '14.7px',
                                        width: '185px',
                                      },
                                    }}
                                  >
                                    {data?.deliverable_name}
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{ color: '#310085', width: '170px' }}
                                >
                                  <Grid container width="140px">
                                    <Grid
                                      item
                                      fontSize={12}
                                      fontWeight={700}
                                      marginLeft={2}
                                      minHeight="95px"
                                      width="100%"
                                      borderRadius={4}
                                      bgcolor="#F8F9FA"
                                      padding="2px"
                                      lineHeight={2}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      textAlign="center"
                                    >
                                      <span>Level Of Efforts</span>
                                      <span
                                        style={{
                                          color: '#711FFF',
                                          fontSize: '15px',
                                          fontWeight: 200,
                                        }}
                                      >
                                        {data?.deliverable_worktype.length <= 1
                                          ? 0
                                          : TotalHours(
                                              data?.deliverable_worktype,
                                            )}{' '}
                                        hr
                                      </span>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: '#310085',
                                    width: '255px',
                                    '@media screen and (max-width: 1406px)': {
                                      width: '450px',
                                    },
                                  }}
                                >
                                  <Grid
                                    container
                                    width="217px"
                                    marginLeft={0.2}
                                    minHeight="55px"
                                    borderRadius={4}
                                    bgcolor="#F8F9FA"
                                    padding="8px"
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    textAlign="center"
                                  >
                                    <span>
                                      <Grid
                                        item
                                        fontSize={15}
                                        margin={1}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        lineHeight="19px"
                                        padding={1}
                                        width="73px"
                                        marginBottom={2}
                                        fontWeight={700}
                                        borderBottom="1px dashed #ADADAD"
                                      >
                                        Duration
                                        <span
                                          style={{
                                            display: 'flex',
                                            color: '#711FFF',
                                          }}
                                        >
                                          {data?.duration}
                                        </span>
                                      </Grid>
                                    </span>
                                    <span>
                                      <Grid
                                        item
                                        fontSize={15}
                                        margin={1}
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        lineHeight="19px"
                                        padding={1}
                                        width="85px"
                                        borderLeft="2px solid #ADADAD"
                                        color="#03071E80"
                                      >
                                        <span
                                          style={{
                                            marginInline: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <button
                                            type="button"
                                            onClick={() =>
                                              IncreaseDuration(index)
                                            }
                                          >
                                            {' '}
                                            <ArrowDropUpIcon
                                              sx={{ background: '#F8F9FA' }}
                                            />
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() =>
                                              DecreaseDuration(index)
                                            }
                                          >
                                            {' '}
                                            <ArrowDropDownIcon
                                              sx={{ background: '#F8F9FA' }}
                                            />
                                          </button>
                                        </span>
                                        Days
                                      </Grid>
                                    </span>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{ color: '#310085', width: '245px' }}
                                >
                                  <Grid
                                    container
                                    bgcolor="#F8F9FA"
                                    minHeight="15px"
                                    borderRadius={4}
                                    padding="1px"
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    textAlign="center"
                                    sx={{}}
                                  >
                                    <Grid
                                      item
                                      width="316px"
                                      fontSize={15}
                                      margin={1}
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="center"
                                      alignItems="start"
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'start',
                                          textAlign: 'start',
                                          margin: 0.4,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: '12px',
                                            fontWeight: 700,
                                            color: '#310085',
                                          }}
                                        >
                                          DEPENDENCY
                                        </Typography>
                                        <Autocomplete
                                          options={filteredOptions(data)}
                                          defaultValue={
                                            data?.deliverable_dependency &&
                                            deliverables?.find(
                                              (item) =>
                                                item.id ===
                                                data.deliverable_dependency,
                                            )
                                          }
                                          onChange={(_, value) => {
                                            setSelected(value);
                                            setRealData((prevRealData) => {
                                              const updatedDeliverables = {
                                                ...prevRealData,
                                                deliverable: (
                                                  prevRealData?.deliverable ||
                                                  []
                                                ).map((item) => {
                                                  if (item?.id === data?.id) {
                                                    return {
                                                      ...item,
                                                      deliverable_dependency:
                                                        value?.id,
                                                    };
                                                  }
                                                  setSelected(value);
                                                  return item;
                                                }),
                                              };
                                              return updatedDeliverables;
                                            });
                                          }}
                                          getOptionLabel={(option) => {
                                            return option?.deliverable_name;
                                          }}
                                          style={{ width: 320 }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="Please Select a task to begin"
                                              color="secondary"
                                              sx={{
                                                paddingBottom: '3px',
                                                borderRadius: '8px',
                                                background: '#F8F9FA',
                                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                  {
                                                    color: 'black',
                                                  },
                                                borderBottom:
                                                  '1px dashed #ADADAD',
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-focused fieldset': {
                                                    border: 'none',
                                                  },
                                                  '&.Mui-error fieldset': {
                                                    borderColor: 'red',
                                                  },
                                                },
                                                '& .MuiFormLabel-root': {
                                                  color: '#03071e66',
                                                },
                                                '& .MuiFormLabel-root.Mui-focused':
                                                  {
                                                    color: '#5a0bdf',
                                                  },
                                                '& .MuiFormLabel-root.Mui-error':
                                                  {
                                                    color: 'red',
                                                  },
                                              }}
                                              InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                  width: '100%',
                                                  color:
                                                    selected === 'None'
                                                      ? '#310085'
                                                      : 'green',
                                                },
                                              }}
                                            />
                                          )}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: '#310085',
                                    width: '265px',
                                    position: 'relative',
                                  }}
                                  colSpan={4}
                                >
                                  <Grid
                                    container
                                    minHeight="55px"
                                    width="260px"
                                    borderRadius={4}
                                    bgcolor="#F8F9FA"
                                    padding="4px"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                  >
                                    <Grid
                                      item
                                      fontSize={13}
                                      fontWeight={700}
                                      margin={0.6}
                                      display="flex"
                                      justifyContent="start"
                                      lineHeight="2px"
                                      padding={0.5}
                                      color="#004D3D"
                                      width="73px"
                                    >
                                      <span
                                        onClick={() => handleExpendRow(index)}
                                      >
                                        Due Date
                                      </span>
                                    </Grid>
                                    <Grid
                                      item
                                      fontSize={13}
                                      margin={0.6}
                                      display="flex"
                                      justifyContent="start"
                                      lineHeight="2px"
                                      padding={0.7}
                                      color="#008066"
                                      width="100%"
                                      sx={{
                                        '@media screen and (max-width:1410px)':
                                          {
                                            width: '250px',
                                            fontSize: 17,
                                          },
                                      }}
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DesktopDatePicker
                                          format="YYYY-MM-DD"
                                          sx={{
                                            '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                                              {
                                                color: '#008066',
                                                marginRight: '0px',
                                                position: 'absolute',
                                                borderRadius: '0px !important',
                                                width: '95px !important',
                                                height: '25px',
                                              },
                                            '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                                              {
                                                color: '#008066',
                                                marginRight: '0px',
                                                position: 'absolute',
                                                borderRadius: '0px !important',
                                                width: '95px !important',
                                                height: '25px',
                                              },
                                            '& .MuiInputAdornment-root': {
                                              color: '#008066',
                                              position: 'absolute',
                                              top: '10px',
                                              left: '-15px',
                                              borderRadius: '0px',
                                              width: '95px',
                                            },

                                            '& .MuiSvgIcon-root': {
                                              display: 'none',
                                            },
                                            '& .MuiInputBase-root': {
                                              width: '100%',
                                              position: 'relative',
                                            },
                                            '& .MuiOutlinedInput-input': {
                                              color: '#008066',
                                              background: '#F8F9FA',
                                              width: '80px',
                                              height: '15px',
                                              padding: '0px',
                                              borderBottom:
                                                '2px dotted lightgray',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline':
                                              {
                                                outline: 'none !important',
                                                border: 'none !important',
                                                width: '80px',
                                                height: '70px',
                                              },
                                          }}
                                          value={
                                            data?.est_enddate === ''
                                              ? 'null'
                                              : dayjs(data?.deliverable_enddate)
                                          }
                                          onChange={async (newValue) => {
                                            setRealData((prevRealData) => {
                                              const updatedDeliverables = {
                                                ...prevRealData,
                                                deliverable: (
                                                  prevRealData?.deliverable ||
                                                  []
                                                ).map((item, i) => {
                                                  if (index === i) {
                                                    // Update only the specific item at the provided index
                                                    return {
                                                      ...item,
                                                      deliverable_enddate:
                                                        dayjs(
                                                          newValue,
                                                        ).format(),
                                                      duration: DurationData(
                                                        dayjs(
                                                          newValue,
                                                        ).format(),
                                                        item?.deliverable_startdate,
                                                      ),
                                                    };
                                                  }
                                                  return item;
                                                }),
                                              };
                                              return updatedDeliverables;
                                            });
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              sx={{
                                                '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                    border: '1px solid #d4e8fb',
                                                  },
                                                  '&:hover fieldset': {
                                                    border: '1px solid #d4e8fb',
                                                  },
                                                  '&.Mui-error fieldset': {
                                                    border: '1px solid #d4e8fb',
                                                  },
                                                },
                                              }}
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                    <span
                                      style={{
                                        border: '1px dashed #ADADAD',
                                        width: '95%',
                                        margin: '5px',
                                      }}
                                    />
                                  </Grid>
                                  {expended === index && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '4px',
                                        position: 'absolute',
                                        right: '7%',
                                        top: '74%',
                                        width: '620px',
                                      }}
                                    >
                                      <Button
                                        sx={{
                                          background: '#F8F9FA',
                                          padding: '10px 29px',
                                          color: 'red',
                                          fontWeight: 200,
                                          borderRadius: '15px',
                                        }}
                                      >
                                        I Confirm This Estimate Dates{' '}
                                      </Button>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          ml: 18,
                                        }}
                                      >
                                        <Button sx={{ mr: 4, color: 'black' }}>
                                          {' '}
                                          Cancell{' '}
                                        </Button>
                                        <Button
                                          sx={{
                                            color: 'white',
                                            padding: '0px 24px',
                                            background: '#711FFF',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          {' '}
                                          Save{' '}
                                        </Button>
                                      </Box>
                                    </Box>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      pb: 4,
                      width: { xs: '300px', md: '80%' },
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => setSteps(0)}
                      sx={{
                        borderColor: '#711FFF',
                        color: '#711FFF',
                        width: '100px',
                        '&:hover': { borderColor: '#711FFF' },
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => handleConvert()}
                      variant="contained"
                      sx={{
                        backgroundColor: '#711FFF',
                        color: 'white',
                        width: '100px',
                        '&:hover': { backgroundColor: '#711FFF' },
                      }}
                    >
                      Finish
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </ModalMui>
      </div>
      {whiteLoading ? (
        Array.isArray(projects) && projects.length ? (
          <Box sx={{ height: '100vh' }}>
            {' '}
            <Bounce left>
              <>
                {megaLoading && (
                  <section
                    className="loading"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh',
                      width: '100%',
                      backgroundColor: 'rgba(255,255,255,0.5)',
                      position: 'fixed',
                      top: 0,
                      left: 0,
                    }}
                  >
                    {!IsError && (
                      <Box
                        sx={{
                          width: '100px',
                          height: '100px',
                        }}
                      >
                        <PreLoader />
                      </Box>
                    )}
                    {IsError && (
                      <div
                        style={{
                          gap: '0.5rem',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h2 className="sub-heading sentSuc fsMedium">
                          Conversion Failed
                        </h2>
                        <p className="fstxt">Cannot Convert This Opportunity</p>
                        <p className="fstxt">{error}</p>
                        <button
                          type="button"
                          className="btn btnSm"
                          onClick={() => setMegaLoading(false)}
                        >
                          Continue
                        </button>
                      </div>
                    )}
                  </section>
                )}
                <section className="oppor-section">
                  <div className="oppor-row">
                    <div className="oppor-col">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 className="fstxtPro">Sales</h3>
                        <h5>(opportunities & estimates)</h5>
                      </div>
                      <img className="icon-sm" src={Dots} alt="dots" />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          background: 'inherit',
                          border: 'none',
                          outline: 'none',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            background: 'inherit',
                            border: 'none',
                            outline: 'none',
                          }}
                          onClick={() => {
                            setFilterOptions(true);
                          }}
                          id="filter"
                        >
                          <img
                            id="filter"
                            className="icon-sm"
                            src={Gear}
                            alt="gear"
                          />
                        </button>
                      </div>
                      <img
                        alt="help-video"
                        src={helpVid}
                        onClick={() => {
                          setShow(true);
                        }}
                        style={{
                          color: '#a6a6a6',
                          marginLeft: '10px',
                          width: '28px',
                          height: '28px',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                    <button
                      type="button"
                      className="create-btn"
                      id="c-btn"
                      onClick={handleCreation}
                    >
                      + New
                    </button>
                    {makeNew ? (
                      <Dropdown
                        updateInnerNetNetTabs={updateInnerNetNetTabs}
                        setShowModal={setShowModal}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <Box
                    sx={{
                      width: '100%',
                      minHeight: screenHeight - 0,
                      maxHeight: screenHeight - 0,
                      borderRadius: '8px',
                    }}
                  >
                    <PrevBox
                      setArgs={setArgs}
                      args={args}
                      setSearchQuery={setSearchQuery}
                      searchQuery={searchQuery}
                      sx={{
                        borderRadius: '8px',
                      }}
                    />

                    <DataGridPro
                      rows={rows3}
                      columns={columns}
                      rowHeight={60}
                      sx={{
                        minHeight: screenHeight - 225,
                        maxHeight: screenHeight - 225,
                        overflow: 'auto',
                        borderRadius: '8px',
                      }}
                    />
                  </Box>
                </section>

                <Offcanvas
                  show={filterOptions}
                  onHide={() => setFilterOptions(false)}
                  scroll={false}
                  backdrop={true}
                  placement="bottom"
                >
                  <Offcanvas.Header>
                    <h4 className="sub-heading">Opportunities Filters</h4>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <section className="filterBody">
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          width: '74%',
                        }}
                      >
                        <div className="fstxt">Type :</div>

                        <FormControlLabel
                          control={
                            <Switch
                              checked={filterBase.includes('retainer')}
                              color="secondary"
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  setFilterBase(
                                    filterBase.filter(
                                      (item) => item !== 'retainer',
                                    ),
                                  );
                                } else {
                                  setFilterBase([...filterBase, 'retainer']);
                                }
                              }}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#711fff',
                                },
                                '&.Mui-checked:hover': {
                                  backgroundColor: '#711fff',
                                },
                              }}
                            />
                          }
                          label={<div className="fstxt">Retainer</div>}
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={filterBase.includes('project')}
                              color="secondary"
                              onChange={(e) => {
                                if (!e.target.checked) {
                                  setFilterBase(
                                    filterBase.filter(
                                      (item) => item !== 'project',
                                    ),
                                  );
                                } else {
                                  setFilterBase([...filterBase, 'project']);
                                }
                              }}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#711fff',
                                },
                                '&.Mui-checked:hover': {
                                  backgroundColor: '#711fff',
                                },
                              }}
                            />
                          }
                          label={<div className="fstxt">Project</div>}
                        />
                      </FormGroup>
                    </section>
                    <section className="dFlex">
                      <button
                        aria-label="close-modal"
                        type="button"
                        style={{ marginTop: '1rem' }}
                        className="close-modal"
                        onClick={() => setFilterOptions(false)}
                      />
                      <section className="AlRight">
                        <button
                          type="button"
                          className="cancel-btn"
                          onClick={() => {
                            setFilterOptions(false);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="create-btn"
                          onClick={() => {
                            setProjects(
                              projects.filter((item) =>
                                filterBase.includes(item.opportunity_type),
                              ),
                            );
                          }}
                        >
                          Apply
                        </button>
                      </section>
                    </section>
                  </Offcanvas.Body>
                </Offcanvas>
                {sureDelete && (
                  <SureDeleteOpp
                    setSureDelete={setSureDelete}
                    currentTarget={currentTarget}
                  />
                )}
                {sureLost && (
                  <SureLost
                    setSureLost={setSureLost}
                    currentTarget={currentTarget}
                  />
                )}
                {sureDuplicate && (
                  <SureDuplicate
                    setSureDuplicate={setSureDuplicatet}
                    currentTarget={currentTarget}
                    myArr2={myArr2}
                  />
                )}
              </>
            </Bounce>
            {showModal && (
              <Drawer
                sx={{
                  '& .MuiDrawer-paper': {
                    maxHeight: '82vh !important',
                  },
                }}
                open={showModal}
                onClose={closeModal}
              >
                <Modal
                  html={html}
                  setHtml={setHtml}
                  formData={formData}
                  setFormData={setFormData}
                  detailData={detailData}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setDetailData={setDetailData}
                />
              </Drawer>
            )}
            <HelpVideo
              show={show}
              setShow={setShow}
              welcomeChapters={welcomeChapters}
            />
          </Box>
        ) : (
          <>
            <section className="oppor-section">
              <div className="oppor-row">
                <div className="oppor-col">
                  <h3 className="fstxtPro">Opportunities</h3>
                  <img className="icon-sm" src={Dots} alt="dots" />
                  <img className="icon-sm" src={Gear} alt="gear" />
                  <p>Filters</p>
                  <img
                    alt="help-video"
                    src={helpVid}
                    onClick={() => {
                      setShow(true);
                    }}
                    style={{
                      color: '#a6a6a6',
                      marginLeft: '10px',
                      width: '28px',
                      height: '28px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </div>
              <section className="myCenter">
                <article className="box-txt">
                  <h3 className="txtCenter">No Opportunities yet</h3>
                  <p className="txtCenter">
                    All Your Opportunities Would Appear Here!
                  </p>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => setShowDrop(true)}
                  >
                    Create New
                  </button>
                </article>
              </section>
            </section>

            <Modal showModal={showModal} setShowModal={setShowModal} />
            <Backdrop open={showDrop}>
              <InfoCreate
                updateInnerNetNetTabs={updateInnerNetNetTabs}
                setShowDrop={setShowDrop}
                setShowModal={setShowModal}
              />
            </Backdrop>
          </>
        )
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '4rem 1rem',
          }}
        >
          <Skeleton
            sx={{ background: '#f0f0f0' }}
            variant="rounded"
            width="100%"
            height={80}
            animation="wave"
          />
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 2, 3, 3].map((item) => {
            return (
              <Skeleton
                key={item}
                sx={{ background: '#f0f0f0' }}
                variant="rounded"
                width="100%"
                height="40px"
                animation="wave"
              />
            );
          })}
        </Box>
      )}
    </>
  );
}

export default Opportunity;

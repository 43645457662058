import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid } from '@mui/material';
import RepeatIcon from '@mui/icons-material/Repeat';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import dayjs from 'dayjs';

import Shake from 'react-reveal/Shake';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import './Modal.css';
import { PreLoader1 } from '../Misc';
import RichTextEditor from '../atomic-organisms/RichTextEditor/RichTextEditor';
import { ENDPOINT } from '../../pages/Opportunity/Redux/oppor';

function ClientInformation({
  formData,
  setFormData,
  currentTarget,
  currentCompany,
  detailData,
}) {
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const currentPeoples = useSelector((state) => state.Contacts.persons);
  const companyOptions = currentCompanies.map((company) => ({
    name: company.name,
    id: company.id,
  }));

  const comp = formData?.company
    ? companyOptions.filter((item) => item.id === formData?.company)
    : null;
  const peo = comp
    ? currentPeoples.filter((item) => item.company === comp[0]?.name)
    : currentPeoples;

  const peopleOptions = peo.map((people) => ({
    name: `${people.first_name} ${people.last_name}`,
    id: people.id,
  }));
  return (
    <Grid sx={{ width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <h3 className="sub-title">Client Information</h3>
        <Box
          sx={{
            backgroundColor: '#f2ebff',
            padding: '18px 23px',
            borderRadius: '8px',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span className="modalSpan">Company</span>
            <Autocomplete
              id="company"
              options={companyOptions}
              includeInputInList
              value={
                formData?.company
                  ? companyOptions?.find(
                      (item) => item.id === formData?.company,
                    )
                  : currentCompany
                    ? {
                        name: currentCompany?.name,
                        id: currentCompany?.id,
                      }
                    : currentTarget
                      ? {
                          name: currentTarget?.company,
                          id: currentTarget?.id,
                        }
                      : detailData?.company
                        ? companyOptions?.find(
                            (item) => item?.id === detailData?.company,
                          )
                        : { name: '', id: '' }
              }
              onChange={(e, value) => {
                if (value) {
                  setFormData({
                    ...formData,
                    company: value.id,
                  });
                } else {
                  // Handle the case where value is null (clear icon clicked)
                  setFormData({
                    ...formData,
                    company: null, // or any other appropriate action
                  });
                }
              }}
              getOptionLabel={(option) => {
                return option.name;
              }}
              style={{ width: '100%', marginTop: '0.7rem' }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Enter Company Name"
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f2ebff',
                      },
                      '&:hover fieldset': {
                        borderColor: '#f2ebff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#5a0bdf',
                      },

                      '&.Mui-error fieldset': {
                        borderColor: 'red',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { width: '100%' },
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span className="modalSpan ">Person</span>
            <Autocomplete
              id="company"
              options={peopleOptions}
              includeInputInList
              value={
                currentTarget
                  ? {
                      name: `${currentTarget?.first_name || ''} ${currentTarget?.last_name || ''}`,
                      id: currentTarget.id,
                    }
                  : formData && formData.contact
                    ? peopleOptions.find((item) => item.id === formData.contact)
                    : detailData && detailData.contact
                      ? peopleOptions.find(
                          (item) => item.id === detailData.contact,
                        )
                      : null
              }
              onChange={(e, value) => {
                if (value) {
                  setFormData({
                    ...formData,
                    contact: value.id,
                  });
                } else {
                  // Handle the case where value is null (clear icon clicked)
                  setFormData({
                    ...formData,
                    contact: null, // or any other appropriate action
                  });
                }
              }}
              getOptionLabel={(option) => option.name}
              style={{ width: '100%', marginTop: '0.7rem' }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Enter Person Name"
                  variant="outlined"
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f2ebff',
                      },
                      '&:hover fieldset': {
                        borderColor: '#f2ebff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#5a0bdf',
                      },

                      '&.Mui-error fieldset': {
                        borderColor: 'red',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    style: { width: '100%' },
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

const spanSx = {
  fontWeight: '600',

  fontSize: '1.1rem',
};

function SummaryInformation({
  formData,
  setFormData,
  html,
  setHtml,
  detailData,
  check,
}) {
  const sumInfoSx = {
    backgroundColor: '#F1F3F5E5',
    height: '100%',
    width: '100%',
    padding: '18px 24px 18px 24px',
    borderRadius: '8px',
    gap: '1rem',
    boxShadow: '0px 8px 25px 0px #00000000',
  };

  const [selected, setSelected] = useState(1);
  useEffect(() => {
    if (detailData && formData) {
      setSelected(
        detailData.opportunity_type === 'Retainer Opportunity' ? 2 : 1,
      );
    }
  }, [detailData]);

  const [openLink, setOpenLink] = useState(false);
  return (
    <Grid
      item
      container
      sx={sumInfoSx}
      direction="column"
      alignItems="flex-start"
    >
      <h3 className="sub-title">Summary Information</h3>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.5rem',
          width: '100%',
        }}
      >
        <span style={spanSx}>Enter Your Opportunity&apos;s Name</span>
        <TextField
          id="Cypess-test-OpporName"
          type="text"
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
          variant="outlined"
          color="secondary"
          placeholder="Enter Opportunity"
          InputLabelProps={{
            style: {
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
            },
          }}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ADADAD',
              },
              '&:hover fieldset': {
                borderColor: '#711fff',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#5a0bdf',
              },

              '&.Mui-error fieldset': {
                borderColor: 'red',
              },

              '&.Mui-disabled fieldset': {
                borderColor: 'grey',
              },
            },
            '& .MuiFormLabel-root': {
              color: '#03071e66',
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: '#5a0bdf',
            },
            '& .MuiFormLabel-root.Mui-error': {
              color: 'red',
            },
          }}
          InputProps={{
            style: {
              lineHeight: 'normal',
              backgroundColor: 'white',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1rem',
          width: '100%',
        }}
      >
        <span style={spanSx}>Select Your Opportunity Type</span>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: '#E0E0DF',
            padding: '0.88rem 1rem',
            borderRadius: '8px',
            width: '100%',
            gap: '1rem',
          }}
        >
          <button
            type="button"
            className={selected === 1 ? 'estaBtn slctd' : 'estaBtn'}
            onClick={() => {
              setSelected(1);

              setFormData({
                ...formData,
                description: formData.description,
                opportunity_type: 'project_opportunity',
              });
            }}
          >
            Project
          </button>
          <button
            type="button"
            className={selected === 2 ? 'estaBtn slctd' : 'estaBtn'}
            onClick={() => {
              setSelected(2);

              setFormData({
                ...formData,
                description: formData.description,
                opportunity_type: 'retainer_opportunity',
              });
            }}
          >
            Retainer
          </button>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#FBFBFB',
          padding: '0.88rem 1rem',
          borderRadius: '8px',
          height: 'fit-content',
        }}
      >
        <span
          style={{
            fontWeight: '600',
            fontSize: '17px',
          }}
        >
          Description
        </span>

        <RichTextEditor
          open={openLink}
          setOpen={setOpenLink}
          setHtml={setHtml}
          html={html}
          check={check}
        />
      </Box>
    </Grid>
  );
}

function TargetBudget({ formData, setFormData, detailData, setDetailData }) {
  function updateBudgetOnFormData(e) {
    const rawValue = e.target.value.replace(/\D/g, '');
    const numericValue = parseFloat(rawValue);

    if (!Number.isNaN(numericValue) && numericValue >= 0) {
      setFormData({
        ...formData,
        budget: numericValue,
      });
    } else if (rawValue === '') {
      setFormData({
        ...formData,
        budget: 0,
      });
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        padding: '1.56rem 1rem',
        backgroundColor: '#fff',
        border: '1px solid #d9d9d9',
        borderRadius: '8px',
        margin: '1rem 0',
        gap: '1rem',
        transition: '0.5s ease-in-out',
      }}
    >
      <h3 className="sub-title">Target Goals</h3>

      {formData.opportunity_type === 'project_opportunity' ? (
        <>
          <Box
            sx={{
              width: '100%',
              backgroundColor: '#d2b8ff',
              borderRadius: '8px',
              padding: '1rem 1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p className="baseTypography2">Target Budget</p>
            <Box
              sx={{
                display: 'flex',
                width: { xl: '80%', lg: '75%', md: '73%' },
              }}
            >
              <TextField
                id="Cypress-test-TargetBudget"
                placeholder="Enter Amount"
                defaultValue={
                  formData.budget
                    ? Number(formData.budget).toLocaleString()
                    : '0'
                }
                type="text"
                value={
                  formData.budget
                    ? Number(formData.budget).toLocaleString()
                    : '0'
                }
                onChange={(e) => {
                  updateBudgetOnFormData(e);
                }}
                style={{
                  backgroundColor: '#fff',
                  borderStartStartRadius: '10px',
                  borderEndStartRadius: '10px',
                }}
                color="secondary"
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },

                    '&.Mui-error fieldset': {
                      borderColor: 'red',
                    },

                    '&.Mui-disabled fieldset': {
                      border: 'none',
                    },
                  },
                  '& .MuiFormLabel-root': {
                    color: '#03071e66',
                  },
                  '& .MuiFormLabel-root.Mui-focused': {
                    color: '#5a0bdf',
                  },
                  '& .MuiFormLabel-root.Mui-error': {
                    color: 'red',
                  },
                }}
              />
              <Box
                sx={{
                  width: { xl: '100px', lg: '75px', md: '75px' },
                  backgroundColor: '#e6e6e6',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 10px',
                  borderStartEndRadius: '10px',
                  borderEndEndRadius: '10px',
                  justifyContent: 'center',
                }}
              >
                <p>USD</p>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              backgroundColor: '#d4e8fb',
              borderRadius: '8px',
              padding: '1rem 1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p className="baseTypography2">Est Start Date</p>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM-dd-yyyy"
                value={dayjs(formData.target_start) || null}
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    target_start: newValue.$d.toISOString(),
                  });
                }}
                sx={{
                  width: '80%',
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                    {
                      color: '#711FFF',
                    },
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                    {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                  '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                    marginRight: '0px',
                    position: 'absolute',
                    borderRadius: '0px !important',
                    width: '100% !important',
                    height: '60px',
                  },
                  '& .MuiInputAdornment-root': {
                    position: 'absolute',
                    top: '28px',
                    left: '-6px',
                    borderRadius: '0px',
                    width: '100%',
                  },
                  '& .MuiSvgIcon-root': {
                    position: 'absolute',
                    right: '10px',
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                    position: 'relative',
                  },
                  '& .MuiOutlinedInput-input': {
                    width: '100%',
                    height: '60px',
                    padding: '0px',
                    color: 'black',
                    marginLeft: '10px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    width: '100%',
                    height: '60px',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{
                      width: { xl: '81%', lg: '77%', md: '76%' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '&.Mui-error fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '& .MuiFormLabel-root': {
                          color: '#000',
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box
            sx={{
              width: '100%',
              backgroundColor: '#d4e8fb',
              borderRadius: '8px',
              padding: '1rem 1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p className="baseTypography2">Est Finish Date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Finish Date"
                inputFormat="MM-dd-yyyy"
                value={dayjs(formData.finish_date) || null}
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    finish_date: newValue.$d.toISOString(),
                  });
                }}
                sx={{
                  width: '80%',
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                    {
                      color: '#711FFF',
                    },
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                    {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                  '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                    marginRight: '0px',
                    position: 'absolute',
                    borderRadius: '0px !important',
                    width: '100% !important',
                    height: '60px',
                  },
                  '& .MuiInputAdornment-root': {
                    position: 'absolute',
                    top: '28px',
                    left: '-6px',
                    borderRadius: '0px',
                    width: '100%',
                  },
                  '& .MuiSvgIcon-root': {
                    position: 'absolute',
                    right: '10px',
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                    position: 'relative',
                  },
                  '& .MuiOutlinedInput-input': {
                    width: '100%',
                    height: '60px',
                    padding: '0px',
                    color: 'black',
                    marginLeft: '10px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    width: '100%',
                    height: '60px',
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{
                      width: { xl: '81%', lg: '77%', md: '76%' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '&.Mui-error fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '& .MuiFormLabel-root': {
                          color: '#000',
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{ background: '#d2b8ff', width: '100%', borderRadius: '8px' }}
          >
            <Box
              sx={{
                width: '52%',
                backgroundColor: '#d2b8ff',
                borderRadius: '8px',
                padding: '1rem 1.1rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '.8rem',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{ textAlign: 'start', fontSize: '17px', color: '#000' }}
              >
                Target Budget
              </p>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <TextField
                  placeholder="Enter Amount"
                  defaultValue={
                    formData.budget
                      ? Number(formData.budget).toLocaleString()
                      : '0'
                  }
                  type="text"
                  value={
                    formData.budget
                      ? Number(formData.budget).toLocaleString()
                      : '0'
                  }
                  onChange={(e) => {
                    updateBudgetOnFormData(e);
                  }}
                  style={{
                    width: 180,
                    backgroundColor: '#fff',
                    borderStartStartRadius: '10px',
                    borderEndStartRadius: '10px',
                  }}
                  color="secondary"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },

                      '&.Mui-error fieldset': {
                        borderColor: 'red',
                      },

                      '&.Mui-disabled fieldset': {
                        border: 'none',
                      },
                    },
                    '& .MuiFormLabel-root': {
                      color: '#03071e66',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                      color: '#5a0bdf',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                      color: 'red',
                    },
                  }}
                />
                <Box
                  sx={{
                    width: '80px',
                    backgroundColor: '#e6e6e6',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px',
                    borderStartEndRadius: '10px',
                    borderEndEndRadius: '10px',
                  }}
                >
                  <p>/Month</p>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                backgroundColor: '#d2b8ff',
                width: '100%',
                padding: '10px 15px',
                borderRadius: '10px',
              }}
            >
              <p
                style={{ textAlign: 'start', fontSize: '17px', color: '#000' }}
              >
                *Select your Term (Duration)
              </p>
              <Box sx={{ display: 'flex', gap: '15px', marginTop: '10px' }}>
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    placeholder="____  ____  ____"
                    value={formData.duration}
                    disabled={formData?.month_to_month}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        duration: e.target.value,
                      });
                    }}
                    style={{
                      width: 180,
                      backgroundColor: '#fff',
                      borderStartStartRadius: '10px',
                      borderEndStartRadius: '10px',
                    }}
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          border: 'none',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    type="number"
                  />
                  <Box
                    sx={{
                      width: '80px',
                      backgroundColor: '#e6e6e6',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 10px',
                      borderStartEndRadius: '10px',
                      borderEndEndRadius: '10px',
                    }}
                  >
                    <p>Month(s)</p>
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#ebeded',
                    width: '40%',
                    display: 'flex',
                    padding: '10px 10px',
                    gap: '15px',
                    borderRadius: '10px',
                  }}
                >
                  {formData && formData.month_to_month === 'month' ? (
                    <CheckBoxIcon
                      sx={{ fontSize: '27px', color: '#711FFF' }}
                      onClick={() =>
                        setDetailData({ ...detailData, month_to_month: null })
                      }
                    />
                  ) : (
                    <TextField
                      type="checkbox"
                      color="secondary"
                      sx={{
                        outline: 'none',
                        width: '20px',
                        height: '20px',
                      }}
                      checked={formData.month_to_month === 'month'}
                      defaultChecked={formData.month_to_month === 'month'}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        setFormData({
                          ...formData,
                          month_to_month: isChecked ? 'month' : null,
                        });
                      }}
                    />
                  )}
                  <RepeatIcon sx={{ cursor: 'pointer' }} />
                  <h6>Month to Month</h6>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              backgroundColor: '#d4e8fb',
              borderRadius: '8px',
              padding: '1rem 1.1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p className="baseTypography2">Est Start Date</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="DD/MM/YYYY"
                inputFormat="dd-MM-yyyy"
                value={dayjs(formData.target_start) || null}
                onChange={(newValue) => {
                  setFormData({
                    ...formData,
                    target_start: newValue.$d,
                  });
                }}
                sx={{
                  width: '80%',
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                    {
                      color: '#711FFF',
                    },
                  '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: '#711FFF',
                    },
                  '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                    {
                      marginRight: '0px',
                      position: 'absolute',
                      borderRadius: '0px !important',
                      width: '100% !important',
                      height: '60px',
                    },
                  '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                    marginRight: '0px',
                    position: 'absolute',
                    borderRadius: '0px !important',
                    width: '100% !important',
                    height: '60px',
                  },
                  '& .MuiInputAdornment-root': {
                    position: 'absolute',
                    top: '28px',
                    left: '-6px',
                    borderRadius: '0px',
                    width: '100%',
                  },
                  '& .MuiSvgIcon-root': {
                    position: 'absolute',
                    right: '10px',
                  },
                  '& .MuiInputBase-root': {
                    width: '100%',
                    position: 'relative',
                  },
                  '& .MuiOutlinedInput-input': {
                    width: '100%',
                    height: '60px',
                    padding: '0px',
                    color: 'black',
                    marginLeft: '10px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    width: '100%',
                    height: '60px',
                  },
                }}
                InputProps={{
                  style: { color: '#000' },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{
                      width: '78%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #d4e8fb',
                          color: '#000',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #d4e8fb',
                          color: '#000',
                        },
                        '&.Mui-error fieldset': {
                          border: '1px solid #d4e8fb',
                        },
                        '& .MuiFormLabel-root': {
                          color: '#000',
                        },
                        '& .MuiInputBase-input': {
                          color: '#000',
                        },
                        '& .MuiInputLabel-formControl': {
                          color: '#000',
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </>
      )}
    </Box>
  );
}

function Modal({
  showModal,
  setShowModal,
  currentTarget,
  setCurrentTarget,
  currentCompany,
  setCurrentCompany,
  detailData,
  setDetailData,
}) {
  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.user.access);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    opportunity_type: 'project_opportunity',
    payment_terms: 'weekly',
    expected_value: '',
    company: currentCompany ? currentCompany.id : '',
    contact: currentTarget ? currentTarget.id : '',
    target_start: '',
    finish_date: '',
    budget: 0,
    period: '1',
    connverted_to_project: false,
    opportunity_status: 'active',
  });

  const [html, setHtml] = useState(detailData?.description || '<p></p>');

  const closeModal = () => {
    setShowModal(false);
    if (setDetailData) {
      setDetailData(false);
    }
    setFormData({
      name: '',
      description: '',
      opportunity_type: 'project_opportunity',
      payment_terms: 'weekly',
      expected_value: '',
      company: currentCompany ? currentCompany.id : '',
      contact: currentTarget ? currentTarget.id : '',
      target_start: '',
      finish_date: '',
      budget: 0,
      period: '1',
      connverted_to_project: false,
      opportunity_status: 'active',
    });
    setHtml('');
  };
  useEffect(() => {
    if (formData.description) {
      setHtml(formData.description);
    }
  }, [formData.description]);

  const check = () => {
    setFormData((prev) => ({
      ...prev,
      description: html,
    }));
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, [2000]);
    }
  }, [error]);

  useEffect(() => {
    if (detailData) {
      setFormData({
        name: detailData.name,
        description: detailData?.description || '<p></p>',
        opportunity_type:
          detailData.opportunity_type === 'Retainer Opportunity'
            ? 'project_retainer'
            : 'project_opportunity',
        payment_terms: 'weekly',
        month_to_month: detailData.month_to_month ? 'month' : false,
        expected_value: '',
        company: currentCompany ? currentCompany.id : detailData.company || '',
        contact: currentTarget ? currentTarget.id : detailData.contact || '',
        target_start: detailData.target_start
          ? new Date(
              new Date(detailData.target_start).setDate(
                new Date(detailData.target_start).getDate(),
              ),
            )
          : '',
        finish_date: detailData.finish_date
          ? new Date(
              new Date(detailData.finish_date).setDate(
                new Date(detailData.finish_date).getDate(),
              ),
            )
          : '',
        budget: detailData.budget || 0,
        period: '1',
        connverted_to_project: false,
        opportunity_status: 'active',
      });
      setHtml(detailData?.description);
    } else {
      setFormData({
        name: '',
        description: '',
        opportunity_type: 'project_opportunity',
        payment_terms: 'weekly',
        month_to_month: false,
        expected_value: '',
        company: '',
        contact: '',
        target_start: '',
        finish_date: '',
        budget: 0,
        period: '1',
        connverted_to_project: false,
        opportunity_status: 'active',
      });
      setHtml('<p></p>');
    }
  }, [detailData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === '') {
      setIsError(true);
      setError('Name Is Required');
    } else {
      Object.keys(formData).forEach((key) => {
        if (!formData[key]) {
          delete formData[key];
        }
      });
      if (!currentTarget && !currentCompany) {
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/opportunity/create/`, {
          method: 'POST',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            formData.target_start && formData.finish_date
              ? {
                  ...formData,
                  target_start: formData.target_start,
                  finish_date: formData.finish_date,
                  description: html,
                }
              : formData,
          ),
        });
        const data = await response.json();
        if (!response.ok) {
          setIsError(true);
          setError(data.name);
          setLoading(false);
        } else {
          setLoading(false);
          setShowModal(false);
          setFormData({
            name: '',
            description: '',
            opportunity_type: 'project_opportunity',
            payment_terms: 'weekly',
            expected_value: '',
            company: currentCompany ? currentCompany.id : '',
            contact: currentTarget ? currentTarget.id : '',
            target_start: '',
            finish_date: '',
            budget: 0,
            period: '1',
            connverted_to_project: false,
            opportunity_status: 'active',
          });
        }
      } else if (currentTarget) {
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/opportunity/create/`, {
          method: 'POST',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            formData.target_start && formData.finish_date
              ? {
                  ...formData,
                  target_start: formData.target_start
                    ? new Date(
                        new Date(formData.target_start).setDate(
                          new Date(formData.target_start).getDate(),
                        ),
                      )
                    : '',
                  finish_date: formData.finish_date
                    ? new Date(
                        new Date(formData.finish_date).setDate(
                          new Date(formData.finish_date).getDate(),
                        ),
                      )
                    : '',
                  description: html,
                }
              : formData,
          ),
        });
        const data = await response.json();
        if (!response.ok) {
          setIsError(true);
          setError(data.name);
          setLoading(false);
        } else {
          setCurrentTarget({
            ...currentTarget,
            contact_opportunity: [...currentTarget.contact_opportunity, data],
          });
          setLoading(false);
          setShowModal(false);
        }
      } else if (currentCompany) {
        setLoading(true);
        const response = await fetch(`${ENDPOINT}/api/opportunity/create/`, {
          method: 'POST',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            formData.target_start && formData.finish_date
              ? {
                  ...formData,
                  target_start: formData.target_start
                    ? new Date(
                        new Date(formData.target_start).setDate(
                          new Date(formData.target_start).getDate(),
                        ),
                      )
                    : '',
                  finish_date: formData.finish_date
                    ? new Date(
                        new Date(formData.finish_date).setDate(
                          new Date(formData.finish_date).getDate(),
                        ),
                      )
                    : '',
                  description: html,
                }
              : {
                  ...formData,
                  company: formData.company
                    ? formData.company
                    : currentCompany.id,
                },
          ),
        });
        const data = await response.json();
        if (!response.ok) {
          setIsError(true);
          Object.keys(data).forEach((key) => {
            setError(data[key]);
          });
          setLoading(false);
        } else {
          setCurrentCompany({
            ...currentCompany,
            company_opportunity: [data],
          });
          setLoading(false);
          setShowModal(false);
          setFormData({
            name: '',
            description: html,
            opportunity_type: 'project_opportunity',
            payment_terms: 'weekly',
            expected_value: '',
            company: currentCompany
              ? currentCompany.id
              : detailData?.company
                ? detailData.company
                : '',
            contact: currentTarget
              ? currentTarget.id
              : detailData?.contact
                ? detailData.contact
                : '',
            target_start: '',
            finish_date: '',
            budget: 0,
            period: '1',
            connverted_to_project: false,
            opportunity_status: 'active',
          });
        }
      }
    }
  };
  const currentCompanies = useSelector((state) => state.Contacts.companies);

  const handleUpdate = async () => {
    setLoading(true);
    const response = await fetch(
      `${ENDPOINT}/api/opportunity/update/${detailData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          description: html,
          opportunity_type:
            formData && formData.opportunity_type === 'project_opportunity'
              ? 'project_opportunity'
              : 'retainer_opportunity',
          payment_terms: 'weekly',
          expected_value: '',
          company:
            formData && formData?.company
              ? currentCompanies?.find((item) => item?.id === formData?.company)
                  ?.id
              : formData?.company,
          contact: currentTarget
            ? currentTarget.id
            : formData.contact
              ? formData.contact
              : null,
          month_to_month:
            formData && formData.month_to_month
              ? formData.month_to_month
              : null,
          budget: formData && formData.budget ? formData.budget : 0,
          duration: formData && formData.duration ? formData.duration : '',
          period: '1',
          connverted_to_project: false,
          target_start: formData.target_start
            ? new Date(
                new Date(formData.target_start).setDate(
                  new Date(formData.target_start).getDate(),
                ),
              )
            : null,
          finish_date: formData.finish_date
            ? new Date(
                new Date(formData.finish_date).setDate(
                  new Date(formData.finish_date).getDate(),
                ),
              )
            : null,
        }),
      },
    );

    if (!response.ok) {
      setLoading(false);
    } else {
      setShowModal(false);
      setFormData({
        name: '',
        description: '',
        opportunity_type: 'project_opportunity',
        payment_terms: 'weekly',
        expected_value: '',
        company: currentCompany ? currentCompany.id : '',
        contact: currentTarget ? currentTarget.id : '',
        target_start: '',
        finish_date: '',
        budget: 0,
        period: '1',
        connverted_to_project: false,
        opportunity_status: 'active',
      });
      setDetailData(false);
      setLoading(false);
    }
  };

  return (
    <AnimatePresence mode="wait">
      {showModal && (
        <motion.div
          className="backdrop"
          variants={backdrop}
          initial="hidden"
          animate="visible"
          style={{
            height: '82vh !important',
          }}
        >
          <button
            type="button"
            aria-label="Close Modal"
            className="close-modal"
            onClick={closeModal}
          />
          <h3 className="modal-title">
            {detailData && detailData.name
              ? 'Update Opportunity'
              : 'Create Opportunity'}
          </h3>
          <Grid
            container
            direction="row"
            sx={{
              marginTop: '1rem',
              overflowY: 'auto',
              height: '100%',
              gap: '1rem',
              justifyContent: {
                xl: 'space-between',
                lg: 'space-between',
                md: 'center',
              },
            }}
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              xl={5.5}
              lg={5.5}
              md={5.5}
            >
              <SummaryInformation
                formData={formData}
                setFormData={setFormData}
                html={html}
                setHtml={setHtml}
                detailData={detailData}
                setDetailData={setDetailData}
                check={check}
              />
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="flex-start"
              sx={{
                gap: '1rem',
              }}
              xl={5.5}
              lg={5.5}
              md={6.2}
            >
              <ClientInformation
                formData={formData}
                setFormData={setFormData}
                currentTarget={currentTarget}
                setCurrentTarget={setCurrentTarget}
                currentCompany={currentCompany}
                detailData={detailData}
                setDetailData={setDetailData}
              />
              <TargetBudget
                formData={formData}
                setFormData={setFormData}
                detailData={detailData}
                setDetailData={setDetailData}
              />
            </Grid>
          </Grid>
          {isError && (
            <Shake>
              <p className="error" style={{ width: '98%', textAlign: 'end' }}>
                {error}
              </p>
            </Shake>
          )}

          <div className="button-groups">
            <button type="button" className="cancel-btn" onClick={closeModal}>
              Cancel
            </button>
            {detailData && detailData.name ? (
              <button
                type="button"
                className="create-btn"
                onClick={handleUpdate}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.6rem',
                    }}
                  >
                    <PreLoader1 />
                  </Box>
                ) : (
                  'Update'
                )}{' '}
              </button>
            ) : (
              <button
                type="button"
                className="create-btn"
                onClick={handleSubmit}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.6rem',
                    }}
                  >
                    <PreLoader1 />
                  </Box>
                ) : (
                  'Create'
                )}{' '}
              </button>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Modal;

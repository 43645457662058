import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIRichTextEditor from 'mui-rte';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './notificationAndAlerts.css';
import dot from './assets/dot.svg';
import circleLogo from './assets/circleLogo.svg';
import cancel from './assets/cancel.svg';
import Publish from './Publish';
import { useRouteTo } from '../../../../../hooks/useRouteTo';
import { appRoutes } from '../../../../../appRoutes';

const gradientStyle = {
  background:
    'linear-gradient(0deg, #E9ECEF 59.05%, rgba(233, 236, 239, 0) 100%)',
  width: '100%',
  marginLeft: 0,
};

const TypeAndTitle = ({ formData, setFormData }) => {
  return (
    <div className="left_side">
      <div className="info_title">
        <h3 className="">Type and Title</h3>
        <InfoOutlinedIcon className="info_icon" />
      </div>
      <div className="name" style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Box className="sideFormSpan sideFormSpan2">
          <span className="formSpan2">Type</span>
          <Select
            value={formData.notification_type}
            onChange={(e) => {
              setFormData({ ...formData, notification_type: e.target.value });
            }}
            displayEmpty
            style={{
              width: '260px',
              color: 'rgba(0, 0, 0, 0.6)',
              fontFamily: 'Open Sans',
            }}
            variant="outlined"
            sx={{
              '& fieldset': {
                legend: {
                  width: 0,
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              Select Type
            </MenuItem>
            <MenuItem value="alert">Alert</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="alert/email">Alert/Email</MenuItem>
          </Select>
        </Box>
      </div>
      <div className="name">
        <Box className="sideFormSpan sideFormSpan2">
          <span className="formSpan2">Title</span>

          <TextField
            id="outlined-basic"
            //  size='small'
            label="Enter Title"
            variant="outlined"
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
            sx={{
              '.css-1in441m': {
                width: '4rem',
              },
            }}
            style={{
              width: '260px',
            }}
          />
        </Box>
      </div>
    </div>
  );
};

const PublishOptions = ({ formData, setFormData }) => {
  const [selected, setSelected] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <div
      className="right_side"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div className="info_title">
        <h3 className="">Publish Option</h3>
        <InfoOutlinedIcon className="info_icon" />
      </div>
      <div className="name" style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Box className=" sideFormSpan sideFormSpan2">
          <span className="formSpan2">Publish Option</span>
          <Select
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value);
              setFormData({ ...formData, publish_options: e.target.value });
            }}
            displayEmpty
            style={{
              width: '260px',
              color: 'rgba(0, 0, 0, 0.6)',
              fontFamily: 'Open Sans',
            }}
            variant="outlined"
            sx={{
              '& fieldset': {
                legend: {
                  width: 0,
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              Select Publish Option
            </MenuItem>
            <MenuItem value="publish now">Publish Now</MenuItem>
            <MenuItem value="publish later">Publish Later</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
          </Select>
        </Box>
      </div>

      <div className="name">
        <Box className="sideFormSpan sideFormSpan2">
          <span className="formSpan2">Publish Date</span>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={formData.publish_date}
              onChange={(newValue) =>
                setFormData({ ...formData, publish_date: newValue })
              }
              renderInput={(props) => (
                <TextField
                  variant="outlined"
                  sx={{
                    width: '260px',
                    '.css-1laqsz7-MuiInputAdornment-root': {
                      paddingRight: '25px',
                    },
                    '.css-1in441m': {
                      width: '5.3rem',
                    },
                  }}
                  {...props}
                  label="MM/DD/YYYY"
                  onClick={() => setCalendarOpen(true)}
                  value={props.value ? props.value.format('MM/DD/YY') : ''}
                />
              )}
              open={calendarOpen}
              onClose={() => setCalendarOpen(false)}
            />
          </LocalizationProvider>
        </Box>
      </div>
    </div>
  );
};

const NotificationContent = ({ html, setHtml, formData, setFormData }) => {
  const myTheme = createTheme();

  const handleEditorChange = (newEditorState) => {
    const htmlContent = stateToHTML(newEditorState.getCurrentContent());
    setHtml(htmlContent);
    setFormData({ ...formData, notification_content: htmlContent });
  };

  return (
    <Box
      className="flex-editor"
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        margin: '52px 0',
      }}
    >
      <div className="info_title">
        <h3 className="">Content</h3>
        <InfoOutlinedIcon className="info_icon" />
      </div>
      <div className="richEditor">
        <div className="name" style={{ width: '160px' }}>
          <Box className="sideFormSpan" style={{ width: '160px' }}>
            <span className="formSpan2">Content</span>
          </Box>
        </div>
        <div
          style={{
            width: '100%',
            height: 'auto',
            position: 'relative',
            flex: 1,
            paddingRight: '5%',
          }}
        >
          <ThemeProvider theme={myTheme}>
            <MUIRichTextEditor
              label="Enter Content"
              height="400px"
              onChange={handleEditorChange}
              controls={['bold', 'italic', 'underline', 'strikethrough']}
              customControls={[
                {
                  name: 'stateLine',
                  icon: '|',
                  type: 'inline',
                },
                {
                  name: 'stateLine2',
                  icon: '|',
                  type: 'inline',
                },
              ]}
            />
          </ThemeProvider>
        </div>
      </div>
    </Box>
  );
};

const NotiDetail = ({ formData }) => {
  const { title, notification_content } = formData;

  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  const defaultContent = 'Content';

  return (
    <>
      <div className="left_side">
        <div className="info_title" style={{ marginBottom: '30px' }}>
          <h3 className="">Preview</h3>
          <InfoOutlinedIcon className="info_icon" />
        </div>

        <div className="previewContainer">
          <div className="dot">
            <img src={dot} alt="dot" />
          </div>
          <div className="innnerContainer">
            <div className="logoSideContainer">
              <div className="logoSide">
                <img src={circleLogo} alt="" />
                <div className="teamInfo">
                  <p className="team">The Net Net Team</p>
                  <p className="times">3 hours ago</p>
                </div>
              </div>
              <div className="cancel">
                <img src={cancel} alt="" />
              </div>
            </div>
            <div className="titleSpace">{title ? title : 'Title'}</div>
            <div className="contentSpace">
              {sanitizeHTML(notification_content) || defaultContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NewNotiAndAlert = () => {
  const routeTo = useRouteTo();
  const [html, setHtml] = useState('');
  const [formData, setFormData] = useState({
    notification_type: '',
    title: '',
    publish_options: '',
    publish_date: '',
    notification_content: '',
  });

  const handleSubmition = async (e) => {
    e.preventDefault();
    routeTo(`${appRoutes.superAdmin.root}${appRoutes.superAdmin.tools.toolsNotificationsandAlerts}`);
  };

  const handleResize = () => {};
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const titleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    height: '73px',
    padding: ' 1.13% 1.38% 1.13% 1.38%',
    backgroundColor: '#fff',
  };

  return (
    <>
      <div
        style={{
          backgroundColor: '#F7F7FA',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="" style={titleStyle}>
          <div className="" style={{ display: 'flex', gap: '8px' }}>
            <h3 className="fstxtPro">New Notification</h3>
          </div>
        </div>
        <section
          className="col-system my-row"
          style={{
            borderRadius: '8px',
            scrollbarWidth: 'thin',
            scrollbarColor: 'transparent',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            margin: '1.13% 1.38% 1.13% 1.38%',
            padding: '4% 3.8%',
            height: '100%',
          }}
        >
          <div className="top-title">
            <TypeAndTitle
              className="Dis_info"
              formData={formData}
              setFormData={setFormData}
            />
            <PublishOptions
              className="amount_info"
              formData={formData}
              setFormData={setFormData}
            />
          </div>
          <div className="row3">
            <NotificationContent
              formData={formData}
              setFormData={setFormData}
              html={html}
              setHtml={setHtml}
            />
          </div>

          <div className="row3">
            <NotiDetail formData={formData} />
          </div>
        </section>
        <div className="alCenter" style={gradientStyle}>
          <button
            className="cancel-btn"
            onClick={() => {
              routeTo(-1);
            }}
          >
            Cancel
          </button>
          <button className="cancel-btn" onClick={handleSubmition}>
            {' '}
            Save Draft{' '}
          </button>
          <Publish>
            <button
              className="create-btn"
              style={{ fontWeight: '300', fontFamily: 'Open Sans' }}
            >
              {' '}
              Publish{' '}
            </button>
          </Publish>
        </div>
      </div>
    </>
  );
};

export default NewNotiAndAlert;

import React from 'react';
import {
    Grid,
    Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { New_channels, getMessages } from '../Stream/streamRedux';
import { ENDPOINT } from '../Opportunity/Redux/oppor';
import RichTextEditor from '../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import SimpleDialog from '../../Componenets/atomic-organisms/RichTextEditor/SimpleDialog';
import { DirectReplyButton, ReplyButton } from '../../Componenets/Misc';
import Bounce from 'react-reveal/Bounce';

function DeliverableChat({ setComments, comments, formData, setDelivChatShow, setIsTyping, deliverableChat, channelList }) {

    const new_channals = useSelector((state) => state.Stream.new_channels)
    const [show, setShow] = useState(true);
    const [responseBox, setResponseBox] = useState(null)
    const [search, setSearch] = useState('');

    const [html, setHtml] = useState('');
    const handleClose = () => {
        setDelivChatShow(false)
        setShow(false)
        setIsTyping(false)
    };


    const [chats, setChats] = useState([])
    const [deliverableChannel, setDeliverableChannel] = useState()


    useEffect(() => {
        if (new_channals.length && deliverableChat.id) {

            const filtered = new_channals.filter((item) => item?.deliverable && !item?.task)

            const crtMessages = filtered?.find(
                (item) => item?.deliverable === deliverableChat?.id
            );
            if (crtMessages && crtMessages.id) {
                const response = fetch(`${ENDPOINT}/api/chat/channel/detail/${crtMessages?.id}/`, {
                    method: "GET",
                    headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json'
                    },
                }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                    .then(data => {
                        const sorted = data.message_in_channel.sort((a, b) => new Date(a?.created) - new Date(b?.created))
                        setDeliverableChannel(data.id)
                        setComments(sorted ?? [])
                        setChats(sorted ?? [])
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        }
    }, [deliverableChat, new_channals])

    const access_token = useSelector((state) => state.auth.user.access)
    const userData = useSelector((item) => item.userData.userData)
    const dispatch = useDispatch()

    async function send_Message(e) {

        if (!notRun) {
            if (e.key === 'Enter') {
                e.preventDefault();
                if (e.key !== 'Enter' || e.shiftKey) {
                    return;
                }
                btn?.editor?.commands.clearContent()
                setHtml('');
                const cleanedHtml = html?.replace(/\n/g, '').replace(/<\/?p>/g, '').trim();
                const deliverableTitleHtml = `<span class="deliverable-mention">~${deliverableChat?.title}</span>`;
                const updatedMessage = `<p>${cleanedHtml} ${deliverableTitleHtml}</p>`;

                const tempMessage = {
                    created: new Date().toISOString(),
                    channel: deliverableChannel && deliverableChannel,
                    is_response: false,
                    message: updatedMessage,
                    received: null,
                    receiver_read: false,
                    seen: null,
                    sender_read: true, // Assuming the sender has already read their own message
                    sender: userData,
                    tempId: Math.random().toString(36).substring(7), // Unique temporary ID
                };
                setChats([...chats, tempMessage])
                const response = await fetch(
                    `${ENDPOINT}/api/chat/channel/message/create/`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                        },
                        body: JSON.stringify({
                            channel: deliverableChannel && deliverableChannel,
                            message: updatedMessage,
                        }),
                    }
                );

                if (!response.ok) {
                    const data = await response.json();
                    return;
                }

                const data = await response.json();
                const find = data?.read_message.some(readMessage => readMessage.read_by === userData.id);
                if (data) {
                    if (!find) {
                        const response2 = await fetch(
                            `${ENDPOINT}/api/chat/channel/message/read/create/`,
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `JWT ${access_token}`,
                                },
                                body: JSON.stringify({
                                    message: data && data?.id,
                                    read_by: userData.id
                                }),
                            }
                        );
                        if (!response2.ok) {
                            const errorData = await response2.json();
                            console.error("Error:", errorData);
                            return; // Optionally handle the error
                        }
                        const data2 = await response2.json();
                    }
                }

                setChats([...chats, {
                    ...data,
                    sender: userData
                }])
                setComments([...comments, {
                    ...data,
                    sender: userData,
                }])
                dispatch(getMessages({ access_token }))
                dispatch(New_channels({ access_token }))

            }
        }
    }
    const [btn, setBtn] = useState()
    const check = (e) => {
        setBtn(e)
    }
    const [notRun, setNotRun] = useState(false)
    const [openLink, setOpenLink] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');

    const setLink = () => {

        const url = linkUrl.trim(); // Trim whitespace from the input URL
        // Update link only if the URL is not empty
        if (url !== '') {
            btn.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }
        setOpenLink(false)
        setLinkUrl('')
    }
    return (
        <Offcanvas show={show} onHide={handleClose} placement="bottom ">
            <SimpleDialog
                open={openLink}
                setOpen={setOpenLink}
                linkUrl={linkUrl}
                setLinkUrl={setLinkUrl}
                setLink={setLink}
            />
            <Offcanvas.Header>
                <button className="close-modal" onClick={() => {
                    setDelivChatShow(false)
                    setShow(false)
                    setIsTyping(false)
                }} />
            </Offcanvas.Header>
            <Offcanvas.Body style={{ overflowY: 'hidden' }}>

                <Grid container direction="column" alignItems="flex-start">
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="start"
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        sx={{ width: '90%', margin: '0 auto' }}
                    >
                        <Box>
                            <h5>Chats: Deliverable</h5>
                            <h6
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    color: '#9d5cf2',
                                    padding: '10px 5px',
                                    borderRadius: '10px',

                                }}
                            >
                                {deliverableChat ? deliverableChat.title : ''}
                            </h6>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                minHeight: '350px',
                                border: '1px solid gray',
                                borderRadius: '8px',
                                padding: '0px 10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Box sx={{
                                minHeight: '220px', maxHeight: '400px', width: '99%', marginLeft: '10px', overflowY: 'auto', display: 'flex',
                                flexDirection: 'column-reverse'
                            }}>
                                <List sx={{ display: 'flex', flexDirection: 'column' }}>
                                    {Array.isArray(chats) && chats.length ?

                                        chats.map((item, index) => {
                                            return (
                                                <>
                                                    <Bounce bottom>
                                                        <List sx={{ width: '100%' }}>
                                                            <Box key={item.id}>
                                                                <ListItem alignItems="flex-start" key={item.id} id={`message-${item.id}`} >
                                                                    <ListItemAvatar>
                                                                        <Avatar
                                                                            alt={item.sender.first_name}
                                                                            src={item.sender.avatar ? item.sender.avatar : "/static/images/avatar/1.jpg"}
                                                                        />
                                                                    </ListItemAvatar>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="subtitle2"
                                                                                sx={{
                                                                                    color: '#03071E',
                                                                                    letterSpacing: '-0.3px',
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '1rem',
                                                                                    fontFamily: 'Open Sans',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    gap: '0.5rem',
                                                                                    opacity: item?.tempId ? 0.5 : 1,
                                                                                }}
                                                                            >
                                                                                {item?.sender?.first_name}

                                                                                <p
                                                                                    style={{
                                                                                        color: 'rgba(3, 7, 30, 0.4)',
                                                                                        letterSpacing: '-0.3px',
                                                                                        fontSize: '11px',
                                                                                        fontWeight: '400',
                                                                                        textAlign: 'left',
                                                                                        fontFamily: 'Open Sans',
                                                                                        marginBottom: '0',

                                                                                    }}
                                                                                >
                                                                                    {!item?.tempId ? `${new Date(item.created).toLocaleTimeString(
                                                                                        [],
                                                                                        {
                                                                                            hour: '2-digit',
                                                                                            minute: '2-digit',
                                                                                            hour12: true,
                                                                                        }
                                                                                    )}, ${new Date(item.created).toDateString()}` : 'Sending'}
                                                                                </p>
                                                                            </Typography>
                                                                        }
                                                                        secondary={
                                                                            <div
                                                                                style={{
                                                                                    fontWeight: '400',
                                                                                    fontSize: '14px',
                                                                                    lineHeight: '22px',
                                                                                    fontStyle: 'normal',
                                                                                    color: '#03071E',
                                                                                    opacity: item?.tempId ? 0.5 : 1,
                                                                                }}
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item.message,
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </ListItem>
                                                                {search ? null : (
                                                                    <div className="ms-5 ps-1">
                                                                        {responseBox === item.id ? (
                                                                            <div className="response-dropdown">
                                                                                {item?.reply_to_chatmesage?.map((cv, int) => {
                                                                                    return (
                                                                                        <div className="ms-3 mb-3 d-flex align-items-start" key={cv.id}>
                                                                                            <Avatar
                                                                                                alt={cv?.sender?.first_name}
                                                                                                src={cv?.sender?.avatar ? cv?.sender?.avatar : "/static/images/avatar/1.jpg"}
                                                                                                className="me-2"
                                                                                            />
                                                                                            <div>
                                                                                                <div className="d-flex align-items-center pt-2">
                                                                                                    <p
                                                                                                        className="fw-bold mb-0 me-2"
                                                                                                        style={{
                                                                                                            fontSize: '14px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {cv?.sender?.first_name ?? ''}
                                                                                                    </p>
                                                                                                    <p
                                                                                                        style={{
                                                                                                            color: 'rgba(3, 7, 30, 0.4)',
                                                                                                            letterSpacing: '-0.3px',
                                                                                                            fontSize: '11px',
                                                                                                            textAlign: 'left',
                                                                                                            fontFamily: 'Open Sans',
                                                                                                        }}
                                                                                                    >
                                                                                                        {new Date().toLocaleTimeString([], {
                                                                                                            hour: '2-digit',
                                                                                                            minute: '2-digit',
                                                                                                            hour12: true,
                                                                                                        })}
                                                                                                        , {new Date().toDateString()}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <p
                                                                                                    style={{
                                                                                                        fontWeight: '400',
                                                                                                        fontSize: '14px',
                                                                                                        lineHeight: '22px',
                                                                                                        fontStyle: 'normal',
                                                                                                        color: '#03071E',
                                                                                                    }}
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: cv?.message ?? '',
                                                                                                    }}
                                                                                                ></p>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}

                                                                                <ReplyButton

                                                                                    chats={chats}
                                                                                    setChats={setChats}
                                                                                    userData={userData}
                                                                                    channel_id={item}
                                                                                />
                                                                                <button
                                                                                    className="response-btn"
                                                                                    onClick={() => {
                                                                                        setResponseBox(false);
                                                                                    }}
                                                                                >
                                                                                    Hide Responses
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {item?.reply_to_chatmesage?.length && item?.reply_to_chatmesage?.length > 0 ? (
                                                                                    <div className="respose">
                                                                                        <AvatarGroup
                                                                                            className="response-avatar"
                                                                                            max={3}
                                                                                            sx={{
                                                                                                justifyContent: 'flex-end',
                                                                                                paddingLeft: '15px',
                                                                                            }}
                                                                                        >
                                                                                            {Array.isArray(item?.reply_to_chatmesage?.length) &&
                                                                                                item?.reply_to_chatmesage?.length
                                                                                                ? [
                                                                                                    ...new Set(
                                                                                                        item?.reply_to_chatmesage.map((va) =>
                                                                                                            JSON.stringify(va.sender)
                                                                                                        )
                                                                                                    ),
                                                                                                ]
                                                                                                    .map((la) => JSON.parse(la))
                                                                                                    .map((per) => {

                                                                                                        return (
                                                                                                            <Avatar
                                                                                                                key={per.id}
                                                                                                                src={per.avatar ? per.avatar : "/static/images/avatar/1.jpg"}
                                                                                                                alt={per.first_name}
                                                                                                            />
                                                                                                        );
                                                                                                    })
                                                                                                : null}
                                                                                        </AvatarGroup>

                                                                                        <button
                                                                                            className="response-btn"
                                                                                            onClick={() => {
                                                                                                setResponseBox(item.id);
                                                                                            }}
                                                                                        >
                                                                                            <span className="me-1">
                                                                                                {item?.reply_to_chatmesage?.length + ' ' + 'Responses'}
                                                                                            </span>
                                                                                        </button>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="response-dropdown">
                                                                                        <DirectReplyButton
                                                                                            chats={chats}
                                                                                            setChats={setChats}
                                                                                            userData={userData}
                                                                                            channel_id={item}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Box>
                                                        </List>
                                                    </Bounce>
                                                </>
                                            );
                                        })
                                        :
                                        <Box sx={{ minHeight: '220px', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>No Conversation in Current Deliverable</Box>
                                    }


                                </List>
                                {/* } */}
                            </Box>
                            <Box
                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    width: '100%',
                                    borderRadius: '10px',
                                    pb: 2
                                }}
                                onKeyDown={async (event) => {
                                    send_Message(event)
                                }}
                            >
                                {' '}
                                <RichTextEditor open={openLink} setOpen={setOpenLink} setNotRun={setNotRun} html={html} setHtml={setHtml} check={check} deliverbales={deliverableChat ? [deliverableChat.title] : []} mentions={formData && Array.isArray(formData?.project_subteam) && formData.project_subteam.length && Array.isArray(formData.project_subteam[0].members) && formData.project_subteam[0].members.length ? formData.project_subteam[0].members.map((item) => (item.first_name)) : []} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default DeliverableChat;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { ENDPOINT } from '../../../Opportunity/Redux/oppor';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Gear from './assets/Gear.png';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useRouteTo } from '../../../../hooks/useRouteTo';
import { appRoutes } from '../../../../appRoutes';

const gradientStyle = {
  background:
    'linear-gradient(0deg, #E9ECEF 59.05%, rgba(233, 236, 239, 0) 100%)',
  width: '100%',
  marginLeft: 0,
};
const Header = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: ' 1% 1.38% 1% 1.38%',
        boxSizing: 'border-box',
        textAlign: 'center',
        fontSize: '29px',
        color: '#03071e',
        fontFamily: "'Open Sans'",
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '20px',
        }}
      >
        <b style={{ position: 'relative', lineHeight: '100%' }}>New Discount</b>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgba(3, 7, 30, 0.4)',
          }}
        >
          <div
            style={{
              borderRadius: '4px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '3px',
            }}
          >
            <img
              style={{
                position: 'relative',
                width: '18px',
                height: '18px',
                overflow: 'hidden',
                flexShrink: '0',
              }}
              alt=""
              src={Gear}
            />
            <div
              style={{
                position: 'relative',
                letterSpacing: '-0.3px',
                lineHeight: '19px',
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '5px 0',
          fontSize: '14px',
          color: '#fff',
        }}
      ></div>
    </div>
  );
};

const DiscountInformation = ({ formData, setFormData }) => {
  const generateRandomCode = () => {
    const randomCode = Math.floor(Math.random() * 900000000) + 100000000;
    setFormData({ ...formData, code: String(randomCode) });
  };

  useEffect(() => {
    generateRandomCode();
  }, []);

  return (
    <div className="left_side">
      <div className="info_title">
        <h3 className="">Discount Information</h3>
        <InfoOutlinedIcon className="info_icon" />
      </div>
      <div className="name">
        <Box className="sideFormSpan">
          <span className="formSpan">Discount Name</span>

          <TextField
            label="Enter Discount Name"
            variant="outlined"
            value={formData.discount_name}
            onChange={(e) =>
              setFormData({ ...formData, discount_name: e.target.value })
            }
            fullWidth
            sx={{
              '.css-1in441m': {
                width: '7.7rem',
              },
            }}
          />
        </Box>
      </div>
      <div className="name" style={{ marginTop: '5px', marginBottom: '5px' }}>
        <Box className="sideFormSpan">
          <span className="formSpan">Discount Type</span>
          <Select
            value={formData.type}
            onChange={(e) => {
              setFormData({
                ...formData,
                type: e.target.value,
                amount_type: '%',
              });
            }}
            displayEmpty
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontFamily: 'Open Sans',
            }}
            fullWidth
            variant="outlined"
            sx={{
              '& fieldset': {
                legend: {
                  width: 0,
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              Select Discount Type
            </MenuItem>
            <MenuItem value="percentage">Percentage</MenuItem>
            <MenuItem value="project">Project</MenuItem>
          </Select>
        </Box>
      </div>
      <div className="name">
        <Box className="sideFormSpan">
          <span className="formSpan">Type</span>
          <Select
            value={formData.issuance}
            onChange={(e) => {
              setFormData({ ...formData, issuance: e.target.value });
            }}
            displayEmpty
            style={{
              color: 'rgba(0, 0, 0, 0.6)',
              fontFamily: 'Open Sans',
            }}
            sx={{
              '& fieldset': {
                legend: {
                  width: 0,
                },
              },
            }}
            fullWidth
            variant="outlined"
          >
            <MenuItem value="" disabled>
              Select Discount Type
            </MenuItem>
            <MenuItem value="assigned">Assigned</MenuItem>
            <MenuItem value="batch">Batch</MenuItem>
            <MenuItem value="code">Code</MenuItem>
          </Select>
        </Box>
      </div>
      {formData.issuance === 'code' && (
        <>
          <div className="name" style={{ marginTop: '15px' }}>
            <Box className="sideFormSpan">
              <span
                className="formSpan"
                style={{ alignItems: 'Start', justifyContent: 'flex-start' }}
              >
                Expiration Date
              </span>
              <TextField
                value={formData.code}
                onChange={(e) =>
                  setFormData({ ...formData, code: e.target.value })
                }
                fullWidth
                id="outlined-basic"
                variant="outlined"
                outline="lightgray"
                placeholder="_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ "
                sx={{
                  '& .MuiOutlinedInput-input': {
                    fontSize: '20px',
                    letterSpacing: '14px',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    height: '40px',
                    outline: 'none !important',
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                    {
                      borderColor: 'gray', // Remove outline color on focus
                      outline: 'none !important',
                    },
                }}
              />
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

const AmountInformation = ({ formData, setFormData }) => {
  const [selected, setSelected] = useState('');
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <div className="right_side">
      <div className="info_title">
        <h3 className="">Discount Amount Information</h3>
        <InfoOutlinedIcon className="info_icon" />
      </div>
      <div className="name">
        <Box className="sideFormSpan">
          <span className="formSpan">Amount</span>
          <TextField
            label="Enter the Amount"
            variant="outlined"
            // size='small'
            fullWidth
            value={formData.amount}
            onChange={(e) =>
              setFormData({ ...formData, amount: e.target.value })
            }
            sx={{
              '.css-1in441m': {
                width: '6.5rem',
              },
            }}
          />
        </Box>
      </div>

      <div className="info_title" style={{ marginTop: '20px' }}>
        <h3 className="">Discount Expiration</h3>
        <InfoOutlinedIcon className="info_icon" />
      </div>
      <div>
        <div className="name" style={{ marginTop: '5px', marginBottom: '5px' }}>
          <Box className="sideFormSpan">
            <span className="formSpan">Expire By</span>
            <Select
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
              displayEmpty
              fullWidth
              label="Select Expiration Option"
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontFamily: 'Open Sans',
              }}
              sx={{
                '& fieldset': {
                  legend: {
                    width: 0,
                  },
                },
              }}
              variant="outlined"
            >
              <MenuItem value="" disabled>
                Select Expiration Option
              </MenuItem>
              <MenuItem value="By Date">By Date</MenuItem>
              <MenuItem value="By TimeFrame">By TimeFrame</MenuItem>
            </Select>
          </Box>
        </div>
        {selected === 'By Date' && (
          <div className="name" style={{ marginTop: '15px' }}>
            <Box className="sideFormSpan">
              <span
                className="formSpan"
                style={{ alignItems: 'Start', justifyContent: 'flex-start' }}
              >
                Expiration Date
              </span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={formData.expiration}
                  onChange={(newValue) =>
                    setFormData({ ...formData, expiration: newValue })
                  }
                  PopperProps={{
                    sx: {
                      right: 10,
                    },
                  }}
                  renderInput={(props) => (
                    <TextField
                      variant="outlined"
                      sx={{
                        '.css-1laqsz7-MuiInputAdornment-root': {
                          paddingRight: '10px',
                        },
                        '& fieldset': {
                          legend: {
                            width: 0,
                          },
                        },
                      }}
                      style={{
                        color: 'rgba(0, 0, 0, 0.6)',
                        fontFamily: 'Open Sans',
                        textTransform: 'uppercase',
                      }}
                      {...props}
                      fullWidth
                      onClick={() => setCalendarOpen(true)}
                      value={props.value ? props.value.format('MM/DD/YY') : ''}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </div>
        )}
        {selected === 'By TimeFrame' && (
          <>
            <div className="name" style={{ marginTop: '15px' }}>
              <Box
                className="sideFormSpan"
                style={{ alignItems: 'start', justifyContent: 'flex-start' }}
              >
                <span className="formSpan">Expiration TimeFrame</span>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    paddingBottom: '8px',
                  }}
                >
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      style={{
                        margin: 0,
                        backgroundColor: '#F7F7FA',
                        border: '0px',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                      placeholder="   ___   ___   ___"
                    />
                    <span
                      class="input-group-text"
                      style={{
                        border: '0px',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                      id="basic-addon2"
                    >
                      Month(s)
                    </span>
                  </div>
                  <div class="input-group mb-3 input-group-lg">
                    <input
                      type="text"
                      class="form-control"
                      style={{
                        margin: 0,
                        backgroundColor: '#F7F7FA',
                        border: '0px',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                      placeholder="    ___  :  ___ "
                    />
                    <span
                      class="input-group-text"
                      style={{
                        border: '0px',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                      id="basic-addon2"
                    >
                      Time
                    </span>
                  </div>
                </div>
              </Box>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const NewDisc = () => {
  const routeTo = useRouteTo();
  const [formData, setFormData] = useState({
    discount_name: '',
    type: '',
    issuance: '',
    amount: '',
    expiration: '',
    amount_type: '',
    code: '',
  });

  const access_token = useSelector((state) => state.auth.user.access);

  const handleSubmition = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${ENDPOINT}/api/superadmin/discount/create/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
          body: JSON.stringify(formData),
        },
      );

      if (!response.ok) {
        const errorData = await response.json();
      } else {
        // Reset form data and navigate to a success page or perform any other action
        setFormData({
          discount_name: '',
          type: '',
          issuance: '',
          amount: '',
          expiration: '',
        });
        routeTo(`${appRoutes.superAdmin.root}${appRoutes.superAdmin.tools.toolsDiscountEngine}`);
      }
    } catch (error) {
      // Handle any network or other errors
    }
  };

  const handleResize = () => {};
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="discountFlex">
        <section className="newForm">
          <div className="row1 newDiscountForm">
            <DiscountInformation
              className="Dis_info"
              formData={formData}
              setFormData={setFormData}
            />
            <AmountInformation
              className="amount_info"
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </section>
        <div>
          <div className="alCenter" style={gradientStyle}>
            <button
              className="cancel-btn"
              onClick={() => {
                routeTo(-1);
              }}
            >
              Cancel
            </button>
            <button
              style={{
                fontWeight: '400',
                fontSize: 14,
                width: '90px',
                height: '36px',
              }}
              className="btn btn-sm"
              onClick={handleSubmition}
            >
              {' '}
              Save{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewDisc;

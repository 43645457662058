import Gear from './assets/Gear.png';
import Dots from './assets/Dots.png';
import {
  Box, Button, Grid, CircularProgress,
} from '@mui/material';
import { useState, useEffect } from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MyList from './assets/MyLists.png';
import LightMyList from './assets/LightMyList.png';
import './netnetU.css';
import { useDispatch, useSelector } from 'react-redux';
import Scripture from './Scripture';
import { getCourses, getchapters } from './redux';
import VideoDemo from '../../Componenets/VideoDemo';
import HelpVideo from '../../Componenets/TopNav/HelpVideo';
import helpVid from '../Opportunity/assets/Section_Help-idle.svg'
import Bounce from 'react-reveal/Bounce';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';

const NetNetU = ({screenHeight}) => {
  const [params, setParams] = useState('Courses');
  const [megaLoading, setMegaLoading] = useState(false);
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.user.access);
  const [myCourses, setMyCourses] = useState([]);
  const courses = useSelector((state) => state.NetNetU.courses).filter(item => item.course_type === 'netnet_u' || item.course_type === null)
  const routeTo = useRouteTo();

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getCourses(meta));
    dispatch(getchapters(meta));
  }, []);

  useEffect(() => {
    setMyCourses(courses);
  }, [courses]);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getCourses(meta));
    dispatch(getchapters(meta));
  }, [megaLoading]);

  // Function to extract video ID from the YouTube URL
  const coursesHelp = useSelector((state) => state.NetNetU.courses).filter(item => item.course_type === 'help_videos')
  const welcomeChapters = coursesHelp.flatMap(course => {
    return course.chapters.filter(chapter => chapter.title === 'NetNet U');
  });
  const [show, setShow] = useState(false)

  return (
    <Bounce left>
      <>
        <HelpVideo show={show} setShow={setShow} welcomeChapters={welcomeChapters} />
        {megaLoading && (
          <section
            className="loading"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100%',
              backgroundColor: 'rgba(255,255,255,0.5)',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 999,
            }}
          >
            <CircularProgress color="secondary" />
          </section>
        )}
        <section className="oppor-section">
          <div className="oppor-row">
            <div className="oppor-col">
              <h3 className="fstxtPro">NetNet U</h3>
              <img className="icon-sm" src={Dots} alt="dots" />
              <img className="icon-sm" src={Gear} alt="gear" />
              <img src={helpVid} onClick={() => { setShow(true) }} style={{ color: '#a6a6a6', marginLeft: '10px', width: '28px', height: '28px', cursor: 'pointer' }} />
            </div>
          </div>
          <section
            className="netnetu-section"
            style={{
              height: screenHeight - 155,
              width: '100%',
            }}
          >
            <section className="oppor-row ">
              <h3 className="fstxtPro">
                {params == 'Courses'
                  ? 'All Courses'
                  : params == 'lessons'
                    ? 'All Lessons'
                    : 'My List'}
              </h3>
              <button
                className="noBorder specialTxT"
                onClick={() => (params == 'Courses'
                  ? routeTo(appRoutes.allCourses)
                  : params == 'lessons'
                    ? routeTo(appRoutes.allLessons)
                    : null)}
              >
                Explore All
                {' '}
                {params == 'Courses'
                  ? 'Courses'
                  : params == 'lessons'
                    ? 'Lessons'
                    : 'Courses'}
              </button>
            </section>
            <section
              className="oppor-row g10-p5-bg-300"
              style={{
                border: '1px solid #D9D9D9',
                padding: '1.2rem 2rem',
                borderRadius: '8px',
                marginBottom: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alingItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <button
                  className={
                    params == 'Courses' ? 'netnetu-btn-act' : 'netnetu-btn'
                  }
                  onClick={() => setParams('Courses')}
                >
                  Courses
                </button>
                <button
                  className={
                    params == 'lessons' ? 'netnetu-btn-act' : 'netnetu-btn'
                  }
                  onClick={() => setParams('lessons')}
                >
                  Lessons
                </button>
                <button
                  className={
                    params == 'myLists'
                      ? 'netnetu-btn-act'
                      : 'netnetu-btn btg-pq-ps-q5'
                  }
                  onClick={() => setParams('myLists')}
                >
                  <img
                    src={params == 'myLists' ? LightMyList : MyList}
                    alt="mylist"
                  />
                  My List
                </button>
              </Box>
              <Button className="search-btn">
                <ManageSearchIcon className="search-icon" />
              </Button>
            </section>
            {params == 'Courses' ? (
              <Grid container direction="column" gap="2rem">
                {myCourses
                  && myCourses.map((codex) => (
                    <Grid item>
                      <Scripture codex={codex} setMegaLoading={setMegaLoading} />
                    </Grid>
                  ))}
                <Grid container item justifyContent="space-between">
                  <Grid item>
                    <h3 className="fstxtPro">New Featured Lessons</h3>
                  </Grid>
                  <Grid item>
                    <button className="noBorder specialTxT">
                      Explore All Lessons
                    </button>
                  </Grid>

                  <Grid container item>
                    {myCourses.map((codex, index) => codex.chapters.map((item) => item?.lessons.map((lesson) => index < 4 && (
                      <Grid item xs={3} sx={{ display: 'flex', gap: '20px', width: '100%' }} >
                        <Box >
                          <VideoDemo
                            video={lesson.video}
                            duration={lesson.duration}
                            thumbnail={lesson.thumbnail}
                            lesson={lesson}
                          />
                        </Box>

                      </Grid>
                    ))))}
                  </Grid>
                </Grid>
              </Grid>
            ) : params == 'lessons' ? (
              <h1>Lessons</h1>
            ) : (
              <Grid container direction="column" gap="2rem">
                {myCourses
                  .filter((item) => item.is_saved == true)
                  .map((codex) => (
                    <Grid item>
                      <Scripture codex={codex} setMegaLoading={setMegaLoading} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </section>
        </section>
      </>
    </Bounce>

  );
};
export default NetNetU;

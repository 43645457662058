import React from 'react';
import {
  Grid,
  Box,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { ENDPOINT } from './Redux/oppor';
import RichTextEditor from '../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';

function DescriptionEstSixthStep({ setShowDescription, showDescription, setIsDetOpen, formData, access_token, setFormData, setDeliverable, setEditSection, editSection }) {
  const [html, setHtml] = useState(editSection?.description ?? '<p></p>');

  const handleClose = () => {
    setShowDescription(false)
    setIsDetOpen(true)
    setEditSection('')
  };
  const [desc, setDesc] = useState();
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (editSection) {
      setTitle(editSection.text)
      const htmlContent = editSection.description ?? '<p></p>';

      // Convert HTML to ContentState
      const blocksFromHTML = convertFromHTML(htmlContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      // Convert ContentState to string
      const contentStateString = JSON.stringify(convertToRaw(contentState));
      setDesc(contentStateString)
    }
  }, [editSection])

  const [openLink, setOpenLink] = useState(false);
  return (
    <Offcanvas show={showDescription} onHide={handleClose} placement="bottom ">
      <Offcanvas.Header>
        <button className="close-modal" onClick={() => {
          setShowDescription(false)
          setIsDetOpen(true)
          setEditSection(false)
        }} />
      </Offcanvas.Header>
      <Offcanvas.Body style={{ overflowY: 'hidden' }}>
        <Grid container direction="column" alignItems="flex-start">
          <Grid
            item
            container
            direction="column"
            alignItems="start"
            xl={12}
            lg={12}
            md={12}
            sm={12}
            sx={{ width: '90%', margin: '0 auto' }}
          >
            <Box sx={{ marginBottom: '1rem' }}>
              <h5>Add Your Section</h5>
            </Box>

            <Box
              className="df-r-j-c-gp-15 Editor-para15"
              sx={{
                backgroundColor: '#f5f5f5',
                width: '98%',
                borderRadius: '10px',
                padding: '1rem 1rem',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center',
                    borderBottom: '1px solid gray',
                    paddingBottom: '1rem',
                    width: '50%',
                  }}
                >
                  <h6 style={{ color: '#000' }}>Section Title</h6>
                  <TextField
                    placeholder="Title"
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    value={title}
                    sx={{
                      border: 'none',
                      inline: 'none',
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      width: '300px',
                    }}
                  />
                </Box>
        
                <Box
                  sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '0.88rem 1rem',
                    borderRadius: '8px',
                    border: '1px solid #d9d9d9',
                    marginTop: "6px",
          
                  }}
                >
                  <Box
                    sx={{ height: '85%', display: 'flex', flexDirection: 'column-reverse', }}
                  >
                    <RichTextEditor open={openLink} setOpen={setOpenLink} bottom={true} html={html} setHtml={setHtml} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Grid item sx={{ width: '99%', height: 'auto', padding: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '2rem'
                }}
              >
                <button className="cancel-btn" onClick={() => {
                  setShowDescription(false)
                  setIsDetOpen(true)
                }}>
                  Cancel
                </button>
                <button
                  className="create-btn"
                  onClick={async () => {
                    if (editSection && Object.keys(editSection).length > 0) {

                      const res = await fetch(`${ENDPOINT}/api/opportunity/update/estimate/section/${editSection.id}/`, {

                        method: 'PUT',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          estimate_id: formData.id,
                          section_title: title,
                          section_description: html,
                        })
                      })
                      const data = await res.json()
                      if (!res.ok) {

                        return
                      }
                      else {
                        setTitle('')
                        setHtml('')
                        const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                        });
                        const dataGet = await resGet.json();
                        if (!resGet.ok) {
                          return
                        }

                        setFormData(dataGet)


                        if (
                          dataGet &&
                          Array.isArray(dataGet.deliverable) &&
                          dataGet.deliverable.length
                        ) {
                          setDeliverable(dataGet.deliverable);
                        }
                      }
                      setShowDescription(false)
                      setIsDetOpen(true)
                      setEditSection()
                    }
                    else {
                      const res = await fetch(`${ENDPOINT}/api/opportunity/create/estimate/section/`, {

                        method: 'POST',
                        headers: {
                          Authorization: `JWT ${access_token}`,
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          estimate_id: formData.id,
                          section_title: title,
                          section_description: html,
                          sort_order: formData?.section?.length + 1
                        })
                      })
                      const data = await res.json()
                      if (!res.ok) {
                        return
                      }
                      else {
                        setTitle('')
                        setHtml('')
                        const resGet = await fetch(`${ENDPOINT}/api/opportunity/estimate/${formData?.unique_id}/`, {
                          method: 'GET',
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `JWT ${access_token}`,
                          },
                        });
                        const dataGet = await resGet.json();
                        if (!resGet.ok) {
                          return
                        }
                        setFormData(dataGet)
                        if (
                          dataGet &&
                          Array.isArray(dataGet.deliverable) &&
                          dataGet.deliverable.length
                        ) {
                          setDeliverable(dataGet.deliverable);
                        }
                      }
                      setShowDescription(false)
                      setIsDetOpen(true)
                      setEditSection()
                    }
                  }}
                >
                  Save
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Offcanvas.Body>
    </Offcanvas >
  );
}

export default DescriptionEstSixthStep;

import React from 'react';
import { useState, useEffect } from 'react';
import Header from './Header';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Link } from 'react-router-dom';
import { MbStopWatch } from '../../Componenets/StopWatch';
import { appRoutes } from '../../appRoutes';

function TaskDetails({ detailTask }) {
  const [show, setShow] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [data, setData] = useState();

  const topTitleStyling = {
    backgroundColor: '#7b2eff',
    height: '40px',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: 'white',
    fontWeight: 'bold',
    display: 'flex',
    fontFamily: 'sans-serif',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'space-between',
  };

  return (
    <div style={{ width: screenSize }}>
      <div>
        <Header />
      </div>

      <div style={topTitleStyling}>
        <Link to={appRoutes.tasks}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              color: 'white',
            }}
          >
            <ArrowBackOutlinedIcon />
            <p>Timer</p>
          </div>
        </Link>

        <FilterListOutlinedIcon />
      </div>

      <div
        style={{
          width: '90%',
          margin: '0 auto',
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0 3px 3px rgba(0, 0, 0, 0.3)',
        }}
      >
        <div>
          <div
            style={{
              backgroundColor: '#1864de',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingBottom: '15px',
            }}
          >
            <div
              style={{
                color: 'white',
                fontSize: '18px',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                marginLeft: '15px',
                marginRight: '15px',
                marginTop: '15px',
              }}
            >
              <p>{detailTask?.task_name}</p>
            </div>

            <div
              style={{
                backgroundColor: '#629dfc',
                marginLeft: '13px',
                width: '70%',
                padding: '7px',
                borderRadius: '8px',
                color: '#7b2eff',
              }}
            >
              <p>{detailTask?.company}</p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: '#f0f1f2',
            }}
          >
            <div
              style={{
                padding: '15px',
                fontSize: '15px',
                fontWeight: 'bold',
              }}
            >
              <p>
                PROJECT :{' '}
                <span style={{ color: '#7b2eff' }}>{detailTask?.work_type}</span>
              </p>
            </div>
          </div>

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '90%',
                alignItems: 'center',
                margin: '0 auto',
                padding: '2px',
              }}
            >
              <div>
                <p>Description</p>
              </div>

              <div>
                <p onClick={() => setShow(!show)}>
                  {show ? (
                    <KeyboardArrowDownOutlinedIcon />
                  ) : (
                    <KeyboardArrowUpOutlinedIcon />
                  )}
                </p>
              </div>
            </div>

            <div
              style={{
                width: '90%',
                alignItems: 'center',
                margin: '0 auto',
                padding: '2px',
                lineHeight: '1',
              }}
            >
              <div style={{ marginBottom: '5px' }}>
                {show ? (
                  <p
                    dangerouslySetInnerHTML={{ __html: detailTask?.description }}
                  ></p>
                ) : null}
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
            }}
          >
            <div
              style={{
                width: '60%',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid black',
                padding: '10px',
                justifyContent: 'space-around',
                borderRadius: '10px',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    {' '}
                    <p>Assignor</p>{' '}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      alt={detailTask?.created_by.first_name}
                      src={
                        detailTask?.created_by.avatar
                          ? detailTask?.created_by.avatar
                          : '/static/images/avatar/1.jpg'
                      }
                      style={{ width: '20px', height: '20px' }}
                    />
                    <p>{detailTask?.created_by.first_name}</p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <div>
                  {' '}
                  <p>Time</p>{' '}
                </div>

                <div
                  style={{
                    backgroundColor: 'orange',
                    borderRadius: '5px',
                    paddingRight: '5px',
                    paddingLeft: '5px',
                  }}
                >
                  <p>{detailTask?.estimated_hours}</p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#f0f1f2',
                width: '37%',
                border: '1px solid black',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <p>Due Date</p>
              <p style={{ marginTop: '10px' }}>
                {detailTask?.due_date ? detailTask?.due_date.split('T')[0] : null}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '20px' }}>
        <div>
          <div
            style={{
              backgroundColor: '#f6e3fc',
              width: '90%',
              borderRadius: '20px',
              padding: '15px',
              margin: '0 auto',
            }}
          >
            <div
              style={{
                backgroundColor: '#0225a6',
                display: 'flex',
                borderRadius: '20px',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '130px',
              }}
            >
              <MbStopWatch params={detailTask} data={data} setData={setData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskDetails;

import { Box, Typography } from '@mui/material';
import { useRouteTo } from '../hooks/useRouteTo';
import { appRoutes } from '../appRoutes';

function NotFound({ updateInnerNetNetTabs }) {
  const routeTo = useRouteTo();
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: '700',
            width: '100%',
            fontSize: '5.5rem',
            textAlign: 'center',
          }}
        >
          404
        </Typography>
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '1.5rem',
            color: '#000',
            textAlign: 'center',
            fontFamily: "'Neue Haas Display Pro', 'Open Sans', sans-serif",
            paddingRight: '10px',
          }}
        >
          Looks like you are Lost
        </Typography>
        <Typography
          className="fstxt"
          sx={{
            fontSize: '1.5rem',
            fontWeight: '500',
          }}
        >
          The page you are looking for does not exists :(
        </Typography>
        <button
          type="button"
          className="btn"
          onClick={() => {
            routeTo(appRoutes.rootRoute);
            updateInnerNetNetTabs('Me', appRoutes.rootRoute);
          }}
        >
          Continue
        </button>
      </Box>
    </Box>
  );
}

export default NotFound;

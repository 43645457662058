import React, { Component } from 'react';
import Bounce from 'react-reveal/Bounce';

import { Alert } from '@mui/material';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      countdown: 10,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error('Caught error:', error, info);

    this.setState({ countdown: 10 });

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }

    this.countdownInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown > 1) {
          return { countdown: prevState.countdown - 1 };
        }

        clearInterval(this.countdownInterval);
        this.setState({ hasError: false });

        return null;
      });
    }, 1000);
  }

  closeDialog = () => {
    clearInterval(this.countdownInterval);
    this.setState({ hasError: false });
  };

  render() {
    const { hasError, countdown } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <Bounce left>
            <div
              style={{
                position: 'fixed',
                bottom: '20px',
                left: '20px',
                width: '400px',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                border: '1px solid rgb(204, 204, 204)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
                textAlign: 'center',
              }}
            >
              <h3 className="fstxtPro">Whoops...</h3>
              <p
                className="fstxt"
                style={{
                  textAlign: 'center',
                }}
              >
                An unexpected error has occurred.
              </p>

              <Alert
                severity="info"
                sx={{
                  display: 'flex',
                  marginTop: '10px',
                }}
              >
                This message will disappear in {countdown} seconds. And the page
                will reload.
              </Alert>

              <div style={{ marginTop: '10px' }}>
                <button
                  type="button"
                  aria-label="close dialog"
                  title="Closing this dialog will reload the page"
                  className="btn"
                  onClick={this.closeDialog}
                >
                  Ok, close
                </button>

                <p
                  style={{
                    fontSize: '12px',
                    color: '#999',
                  }}
                >
                  * Closing this dialog will reload the page.
                </p>
              </div>
            </div>
          </Bounce>

          {children}
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import filterIcon from '../sort 1.svg';
import './authorNew.css';
import TextField from '@mui/material/TextField';
import 'quill/dist/quill.snow.css';
import React, { useEffect, useRef, useState } from 'react';
import youTubeLogo from '../youTubeLogo.svg';
import ImageIconOutlined from '@mui/icons-material/ImageOutlined';
import { useSelector } from 'react-redux';
import { DropzoneArea } from 'react-mui-dropzone';
import RichTextEditor from '../../../../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import { Box } from '@mui/system';
import { appRoutes } from "../../../../../appRoutes";

const Header = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: ' 1% 1.38% 1% 1.38%',
        boxSizing: 'border-box',
        textAlign: 'center',
        fontSize: '29px',
        color: '#03071e',
        fontFamily: "'Open Sans'",
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '20px',
        }}
      >
        <b style={{ position: 'relative', lineHeight: '100%' }}>
          Net Net U Authors
        </b>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            fontSize: '14px',
            color: 'rgba(3, 7, 30, 0.4)',
          }}
        >
          <div
            style={{
              borderRadius: '4px',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '3px',
            }}
          >
            <img
              style={{
                position: 'relative',
                width: '18px',
                height: '18px',
                overflow: 'hidden',
                flexShrink: '0',
              }}
              alt=""
              src={filterIcon}
            />
            <div
              style={{
                position: 'relative',
                letterSpacing: '-0.3px',
                lineHeight: '19px',
              }}
            >
              Filters
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          padding: '5px 0 ',
          fontSize: '14px',
          color: '#fff',
        }}
      >
        <Link to={`${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.newNetNetUAuthors}`}>
          <Button
            sx={{
              width: 108,
              height: 35,
              textTransform: 'capitalize',
              fontWeight: '300',
              backgroundColor: '#711FFF',
              lineHeight: '19px',
              fontSize: '14px',
              '&:hover': {
                backgroundColor: '#711DDD',
              },
            }}
            variant="contained"
          >
            + New
          </Button>
        </Link>
      </div>
    </div>
  );
};

const AuthorsNew = () => {
  const [files, setFiles] = useState([]);

  const [description, setDescription] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company_name: '',
    email: '',
    youtube_url: '',
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      description: description,
    }));
  }, [description]);

  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();

    if (files.length > 0) {
      formDataWithFile.append('profile_image', files[0]);
    }
  };

  return (
    <>
      <div style={{ padding: '4px 18px' }}>
        <Header />
        <div
          className="authorCoursesList"
          style={{
            height: '90vh',
            overflowY: 'auto',
            width: '97.2%',
            overflowX: 'auto',
          }}
        >
          <div className="authorCourses">
            <h3>Register as Author</h3>
            <p>Please Add your details to register as author</p>
          </div>

          <div className="authorDetailContainer">
            <div className="fullName">
              <TextField
                sx={{
                  borderRadius: '4px',
                  borderColor: '#d9d9d9',
                  height: '51px',
                  minWidth: '300px',
                  flex: 1,
                }}
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: e.target.value })
                }
                fullWidth
                placeholder="Enter Your First Name Here"
                label="First Name"
                variant="outlined"
              />
              <TextField
                sx={{
                  borderRadius: '4px',
                  borderColor: '#d9d9d9',
                  height: '51px',
                  minWidth: '300px',
                  flex: 1,
                }}
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: e.target.value })
                }
                fullWidth
                label="Last Name"
                placeholder="Enter Your Last Name Here"
                variant="outlined"
              />
            </div>
            <div className="authorDetail">
              <div className="detailLeft">
                <div className="companyName">
                  <div
                    style={{
                      width: '100%',
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      padding: '9px 8px 8px',
                      boxSizing: 'border-box',
                      gap: '3px',
                      textAlign: 'left',
                      fontSize: '14px',
                      color: 'rgba(3, 7, 30, 0.80)',
                      fontFamily: 'Open Sans',
                      fontWeight: 600,
                    }}
                  >
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        position: 'relative',
                        letterSpacing: '0.15px',
                        lineHeight: '19px',
                      }}
                    >
                      Company Name
                    </div>
                    <div
                      style={{
                        alignSelf: 'stretch',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        fontSize: '19px',
                        height: '51px',
                        color: 'rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      <TextField
                        name="company name"
                        sx={{
                          '.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
                            height: '2.2rem',
                          },
                        }}
                        value={formData.company_name}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            company_name: e.target.value,
                          })
                        }
                        variant="standard"
                        placeholder="Enter Your Registered Company Name"
                        fullWidth
                        height={51}
                      />

                      <div
                        style={{
                          position: 'relative',
                          width: '0px',
                          height: '24px',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="emailID">
                  <p style={{ fontSize: '16px', fontWeight: 600 }}>
                    Add Your Email ID
                  </p>

                  <TextField
                    name="company name"
                    sx={{
                      '.css-10botns-MuiInputBase-input-MuiFilledInput-input ': {
                        height: '2.2rem',
                      },
                      '.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                        {
                          color: '#711fff',
                        },
                      marginTop: '16px',
                    }}
                    label="Email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    variant="filled"
                    placeholder="Enter Your Email Adress Here"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>

              <div className="dropContainer">
                <div className="detailRight">
                  <div className="ImageIcon">
                    <ImageIconOutlined
                      style={{
                        height: '38px',
                        width: '38px',
                      }}
                    />

                    <p>Upload Profile Image</p>
                  </div>
                  <div className="dropZone">
                    <DropzoneArea
                      showPreviewsInDropzone={false}
                      acceptedFiles={['image/*']}
                      onChange={handleFileChange}
                      dropzoneText="Upload 
                        or
                        Drag and Drop"
                    />
                  </div>

                  <p className="recomended">Recommended Size 720 x 720</p>
                </div>
              </div>
            </div>

            {/* <Box className={'Test'} sx={{
              width: "100%", minHeight: "220px", paddingBottom: "0.1rem", border: "1px solid #a9a9a9", borderRadius: "8px",
              "&:hover, &:focus-within": {  // Apply the same border color on hover and focus
                border: "1px solid #711fff",
              },
            }} > */}
            <RichTextEditor setHtml={setDescription} />
            {/* </Box> */}
            <div className="authorYoutubeLink">
              <div
                style={{
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '10px',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                <img
                  style={{
                    position: 'relative',
                    width: '35.71px',
                    height: '25px',
                  }}
                  alt=""
                  src={youTubeLogo}
                />
                <div
                  style={{
                    position: 'relative',
                    lineHeight: '100%',
                    fontWeight: '600',
                  }}
                >
                  Enter Your Course YouTube LInk
                </div>
              </div>
              <div
                style={{
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  color: '#03071e',
                }}
              >
                <div
                  style={{
                    alignSelf: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    gap: '4.5px',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      lineHeight: '14px',
                      fontWeight: '600',
                    }}
                  >
                    YouTube URL
                  </div>
                  <TextField
                    id="standard-number"
                    placeholder="Enter Your Link"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.youtube_url}
                    onChange={(e) =>
                      setFormData({ ...formData, youtube_url: e.target.value })
                    }
                    variant="standard"
                    fullWidth
                  />
                </div>
              </div>
            </div>

            <div className="bottomActionButtons" style={{
              margin: "15px auto"
            }}>
              <Link to={`${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.netNetUAuthors}`}>
                <Button
                  variant="contained"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.06)',
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.06)',
                    color: 'black',
                    textTransform: 'capitalize',
                    width: '84px',
                    paddingX: 1,
                    fontFamily: 'Open Sans',
                  }}
                >
                  Go Back
                </Button>
              </Link>
              <Link
                to={`${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.netnetUCourses}`}
              >
                <Button
                  onClick={handleSubmission}
                  variant="contained"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#711DDD',
                      color: 'white',
                    },
                    backgroundColor: '#711FFF',
                    textTransform: 'capitalize',
                    width: '100%',
                    fontFamily: 'Open Sans',
                  }}
                >
                  Continue
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorsNew;

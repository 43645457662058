import { useEffect, useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Favicon from 'react-favicon';

import Auth from './Componenets/Auth/Login/Auth';
import Signup from './Componenets/Auth/Signup/Signup';
import RequireAuth from './Componenets/ProtectedRoute/RequireAuth';
import Onbording from './Componenets/Auth/Signup/Onbording';
import Verified from './Componenets/Auth/Signup/Verified';
import ResetPassword from './Componenets/Auth/ResetPassword/ResetPassword';
import AllSet from './Componenets/Auth/Signup/AllSet';
import ConfirmReset from './Componenets/Auth/ResetPassword/ConfirmReset';
import Invitation from './Componenets/Invitation/Invitation';

import InnerApp from './Screens/InnerApp/InnerApp';
import ResendEmail from './Screens/InnerApp/ResendEmail';

import './Fonts/NeueHaasDisplay/NeueHaasDisplayBlack.ttf';
import favi from './Componenets/ms-icon-150x150.png';

import ErrorBoundary from './ErrorBoundary';
import { appRoutes } from './appRoutes';

function App() {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const handleScreen = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleScreen);
    return () => {
      window.removeEventListener('resize', handleScreen);
    };
  });

  return (
    <>
      <Favicon url={favi} />
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route
              exact
              path={appRoutes.register}
              element={<Signup screenHeight={screenHeight} />}
            />
            <Route
              exact
              path={appRoutes.login}
              element={<Auth screenHeight={screenHeight} />}
            />
            <Route
              exact
              path={appRoutes.check}
              element={<Onbording screenHeight={screenHeight} />}
            />
            <Route
              exact
              path={appRoutes.success}
              element={<Verified screenHeight={screenHeight} />}
            />
            <Route
              exact
              path={appRoutes.reset}
              element={<ResetPassword screenHeight={screenHeight} />}
            />
            <Route path={appRoutes.teamInvitation} element={<Invitation />} />
            <Route path={appRoutes.resetPassword} element={<ConfirmReset />} />
            <Route
              path={appRoutes.resendActivation}
              element={<ResendEmail />}
            />
            <Route element={<RequireAuth />}>
              <Route exact path={appRoutes.complete} element={<AllSet />} />
              <Route
                exact
                path={`${appRoutes.rootRoute}*`}
                element={<InnerApp />}
              />
            </Route>
          </Routes>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import Bounce from 'react-reveal/Bounce';
import { Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { CSVLink } from 'react-csv';
import helpVid from '../../Opportunity/assets/Section_Help-idle.svg';
import { getCompanies } from '../ContactRedux';
import Dots from '../assets/Dots.png';
import ExportIcon from '../assets/ExportIcon.png';
import DeleteIcon from '../assets/DeleteIcon.png';
import { CompanyDelete, CompanySureDelete } from './Company_Model';
import editTask from '../assets/editTask.png';
import HelpVideo from '../../../Componenets/TopNav/HelpVideo';
import { FindIcon } from '../../Projects/_module';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

function DataGripComponent({
  rows,
  updatedColumns,
  companySortModel,
  handleColumnOrderChange,
  selectedCompanies,
  setSelectedCompanies,
  setCompanySortModel,
  screenHeight,
}) {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: screenHeight - 0,
        maxHeight: screenHeight - 0,
        borderRadius: '8px',
      }}
    >
      <DataGridPro
        rows={rows}
        columns={updatedColumns}
        sortModel={companySortModel}
        onSortModelChange={(model) => {
          setCompanySortModel(model);
        }}
        onColumnOrderChange={handleColumnOrderChange}
        rowHeight={60}
        checkboxSelection
        rowSelectionModel={selectedCompanies}
        onRowSelectionModelChange={(id) => setSelectedCompanies(id)}
        sx={{
          minHeight: screenHeight - 155,
          maxHeight: screenHeight - 155,
          overflow: 'auto',
          borderRadius: '8px',
        }}
      />
    </Box>
  );
}

function OptBtn({ params, setDelet, setCustomParams, updateInnerNetNetTabs }) {
  const currentUser = useSelector((state) => state.userData.userData);
  const routeTo = useRouteTo();

  useEffect(() => {
    setCustomParams(params);
  }, []);

  return (
    <Popup
      trigger={
        <button type="button" className="noBorder">
          <img src={params.value} alt="more" />
        </button>
      }
      position="right center"
    >
      <ul className="popup" style={{ width: '150px' }} id={params.id}>
        <li
          className="popupChildren"
          onClick={() => {
            routeTo(`${appRoutes.editCompany}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Edit Company',
              `${appRoutes.editCompany}/${params.row.unique_id}`,
            );
          }}
        >
          {' '}
          <img alt="icon" src={editTask} className="icon-sm" />
          Edit
        </li>

        {currentUser.is_owner || currentUser.is_admin ? (
          <li
            className="popupChildren LastChild"
            onClick={() => {
              setCustomParams(params.row);
              setDelet(true);
            }}
            style={{ color: 'red' }}
          >
            <img
              alt="icon"
              src={DeleteIcon}
              className="icon-sm"
              style={{ marginLeft: '-5px' }}
            />
            Delete
          </li>
        ) : null}
      </ul>
    </Popup>
  );
}

function Companies({ screenHeight, updateInnerNetNetTabs }) {
  const [sureDelete, setSureDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [delet, setDelet] = useState(false);
  const [customParams, setCustomParams] = useState({
    row: {},
  });
  const [show, setShow] = useState(false);

  const [menuIconEl, setMenuIconEl] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [myDropDown, setShowDropDown] = useState(false);
  const routeTo = useRouteTo();
  const currentUser = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [filterOption, setFilterOption] = useState(false);
  const [companyDetail] = useState(false);
  const access_token = useSelector((state) => state.auth.user.access);

  useEffect(() => {
    dispatch(
      getCompanies({
        access_token,
      }),
    );
  }, []);

  const companies = useSelector((state) => state.Contacts.companies);

  const [ourCompanies, setOurCompanies] = useState([
    {
      id: 1,
      name: 'Company 1',
      address: 'Address 1',
      address_zip: '',
      linkedin_url: '',
      facebook_url: '',
      twitter_url: '',
      instagram_url: '',
      contact_type: '',
      tags: '',
      category: '',
      activated: '',
      created_by: 1,
      state: '',
      city: '',
    },
  ]);

  useEffect(() => {
    setOurCompanies(companies);
  }, [companies]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(companies);
  }, [companies]);

  document.addEventListener('click', (e) => {
    if (e.target.id !== 'c-btn') {
      setShowDropDown(false);
    }
  });
  document.addEventListener('click', (e) => {
    if (e.target.id !== 'filter') {
      setFilterOption(false);
    }
  });

  const handleExportToExcel = () => {
    if (selectedCompanies.length > 0) {
      const companiesRecord = selectedCompanies
        .map((cid) => companies.find((company) => company.id === cid))
        .filter((company) => !!company)
        .map(
          ({
            id,
            unique_id,
            name,
            address,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            activated,
            category,
            country,
            street,
            city,
            state,
            company_url,
          }) => ({
            id,
            unique_id,
            name,
            address,
            address_zip,
            linkedin_url,
            facebook_url,
            twitter_url,
            instagram_url,
            contact_type,
            activated,
            category,
            country,
            street,
            city,
            state,
            company_url,
            ' ': '',
          }),
        );
      return companiesRecord;
    }
    return [];
  };

  useEffect(() => {
    setProjects(companies);
  }, [filterOption]);

  useEffect(() => {
    const meta = {
      access_token,
    };
    dispatch(getCompanies(meta));
  }, [show]);

  const [columns, setColumns] = useState([
    {
      field: 'name',
      headerName: 'Company name',
      flex: 2,
      renderHeader: () => <div className="tableHeader">Company Name</div>,
      renderCell: (params) => (
        <div
          className="td"
          onClick={() => {
            routeTo(`${appRoutes.companyDetail}/${params.row.unique_id}`);
            updateInnerNetNetTabs(
              'Company Detail',
              `${appRoutes.companyDetail}/${params.row.unique_id}`,
            );
          }}
        >
          {params.value}
        </div>
      ),
      align: 'left',
      resizable: true,
    },
    {
      field: 'web_address',
      headerName: 'Address',
      flex: 2,
      renderHeader: () => <div className="tableHeader">Web Address</div>,
      renderCell: (params) => {
        return (
          <div className="td">
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (Array.isArray(params.value) && params.value.length) {
                  const url = params.value[0].domain_name;
                  window.open(url, '_blank');
                }
              }}
            >
              {Array.isArray(params.value) && params.value.length
                ? params.value[0].domain_name
                : ''}
            </Box>
          </div>
        );
      },
    },

    {
      field: 'state',
      headerName: 'State',
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">State</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1.5,
      renderHeader: () => <div className="tableHeader">City</div>,
      renderCell: (params) => <div className="td">{params.value}</div>,
    },
    {
      field: 'Options',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params) => (
        <div style={{ padding: '0 1rem 0 0' }}>
          <OptBtn
            updateInnerNetNetTabs={updateInnerNetNetTabs}
            params={params}
            setDelet={setDelet}
            setCustomParams={setCustomParams}
            delet={delet}
          />
        </div>
      ),
    },
  ]);

  const [companySortModel, setCompanySortModel] = useState(
    sessionStorage.getItem('CompanySortModel')
      ? JSON.parse(sessionStorage.getItem('CompanySortModel'))
      : [
          {
            field: 'name',
            sort: 'asc',
          },
        ],
  );

  useEffect(() => {
    sessionStorage.setItem(
      'CompanySortModel',
      JSON.stringify(companySortModel),
    );
  }, [companySortModel]);

  const rows = projects.map((company) => ({
    id: company.id,
    name: company.name,
    web_address: company.company_url,
    state: company.state,
    city: company.city,
    Options: Dots,
    unique_id: company.unique_id,
  }));

  const searchedRows = searchQuery
    ? rows.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.city?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          item?.state?.toLowerCase().includes(searchQuery.toLowerCase()),
      )
    : rows;

  const handleColumnOrderChange = (newColumnOrder) => {
    // eslint-disable-next-line
    const updatedColumns1 = [...columns.map((item) => Object.assign({}, item))];
    const columnToMove = updatedColumns1[newColumnOrder.targetIndex - 1];

    const find = columns.find(
      (item) => item.field === newColumnOrder.column.field,
    );

    updatedColumns1[newColumnOrder.targetIndex - 1] = find;
    updatedColumns1[newColumnOrder.oldIndex - 1] = columnToMove;
    sessionStorage.setItem(
      'CompanycolumnOrder',
      JSON.stringify(updatedColumns1),
    );
    setColumns(updatedColumns1);
  };

  useEffect(() => {
    if (sessionStorage.getItem('CompanycolumnOrder')) {
      setColumns(JSON.parse(sessionStorage.getItem('CompanycolumnOrder')));
    } else {
      setColumns(columns);
    }
  }, []);

  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter((chapter) => chapter.title === 'Company');
  });
  const [show1, setShow1] = useState(false);

  return companies.length < 1 ? (
    <section className="oppor-section">
      <div className="oppor-row">
        {sureDelete && (
          <CompanySureDelete
            selected={selectedCompanies}
            setSureDelete={setSureDelete}
          />
        )}
        {delet && (
          <CompanyDelete customParams={customParams} setSureDelete={setDelet} />
        )}
        {companyDetail && (
          <article className="Center">
            <img className="icon-sm" src={Dots} alt="dots" />
          </article>
        )}

        <div className={!companyDetail ? 'oppor-col' : 'noDisplay'}>
          <h3 className="fstxtPro">Companies</h3>
          <Menu
            id="company-icon-menu"
            sx={{
              '& .MuiMenu-paper': {
                border: '1px solid #03071E33',
                borderRadius: '10px',
              },
              '& .MuiMenu-list': {
                margin: '5px 15px 5px 5px',
              },
            }}
            anchorEl={menuIconEl}
            open={!!menuIconEl}
            onClose={() => setMenuIconEl(null)}
            autoFocus={false}
          >
            <MenuItem className="mb-2">
              <CSVLink
                style={{ color: 'black' }}
                enclosingCharacter=""
                data={handleExportToExcel()}
                onClick={() => handleExportToExcel().length > 0}
                filename="Companies.csv"
              >
                <img alt="icon" src={ExportIcon} className="icon-sm" />
                Export Selected
              </CSVLink>
            </MenuItem>
            {currentUser.is_owner || currentUser.is_admin ? (
              <MenuItem
                className="text-danger mb-2"
                onClick={() => {
                  setSureDelete(true);
                  setMenuIconEl(null);
                }}
              >
                <img alt="icon" src={DeleteIcon} className="icon-sm" />
                Delete Selected
              </MenuItem>
            ) : null}
          </Menu>
        </div>

        <button
          type="button"
          className={!companyDetail ? 'create-btn' : 'noDisplay'}
          onClick={() => setShowDropDown(!myDropDown)}
          id="c-btn"
        >
          + New
        </button>
        {myDropDown && (
          <ul className="menu peopleDrop">
            <li
              className="menu-item popupChildren"
              onClick={() => {
                routeTo(appRoutes.newPerson);
                updateInnerNetNetTabs('New Person', appRoutes.newPerson);
              }}
            >
              New Person
            </li>
            <li
              className="menu-item LastChild popupChildren"
              onClick={() => {
                routeTo(appRoutes.newCompany);
                updateInnerNetNetTabs('New Company', appRoutes.newCompany);
              }}
            >
              New Company
            </li>
            <li
              className="menu-item popupChildren"
              onClick={() => {
                updateInnerNetNetTabs(
                  'Import People & Companies',
                  appRoutes.importContacts,
                );
                routeTo(appRoutes.importContacts);
              }}
            >
              Import People & Companies
            </li>
          </ul>
        )}
      </div>
      <section className="myCenter" style={{ height: screenHeight - 155 }}>
        <article className="box-txt">
          <h3 className="txtCenter">
            Whoopsy.... You don&apos;t have any Companies just yet!
          </h3>
          <p className="txtCenter initialParagraph">
            That&apos;s okay, you can easily add one at a time or many through
            the import process. Let&apos;s get started, eh?
          </p>
          <article className="buttonGroup">
            <button
              type="button"
              className="btn"
              onClick={() => {
                updateInnerNetNetTabs(
                  'Import People & Companies',
                  appRoutes.importContacts,
                );
                routeTo(appRoutes.importContacts);
              }}
            >
              Import
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => {
                routeTo(appRoutes.newCompany);
                updateInnerNetNetTabs('New Company', appRoutes.newCompany);
              }}
            >
              New Company
            </button>
          </article>
        </article>
      </section>
      <HelpVideo
        show={show}
        setShow={setShow}
        welcomeChapters={welcomeChapters}
      />
    </section>
  ) : (
    <Bounce left>
      <section className={!companyDetail ? 'oppor-section' : 'oppor-section'}>
        <div className="oppor-row">
          {sureDelete && (
            <CompanySureDelete
              selected={selectedCompanies}
              setSureDelete={setSureDelete}
            />
          )}
          {delet && (
            <CompanyDelete
              customParams={customParams}
              setSureDelete={setDelet}
            />
          )}
          {companyDetail && (
            <article className="Center">
              <img className="icon-sm" src={Dots} alt="dots" />
            </article>
          )}

          <div className={!companyDetail ? 'oppor-col' : 'noDisplay'}>
            <h3 className="fstxtPro">Companies</h3>
            {/* eslint-disable-next-line */}
            <img
              style={{ cursor: 'pointer' }}
              className="icon-sm"
              src={Dots}
              alt="dots"
              id="company-icon"
              aria-haspopup="true"
              aria-controls={menuIconEl ? 'company-menu' : undefined}
              aria-expanded={menuIconEl ? 'true' : undefined}
              onClick={(e) => setMenuIconEl(e.currentTarget)}
            />
            <Menu
              id="company-icon-menu"
              sx={{
                '& .MuiMenu-paper': {
                  border: '1px solid #03071E33',
                  borderRadius: '10px',
                },
                '& .MuiMenu-list': {
                  margin: '5px 15px 5px 5px',
                },
              }}
              anchorEl={menuIconEl}
              open={!!menuIconEl}
              onClose={() => setMenuIconEl(null)}
              autoFocus={false}
            >
              <MenuItem className="mb-2">
                <CSVLink
                  style={{ color: 'black' }}
                  enclosingCharacter=""
                  data={handleExportToExcel()}
                  onClick={() => handleExportToExcel().length > 0}
                  filename="Companies.csv"
                >
                  <img alt="icon" src={ExportIcon} className="icon-sm" />
                  Export Selected
                </CSVLink>
              </MenuItem>
              {currentUser.is_owner || currentUser.is_admin ? (
                <MenuItem
                  className="text-danger mb-2"
                  onClick={() => {
                    setSureDelete(true);
                    setMenuIconEl(null);
                  }}
                >
                  <img alt="icon" src={DeleteIcon} className="icon-sm" />
                  Delete Selected
                </MenuItem>
              ) : null}
            </Menu>
            <img
              alt="icon"
              src={helpVid}
              onClick={() => setShow1(true)}
              style={{
                color: '#a6a6a6',
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
              }}
            />
            <Box sx={{ marginLeft: '1rem' }}>
              {' '}
              <FindIcon
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Box>
          </div>

          <button
            type="button"
            className={!companyDetail ? 'create-btn' : 'noDisplay'}
            onClick={() => setShowDropDown(!myDropDown)}
            id="c-btn"
          >
            + New
          </button>
          {myDropDown && (
            <ul className="menu peopleDrop">
              <li
                className="menu-item popupChildren"
                onClick={() => {
                  routeTo(appRoutes.newPerson);
                  updateInnerNetNetTabs('New Person', appRoutes.newPerson);
                }}
              >
                New Person
              </li>
              <li
                className="menu-item LastChild popupChildren"
                onClick={() => {
                  routeTo(appRoutes.newCompany);
                  updateInnerNetNetTabs('New Company', appRoutes.newCompany);
                }}
              >
                New Company
              </li>
              <li
                className="menu-item popupChildren"
                onClick={() => {
                  updateInnerNetNetTabs(
                    'Import People & Companies',
                    appRoutes.importContacts,
                  );
                  routeTo(appRoutes.importContacts);
                }}
              >
                Import People & Companies
              </li>
            </ul>
          )}
        </div>

        <DataGripComponent
          filterOption={filterOption}
          ourCompanies={ourCompanies}
          rows={searchedRows}
          updatedColumns={columns}
          companySortModel={companySortModel}
          handleColumnOrderChange={handleColumnOrderChange}
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          setCompanySortModel={setCompanySortModel}
          screenHeight={screenHeight}
        />
        <HelpVideo
          show={show1}
          setShow={setShow1}
          welcomeChapters={welcomeChapters}
        />
      </section>
    </Bounce>
  );
}
export default Companies;

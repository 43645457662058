import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Chip, Box, Popper } from '@mui/material';

import { NetnetField } from '../../../Componenets/NetField';
import Dots from '../assets/Dots.png';
import Gear from '../assets/Gear.png';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import PreLoader, { myCountries } from '../../../Componenets/Misc';
import { getPersons } from '../ContactRedux';
import { useRouteTo } from '../../../hooks/useRouteTo';

function PreLoading() {
  return (
    <section
      style={{
        height: '100vh',
        width: '100%',
      }}
    >
      <section
        className="loading"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            width: '100px',
            height: '100px',
          }}
        >
          <PreLoader />
        </Box>
      </section>
    </section>
  );
}

function EditContent({ screenHeight }) {
  const routeTo = useRouteTo();
  const { id } = useParams();
  const CompanyCreate = `${ENDPOINT}/api/contact/company/create/`;
  const filter = createFilterOptions();
  const access_token = useSelector((state) => state.auth.user.access);
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const [loading, setLoading] = useState(true);
  const currentPeoples = useSelector((state) => state.Contacts.persons);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    company: '',
    title: '',
    country: '',
    street: '',
    state: '',
    city: '',
    address_zip: '',
    linkedin_url: '',
    twitter_url: '',
    facebook_url: '',
    instagram_url: '',
    email: '',
    mobile_num: '',
    office_phone: '',
    street2: '',
  });

  const companyOptions = currentCompanies.map((company) => ({
    name: company.name,
    id: company.id,
  }));

  const ourTarget = currentPeoples.find((p) => p.id === id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${ENDPOINT}/api/contact/detail/${id}/`, {
          method: 'GET',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!res.ok) {
          routeTo(-1);
        } else {
          const data = await res.json();
          if (data) {
            setLoading(false);
            setFormData(data);
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    };
    fetchData();
  }, [id, ourTarget]);

  const handleSubmition = async (e) => {
    setShowSpinner(true);
    e.preventDefault();
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        delete formData[key];
      }
    });
    if (formData.company) {
      const company_id = currentCompanies.find(
        (c) => c.name === formData.company,
      );
      if (company_id) {
        const response = await fetch(
          `${ENDPOINT}/api/contact/update/${formData.id}/`,
          {
            method: 'PUT',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...formData,
              company: company_id.id,
            }),
          },
        );
        const data = await response.json();
        if (!response.ok) {
          setShowSpinner(false);
          setIsError(true);
          Object.keys(data).forEach((key) => {
            setErrorMessage(
              data[key].Error
                ? data[key].Error
                : (Object.values(data) ?? 'Oops Something Went Wrong'),
            );
          });
        } else {
          dispatch(getPersons({ access_token }));
          setShowSpinner(false);
          routeTo(-1);
        }
      } else {
        const secondary = await fetch(CompanyCreate, {
          method: 'POST',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.company,
          }),
        });
        const secondarydata = await secondary.json();
        if (!secondary.ok) {
          setShowSpinner(false);
          setIsError(true);
          Object.keys(secondarydata).forEach((key) => {
            setErrorMessage(
              secondarydata[key].Error
                ? secondarydata[key].Error
                : (Object.values(secondarydata) ?? 'Oops Something Went Wrong'),
            );
          });
        } else {
          const Tresponse = await fetch(
            `${ENDPOINT}/api/contact/update/${formData.id}/`,
            {
              method: 'PUT',
              headers: {
                Authorization: `JWT ${access_token}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                ...formData,
                company: secondarydata.id,
              }),
            },
          );

          const Tdata = await Tresponse.json();

          if (!Tresponse.ok) {
            setIsError(true);
            setShowSpinner(false);
            Object.keys(Tdata).forEach((key) => {
              setErrorMessage(
                Tdata[key].Error
                  ? Tdata[key].Error
                  : (Object.values(Tdata) ?? 'Oops Something Went Wrong'),
              );
            });
          } else {
            dispatch(getPersons({ access_token }));
            setShowSpinner(false);
            routeTo(-1);
          }
        }
      }
    } else {
      const Fresponse = await fetch(
        `${ENDPOINT}/api/contact/update/${formData.id}/`,
        {
          method: 'PUT',
          headers: {
            Authorization: `JWT ${access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        },
      );

      const Fdata = await Fresponse.json();
      if (!Fresponse.ok) {
        setShowSpinner(false);
        setIsError(true);
        Object.keys(Fdata).forEach((key) => {
          setErrorMessage(
            Fdata[key].Error
              ? Fdata[key].Error
              : (Object.values(Fdata) ?? 'Oops Something Went Wrong'),
          );
        });
      } else {
        dispatch(getPersons({ access_token }));
        setShowSpinner(false);
        routeTo(-1);
      }
    }
  };

  const types = [
    'Client',
    'Prospect',
    'Subcontractor',
    'Vendor',
    'Partner',
    'Employee',
  ];

  const handleDelete = (chipToDelete) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: prevFormData.tags
        .split(',')
        .filter((chip) => chip.trim() !== chipToDelete.trim())
        .join(','),
    }));
  };
  const persons = useSelector((item) => item.Contacts.persons).filter(
    (item) => item.tags,
  );
  const flattenedTags = persons
    .map((person) => person.tags.split(',').map((tag) => tag.trim()))
    .flat();

  const uniqueTagsArray = [];
  const seenTags = new Set();
  // eslint-disable-next-line
  for (const tag of flattenedTags) {
    if (!seenTags.has(tag)) {
      seenTags.add(tag);
      uniqueTagsArray.push(tag);
    }
  }

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  return (
    <>
      {loading && (
        <Box className="oppor-section">
          <PreLoading />
        </Box>
      )}
      {showSpinner && <PreLoading />}
      <div className="oppor-section2">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">Edit Contact</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
          </div>
        </div>
        <section
          className="grid-system"
          style={{ height: screenHeight - 180, overflow: 'auto' }}
        >
          <div className="row">
            <div className="col col-NP-NC">
              <h3 className="sub-title">Basic Information</h3>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">First Name</span>
                <NetnetField
                  id="standard-basic"
                  label="Enter First Name"
                  variant="outlined"
                  className="MUI-P6-M5"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                  color="secondary"
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Last Name</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter Last Name"
                  variant="outlined"
                  className="MUI-P6-M5"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                  color="secondary"
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Email</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter Email"
                  variant="standard"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                  type="email"
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Mobile Number</span>

                <NetnetField
                  id="standard-basic"
                  label="Mobile Number"
                  variant="standard"
                  value={formData.mobile_num}
                  onChange={(e) =>
                    setFormData({ ...formData, mobile_num: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                  helperText="Please Enter a valid Mobile Number"
                />
              </Box>
            </div>
            <div className="col col-NP-NC">
              <h3 className="sub-title">Business Information</h3>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Company</span>
                <Autocomplete
                  id="company"
                  options={companyOptions}
                  includeInputInList
                  // eslint-disable-next-line
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        overflowY: 'none',
                        scrollbarColor: 'transparent transparent',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&::-ms-scrollbar': {
                          width: 0,
                          background: 'transparent transparent',
                        },
                      }}
                    />
                  )}
                  value={formData.company ? formData.company : ''}
                  onChange={(e, value) => {
                    setFormData({
                      ...formData,
                      company: value.inputValue ? value.inputValue : value.name,
                    });
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f2f2f5 !important',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: '#f2f2f5',
                      },
                    },
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Company Name"
                      variant="outlined"
                      value={formData.company}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Title</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter Title"
                  disabled={!formData.company}
                  variant="standard"
                  className="MUI-P6-M5"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Type</span>
                <Autocomplete
                  id="country"
                  includeInputInList
                  // eslint-disable-next-line
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        overflowY: 'none',
                        scrollbarColor: 'transparent transparent',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&::-ms-scrollbar': {
                          width: 0,
                          background: 'transparent transparent',
                        },
                      }}
                    />
                  )}
                  onChange={(e, value) => {
                    setFormData({ ...formData, contact_type: value });
                  }}
                  value={formData.contact_type ? formData.contact_type : ''}
                  options={types}
                  getOptionLabel={(option) => option}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f2f2f5 !important',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: '#f2f2f5',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Type"
                      variant="outlined"
                      value={formData.contact_type}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },

                          '&.Mui-disabled fieldset': {
                            borderColor: 'grey',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>
            </div>
          </div>
          <div className="row">
            <div className="col col-NP-NC">
              <h3 className="sub-title">Residence Information</h3>

              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Address Line 1</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter Address"
                  variant="standard"
                  value={formData.street}
                  onChange={(e) =>
                    setFormData({ ...formData, street: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Address Line 2</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter Address"
                  variant="standard"
                  value={formData.street2}
                  onChange={(e) =>
                    setFormData({ ...formData, street2: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>

              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">City</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter City"
                  variant="standard"
                  value={formData.city}
                  color="secondary"
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">State / Province</span>
                <NetnetField
                  id="standard-basic"
                  label="Enter State"
                  variant="standard"
                  value={formData.state}
                  color="secondary"
                  onChange={(e) =>
                    setFormData({ ...formData, state: e.target.value })
                  }
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">
                  {formData.country === 'United States'
                    ? 'Zip Code '
                    : 'Zip / Postal Code '}
                </span>

                <NetnetField
                  id="standard-basic"
                  label="Enter Zip Code"
                  color="secondary"
                  variant="standard"
                  style={{ width: 300 }}
                  value={formData.address_zip}
                  onChange={(e) =>
                    setFormData({ ...formData, address_zip: e.target.value })
                  }
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Country</span>

                <Autocomplete
                  id="country"
                  includeInputInList
                  // eslint-disable-next-line
                  PopperComponent={(props) => (
                    <Popper
                      {...props}
                      sx={{
                        overflowY: 'none',
                        scrollbarColor: 'transparent transparent',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },

                        '&::-ms-scrollbar': {
                          width: 0,
                          background: 'transparent transparent',
                        },
                      }}
                    />
                  )}
                  onChange={(e, value) => {
                    setFormData({ ...formData, country: value });
                  }}
                  value={formData.country ? formData.country : ''}
                  options={myCountries}
                  getOptionLabel={(option) => option}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f2f2f5 !important',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: '#f2f2f5',
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Enter Country"
                      variant="outlined"
                      value={formData.country}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },

                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },

                          '&.Mui-disabled fieldset': {
                            borderColor: 'grey',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>
            </div>
            <div className="col col-NP-NC">
              <h3 className="sub-title">Social Media</h3>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Linkedin</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter URL"
                  variant="standard"
                  value={formData.linkedin_url}
                  onChange={(e) =>
                    setFormData({ ...formData, linkedin_url: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">X</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter URL"
                  variant="standard"
                  value={formData.twitter_url}
                  onChange={(e) =>
                    setFormData({ ...formData, twitter_url: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Instagram</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter URL"
                  variant="standard"
                  value={formData.instagram_url}
                  onChange={(e) =>
                    setFormData({ ...formData, instagram_url: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: 'auto', maxWidth: '500px' }}
              >
                <span className="modalSpan">Facebook</span>

                <NetnetField
                  id="standard-basic"
                  label="Enter URL"
                  variant="standard"
                  value={formData.facebook_url}
                  onChange={(e) =>
                    setFormData({ ...formData, facebook_url: e.target.value })
                  }
                  color="secondary"
                  style={{ width: 300 }}
                />
              </Box>
            </div>
          </div>

          <div className="row">
            <div className="col col-NP-NC">
              <h3 className="sub-title">Tags</h3>
              <Box
                className="df-r-j-c-gp-5"
                style={{ width: '49%', maxWidth: '500px' }}
              >
                <span className="modalSpan">Tags</span>
                <Autocomplete
                  id="company"
                  options={uniqueTagsArray}
                  defaultValue={inputValue}
                  value={inputValue}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        tags: prevFormData.tags
                          ? `${prevFormData.tags}, ${e.target.value.trim()}`
                          : e.target.value.trim(),
                      }));
                      setInputValue('');
                      e.target.value = '';
                    }
                  }}
                  getOptionLabel={(option) => option}
                  style={{ width: '100%' }}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f2f2f5 !important',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: '#f2f2f5',
                      },
                    },
                  }}
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Tags"
                      variant="outlined"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      inputRef={inputRef}
                      color="secondary"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                          '&:hover fieldset': {
                            borderColor: '#711fff',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#5a0bdf',
                          },
                          '&.Mui-error fieldset': {
                            borderColor: 'red',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#03071e66',
                        },
                        '& .MuiFormLabel-root.Mui-focused': {
                          color: '#5a0bdf',
                        },
                        '& .MuiFormLabel-root.Mui-error': {
                          color: 'red',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        style: { width: '100%' },
                      }}
                    />
                  )}
                />
              </Box>
              <div
                className="input-groups"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginTop: '10px',
                  marginLeft: '10px',
                  height: '100px',
                  overflow: 'auto',
                }}
              >
                {formData.tags &&
                  formData.tags
                    .split(',')
                    .map((tag) => (
                      <Chip
                        key={tag}
                        label={tag.trim()}
                        color="secondary"
                        variant="outlined"
                        style={{ backgroundColor: '#f5f5f5' }}
                        onDelete={() => handleDelete(tag)}
                      />
                    ))}
              </div>
            </div>
          </div>
        </section>
        {isError && <p className="error">{errorMessage}</p>}
        <div className="alCenter">
          <button
            type="button"
            className="cancel-btn"
            onClick={() => {
              routeTo(-1);
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="create-btn"
            onClick={handleSubmition}
          >
            {' '}
            Save{' '}
          </button>
        </div>
      </div>
    </>
  );
}
export { PreLoading };
export default EditContent;

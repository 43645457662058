import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENDPOINT } from '../../../pages/Opportunity/Redux/oppor';
import { setUser } from '../Redux/auth';
import logo from '../Assets/MobileLogo.png';
import verifySvg from '../Assets/verifymail.svg';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

function MailVerified() {
  const user = useSelector((state) => state.auth.newUser);
  const dispatch = useDispatch();
  const routeTo = useRouteTo();
  const [formBody, setFormBody] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (user) {
      setFormBody({
        email: user.newUser.email ? user.newUser.email : '',
        password: user.newUser.password ? user.newUser.password : '',
      });
    }
  }, [user]);

  const reLogin = async (formBody) => {
    const response = await fetch(`${ENDPOINT}/auth/jwt/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formBody),
    });
    const data = await response.json();

    if (response.ok) {
      dispatch(setUser(data));
    }
  };

  useEffect(() => {
    if (formBody.email && formBody.password) {
      reLogin(formBody);
    }
  }, [formBody]);

  const handleRoll = (e) => {
    e.preventDefault();
    routeTo(appRoutes.welcome);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0.55rem',
        margin: 'auto 0',
        width: { lg: '60%', md: '70%', xs: '100%' },
        p: { md: '1.44rem', sm: '1.44rem', xs: '1.44rem' },
      }}
    >
      <h2 className="sub-heading sentSuc fsMedium">You&apos;re All Set!</h2>
      <p className="fstxt">
        Awesome Job{' '}
        <span
          style={{ color: '#03071E', fontWeight: 'bolder', fontSize: '16px' }}
        >
          {user.newUser ? user.newUser.first_name : 'NaN'}.
        </span>{' '}
        Your account was created and your email has been Verified. Now its time
        to onboard your business
      </p>

      <button className="btn inline" onClick={handleRoll} type="button">
        Let&apos;s Roll{' '}
      </button>
    </Box>
  );
}

function Verified() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      container
      component="main"
      justifyContent={screenSize < 900 ? 'center' : null}
      sx={{ height: screenSize < 900 ? '78vh' : '100vh' }}
    >
      {screenSize >= 900 && (
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: '#47d1fc',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ width: { xs: '70%', lg: '60%', xl: '50%', xxl: '50%' } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} width="100%" height="auto" alt="Mobile Logo" />
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                }}
              >
                Putting Profits Into Your Workflow Tools!
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - opportunities & sales
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - project, retainer & task management
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - time tracking
              </Typography>
              <Typography
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  fontWeight: '400',
                  fontFamily: 'open Sans',
                  color: 'white',
                  mt: 1,
                }}
              >
                - team communications
              </Typography>
            </Box>
            <Box>
              <img
                src={verifySvg}
                alt="Login SVG"
                width="100%"
                height="auto"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={5}
        lg={5}
        xl={5}
        sx={{
          gap: '0.45rem',
          padding: '0.45rem 0',
          justifyContent: 'center',
          position: 'relative',
        }}
        direction="column"
        alignItems="center"
      >
        <MailVerified />
      </Grid>
    </Grid>
  );
}
export default Verified;

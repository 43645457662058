import { BsFillCircleFill } from 'react-icons/bs';

import { colorPalette } from '../../utils/colors-helper';
import { style } from '@mui/system';

function KPIChartLegend() {
  return (
    <div className="legend">
      <span>
        <strong>Key Indicators</strong>
      </span>

      <div className="legend-actual-hours">
        <span>
          <BsFillCircleFill style={{ color: colorPalette.actualGreenColor }} />
          (0.1-80%)
        </span>

        <span>
          <BsFillCircleFill style={{ color: colorPalette.actualOrangeColor }} />
          (81-99%)
        </span>

        <span>
          <BsFillCircleFill style={{ color: colorPalette.actualRedColor }} />
          (100%+)
        </span>

        <span>Actual Hours</span>
      </div>

      <span>
        <strong>|</strong>
      </span>

      <span>
        <BsFillCircleFill style={{ color: colorPalette.estimatedColor }} />{' '}
        Estimated Hours
      </span>

      <span>
        <strong>|</strong>
      </span>

      <span>
        <BsFillCircleFill style={{ color: colorPalette.assignedColor }} />{' '}
        Assigned Hours
      </span>

      <span>
        <strong>|</strong>
      </span>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          fontSize: '12px',
        }}
      >
        <span>Actual 2 Estimated (A2E)</span>
        <span>Actual 2 Assigned (A2A)</span>
      </div>
    </div>
  );
}

export default KPIChartLegend;

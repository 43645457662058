import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  Box,
  Stepper,
  Step,
  Grid,
  Typography,
  Slider,
  Popover,
  Button,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import Deliverable from './Deliverable';
import JobDetailsKPISummaries from './KPIs/JobDetailsKPI';
import Timeline from './Timeline';
import Changes from './Changes';
import Notes from './Notes';
import Settings from './Settings';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { PreLoading } from '../../Contacts/People/EditContact';
import { ViewMode } from 'gantt-task-react';
import 'rsuite/dist/rsuite.min.css';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import helpVid from '../../Opportunity/assets/Section_Help-idle.svg';
import dayjs from 'dayjs';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import ProjectHelpVideos from './ProjectHelpVideos';
import ProjectChat from './ProjectChat';
import DeliverableChat from '../DeliverableChat';
import TaskChat from '../TaskChat';
import { New_channels } from '../../Stream/streamRedux';

import calculateTotalTimeObtained from '../../../utils/calculateTotalTimeObtained';

const step = [
  {
    label: 'Deliverables/Tasks',
  },

  {
    label: `Timeline`,
  },

  {
    label: `KPI's`,
  },
  {
    label: 'Changes',
  },
  {
    label: 'Notes',
  },
  {
    label: 'Settings',
  },
];

const VerticalLinearSteppe = ({
  comments,
  setComments,
  setSteps,
  steps,
  isDetOpen,
  formData,
  setIsDetOpen,
  setIsTyping,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const handleShowProjectChat = () => {
    setShowChatEst(true);
    setIsTyping(true);
  };

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [show, setShow] = useState(true);
  const [showChatEst, setShowChatEst] = useState(false);

  return isDetOpen ? (
    <Box sx={{ zIndex: showChatEst ? 1 : 999 }}>
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '1rem 0rem',
          borderRadius: '10px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)',
          display: show ? 'flex' : 'none',
          jusitfyContent: 'center',
          position: 'fixed',
          top: '17%',
          opacity: show ? 1 : 0,
          zIndex: show ? 10 : 1,
          transition: 'opacity 0.3s ease-in-out',
          height: show ? 'auto' : '0',
        }}
        className="firstMenu"
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{ margin: '0 auto', width: '90%' }}
        >
          {step.map((step, index) => {
            if (
              formData.project_type === 'retainer' &&
              step.label === 'Timeline'
            ) {
              return;
            }
            return (
              <Step key={step.label}>
                <button
                  onClick={() => {
                    setSteps(index + 1);
                  }}
                  style={{
                    gap: screenSize < 1700 && screenSize > 1400 ? 5 : 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    borderRadius: '10px',
                    border: 'none',
                    overflow: 'hidden',
                    backgroundColor:
                      steps === index + 1
                        ? '#cbb6fc'
                        : steps > index + 1
                          ? '#7323fa'
                          : '#f6f5f7',
                    height: '40px',
                    cursor: 'pointer',
                    paddingLeft: '8px',
                  }}
                >
                  <CheckIcon
                    style={{
                      color:
                        steps === index + 1
                          ? '#af8dfc'
                          : steps > index + 1
                            ? 'white'
                            : '#efe8ff',
                    }}
                  />
                  <Typography
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '20px',
                      padding: '1px 3px',
                      fontSize: '12px',
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        steps === index + 1
                          ? '#171717'
                          : steps > index + 1
                            ? 'white'
                            : '#575656',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                  >
                    {step.label}
                  </Typography>
                </button>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Box
        sx={{
          marginLeft: '5px',
          position: 'fixed',
          top: '17%',
          zIndex: 1,
        }}
        className="secondMenu"
      >
        <p
          onClick={() => setShow(!show)}
          style={{
            cursor: 'pointer',
            writingMode: 'vertical-rl',
            textOrientation: 'revert-layer',
            padding: '15px 2px',
            backgroundColor: show ? '#ac7cfc' : '#c6a7fa',
            backgroundColor: show ? '#ac7cfc' : '#c6a7fa',
            borderStartEndRadius: screenSize > 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize < 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            color: show ? 'white' : 'black',
            letterSpacing: '0.1rem',
          }}
        >
          JOB INFO
        </p>
        <Box
          sx={{
            padding: '15px 2px',
            fontSize: '10px',
            color: 'white',
            backgroundColor: '#ac7cfc',
            borderStartStartRadius: screenSize < 1200 && '12px',
            borderTopRightRadius: screenSize > 1200 && '12px',
            borderEndEndRadius: screenSize > 1200 && '12px',
            borderEndStartRadius: screenSize < 1200 && '12px',
            marginTop: '5px',
            width: '27px',
            cursor: 'pointer',
          }}
          onClick={handleShowProjectChat}
        >
          <TextsmsOutlinedIcon color="white" />
        </Box>
      </Box>
      {showChatEst ? (
        <ProjectChat
          comments={comments}
          setComments={setComments}
          formData={formData}
          setShowChatEst={setShowChatEst}
          setIsTyping={setIsTyping}
        />
      ) : null}
    </Box>
  ) : null;
};

const TimeLineCheck = ({
  currentMonthIndex,
  setCurrentMonthIndex,
  formData,
  setFormData,
}) => {
  const start =
    formData && formData.target_start
      ? formData.target_start.split('T')[0]
      : false;
  const end =
    formData && formData.finish_date
      ? formData.finish_date.split('T')[0]
      : false;

  const today = moment();

  const currentWeek = start ? today.diff(moment(start), 'weeks') + 1 : 0;
  const totalWeeks = end ? moment(end).diff(moment(start), 'weeks') + 1 : 0;

  const totalEstimatedHours =
    Array.isArray(formData?.deliverable_project) &&
    formData?.deliverable_project.length
      ? formData?.deliverable_project
          .map((deliverable) =>
            deliverable?.deliverable_worktype.reduce(
              (ka, wt) => ka + Number(wt.hours),
              0,
            ),
          )
          .reduce((ka, wt) => ka + wt, 0)
      : 1;

  const workedHours =
    Array.isArray(formData?.deliverable_project) &&
    formData?.deliverable_project.length
      ? formData?.deliverable_project
          .map((deliverable) =>
            deliverable.task_deliverable.reduce((acc, item) => {
              return (
                acc + Number(calculateTotalTimeObtained(item.time_tracking))
              );
            }, 0),
          )
          .reduce((acc, item) => acc + item, 0)
      : 0;

  const percentageOfJobCompletion = (
    (workedHours / totalEstimatedHours) *
    100
  ).toFixed(2);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleNextMonth = async () => {
    setCurrentMonthIndex((prevIndex) => (prevIndex + 1) % months.length);
  };

  const handlePreviousMonth = async () => {
    setCurrentMonthIndex(
      (prevIndex) => (prevIndex - 1 + months.length) % months.length,
    );
  };

  const startDate = new Date(formData.target_start);
  const finishDate = new Date(formData.finish_date);

  // Get the start and finish years
  const startYear = startDate.getFullYear();
  const finishYear = finishDate.getFullYear();

  // Initialize an array to hold the months
  const monthsToShow = [];

  // Iterate through the months between the start and finish dates
  for (let year = startYear; year <= finishYear; year++) {
    const startMonth = year === startYear ? startDate.getMonth() : 0;
    const finishMonth = year === finishYear ? finishDate.getMonth() : 11;

    for (let month = startMonth; month <= finishMonth; month++) {
      monthsToShow.push({ monthIndex: month, year });
    }
  }

  return (
    <Box
      sx={{
        p: '1rem 1.5rem',
        width: formData.project_type === 'project' ? '440px' : '280px',
        backgroundColor: '#fff',
        borderRadius: '8px',
      }}
    >
      {formData.project_type === 'project' ? (
        <Box
          sx={{
            display: 'flex',
            align: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              padding: '0.5rem',
            }}
          >
            <Typography
              sx={{
                color: '#7e808a',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                fontSize: '0.9rem',
              }}
            >
              JOB COMPLETION:
            </Typography>
            <Typography
              sx={{
                color: '#711fff',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                fontSize: '1rem',
              }}
            >
              {percentageOfJobCompletion || 0}%
            </Typography>
          </Box>

          <Box
            sx={{
              width: '1px',
              height: '48px',
              backgroundColor: '#c9c9c9',
              borderRadius: '8px',
            }}
          />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              padding: '0.5rem',
            }}
          >
            <Typography
              sx={{
                color: '#7e808a',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                fontSize: '0.9rem',
              }}
            >
              TIMELINE:
            </Typography>
            <Typography
              sx={{
                color: '#711fff',
                fontWeight: '600',
                fontFamily: 'Open Sans',
                fontSize: '1rem',
              }}
            >
              {currentWeek ? currentWeek : 0}/{totalWeeks ? totalWeeks : 0}{' '}
              Weeks
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <ArrowBackIosIcon
            sx={{
              cursor: 'pointer',
              ':hover': {
                color: '#711fff',
              },
            }}
            onClick={handlePreviousMonth}
          />
          <Box
            sx={{
              padding: '6px 21px',
              color: '#fff',
              background: '#711fff',
              fontSize: '16px',
              borderRadius: '5px',
            }}
          >
            {months[currentMonthIndex]}
          </Box>
          <ArrowForwardIosIcon
            sx={{
              cursor: 'pointer',
              ':hover': {
                color: '#711fff',
              },
            }}
            onClick={handleNextMonth}
          />
        </Box>
      )}
    </Box>
  );
};

const ProjectDet = ({ settings, screenHeight }) => {
  const [value1, setValue1] = useState([null, null]);
  const { id: unique_id } = useParams();

  const [steps, setSteps] = useState(1);
  const [changeMode, setChangeMode] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [dileverable, setDeliverable] = useState([]);
  const [detOpen, setDetOpen] = useState(false);
  const userTeam = useSelector((state) => state?.userTeam?.team?.member || []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(New_channels({ access_token }));
  }, [dispatch]);

  useEffect(() => {
    if (changeMode && steps !== 4) {
      setChangeMode(false);
    }
  }, [changeMode, steps]);

  useEffect(() => {
    if (settings) {
      setSteps(6);
    }
  }, [settings]);

  const [formData, setFormData] = useState();
  const [project_notes, setProjectNotes] = useState([]);
  const [project_change, setProjectChange] = useState([]);
  const access_token = useSelector((item) => item.auth.user.access);

  const [day, setDay] = useState('This Month');

  const [dateRange, setDateRange] = useState(false);

  const handleDateRange = (v) => {
    setDateRange(v);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
    setValue1([null, null]);
    setDateRange(false);
  };

  const handleClickDay = (v, i) => {
    setDay(v);
    if (v === 'Today') {
      const today = dayjs();
      setDateRange([today]);
    } else if (v === 'Yesterday') {
      const today = dayjs();
      setDateRange([today.subtract(1, 'day')]);
    } else if (v === 'This Week') {
      const today = dayjs();
      setDateRange([today.startOf('week')]);
    } else if (v === 'Past 2 Week') {
      const today = dayjs();
      const prevWeek = today.subtract(14, 'day');
      setDateRange([prevWeek.startOf('week')]);
    } else if (v === 'This Month') {
      const today = dayjs();
      setDateRange([today.startOf('month')]);
    } else if (v === 'Last Month') {
      const today = dayjs();
      const startOfNextMonth = today.startOf('month').subtract(1, 'month');
      setDateRange([startOfNextMonth]);
    } else if (v === 'This Year') {
      const today = dayjs();
      setDateRange([today.startOf('year')]);
    } else if (v === 'Last Year') {
      const today = dayjs();
      setDateRange([today.startOf('year').subtract(1, 'year')]);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [dltingTask, SetDltingTask] = useState();
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${ENDPOINT}/api/project/detail/${unique_id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },
      });
      const data = await res.json();
      if (!res.ok) {
      }

      setFormData(data);

      setProjectNotes(
        Array.isArray(userTeam) &&
          userTeam.length &&
          Array.isArray(data.project_note) &&
          data.project_note.length
          ? data.project_note.map((item) => {
              return {
                ...item,
                sender: userTeam.find(
                  (mate) => Number(mate.id) === Number(item.created_by),
                ),
                message: item.note,
              };
            })
          : [],
      );

      setProjectChange(
        Array.isArray(data.project_change) && data.project_change.length
          ? data.project_change
          : [],
      );
      setIsDataReady(true);
      if (
        data &&
        Array.isArray(data.deliverable_project) &&
        data.deliverable_project.length
      ) {
        setDeliverable(data.deliverable_project);
        setIsDataReady(true);
        SetDltingTask(null);
      }
    };

    // Fetch data initially
    fetchData();
  }, []);

  const getData = async () => {
    const res = await fetch(`${ENDPOINT}/api/project/detail/${unique_id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${access_token}`,
      },
    });
    const data = await res.json();
    if (!res.ok) {
    }
    setFormData(data);

    setProjectNotes(
      Array.isArray(userTeam) &&
        userTeam.length &&
        Array.isArray(data.project_note) &&
        data.project_note.length
        ? data.project_note.map((item) => {
            return {
              ...item,
              sender: userTeam.find(
                (mate) => Number(mate.id) === Number(item.created_by),
              ),
              message: item.note,
            };
          })
        : [],
    );

    setProjectChange(
      Array.isArray(data.project_change) && data.project_change.length
        ? data.project_change
        : [],
    );
    setIsDataReady(true);
    if (
      data &&
      Array.isArray(data.deliverable_project) &&
      data.deliverable_project.length
    ) {
      setDeliverable(data.deliverable_project);
      setIsDataReady(true);
      SetDltingTask(null);
    }
  };

  const [newChange, setNewChange] = useState(false);
  const [endOrStart, setEndOrStart] = useState();
  const [view, setView] = useState('Month');
  const [temp, setTemp] = useState();

  async function handleRangeChange() {
    const shallowCopy = {
      ...formData,
    };

    delete shallowCopy.work_type;
    delete shallowCopy.estimate_hardcost;

    const res = await fetch(`${ENDPOINT}/api/project/update/${formData.id}/`, {
      method: 'PUT',
      headers: {
        Authorization: `JWT ${access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...shallowCopy,
        target_start:
          endOrStart === 'start'
            ? new Date(new Date(dateRange)).toISOString()
            : formData?.target_start,
        finish_date:
          endOrStart === 'end'
            ? new Date(new Date(dateRange)).toISOString()
            : formData?.finish_date,
        project_deliverable: dileverable?.map((item, index) => {
          const durationInMilliseconds =
            new Date(item.deliverable_enddate) -
            new Date(item.deliverable_startdate);
          const deliverable_duration =
            durationInMilliseconds / (24 * 60 * 60 * 1000);

          const dynamicChange =
            new Date(item.deliverable_startdate) -
            new Date(formData.target_start);
          const dynamic_duration = dynamicChange / (24 * 60 * 60 * 1000);
          delete item.deliverable_multiplier;
          delete item.task_deliverable;
          return {
            ...item,
            deliver_id: item.id,

            deliverable_startdate:
              endOrStart === 'start'
                ? new Date(
                    new Date(dateRange).setDate(
                      new Date(dateRange).getDate() + dynamic_duration,
                    ),
                  ).toISOString()
                : item.deliverable_startdate,
            deliverable_enddate:
              endOrStart === 'start'
                ? new Date(
                    new Date(dateRange).setDate(
                      new Date(dateRange).getDate() +
                        dynamic_duration +
                        deliverable_duration,
                    ),
                  ).toISOString()
                : item.deliverable_enddate,
            deliverable_worktype: item.deliverable_worktype.map((wtt) => ({
              ...wtt,
              hours: wtt.hours ? Number(wtt.hours) : null,
            })),
          };
        }),
      }),
    });
    const data = await res.json();
    if (!res.ok) {
      return;
    } else {
      const resGet = await fetch(
        `${ENDPOINT}/api/project/detail/${formData?.unique_id}/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );
      const dataGet = await resGet.json();
      if (!resGet.ok) {
        return;
      }
      setFormData(dataGet);
      setValue1(dateRange);
      setDateRange(false);
      if (
        dataGet &&
        Array.isArray(dataGet.deliverable_project) &&
        dataGet.deliverable_project.length
      ) {
        setDeliverable(dataGet.deliverable_project);
      }
    }
  }

  const start =
    formData && formData.target_start && formData.target_start?.endsWith('Z')
      ? formData.target_start.split('T')
      : null;
  const end =
    formData && formData.finish_date && formData.finish_date.endsWith('Z')
      ? formData.finish_date.split('T')
      : null;

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isDetOpen, setIsDetOpen] = useState(true);
  const courses = useSelector((state) => state.NetNetU.courses).filter(
    (item) => item.course_type === 'help_videos',
  );
  const welcomeChapters = courses.flatMap((course) => {
    return course.chapters.filter(
      (chapter) => chapter.title === 'Project_Detail',
    );
  });

  const ChannelList = useSelector((state) => state.Stream.data);
  const [delivChatShow, setDelivChatShow] = useState(false);
  const [deliverableChat, setDeliverableChat] = useState(null);
  const [comments, setComments] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow2] = useState(false);
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth(),
  );

  const [showofTask, setShowOfTask] = useState(false);
  const [currentTargetTask, setCurrentTargetTask] = useState({
    task_name: '',
    str_worktype: '',
    description: '',
    task_worktype: [],
  });

  return isDataReady ? (
    <section className="oppor-section">
      <VerticalLinearSteppe
        setSteps={setSteps}
        steps={steps}
        detOpen={detOpen}
        isDetOpen={isDetOpen}
        comments={comments}
        setComments={setComments}
        formData={formData}
        setIsDetOpen={setIsDetOpen}
        setIsTyping={setIsTyping}
      />
      <div
        className="oppor-row"
        style={{ width: '100%', position: 'relative' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div className="oppor-col">
            <Box>
              <input
                onChange={(e) => {
                  setIsTyping(true);
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  });
                }}
                onBlur={async () => {
                  const res = await fetch(
                    `${ENDPOINT}/api/project/update/${formData?.id}/`,
                    {
                      method: 'PUT',
                      headers: {
                        Authorization: `JWT ${access_token}`,
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        title: formData?.title,
                      }),
                    },
                  );
                  const data = await res.json();
                  if (!res.ok) {
                    return;
                  }
                  setIsTyping(false);
                }}
                onMouseEnter={(e) => {
                  e.target.style.borderBottom = '1px dashed rgba(0,0,0,1)';
                }}
                onMouseLeave={(e) => {
                  e.target.style.borderBottom = '1px dashed rgba(0,0,0,0)'; // or set it to the initial border style
                }}
                value={formData.title ? formData.title : ''}
                style={{
                  fontSize: '30px',
                  outline: 'none',
                  border: 'none',
                  background: 'none',
                  fontWeight: '700',
                  color: '#000',
                  borderBottom: '1px dashed rgba(0,0,0,0)',
                  width: 'auto',
                }}
              />
            </Box>
            <Box>
              <img
                onClick={() => {
                  setShow(true);
                }}
                src={helpVid}
                style={{
                  color: '#A6A6A6',
                  marginLeft: '10px',
                  width: '28px',
                  height: '28px',
                  cursor: 'pointer',
                }}
              />
            </Box>
          </div>

          {changeMode && (
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '0.9rem',
                fontWeight: '600',
                color: '#FA2D23',
              }}
            >
              PLEASE NOTE! YOU ARE IN CHANGE MODE
            </Typography>
          )}
        </div>
        {changeMode &&
          Array.isArray(project_change) &&
          !project_change.length && (
            <button className="create-btn" onClick={() => setNewChange(true)}>
              Request New Change
            </button>
          )}
        {steps === 1 && (
          <>
            {' '}
            <TimeLineCheck
              currentMonthIndex={currentMonthIndex}
              setCurrentMonthIndex={setCurrentMonthIndex}
              formData={formData}
              setFormData={setFormData}
            />
          </>
        )}

        {steps === 2 && (
          <Box
            sx={{
              border: '1px solid #D9D9D9',
              padding: '0.7rem .5rem',
              borderRadius: '8px',
              width: { xl: '35%', lg: '40%', md: '45%', sm: '50%' },
              backgroundColor: '#e7deff',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100px',
              marginRight: '1.3rem',
              boxShadow: '1px 1px 1.5px rgba(0, 0, 0, 0.3)',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: 'gray',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '16px',
                  alignItems: 'center',
                  width: '80%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'space-around',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <CalendarMonthOutlinedIcon
                      sx={{
                        cursor: 'pointer',
                        fontSize: {
                          xl: '25px',
                          lg: '22px',
                          md: '20px',
                          sm: '20px',
                        },
                      }}
                      variant="contained"
                      aria-describedby={0}
                      onClick={(event) => {
                        handleClick(event);
                        setValue1(null);
                        const startDate = dayjs(formData.target_start);
                        setDateRange(
                          formData.target_start
                            ? dayjs(formData.target_start)
                            : new Date(),
                        );
                        setEndOrStart('start');
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: {
                          xl: '14px',
                          lg: '13px',
                          md: '12px',
                          sm: '11px',
                        },
                      }}
                    >
                      Start:{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: '14px',
                          lg: '13px',
                          md: '12px',
                          sm: '11px',
                        },
                      }}
                      style={{ marginTop: '0px', fontWeight: 'normal' }}
                    >
                      {start && start[0]
                        ? new Date(start[0]).toLocaleDateString(
                            'en-US',
                            options,
                          )
                        : ''}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <CalendarMonthOutlinedIcon
                      sx={{
                        cursor: 'pointer',
                        fontSize: {
                          xl: '25px',
                          lg: '22px',
                          md: '20px',
                          sm: '20px',
                        },
                      }}
                      onClick={(event) => {
                        handleClick(event);
                        setValue1(null);
                        setDateRange(
                          formData.finish_date
                            ? dayjs(formData.finish_date)
                            : new Date(),
                        );
                        setEndOrStart('end');
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: {
                          xl: '14px',
                          lg: '13px',
                          md: '12px',
                          sm: '11px',
                        },
                      }}
                    >
                      End:{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xl: '14px',
                          lg: '13px',
                          md: '12px',
                          sm: '11px',
                        },
                      }}
                      style={{ marginTop: '0px', fontWeight: 'normal' }}
                    >
                      {end && end[0]
                        ? new Date(end[0]).toLocaleDateString('en-US', options)
                        : ''}
                    </Typography>
                  </div>
                </div>
                {!value1 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '90%',
                      right: 0,
                      zIndex: '99999',
                      background: '#fff',
                    }}
                  >
                    <Box
                      align="center"
                      sx={{
                        width: 'auto',
                        height: 'auto',
                        px: '30px',
                        display: { xs: 'block', md: 'flex' },
                      }}
                    >
                      <Box
                        align="left"
                        sx={{
                          backgroundColor: '#F8F9FA',
                          width: { xs: '100%', md: '200px' },
                          p: 1,
                          pb: 3,
                          my: 4,
                          borderRadius: 2,
                        }}
                      >
                        {[
                          'Today',
                          'Yesterday',
                          'This Week',
                          'Past 2 Week',
                          'This Month',
                          'Last Month',
                          'This Year',
                          'Last Year',
                        ].map((v, i) => {
                          return (
                            <Typography
                              key={i}
                              onClick={() => handleClickDay(v, i)}
                              sx={
                                day === v
                                  ? {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#711FFF',
                                      my: 3,
                                      ml: 2,
                                    }
                                  : {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      cursor: 'pointer',
                                      color: '#03071E',
                                      my: 3,
                                      ml: 2,
                                    }
                              }
                            >
                              {v}
                            </Typography>
                          );
                        })}
                      </Box>
                      <Box sx={{ mx: 4, my: 4 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            value={dayjs(dateRange)}
                            onChange={(newValue) =>
                              handleDateRange(newValue?.$d)
                            }
                            sx={{
                              '& .css-10wpov9-MuiTypography-root ': {
                                fontWeight: '700 !important',
                              },
                              '& .css-cyzddl-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval':
                                {
                                  color: '#03071E !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1gbl7yn-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085 !important',
                                color: 'white !important',
                                borderRadius: '0px !important',
                              },
                              '& .css-1e841vg-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ckjanr-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-2ko3hu-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: 'white !important',
                                  opacity: 'inherit !important',
                                  borderRadius: '0px',
                                  border: 'none !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-1ku4sqv-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  backgroundColor: '#711FFF !important',
                                  color: 'white !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-ahwqre-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: '#310085 !important',
                                  color: '#e569db !important',
                                  borderRadius: '0px !important',
                                  fontSize: '16px !important',
                                  fontWeight: 500,
                                },
                              '& .css-jef1b6-MuiDateRangeCalendar-container:not(:last-of-type)':
                                {
                                  borderRight: 'none !important',
                                },
                              '& .css-3wduhr-Mu.iDateRangeCalendar-root': {
                                flexDirection: 'column !important',
                              },

                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day':
                                {
                                  color: 'inherit',
                                  fontSize: '16px !important',
                                  opacity: 'inherit',
                                  fontWeight: 500,
                                },
                              '& .css-1kex3oi-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(.Mui-selected)':
                                {
                                  backgroundColor: 'inherit',
                                  color: 'black !important',
                                  borderRadius: '50%',
                                  border: '1px solid gray',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .MuiTypography-subtitle1': {
                                color: ' #03071E !important',
                                fontSize: '16px',
                                fontWeight: 700,
                              },
                              '& .MuiDayCalendar-weekDayLabel': {
                                color: ' #03071ECC !important',
                                fontSize: '16px',
                                fontWeight: 400,
                              },
                              '& .MuiSvgIcon-fontSizeInherit': {
                                backgroundColor: 'black',
                                color: 'white',
                                borderRadius: '50%',
                              },
                              '& .MuiPickersDay-today': {
                                backgroundColor: !dateRange && '#711FFF',
                                color: !dateRange && 'white',
                                borderRadius: !dateRange && '0px',
                                fontSize: !dateRange && '16px',
                                fontWeight: !dateRange && 500,
                              },
                              '& .css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
                                {
                                  backgroundColor: '#711FFF',
                                  color: 'white',
                                  borderRadius: '0px',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                },
                              '& .css-gtjfra-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                              '& .css-1i2r8k1-MuiDateRangePickerDay-root': {
                                backgroundColor: '#310085',
                                color: 'white !important',
                                borderRadius: '0px',
                                fontSize: '16px',
                                fontWeight: 500,
                              },
                            }}
                          />
                        </LocalizationProvider>

                        <Box sx={{ display: 'flex', my: 4 }}>
                          <Box sx={{ display: 'flex', mr: 4 }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#711FFF',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            ></Box>
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Start Date and End Date
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <Box
                              sx={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#310085',
                                borderRadius: 2,
                                mr: 2,
                              }}
                            ></Box>
                            <Typography
                              sx={{ fontWeight: 400, fontSize: '14px' }}
                            >
                              Date Range
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        align=""
                        my={3}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: '',
                        }}
                      >
                        <Box align="left" mt={4}>
                          <Box align="left">
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              Start Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {' '}
                              {endOrStart === 'start'
                                ? new Date(dateRange).toLocaleDateString(
                                    'en-US',
                                    options,
                                  )
                                : start && start[0]
                                  ? new Date(start[0]).toLocaleDateString(
                                      'en-US',
                                      options,
                                    )
                                  : ''}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                color: '#03071E99',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              End Date
                            </Typography>
                            <Typography
                              sx={{
                                color: '#03071E',
                                fontSize: '16px',
                                fontWeight: 400,
                              }}
                            >
                              {' '}
                              {endOrStart === 'end'
                                ? new Date(dateRange).toLocaleDateString(
                                    'en-US',
                                    options,
                                  )
                                : end && end[0]
                                  ? new Date(end[0]).toLocaleDateString(
                                      'en-US',
                                      options,
                                    )
                                  : ''}
                            </Typography>
                          </Box>
                        </Box>
                        <Box align="right">
                          <Button
                            variant="text"
                            sx={{
                              textDecoration: 'inherit',
                              color: 'black',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              textDecoration: 'inherit',
                              backgroundColor: '#711FFF',
                              width: { xs: 'auto', lg: '100px' },
                              mr: 1,
                              '&:hover': {
                                backgroundColor: '#711FFF',
                              },
                            }}
                            onClick={() => {
                              handleRangeChange();
                            }}
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </div>
            </Box>{' '}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                color: 'gray',
              }}
            >
              <p
                style={{
                  fontSize: '25px',
                  marginTop: '0px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (view === 'Week') {
                    setView('Year');
                  } else if (view === 'Year') {
                    setView('Month');
                  } else {
                    setView('Week');
                  }
                }}
              >
                -
              </p>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                value={
                  view === 'Week'
                    ? 0
                    : view === 'Month'
                      ? 1
                      : view === 'Year'
                        ? 2
                        : 4
                }
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  const label = ['Week', 'Month', 'Year'];
                  return label[value];
                }}
                step={1}
                min={0}
                max={2}
                sx={{
                  color: 'gray',
                  width: { xl: '40%', lg: '40%', md: '35%', sm: '33%' },
                }}
                onChange={(e) => {
                  if (e.target.value === 0) {
                    setView('Week');
                  }
                  if (e.target.value === 1) {
                    setView('Month');
                  }
                  if (e.target.value === 2) {
                    setView('Year');
                  }
                }}
              />{' '}
              <p
                style={{
                  fontSize: '25px',
                  marginTop: '0px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (view === ViewMode.Week) {
                    setView(ViewMode.Month);
                  } else if (view === ViewMode.Year) {
                    setView(ViewMode.Day);
                  } else if (view === ViewMode.Month) {
                    setView(ViewMode.Year);
                  } else {
                    setView(ViewMode.Week);
                  }
                }}
              >
                +
              </p>
            </Box>
          </Box>
        )}
      </div>
      <Grid container sx={{ maxHeight: screenHeight - 215, overflow: 'auto' }}>
        {delivChatShow ? (
          <DeliverableChat
            formData={formData}
            setComments={setComments}
            comments={comments}
            setDelivChatShow={setDelivChatShow}
            delivChatShow={delivChatShow}
            deliverableChat={deliverableChat}
            setIsTyping={setIsTyping}
            ChannelList={ChannelList}
          />
        ) : (
          ''
        )}
        {showofTask ? (
          <TaskChat
            formData={formData}
            comments={comments}
            setComments={setComments}
            deliverableChat={currentTargetTask}
            setDelivChatShow={setShowOfTask}
            delivChatShow={showofTask}
            setIsTyping={setIsTyping}
          />
        ) : (
          ''
        )}
        {steps === 1 && (
          <Deliverable
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            unique_id={unique_id}
            formData={formData}
            setDetOpen={setDetOpen}
            setIsDetOpen={setIsDetOpen}
            setIsTyping={setIsTyping}
            isTyping={isTyping}
            setFormData={setFormData}
            SetDltingTask={SetDltingTask}
            dltingTask={dltingTask}
            getData={getData}
            setShow={setShow2}
            show={show1}
            screenHeight={screenHeight}
            currentMonthIndex={currentMonthIndex}
            setDelivChatShow={setDelivChatShow}
            delivChatShow={delivChatShow}
            deliverableChat={deliverableChat}
            setDeliverableChat={setDeliverableChat}
            ChannelList={ChannelList}
            setShowOfTask={setShowOfTask}
            showofTask={showofTask}
            comments={comments}
            setComments={setComments}
            setCurrentTargetTask={setCurrentTargetTask}
            currentTargetTask={currentTargetTask}
          />
        )}
        {steps === 2 && (
          <Timeline
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            formData={formData}
            setFormData={setFormData}
            view={view}
            setView={setView}
            temp={temp}
            setTemp={setTemp}
          />
        )}
        {steps === 3 && (
          <JobDetailsKPISummaries
            dileverable={dileverable}
            setDeliverable={setDeliverable}
          />
        )}
        {steps === 4 && (
          <Changes
            setChangeMode={setChangeMode}
            newChange={newChange}
            setNewChange={setNewChange}
            formData={formData}
            setFormData={setFormData}
            unique_id={unique_id}
            project_change={project_change}
            setProjectChange={setProjectChange}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setIsDetOpen={setIsDetOpen}
            setIsTyping={setIsTyping}
          />
        )}
        {steps === 5 && (
          <Notes
            notes={project_notes}
            setNotes={setProjectNotes}
            id={formData.id}
            unique_id={unique_id}
            setIsTyping={setIsTyping}
            setDeliverable={setDeliverable}
            setFormData={setFormData}
            screenHeight={screenHeight}
          />
        )}
        {steps === 6 && (
          <Settings
            formData={formData}
            setFormData={setFormData}
            dileverable={dileverable}
            setDeliverable={setDeliverable}
            setIsTyping={setIsTyping}
          />
        )}
      </Grid>
      <ProjectHelpVideos show={show} setShow={setShow} steps={steps} />
    </section>
  ) : (
    <PreLoading />
  );
};

export default ProjectDet;

const colorPalette = Object.freeze({
  actualGreenColor: '#5ee690',
  actualRedColor: 'rgb(255, 0, 0, 0.5)',
  actualOrangeColor: 'rgb(255, 165, 0, 0.5)',

  assignedColor: 'rgba(94, 230, 144, 0.4)',
  estimatedColor: 'rgba(11, 136, 249, 0.25)',

  backgroundOpaqueRed: 'rgba(255, 0, 0, 0.2)',
  backgroundOpaqueOrange: 'rgba(255, 165, 0, 0.2)',
  backgroundOpaqueGreen: 'rgba(60, 179, 113, 0.2)',
});

const getProgressColor = (progress, total) => {
  const progressInPercent = (progress * 100) / total;

  if (progressInPercent <= 80) {
    return '#5ee690';
  }

  if (progressInPercent < 100) {
    return colorPalette.actualOrangeColor;
  }

  return colorPalette.actualRedColor;
};

const getReportsColor = (progress, total) => {
  if (progress >= total) {
    return '#DC241C';
  }

  return '#F59825';
};

const getBarColor = (overTime, isTarget) => {
  if (isTarget) {
    return '#00cba2';
  }

  if (overTime > 0) {
    return '#a16bff';
  }

  return '#0b88f9';
};

export { getProgressColor, getBarColor, getReportsColor, colorPalette };

import { useEffect, useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import TagChips from '../../NetNetU/Courses/AddNewChapter/TagChips';
import { Link } from 'react-router-dom';
import Header from '../../../../Screens/InnerApp/Header';
import { ENDPOINT } from '../../../Opportunity/Redux/oppor';
import { useSelector } from 'react-redux';
import { IoMdSettings } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import { useRouteTo } from '../../../../hooks/useRouteTo';
import { appRoutes } from '../../../../appRoutes';

const SubHelpVideoScreen = () => {
  const { id } = useParams();
  const access_token = useSelector((state) => state.auth.user.access);
  const [HelpVideoScreen, setHelpVideoScreen] = useState();
  const routeTo = useRouteTo();
  const [formData, setFormData] = useState({
    title: '',
    help_video_screen: id,
  });

  const getVideosScreen = async () => {
    try {
      const response = await fetch(
        `${ENDPOINT}/api/netnetu/help-video-screen-list/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${access_token}`,
          },
        },
      );

      const data = await response.json();
      if (!data) {
      } else {
        const filtered = data?.find((item) => item?.id === parseInt(id));
        setHelpVideoScreen(filtered);
      }
      return data;
    } catch (error) {
      return null;
    }
  };

  const handleAddScreen = async (e) => {
    e.preventDefault();

    if (!formData?.title.length > 0) {
      return;
    }

    const response = await fetch(
      `${ENDPOINT}/api/netnetu/create-help-video-sub-screen/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },

        body: JSON.stringify(formData),
      },
    );

    const responsed = await response.json();

    if (!response) {
      console.log('Error Foundd');
    } else {
      setFormData({ title: '' });
    }

    const allIds = HelpVideoScreen?.help_video_subscreen?.map((item) =>
      parseInt(item.id),
    );
    allIds.push(parseInt(responsed?.id));

    const requestBody = {
      help_video_subscreen: allIds,
    };

    const resp = await fetch(
      `${ENDPOINT}/api/netnetu/update-help-video-screen/${id}/`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${access_token}`,
        },

        body: JSON.stringify(requestBody),
      },
    );

    const realResponse = await resp.json();

    if (!realResponse) {
      console.log('Error Found');
    } else {
      getVideosScreen();
    }

    return realResponse;
  };

  useEffect(() => {
    getVideosScreen();
  }, []);

  const [tagData, setTagData] = useState([
    { key: 0, label: 'Starting Up' },
    { key: 1, label: 'Basic Information' },
    { key: 2, label: 'Acount Information' },
  ]);

  return (
    <>
      <div className="createHelpWrapper">
        <Header />
        <div className="netUWrapperHelp">
          <div className="filledBox">
            <div className="courseTitle">Starting Up With Net Net</div>
            <div className="courseDesc">
              Learning the basics, introduction setting up your account and
              going along with the welcome screen
            </div>
            <div className="youTubeLink">
              <Link
                to="https://youtube.com/watch?v=a8fOAkGb8m0&ab_channel=MarcPitre"
                target="_blank"
              >
                youtube.com/watch?v=a8fOAkGb8m0&ab_channel=MarcPitre
              </Link>
            </div>
            <div className="chipTags">
              <TagChips tagData={tagData} setTagData={setTagData} />
            </div>
          </div>

          <div className="lessonWrapper">
            <div
              className="addLesson"
              style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '7px',
                textAlign: 'left',
                fontSize: '20px',
                color: '#000',
                fontFamily: "'Open Sans'",
              }}
            >
              <b style={{ position: 'relative', lineHeight: '130%' }}>
                Add Your Sub Help Videos
              </b>
            </div>
            <div className="lessonForm">
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  border: 0,
                  borderBottom: '1px solid #d9d9d9',
                  borderLeft: '1px solid #d9d9d9',
                  borderRight: '1px solid #d9d9d9',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '3rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="courseInfo"
              >
                <div className="leftInfo lessonInputs">
                  <TextField
                    sx={{
                      border: '1px solid #00000033',
                    }}
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                    fullWidth
                    label="Enter Your Video Title"
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleAddScreen}
                    sx={{
                      backgroundColor: '#711FFF',
                      marginRight: '6px',
                      padding: '9px 3px',
                      textTransform: 'capitalize',
                      width: '9rem',
                      '&:hover': {
                        backgroundColor: '#711FDD',
                        color: 'white',
                      },
                    }}
                  >
                    Add Sub Screen
                  </Button>
                  <Button>
                    <IoMdSettings
                      color="#0000008F"
                      size={25}
                      style={{ marginRight: '4px' }}
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div className="lessonForm">
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  border: 0,
                  borderBottom: '1px solid #d9d9d9',
                  borderLeft: '1px solid #d9d9d9',
                  borderRight: '1px solid #d9d9d9',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '3rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className="courseInfo"
              >
                {}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: '50%',
                          color: '#000000DE',
                          fontSize: '16px',
                          fontWeight: 600,
                        }}
                      >
                        {' '}
                        Sub Screens{' '}
                      </TableCell>
                      <TableCell align="center"> Videos </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ bgcolor: '#F1F3F5', overflow: 'auto' }}>
                    {HelpVideoScreen?.help_video_subscreen?.length > 0 &&
                      HelpVideoScreen?.help_video_subscreen?.map(
                        (item, index) => {
                          return (
                            <>
                              <TableRow
                                sx={{
                                  width: '100%',
                                  mb: 3,
                                  transition: '0.3s ease',
                                  height: '50px',
                                }}
                              >
                                <TableCell
                                  sx={{
                                    width: '50%',
                                    color: '#000000DE',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                  }}
                                >
                                  {' '}
                                  {item.title}{' '}
                                </TableCell>
                                <TableCell align="center" sx={{ width: '25%' }}>
                                  {' '}
                                  <Button
                                    onClick={() =>
                                      routeTo(
                                        `${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.netNetUCreateHelpVideosDetail}/${item.id}`,
                                      )
                                    }
                                    sx={{ color: '#711FFF' }}
                                  >
                                    {' '}
                                    + Add Video{' '}
                                  </Button>{' '}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        },
                      )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>

          <div className="backButtons">
            <Button
              variant="contained"
              onClick={() =>
                routeTo(
                  `${appRoutes.superAdmin.root}${appRoutes.superAdmin.netNetU.createNetNetUHelpVideos}`,
                )
              }
              sx={{
                backgroundColor: '#E0E0E0',
                color: 'black',
                textTransform: 'capitalize',
                width: '30%',
                paddingX: 2,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.07)',
                  color: 'black',
                },
              }}
            >
              Go Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHelpVideoScreen;

import React, { useEffect, useState, useRef } from 'react';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid, Box, Popover, Popper, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { EditorState } from 'draft-js';

import { ENDPOINT } from '../Opportunity/Redux/oppor';
import PreLoader, { NetNetCheckBox } from '../../Componenets/Misc';
import { getQuickTask } from './proRedux';
import InputAdornment from '@mui/material/InputAdornment';
import { getWorkType } from '../../Screens/InnerApp/workTypes';
import Shake from 'react-reveal/Shake';
import RichTextEditor from '../../Componenets/atomic-organisms/RichTextEditor/RichTextEditor';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const status = [
  'Backlog',
  'In Progress',
  'Submitted For Review',
  'Needs More Work',
  'Stuck',
  'Completed',
  'Archived',
];

function CreateTask({
  currentTarget,
  state,
  currentCompany,
  setState,
  toggleDrawer,
  anchor,
  counter,
  setCounter,
}) {
  const Team = useSelector((state) => state.userTeam.team);
  const currentUser = useSelector((state) => state.userData.userData);
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const currentPeoples = useSelector((state) => state.Contacts.persons);

  const TeamOptions =
    Team && Team.member
      ? Team.member.map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          avatar: item.avatar,
        }))
      : false;

  const [formData, setFormData] = useState({
    task_name: '',
    description: '',
    work_type: '',
    company: currentTarget
      ? currentCompanies.find((item) => item.name === currentTarget?.company)
          ?.id
      : currentCompany
        ? currentCompany?.id
        : '',
    contact: currentTarget ? currentTarget?.id : '',
    assignee: currentUser
      ? {
          ...currentUser,
          name: currentUser.first_name + ' ' + currentUser.last_name,
        }
      : { name: '' },
    estimated_hours: 1,
    budget: '',
    task_name: '',
    due_date: dayjs(),
    billable: false,
    task_status: 'Backlog',
  });

  const [html, setHtml] = useState('');

  const filter = createFilterOptions();
  // const [editor, setEditor] = useState(() => {
  //   EditorState.createEmpty().getCurrentContent();
  // });

  // const [fieldEmpty, setFieldEmpty] = useState(editor);

  // const fieldText = (e) => {
  //   setEditor(e);
  //   setHtml(stateToHTML(e.getCurrentContent()));
  // };

  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.auth.user.access);

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const companyOptions = currentCompanies
    .map((company) => ({
      name: company.name,
      id: company.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const comp = formData.company
    ? companyOptions.filter((item) => item.id === formData.company)
    : null;
  const peo = comp
    ? currentPeoples.filter((item) => item.company === comp[0]?.name)
    : [...currentPeoples];

  const [typing, setTyping] = useState(false);
  const peopleTyping =
    comp &&
    [...currentPeoples]
      .sort((a, b) => {
        const nameA = a?.company?.toUpperCase();
        const nameB = b?.company?.toUpperCase();

        if (nameA === comp[0]?.name.toUpperCase()) {
          return -1; // Person with the specified name comes first
        }

        if (nameB === comp[0]?.name.toUpperCase()) {
          return 1; // Person with the specified name comes second
        }

        // If neither is the specified name, sort based on lexicographical order of names
        return nameA?.localeCompare(nameB, undefined, { sensitivity: 'base' });
      })
      ?.map((people) => ({
        name: people.first_name + ' ' + people.last_name,
        id: people.id,
      }));

  const peopleOptions = peo.map((people) => ({
    name: people.first_name + ' ' + people.last_name,
    id: people.id,
  }));

  const peopleOptions2 = typing ? peopleTyping : peopleOptions;

  const newws = companyOptions.find((item) => item.id === formData.company);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorkType({ token }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCounter((prev) => prev + 1);

    if (formData.task_name === '') {
      setIsError(true);
      setError('Name Is Required');
    } else if (!formData.assignee) {
      setIsError(true);
      setError('Assignee Is Required');
    } else if (formData.work_type === '') {
      setIsError(true);
      setError('Work Type Is Required');
    } else if (formData.status === '') {
      setIsError(true);
      setError('Status Is Required');
    } else {
      setLoading(true);
      for (const key in formData) {
        if (!formData[key]) {
          delete formData[key];
        }
      }

      const response = await fetch(
        `${ENDPOINT}/api/project/quick/task/create/`,
        {
          method: 'POST',
          headers: {
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            formData.due_date
              ? {
                  task_name: formData.task_name,
                  task_type: 'quick_task',
                  description: formData.description,
                  work_type: formData.work_type,
                  company: formData.company,
                  contact: formData.contact,
                  estimated_hours: formData.estimated_hours,
                  budget: parseFloat(formData?.budget).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  }),
                  billable: formData.billable,
                  due_date: formData.due_date,
                  description: html,
                  assignee: [formData.assignee.id],
                  assigned_hours: formData.estimated_hours,
                  quicktask_worktype: formData.work_type,
                  task_assignee: [
                    {
                      assignee_user: formData.assignee.id,
                      hours: formData.estimated_hours,
                    },
                  ],
                  status:
                    formData.task_status === 'In Progress'
                      ? 'inprogress'
                      : formData.task_status === 'Stuck'
                        ? 'stuck'
                        : formData.task_status === 'Archived'
                          ? 'archive'
                          : formData.task_status === 'Submitted For Review'
                            ? 'review'
                            : formData.task_status === 'Completed'
                              ? 'completed'
                              : formData.task_status === 'Needs More Work'
                                ? 'needs_more_work'
                                : 'backlog',
                }
              : {
                  task_name: formData.task_name,
                  task_type: 'quick_task',
                  description: html ? html : formData.description,
                  work_type: formData.work_type,
                  company: formData.company,
                  contact: formData.contact,
                  estimated_hours: formData.estimated_hours,
                  budget: parseFloat(formData?.budget).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  }),
                  billable: formData.billable,
                  description: html,
                  assignee: [formData.assignee.id],
                  assigned_hours: formData.estimated_hours,
                  quicktask_worktype: formData.work_type,
                  task_assignee: [
                    {
                      assignee_user: formData.assignee.id,
                      hours: formData.estimated_hours,
                    },
                  ],
                  status:
                    formData.task_status === 'In Progress'
                      ? 'inprogress'
                      : formData.task_status === 'Stuck'
                        ? 'stuck'
                        : formData.task_status === 'Archived'
                          ? 'archive'
                          : formData.task_status === 'Submitted For Review'
                            ? 'review'
                            : formData.task_status === 'Completed'
                              ? 'completed'
                              : formData.task_status === 'Needs More Work'
                                ? 'needs_more_work'
                                : 'backlog',
                },
          ),
        },
      );
      const data = await response.json();
      if (!response.ok) {
        setIsError(true);
        for (const key in data) {
          setError(data[key]);
        }
        setLoading(false);
      } else {
        setState({ ...state, bottom: false });
        setCounter((prev) => prev + 1);
        dispatch(
          getQuickTask({
            access_token: token,
          }),
        );
      }
    }
  };

  const userWorkTypes = useSelector((state) => state.Misc.workTypes);

  const types =
    Array.isArray(userWorkTypes) && userWorkTypes.length
      ? userWorkTypes.map((item) => ({
          name: item.name,
          id: item.id,
        }))
      : [];

  // const inputRef = useRef(null);

  // const [open, setOpen] = useState(false)
  // const handleClick = () => {
  //   setOpen(true);
  // };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
        setError('');
      }, [2000]);
    }
  }, [isError, error]);

  const [openLink, setOpenLink] = useState(false);

  const [btn, setBtn] = useState();
  const check = (e) => {
    setBtn(e);
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        direction="column"
        sx={{ height: '82vh' }}
      >
        <Grid
          xs={12}
          container
          item
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            direction: 'column',
            padding: '1rem',
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <button
            className="close-modal"
            onClick={(e) => {
              e.preventDefault();
              setState({ ...state, bottom: false });
            }}
          />
          <Box
            sx={{
              width: '100%',
              padding: '18px 23px',
              textAlign: 'left',
              fontFamily: 'Open Sans',
              backgroundColor: '#f8f9fa',
              color: '#711fff',
              fontWeight: '700',
              height: '68px',
              fontSize: '1.50rem',
              borderRadius: '4px',
              margin: '0.7rem 0',
              textTransform: 'uppercase',
              borderRadius: '8px',
            }}
          >
            Add A New Quick Task
          </Box>
          <Box sx={{ display: 'flex', minHeight: '450px', width: '100%' }}>
            <Grid
              height={'100%'}
              item
              container
              sx={9}
              md={8.3}
              lg={8.3}
              xl={8}
            >
              <Box
                sx={{
                  padding: '0rem 0rem ',
                  borderRadius: '8px',
                  display: 'flex',
                  gap: '1.6rem',
                  background: '#fff',
                  flexDirection: 'column',
                  width: '90%',
                }}
              >
                <Grid
                  container
                  sx={{
                    border: '1.5px solid #d9d9d9',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                  }}
                >
                  <Grid
                    item
                    container
                    direction="column"
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#f8f9fa',
                        padding: '18px 23px',
                        marginBottom: '2.2px',
                        borderRadius: '8px',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          direction: 'column',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'flex-start',
                          marginBottom: '5px',
                        }}
                      >
                        <span className="modalSpan">Name</span>

                        <TextField
                          variant="outlined"
                          className="MUI-P6-M5"
                          id="cypress-task-createQuickTask-taskName"
                          value={formData.task_name}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              task_name: e.target.value,
                            });
                          }}
                          color="secondary"
                          sx={{
                            marginTop: '5px',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#a9a9a9',
                              },
                              '&:hover fieldset': {
                                borderColor: '#711fff',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#5a0bdf',
                              },

                              '&.Mui-error fieldset': {
                                borderColor: 'red',
                              },
                            },
                            '& .MuiFormLabel-root': {
                              color: '#03071e66',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#5a0bdf',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: 'red',
                            },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          direction: 'column',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span
                          style={{ marginBottom: '15rem' }}
                          className="modalSpan"
                        >
                          Description
                        </span>

                        <RichTextEditor
                          check={check}
                          my_logic={true}
                          open={openLink}
                          setOpen={setOpenLink}
                          setHtml={setHtml}
                          btn={btn}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              display={'flex'}
              height={'auto'}
              item
              xs={6}
              md={3.7}
              lg={3.7}
              xl={4}
            >
              <Box
                sx={{
                  backgroundColor: '#f2ebff',
                  padding: '18px 23px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '0.8rem',
                  height: '100%',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '18px' }} className="modalSpan">
                    Company
                  </span>

                  <Autocomplete
                    id="company"
                    options={companyOptions}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          overflowY: 'none', // Enable vertical scrolling
                          scrollbarColor: 'transparent transparent', // For Firefox
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },

                          // Styles for Microsoft Edge
                          '&::-ms-scrollbar': {
                            width: 0,
                            background: 'transparent transparent',
                          },
                        }}
                      />
                    )}
                    includeInputInList
                    value={
                      currentTarget?.company
                        ? {
                            name: currentTarget.company,
                            id: currentTarget.id,
                          }
                        : currentCompany?.name
                          ? {
                              name: currentCompany?.name,
                              id: currentCompany?.id,
                            }
                          : formData.company && {
                              name: newws.name,
                              id: newws.id,
                            }
                    }
                    onChange={(e, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          company: value?.id,
                        });
                      } else {
                        setFormData({
                          ...formData,
                          company: '',
                        });
                      }
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    sx={{ width: '100%' }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="secondary"
                        sx={{
                          marginTop: '0.4rem',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#a9a9a9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#711fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#5a0bdf',
                            },

                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#03071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: 'red',
                          },
                        }}
                      />
                    )}
                  />
                </Box>

                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '8px' }} className="modalSpan">
                    Person
                  </span>
                  <Autocomplete
                    id="company"
                    options={peopleOptions2}
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          overflowY: 'none', // Enable vertical scrolling
                          scrollbarColor: 'transparent transparent', // For Firefox
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },

                          // Styles for Microsoft Edge
                          '&::-ms-scrollbar': {
                            width: 0,
                            background: 'transparent transparent',
                          },
                        }}
                      />
                    )}
                    includeInputInList
                    onChange={(e, value) => {
                      if (value) {
                        setFormData({
                          ...formData,
                          contact: value.id ? value.id : 'no',
                        });
                        const filteredComp = currentCompanies?.find((item) =>
                          item.company_contacts?.some(
                            (ele) => ele.id === value?.id,
                          ),
                        )?.id;
                        if (!formData.company) {
                          setFormData({
                            ...formData,
                            contact: value.id,
                            company: filteredComp ? filteredComp : null,
                          });
                        }
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="secondary"
                        onChange={(e) => {
                          if (comp && e.target.value.length >= 1) {
                            setTyping(true);
                          } else if (e.target.value.length === 0) {
                            setTyping(false);
                          }
                        }}
                        sx={{
                          width: '100%',
                          marginTop: '0.4rem',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#a9a9a9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#711fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#5a0bdf',
                            },

                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#03071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: 'red',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '8px' }} className="modalSpan">
                    Assign to{' '}
                  </span>
                  <Autocomplete
                    options={
                      (Array.isArray(TeamOptions) &&
                        TeamOptions.length &&
                        TeamOptions?.filter(
                          (item) => item?.name !== 'First Name ',
                        )) || [{ name: 'You dont have any team yet' }]
                    }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          overflowY: 'none', // Enable vertical scrolling
                          scrollbarColor: 'transparent transparent', // For Firefox
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },

                          // Styles for Microsoft Edge
                          '&::-ms-scrollbar': {
                            width: 0,
                            background: 'transparent transparent',
                          },
                        }}
                      />
                    )}
                    includeInputnList
                    freeSolo
                    value={formData.assignee}
                    onChange={(e, value) => {
                      setFormData({
                        ...formData,
                        assignee: value,
                      });
                    }}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        value={formData.assignee}
                        color="secondary"
                        sx={{
                          marginTop: '0.4rem',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#a9a9a9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#711fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#5a0bdf',
                            },

                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#03071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: 'red',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '8px' }} className="modalSpan">
                    Work Type
                  </span>
                  <Autocomplete
                    options={
                      Array.isArray(types) && types.length ? [...types] : []
                    }
                    PopperComponent={(props) => (
                      <Popper
                        {...props}
                        sx={{
                          overflowY: 'none', // Enable vertical scrolling
                          scrollbarColor: 'transparent transparent', // For Firefox
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },

                          // Styles for Microsoft Edge
                          '&::-ms-scrollbar': {
                            width: 0,
                            background: 'transparent transparent',
                          },
                        }}
                      />
                    )}
                    id="ssce2"
                    onChange={(_, value) => {
                      setFormData({
                        ...formData,
                        work_type: value.id,
                      });
                    }}
                    noOptionsText={<Typography> No Worktypes </Typography>}
                    style={{ marginTop: '0.7rem' }}
                    sx={{
                      width: '100%',
                    }}
                    renderOption={(props, option) => (
                      <>
                        <li {...props}>{option.name}</li>
                      </>
                    )}
                    getOptionLabel={(opt) => opt.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="secondary"
                        sx={{
                          marginTop: '0.4rem',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#a9a9a9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#711fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#5a0bdf',
                            },

                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#03071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: 'red',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '8px' }} className="modalSpan">
                    Status
                  </span>
                  <Autocomplete
                    id="country"
                    includeInputInList
                    value={formData.task_status}
                    onChange={(e, value) => {
                      setFormData({ ...formData, task_status: value });
                    }}
                    options={status}
                    getOptionLabel={(option) => option}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#f2ebff',
                        },
                        '&.Mui-disabled fieldset': {
                          borderColor: '#f2ebff',
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        value={formData.task_status}
                        color="secondary"
                        sx={{
                          marginTop: '0.4rem',
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#a9a9a9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#711fff',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#a9a9a9',
                            },

                            '&.Mui-error fieldset': {
                              borderColor: 'red',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#03071e66',
                          },
                          '& .MuiFormLabel-root.Mui-focused': {
                            color: '#5a0bdf',
                          },
                          '& .MuiFormLabel-root.Mui-error': {
                            color: 'red',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '8px' }} className="modalSpan">
                    Hours
                  </span>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          style={{ marginRight: '5px' }}
                          position="end"
                        >
                          <span
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <ArrowDropUpIcon
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  estimated_hours:
                                    formData.estimated_hours + 0.25,
                                });
                              }}
                              sx={{ marginBottom: '-5px', cursor: 'pointer' }}
                            />
                            <ArrowDropDownIcon
                              onClick={() => {
                                setFormData({
                                  ...formData,
                                  estimated_hours:
                                    formData.estimated_hours - 0.25,
                                });
                              }}
                              sx={{ cursor: 'pointer' }}
                            />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                    id="outlined-basic"
                    value={formData.estimated_hours}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        estimated_hours: Number(e.target.value),
                      });
                    }}
                    onBlur={(e) => {
                      const inputNumber = parseFloat(e.target.value);
                      if (!isNaN(inputNumber)) {
                        const integerPart = Math.floor(inputNumber);
                        const fractionalPart = inputNumber - integerPart;
                        let roundedFractionalPart = 0;

                        if (fractionalPart <= 0.125) {
                          roundedFractionalPart = 0.0;
                        } else if (fractionalPart <= 0.375) {
                          roundedFractionalPart = 0.25;
                        } else if (fractionalPart <= 0.625) {
                          roundedFractionalPart = 0.5;
                        } else if (fractionalPart <= 0.875) {
                          roundedFractionalPart = 0.75;
                        } else {
                          roundedFractionalPart = 1.0;
                        }

                        const roundedNumber =
                          integerPart + roundedFractionalPart;
                        setFormData({
                          ...formData,
                          estimated_hours: roundedNumber,
                        });
                      }
                    }}
                    color="secondary"
                    sx={{
                      marginTop: '0.4rem',
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        paddingLeft: '0px 10px',
                        '& fieldset': {
                          border: '1px solid #a9a9a9 ',
                        },
                        '&:hover fieldset': {
                          border: '1px solid #711fff',
                        },
                        '&.Mui-focused fieldset': {
                          border: '1px solid #5a0bdf',
                        },
                        '&.Mui-error fieldset': {
                          border: '1px solid #f2ebff',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                      '& .MuiInputBase-input': {
                        paddingLeft: '10px',
                      },
                    }}
                    type="number"
                    inputProps={{ inputMode: 'none' }}
                    className="Mohsin"
                  />
                </Box>
                <Box
                  style={{
                    position: 'relative',
                    direction: 'column',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'flex-start',
                  }}
                >
                  <span style={{ marginBottom: '8px' }} className="modalSpan">
                    Due Date
                  </span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="MM-dd-yyyy"
                      value={formData.due_date}
                      onChange={(newValue) => {
                        setFormData({
                          ...formData,
                          due_date: newValue,
                        });
                      }}
                      sx={{
                        width: '100%',
                        height: '60px',
                        '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            borderColor: 'black',
                          },
                        '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                          {
                            color: 'black',
                          },
                        '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                          {
                            borderColor: '#a9a9a9',
                          },
                        '& .css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline':
                          {
                            border: '#a9a9a9',
                          },
                        '& .MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium':
                          {
                            marginRight: '0px',
                            position: 'absolute',
                            borderRadius: '0px !important',
                            width: '100% !important',
                            height: '60px',
                          },
                        '& .css-slyssw .MuiButtonBase-root-MuiIconButton-root':
                          {
                            marginRight: '0px',
                            position: 'absolute',
                            borderRadius: '0px !important',
                            width: '100% !important',
                            height: '60px',
                          },
                        '& .css-1ald77x.Mui-error': {
                          color: '#000',
                        },
                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                          {
                            marginRight: '0px',
                            position: 'absolute',
                            borderRadius: '0px !important',
                            width: '100% !important',
                            height: '60px',
                          },
                        '& .MuiInputAdornment-root': {
                          position: 'absolute',
                          top: '28px',
                          left: '-6px',
                          borderRadius: '0px',
                          width: '100%',
                        },
                        '& .MuiSvgIcon-root': {
                          position: 'absolute',
                          right: '10px',
                        },
                        '& .MuiInputBase-root': {
                          width: '100%',
                          position: 'relative',
                        },
                        '& .MuiOutlinedInput-input': {
                          width: '100%',
                          height: '60px',
                          padding: '0px',
                          color: 'black',
                          marginLeft: '10px',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          width: '100%',
                          height: '60px',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: '100%',
                            '& .MuiSvgIcon-root': {
                              marginRight: '0.8rem',
                            },
                            '& fieldset': {
                              border: '1px solid #a9a9a9 ',
                            },
                            '& .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused':
                              {
                                borderColor: '#a9a9a9',
                                color: '#711FFF',
                              },
                            '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                borderColor: '#a9a9a9',
                              },
                            '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                              {
                                borderColor: '#a9a9a9',
                              },
                            '& .MuiFormLabel-root': {
                              color: '#000',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                              color: '#000',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                              color: '#000',
                            },
                          }}
                          className="MUI-P6-M5"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box
                  className="dFlex"
                  sx={{
                    gap: '0.5rem',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                  }}
                >
                  <NetNetCheckBox
                    style={{ padding: '0px' }}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        billable: e.target.checked,
                      })
                    }
                    sx={{
                      padding: '0px', // Remove padding
                      marginRight: '0.5rem', // Add spacing between checkbox and text
                    }}
                  />
                  <span>Billable</span>
                </Box>
              </Box>
            </Grid>
          </Box>

          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {isError && (
              <Shake>
                <p className="error">{error}</p>
              </Shake>
            )}
            <div className="button-groups">
              <button
                className="cancel-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setState({ ...state, bottom: false });
                }}
              >
                Cancel
              </button>
              <button
                className="create-btn"
                id="cypress-task-createQuickTask-buttonCreate"
                onClick={handleSubmit}
              >
                {loading ? (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                      margin: '0rem 0.6rem',
                    }}
                  >
                    <PreLoader />
                  </Box>
                ) : (
                  'Create'
                )}{' '}
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateTask;

import React, { useState, useRef } from 'react';

import { Box, Divider, Menu, MenuItem } from '@mui/material';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';

// ! 'Quick Tasks' and 'Team' reports are commented out for now. (Marc's request - 21-10-2024).

function ParamControl({ report, updateInnerNetNetTabs }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const boxRef = useRef();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const routeTo = useRouteTo();
  return (
    <Box sx={{ width: 'fit-content' }}>
      <Box
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        ref={boxRef}
        sx={{
          p: '0.14rem 1.58rem',
          background: '#d1b9ff',
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '45px',
          color: '#000',
          cursor: 'pointer',
        }}
      >
        {report
          ? report === 'quick-tasks-report'
            ? 'QUICK TASKS'
            : report === 'estimate-report'
              ? 'ESTIMATE VS ACTUAL'
              : report === 'team-report'
                ? 'TEAM'
                : report === 'sales-report'
                  ? 'SALES'
                  : report === 'time-report'
                    ? 'TIME'
                    : report?.toUpperCase()
          : ''}{' '}
        REPORT
        <ExpandMoreSharpIcon sx={{ fontSize: '30px' }} />
      </Box>
      <Menu
        sx={{
          '& .MuiPaper-root MuiPaper-elevation MuiPaper-rounded': {
            width: boxRef.current ? `${boxRef.current.clientWidth}px` : 'auto', // Set the width based on the Box's client width
          },

          '& .css-6hp17o-MuiList-root-MuiMenu-list': {
            width: boxRef.current ? `${boxRef.current.clientWidth}px` : 'auto',
            color: '#000',
            maxHeight: '400px',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            routeTo(`${appRoutes.reports.root}${appRoutes.reports.timeReport}`);
            updateInnerNetNetTabs(
              'Time Report',
              `${appRoutes.reports.root}${appRoutes.reports.timeReport}`,
            );
            setAnchorEl(null);
          }}
          disableRipple
        >
          Time
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            routeTo(
              `${appRoutes.reports.root}${appRoutes.reports.estimateReport}`,
            );
            updateInnerNetNetTabs(
              'Estimate vs Actual',
              `${appRoutes.reports.root}${appRoutes.reports.estimateReport}`,
            );
          }}
          disableRipple
        >
          Estimate vs Actual
        </MenuItem>

        {/* <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            routeTo(`${appRoutes.reports.root}/${appRoutes.reports.quickTasksReport}`);
             updateInnerNetNetTabs(
              'Quick Tasks',
              `${appRoutes.reports.root}/${appRoutes.reports.quickTasksReport}`,
            );
          }}
          disableRipple
        >
          Quick Tasks
        </MenuItem> */}

        {/* <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            routeTo(`${appRoutes.reports.root}${appRoutes.reports.teamReport}`);
            updateInnerNetNetTabs(
              'Team',
              `${appRoutes.reports.root}${appRoutes.reports.teamReport}`,
            );
          }}
          disableRipple
        >
          Team
        </MenuItem> */}

        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            routeTo(
              `${appRoutes.reports.root}${appRoutes.reports.salesReport}`,
            );
            updateInnerNetNetTabs(
              'Sales',
              `${appRoutes.reports.root}${appRoutes.reports.salesReport}`,
            );
          }}
          disableRipple
        >
          Sales
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default ParamControl;

import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import initalConfig from './config';
import { useRouteTo } from '../../hooks/useRouteTo';
import { appRoutes } from '../../appRoutes';

// ! 'Quick Tasks' and 'Team' reports are commented out for now. (Marc's request - 21-10-2024).

function InitalizeParam({ updateInnerNetNetTabs }) {
  // * Used by 'Team' report.
  const routeTo = useRouteTo();
  return (
    <>
      <Typography
        sx={{
          fontWeight: '600',
          color: '#000',
          fontSize: '1.2rem',
          mt: '3rem',
          ml: '2rem',
        }}
      >
        Select to see your Desired Task
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: 'auto', gap: '2rem' }}
      >
        {initalConfig.map((item) => {
          return (
            <Box
              key={item.title}
              sx={{
                backgroundColor: item.bg,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '150px',
                width: '420px',
                borderRadius: '8px',
                p: '0.44rem 1.88rem',
                justifyContent: 'center',
                gap: '.5rem',
                my: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (item.param === 'time') {
                  routeTo(
                    `${appRoutes.reports.root}${appRoutes.reports.timeReport}`,
                  );
                  updateInnerNetNetTabs(
                    'Time Report',
                    `${appRoutes.reports.root}${appRoutes.reports.timeReport}`,
                  );
                }

                if (item.param === 'est') {
                  routeTo(
                    `${appRoutes.reports.root}${appRoutes.reports.estimateReport}`,
                  );
                  updateInnerNetNetTabs(
                    'Estimate vs Actual',
                    `${appRoutes.reports.root}${appRoutes.reports.estimateReport}`,
                  );
                }

                // if (item.param === 'tasks') {
                //   routeTo(`${appRoutes.reports.root}${appRoutes.reports.quickTasksReport}`);
                // updateInnerNetNetTabs(
                //   'Quick Tasks',
                //   `${appRoutes.reports.root}${appRoutes.reports.quickTasksReport}`,
                // );
                // }

                // if (item.param === 'team') {
                //   routeTo(`${appRoutes.reports.root}${appRoutes.reports.teamReport}`);
                // updateInnerNetNetTabs(
                //   'Team',
                //   `${appRoutes.reports.root}${appRoutes.reports.teamReport}`,
                // );
                // }

                if (item.param === 'sales') {
                  routeTo(
                    `${appRoutes.reports.root}${appRoutes.reports.salesReport}`,
                  );
                  updateInnerNetNetTabs(
                    'Sales',
                    `${appRoutes.reports.root}${appRoutes.reports.salesReport}`,
                  );
                }
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontWeight: '600',
                  fontSize: '1.1rem',
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: '#fff',
                  fontSize: '1rem',
                }}
              >
                {item.desc}
              </Typography>
            </Box>
          );
        })}
      </Grid>
    </>
  );
}

export default InitalizeParam;

import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PieChart, Pie, Cell } from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { getTime } from '../redux/totalTime';
import Dots from '../assets/Dots.png';
import Gear from '../assets/Gear.png';
import helpVid from '../../Opportunity/assets/Section_Help-idle.svg';
import Bounce from 'react-reveal/Bounce';
import DashboardHelpVideos from './DashboardHelpVideo';
import { useRouteTo } from '../../../hooks/useRouteTo';
import { appRoutes } from '../../../appRoutes';

const CustomChart = ({ tasks }) => {
  const completed = tasks.filter((t) => t.status === 'completed');
  const incomplete = tasks.filter((c) => c.status !== 'completed');
  const data = [];
  const percentage = Array.isArray(completed)
    ? (completed.length / tasks.length) * 100
    : 0;
  if (completed.length > 0) {
    data.push({
      name: 'Completed',
      value: completed.length,
    });
  }

  if (incomplete.length > 0) {
    data.push({
      name: 'Incomplete',
      value: incomplete.length,
    });
  }

  const colors = ['#a5d76e', '#d25559'];

  return (
    <PieChart width={200} height={200}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={0}
        outerRadius={73}
        fill="#8884d8"
        paddingAngle={percentage === 0 || percentage === 100 ? 0 : 3.5}
      >
        {[...Array(data.length).keys()].map((_, index) => (
          <Cell
            key={`cell-${index}`}
            fill={percentage === 0 ? '#d25559' : colors[index]}
            strokeWidth={0}
            stroke="none"
          />
        ))}
      </Pie>
    </PieChart>
  );
};

const TimeBox = ({ tasks }) => {
  const dispatch = useDispatch();
  function getTotalTime(tasks) {
    let totalTime = 0;
    let thisMonthTime = 0;
    let thisWeekTime = 0;
    const weekDayTime = [0, 0, 0, 0, 0, 0, 0];

    const now = moment();

    tasks.forEach((task) => {
      task.time_tracking.forEach((time) => {
        if (time.time_tracking_start && time.time_tracking_stop) {
          const startTime = moment(time.time_tracking_start);
          const endTime = moment(time.time_tracking_stop);
          const timeDiff = endTime.diff(startTime);

          totalTime += timeDiff;

          // Check if time falls within the current month
          if (now.isSame(startTime, 'month')) {
            thisMonthTime += timeDiff;
          }

          // check if time falls within this wekk
          const daysDiff = endTime.diff(startTime, 'days');
          if (daysDiff < 7 && startTime.isoWeek() === now.isoWeek()) {
            thisWeekTime += daysDiff;
          }

          // Add to weekday time
          const weekday = startTime.weekday();
          weekDayTime[weekday] += timeDiff;
        } else {
          console.warn(
            'Invalid Parameter , Start time and End time must be defined',
          );
        }
      });
    });

    // conversion to hours and minutes

    const totalHours = Math.floor(totalTime / 3600000);
    const totalMinutes = Math.floor((totalTime % 3600000) / 60000);

    const thisMonthHours = Math.floor(thisMonthTime / 3600000);
    const thisMonthMinutes = Math.floor((thisMonthTime % 3600000) / 60000);

    const thisWeekHours = Math.floor(thisWeekTime / 3600000);
    const thisWeekMinutes = Math.floor((thisWeekTime % 3600000) / 60000);

    const weekdayHours = weekDayTime.map((time) => Math.floor(time / 3600000));
    const weekdayMinutes = weekDayTime.map((time) =>
      Math.floor((time % 3600000) / 60000),
    );

    return {
      total: { hours: totalHours, minutes: totalMinutes },
      thisMonth: {
        hours: thisMonthHours,
        minutes: thisMonthMinutes,
      },
      weekelyTotal: {
        hours:
          weekdayHours[0] +
          weekdayMinutes[1] +
          weekdayHours[2] +
          weekdayMinutes[3] +
          weekdayHours[4] +
          weekdayHours[5] +
          weekdayMinutes[6],
        minutes: thisWeekMinutes,
      },
      Days: {
        Sunday: { hours: weekdayHours[0], minutes: weekdayMinutes[0] },
        Monday: { hours: weekdayHours[1], minutes: weekdayMinutes[1] },
        Tuesday: { hours: weekdayHours[2], minutes: weekdayMinutes[2] },
        Wednesday: { hours: weekdayHours[3], minutes: weekdayMinutes[3] },
        Thrusday: { hours: weekdayHours[4], minutes: weekdayMinutes[4] },
        Friday: { hours: weekdayHours[5], minutes: weekdayMinutes[5] },
        Sat: { hours: weekdayHours[6], minutes: weekdayMinutes[6] },
      },
    };
  }

  const filteredTasks = tasks.filter(
    (task) => task.time_tracking && task.time_tracking.length > 0,
  );

  const total = getTotalTime(filteredTasks);
  useEffect(() => {
    dispatch(getTime(total));
  }, [total]);

  return (
    <Box
      sx={{
        backgroundColor: '#E9ECEF',
        borderRadius: '8px',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1rem',
        width: '100%',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', gap: '2rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            This Week
          </Typography>
          <Typography
            varient="h5"
            sx={{
              fontSize: '2rem',
              fontWeight: '500',
              fontFamily: 'Open Sans',
              fontStyle: 'italic',
            }}
          >
            {total.weekelyTotal.hours ? total.weekelyTotal.hours : 0}
            :00
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            This Month
          </Typography>
          <Typography
            varient="h5"
            sx={{
              fontSize: '2rem',
              fontWeight: '500',
              fontFamily: 'Open Sans',
              fontStyle: 'italic',
            }}
          >
            {total.total.hours ? total.total.hours : 0}:
            {total.total.minutes ? total.total.minutes : 0}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Monday
          </Typography>
          <Typography
            varient="paragrah"
            sx={{
              color: '#000',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            {total.Days.Monday.hours ? total.Days.Monday.hours : '00'}:
            {total.Days.Monday.minutes ? total.Days.Monday.minutes : '00'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Tuesday
          </Typography>
          <Typography
            varient="paragrah"
            sx={{
              color: '#000',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            {total.Days.Tuesday.hours ? total.Days.Tuesday.hours : '00'}:
            {total.Days.Tuesday.minutes ? total.Days.Tuesday.minutes : '00'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Wednesday
          </Typography>
          <Typography
            varient="paragrah"
            sx={{
              color: '#000',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            {total.Days.Wednesday.hours ? total.Days.Wednesday.hours : '00'}:
            {total.Days.Wednesday.minutes ? total.Days.Wednesday.minutes : '00'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Thrusday
          </Typography>
          <Typography
            varient="paragrah"
            sx={{
              color: '#000',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            {total.Days.Thrusday.hours ? total.Days.Thrusday.hours : '00'}:
            {total.Days.Thrusday.minutes ? total.Days.Thrusday.minutes : '00'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Friday
          </Typography>
          <Typography
            varient="paragrah"
            sx={{
              color: '#000',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            {total.Days.Friday.hours ? total.Days.Friday.hours : '00'}:
            {total.Days.Friday.minutes ? total.Days.Friday.minutes : '00'}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const MyMetrics = ({ tasks }) => {
  const completed = tasks.filter((t) => t.status === 'completed');

  const percentage = Array.isArray(completed)
    ? (completed.length / tasks.length) * 100
    : 0;

  return Array.isArray(tasks) && tasks.length ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        padding: '18px 24px 18px 24px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '0.5rem',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#03071E',
          fontFamily: 'Open Sans',
          fontSize: '1.1rem',
          fontStyle: 'italic',
        }}
      >
        My Metrics
      </Typography>

      <Box sx={{ width: '60%', display: 'flex', alignItems: 'center' }}>
        <CustomChart tasks={tasks} />
        <Typography
          varient="h5"
          sx={{
            fontSize: '2.2rem',
            fontWeight: '500',
            fontFamily: 'Open Sans',
            fontStyle: 'italic',
          }}
        >
          {Math.round(percentage)}%
        </Typography>
      </Box>
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'italic',
          color: '#711fff',
          fontSize: '1.1rem',
          textAlign: 'right',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        View all my metrics
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        padding: '18px 24px 18px 24px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        height: '271.56px',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#03071E',
          fontFamily: 'Open Sans',
          fontSize: '1.1rem',
          fontStyle: 'italic',
        }}
      >
        My Metrics
      </Typography>
      <Box className="fstxt">Metrics unavalible at this moment.</Box>
    </Box>
  );
};

const MyTime = ({ tasks, updateInnerNetNetTabs }) => {
  const routeTo = useRouteTo();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        padding: '18px 24px 18px 24px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '0.5rem',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#03071E',
          fontFamily: 'Open Sans',
          fontSize: '1.1rem',
          fontStyle: 'italic',
        }}
      >
        My Time
      </Typography>
      <TimeBox tasks={tasks} />
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'italic',
          color: '#711fff',
          fontSize: '1.1rem',
          textAlign: 'right',
          width: '100%',
          cursor: 'pointer',
        }}
        onClick={() => {
          routeTo(appRoutes.myTime);
          updateInnerNetNetTabs('My Time', appRoutes.myTime);
        }}
      >
        View all my time entries
      </Box>
    </Box>
  );
};

const MyMentions = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: '18px 24px 18px 24px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      gap: '0.5rem',
    }}
  >
    <Typography
      variant="h5"
      sx={{
        color: '#03071E',
        fontFamily: 'Open Sans',
        fontSize: '1.1rem',
        fontStyle: 'italic',
      }}
    >
      My Mentions
    </Typography>

    <Typography
      variant="h5"
      sx={{
        color: '#000',
        fontFamily: 'Open Sans',
        fontSize: '1.1rem',
        fontStyle: 'italic',
      }}
    >
      You are not mentioned in any chat stream yet.
    </Typography>
  </Box>
);

const AnotherWatch = ({ currentTarget }) => {
  if (!currentTarget) {
    return;
  }
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (seconds > 58) {
          setSeconds(0);
          setMinutes(minutes + 1);
        } else {
          setSeconds(seconds + 1);
        }
        if (minutes > 58) {
          setMinutes(0);
          setHours(hours + 1);
        }
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds, minutes, hours]);

  return isActive ? (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF280',
          padding: '0.44rem 0.88rem',
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          fontSize: '1rem',
          fontStyle: 'italic',
          padding: '0.44rem 0.88rem',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className="static-display">
          {hours === 0 ? (
            <span>00</span>
          ) : hours >= 10 ? (
            <span>{hours}</span>
          ) : (
            <span>0{hours}</span>
          )}
          :
          {minutes === 0 ? (
            <span>00</span>
          ) : minutes >= 10 ? (
            <span>{minutes}</span>
          ) : (
            <span>0{minutes}</span>
          )}
          :
          {seconds === 0 ? (
            <span>00</span>
          ) : seconds >= 10 ? (
            <span>{seconds}</span>
          ) : (
            <span>0{seconds}</span>
          )}
        </div>
        <PauseIcon
          sx={{ cursor: 'pointer' }}
          onClick={() => setIsActive(false)}
        />
      </Box>
    </>
  ) : (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#80FFE5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#00B28F',
        fontSize: '1rem',
        fontStyle: 'italic',
        padding: '0.44rem 0.88rem',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        cursor: 'pointer',
      }}
      onClick={() => {
        setIsActive(true);
      }}
    >
      <div>Paused</div>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        Resume Work
        <PlayArrowIcon />
      </div>
    </Box>
  );
};

const Catalog = ({ task }) => {
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  const dueDate = task.dueDate
    ? `${months[task.due_date.split('-')[1]]} ${task.due_date.split('-')[2]}, ${
        task.due_date.split('-')[0]
      }`
    : false;

  return (
    <Box
      sx={{
        backgroundColor: '#E9ECEF',
        borderRadius: '8px',
        padding: '1.2rem 0 0 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0 1rem',
          width: '100%',
        }}
      >
        <Typography
          varient="paragraph"
          sx={{
            color: '#03071E66',
            fontSize: '1rem',
            fontWeight: '500',
            fontStyle: 'italic',
          }}
        >
          {task.project ? task.project : ''}
        </Typography>
        <Typography
          varient="h5"
          sx={{
            fontSize: '1.3rem',
            fontWeight: '500',
            fontFamily: 'Open Sans',
            fontStyle: 'italic',
          }}
        >
          {task.task_name ? task.task_name : ''}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '2rem',
          padding: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Due Date
          </Typography>
          <Typography
            varient="h5"
            sx={{
              fontSize: '1.3rem',
              fontWeight: '500',
              fontFamily: 'Open Sans',
              fontStyle: 'italic',
            }}
          >
            {dueDate
              ? `${dueDate.split('T')[0]},${dueDate.split(',')[1]}`
              : 'No Due Date Available'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            varient="paragraph"
            sx={{
              color: '#03071E66',
              fontSize: '1rem',
              fontWeight: '500',
              fontStyle: 'italic',
            }}
          >
            Time Tracked
          </Typography>
          <Typography
            varient="h5"
            sx={{
              fontSize: '1.3rem',
              fontWeight: '500',
              fontFamily: 'Open Sans',
              fontStyle: 'italic',
            }}
          >
            00:00:00
          </Typography>
        </Box>
      </Box>
      <AnotherWatch currentTarget={task} />
    </Box>
  );
};

const MyTasks = ({ tasks, updateInnerNetNetTabs }) => {
  const routeTo = useRouteTo();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        padding: '18px 24px 18px 24px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        gap: '0.5rem',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: '#03071E',
          fontFamily: 'Open Sans',
          fontSize: '1.1rem',
          fontStyle: 'italic',
        }}
      >
        My Tasks
      </Typography>

      <Typography
        variant="h5"
        sx={{
          color: '#03071E66',
          fontFamily: 'Open Sans',
          fontSize: '1.1rem',
        }}
      >
        Here you can see all the tasks that have been assigned to you by your
        team member. You can start tracking your time right away!
      </Typography>
      {Array.isArray(tasks) && tasks.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '1rem',
            height: { xl: '520px', lg: '496px' },
            overflowY: 'auto',
            width: '100%',
          }}
        >
          {tasks.length > 5
            ? tasks.slice(0, 5).map((item) => <Catalog task={item} />)
            : tasks.map((tsk) => <Catalog task={tsk} />)}
        </Box>
      ) : (
        <>
          <Typography
            variant="h5"
            sx={{
              color: '#000',
              fontFamily: 'Open Sans',
              fontSize: '1.1rem',
              fontStyle: 'italic',
            }}
          >
            Whoops You have not been assigned any task yet.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '1rem',
              height: '473px',
              overflowY: 'auto',
              width: '100%',
            }}
          />
        </>
      )}
      <Box
        sx={{
          fontFamily: 'Open Sans',
          fontStyle: 'italic',
          color: '#711fff',
          fontSize: '1.1rem',
          textAlign: 'right',
          width: '100%',
          cursor: 'pointer',
        }}
        onClick={() => {
          routeTo(appRoutes.myTasks);
          updateInnerNetNetTabs('My Tasks', appRoutes.myTasks);
        }}
      >
        View all my Tasks
      </Box>
    </Box>
  );
};

const Dashboard = ({ screenHeight, updateInnerNetNetTabs }) => {
  const tasks = useSelector((state) => state.projects.tasks);
  const user = useSelector((state) => state.userData.userData);

  const userTask =
    Array.isArray(tasks) && tasks.length
      ? tasks.filter((tsk) =>
          tsk.assignee_task?.some(
            (ele) => Number(ele.assignee_user) === Number(user.id),
          ),
        )
      : [];
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [show, setShow] = useState(false);

  return Array.isArray(userTask) && userTask.length ? (
    <Bounce left>
      <section className="oppor-section">
        <div className="oppor-row">
          <div className="oppor-col">
            <h3 className="fstxtPro">Dashboard</h3>
            <img className="icon-sm" src={Dots} alt="dots" />
            <img className="icon-sm" src={Gear} alt="gear" />
            <p>Filters</p>
            <img
              src={helpVid}
              onClick={() => {
                setShow(true);
              }}
              style={{
                color: '#a6a6a6',
                marginLeft: '10px',
                width: '28px',
                height: '28px',
                cursor: 'pointer',
              }}
            />
          </div>
        </div>
        <Grid
          sx={{
            width: '100%',
            gap: '1rem',
            height: screenHeight - 150,
            overflow: 'auto',
          }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            sx={{ gap: '1rem' }}
            xl={5.5}
            lg={5}
          >
            <MyMetrics tasks={userTask} />
            <MyTime
              tasks={userTask}
              updateInnerNetNetTabs={updateInnerNetNetTabs}
            />
            <MyMentions />
          </Grid>
          <Grid item xl={5.5} lg={5}>
            <MyTasks
              tasks={userTask}
              updateInnerNetNetTabs={updateInnerNetNetTabs}
            />
          </Grid>
        </Grid>
        <DashboardHelpVideos show={show} setShow={setShow} />
      </section>
    </Bounce>
  ) : (
    <section
      className="oppor-section"
      style={{ width: screenSize < 600 ? screenSize : 'auto' }}
    >
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">Dashboard</h3>
          <img className="icon-sm" src={Dots} alt="dots" />
          <img className="icon-sm" src={Gear} alt="gear" />
          <p>Filters</p>
          <img
            src={helpVid}
            onClick={() => {
              setShow(true);
            }}
            style={{
              color: '#a6a6a6',
              marginLeft: '10px',
              width: '28px',
              height: '28px',
              cursor: 'pointer',
            }}
          />
        </div>
      </div>
      <section
        className="myCenter"
        style={{
          marginBottom: screenSize < 600 && '5rem',
          height: screenHeight - 155,
        }}
      >
        <article className="box-txt">
          <h3 className="txtCenter">No Dashboard yet</h3>
          <p className="txtCenter">
            Your dashboard will be created once you are assigned a Task or
            Project.
          </p>
        </article>
      </section>
      <DashboardHelpVideos show={show} setShow={setShow} />
    </section>
  );
};

export default Dashboard;

import { useSelector } from 'react-redux';

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Alert,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Tooltip,
} from 'recharts';

import dayjs from 'dayjs';

import KPIBarChart from './KPIBarChart';
import KPIChartLegend from '../../../../Componenets/atomic-molecules/KPIChartLegend';

import {
  colorPalette,
  getProgressColor,
} from '../../../../utils/colors-helper';
import calculateTotalTimeObtained from '../../../../utils/calculateTotalTimeObtained';
import { formatNumber } from '../../../../utils/numberUtils';

function CustomTooltip({
  active,
  payload,
  loeEstimatedValue,
  loeAssignedValue,
}) {
  if (active && payload && payload.length > 0) {
    const friendlyLabelMapping = {
      loeAssignedTime: 'Assigned LOE Time',
      loeEstimatedTime: 'Estimated LOE Time',
      loeActualTime: 'Actual LOE Time',
      timelineEstimated: 'Estimated Timeline',
      timelineActual: 'Actual Timeline',
    };

    const data = payload[0].payload;
    const estimated = data.loeEstimatedTime || data.timelineEstimated || 0;
    const actual = data.loeActualTime || data.timelineActual || 0;

    const actualToEstimatedPercentage = estimated
      ? ((actual / estimated) * 100).toFixed(2)
      : null;

    const actualToAssignedPercentage = loeAssignedValue
      ? ((actual / loeAssignedValue) * 100).toFixed(2)
      : null;

    const backgroundColor =
      actualToEstimatedPercentage > 100
        ? colorPalette.backgroundOpaqueRed
        : actualToEstimatedPercentage >= 81
          ? colorPalette.backgroundOpaqueOrange
          : colorPalette.backgroundOpaqueGreen;

    const order = data.loeEstimatedTime
      ? ['loeActualTime', 'loeAssignedTime', 'loeEstimatedTime']
      : ['timelineActual', 'timelineEstimated'];

    const sortedPayload = payload.sort(
      (a, b) => order.indexOf(a.dataKey) - order.indexOf(b.dataKey),
    );

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor,
          padding: '4px',
          borderRadius: '8px',
          fontSize: '13px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor,
            padding: '2.5px 10px',
            borderRadius: '6px',
          }}
        >
          <ul
            style={{
              fontWeight: 'bold',
              listStyle: 'none',
              paddingLeft: 0,
              paddingTop: '10px',
              textAlign: 'left',
            }}
          >
            {sortedPayload.map((entry, index) => {
              const { dataKey, value } = entry;
              const friendlyLabel = friendlyLabelMapping[dataKey] || dataKey;
              let displayValue = value;

              if (dataKey === 'loeEstimatedTime') {
                displayValue = loeEstimatedValue;
              } else if (dataKey === 'loeAssignedTime') {
                displayValue = loeAssignedValue;
              }

              const timeUnit = data.timelineEstimated
                ? data.timelineEstimated === 1 || data.timelineActual === 1
                  ? 'day'
                  : 'days'
                : 'hrs';

              const formattedContent = `${friendlyLabel}: ${formatNumber(displayValue)} ${timeUnit}`;

              return (
                <li
                  key={entry.dataKey ?? index}
                  style={{
                    margin: '0',
                    padding: '0',
                  }}
                >
                  {formattedContent}
                </li>
              );
            })}

            <hr
              style={{
                border: `1px solid #2a2a2a`,
                borderRadius: '5px',
                margin: '5px 0',
                padding: '0',
              }}
            />

            {actualToAssignedPercentage ? (
              <li>
                Actual 2 Assigned: {`${actualToAssignedPercentage}% (A2A)`}
              </li>
            ) : (
              ''
            )}

            {actualToEstimatedPercentage ? (
              <li>
                Actual 2 Estimated: {`${actualToEstimatedPercentage}% (A2E)`}
              </li>
            ) : (
              ''
            )}
          </ul>
        </div>
      </div>
    );
  }
  return null;
}

function JobDetailsKPIBarChart({ data, deliverableDeepCopy }) {
  const projects = useSelector((state) => state.projects.projects);

  const projectId = data[0]?.projectId ?? 0;

  const projectData = projects.find(
    (project) => Number(project.id) === Number(projectId),
  ) ?? {
    target_start: new Date(),
    finish_date: new Date(),
  };

  const currentDate = dayjs().format('MM/DD/YYYY');
  const projectStartDate = dayjs(projectData.target_start).format('MM/DD/YYYY');
  const projectFinishDate = dayjs(projectData.finish_date).format('MM/DD/YYYY');

  const projectEstimateInDays = dayjs(projectFinishDate).diff(
    dayjs(projectStartDate),
    'day',
  );
  const projectActualInDays = dayjs(currentDate).diff(projectStartDate, 'day');
  const adjustedProjectDurationInDays =
    projectActualInDays > 0 ? projectActualInDays : 0;

  const getLOEEstimatedTime = (data) => {
    const dataArray = data;

    if (!dataArray || !dataArray[0] || !dataArray[0].data) {
      return 0;
    }

    return dataArray[0].data.reduce((sum, item) => sum + item.estimitedTime, 0);
  };

  const getLOEActualTime = (data) => {
    const dataArray = data;

    if (!dataArray || !dataArray || !dataArray[0] || !dataArray[0].data) {
      return 0;
    }

    return dataArray[0].data.reduce((sum, item) => sum + item.elapsedTime, 0);
  };

  const getLOEAssignedTime = (deliverableDeepCopy) => {
    const totalAssignedTime = (deliverableDeepCopy ?? []).reduce(
      (acc, deliverable) => {
        return (
          acc +
          (deliverable?.task_deliverable ?? []).reduce((acc, task) => {
            return (
              acc +
              (task?.assignee_task ?? []).reduce((acc, assignee) => {
                return acc + (assignee?.hours ?? 0);
              }, 0)
            );
          }, 0)
        );
      },
      0,
    );

    return totalAssignedTime;
  };

  const loeEstimatedValue = getLOEEstimatedTime(data);
  const loeAssignedValue = getLOEAssignedTime(deliverableDeepCopy);
  let loeEstimatedBar = loeEstimatedValue;
  let loeAssignedBar = loeAssignedValue;

  if (loeEstimatedValue > loeAssignedValue) {
    loeEstimatedBar -= loeAssignedBar;
  } else {
    loeAssignedBar -= loeEstimatedBar;
  }

  const loeData = [
    {
      loeTitle: 'Total LOE: Estimate vs. Actual',
      loeEstimatedTime: loeEstimatedBar,
      loeAssignedTime: loeAssignedBar,
      loeActualTime: getLOEActualTime(data),
    },
  ];

  const timelineData = [
    {
      timelineTitle: 'Total Timeline: Estimate vs. Actual',
      timelineEstimated: projectEstimateInDays,
      timelineActual: adjustedProjectDurationInDays,
    },
  ];

  const spanStyles = {
    padding: '4px 7px',
    borderRadius: '5px',
  };

  return (
    <Box sx={{ width: '100%', height: 400, textAlign: 'center' }}>
      <ResponsiveContainer width="100%" height={200}>
        <h5>
          Total LOE:{' '}
          <span
            style={{
              ...spanStyles,
              background: colorPalette.actualGreenColor,
            }}
          >
            Actual
          </span>{' '}
          |{' '}
          <span
            style={{
              ...spanStyles,
              background: colorPalette.assignedColor,
            }}
          >
            Assigned
          </span>{' '}
          |{' '}
          <span
            style={{
              ...spanStyles,
              background: colorPalette.estimatedColor,
            }}
          >
            Estimated
          </span>
        </h5>

        <BarChart barGap={-60} data={loeData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            unit=" hrs"
            interval={0}
            domain={['dataMin', 'dataMax']}
          />

          <YAxis dataKey="loeActualTime" type="category" />

          {loeEstimatedValue < loeAssignedValue ? (
            <>
              <Bar
                dataKey="loeEstimatedTime"
                fill={colorPalette.estimatedColor}
                barSize={70}
                stackId="a"
              />

              <Bar
                dataKey="loeAssignedTime"
                fill={colorPalette.assignedColor}
                barSize={70}
                margin={{ left: -70 }}
                stackId="a"
              />
            </>
          ) : (
            <>
              <Bar
                dataKey="loeAssignedTime"
                fill={colorPalette.assignedColor}
                barSize={70}
                margin={{ left: -70 }}
                stackId="a"
              />

              <Bar
                dataKey="loeEstimatedTime"
                fill={colorPalette.estimatedColor}
                barSize={70}
                stackId="a"
              />
            </>
          )}

          <Bar
            dataKey="loeActualTime"
            stackId="time"
            barSize={50}
            fill={getProgressColor(
              loeData[0].loeActualTime,
              loeData[0].loeEstimatedTime,
            )}
          >
            <LabelList dataKey="" position="top" />
          </Bar>

          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                loeEstimatedValue={loeEstimatedValue}
                loeAssignedValue={loeAssignedValue}
              />
            }
          />
        </BarChart>

        <h5>
          Total Timeline:{' '}
          <span
            style={{
              ...spanStyles,
              background: colorPalette.actualGreenColor,
            }}
          >
            Actual
          </span>{' '}
          |{' '}
          <span
            style={{
              ...spanStyles,
              background: colorPalette.estimatedColor,
            }}
          >
            Estimated
          </span>
        </h5>
        <BarChart barGap={-60} data={timelineData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            unit=" days"
            interval={0}
            domain={['dataMin', 'dataMax']}
          />
          <YAxis dataKey="timelineActual" type="category" />
          <Bar
            dataKey="timelineEstimated"
            fill={colorPalette.estimatedColor}
            barSize={70}
          />

          <Bar
            dataKey="timelineActual"
            stackId="time"
            barSize={50}
            fill={getProgressColor(
              timelineData[0].timelineActual,
              timelineData[0].timelineEstimated,
            )}
          >
            <LabelList dataKey="" position="top" />
          </Bar>
          <Tooltip
            cursor={false}
            content={
              <CustomTooltip
                loeEstimatedValue={loeEstimatedValue}
                loeAssignedValue={loeAssignedValue}
              />
            }
          />
        </BarChart>

        <KPIChartLegend />
      </ResponsiveContainer>
    </Box>
  );
}

function JobDetailsKPISummaries({ dileverable }) {
  const deliverableDeepCopy = JSON.parse(JSON.stringify(dileverable));

  const teamMembers = useSelector((state) => state.userTeam.team);

  const projectID = deliverableDeepCopy[0]?.deliverable_project ?? 0;

  // ! IF THE CHANGE FEATURE IS IMPLEMENTED, THIS MIGHT NOT WORK.
  const workTypeNames = deliverableDeepCopy[0].deliverable_worktype.map(
    (workType) => workType.name,
  );

  const getDistinctAssigneeUsers = (deliverables) => {
    const allAssigneeTasks = deliverables.flatMap((deliverable) =>
      deliverable.task_deliverable.flatMap((task) => task.assignee_task),
    );
    const uniqueAssigneeUsers = [
      ...new Set(allAssigneeTasks.map((assignee) => assignee.assignee_user)),
    ];
    return uniqueAssigneeUsers;
  };

  const distinctUsers = getDistinctAssigneeUsers(deliverableDeepCopy);

  const getTeamMemberByUserId = (userId) =>
    (teamMembers?.member ?? []).find((member) => member?.id === userId) || {
      first_name: 'Unknown',
      last_name: 'User',
    };

  const tableData = [
    {
      projectId: projectID,
      title: 'DELIVERABLES SUMMARY',
      data: (deliverableDeepCopy ?? []).map((item) => {
        const totalEstimatedTime =
          (item?.deliverable_worktype ?? []).reduce((acc, worktypeItem) => {
            return acc + Number(worktypeItem?.hours ?? 0);
          }, 0) ?? 0;

        const totalElapsedTime =
          item.task_deliverable.reduce((acc, actual) => {
            return (
              acc + calculateTotalTimeObtained(actual?.time_tracking ?? [])
            );
          }, 0) ?? 0;

        const totalAssignedTime = item.task_deliverable.reduce((acc, task) => {
          return (
            acc +
            task.assignee_task.reduce((acc, assignee) => {
              return acc + (assignee?.hours ?? 0);
            }, 0)
          );
        }, 0);

        return {
          title: item?.deliverable_name ?? 'Untitled Deliverable',
          estimitedTime: Math.round(totalEstimatedTime ?? 0),
          elapsedTime: Math.round(totalElapsedTime ?? 0),
          totalAssignedTime: Math.round(totalAssignedTime ?? 0),
        };
      }),
    },
    {
      should: false,
      title: 'WORK TYPES SUMMARY',
      data: (workTypeNames ?? []).map((workTypeName) => {
        const totalEstimatedTime = (deliverableDeepCopy ?? [])
          .map((deliverableItem) => {
            return (deliverableItem?.deliverable_worktype ?? []).reduce(
              (acc, workType) => {
                if (workType?.name === workTypeName) {
                  return acc + Number(workType?.hours ?? 0);
                }
                return acc;
              },
              0,
            );
          })
          .reduce((acc, item) => acc + item, 0);

        const totalElapsedTime = (deliverableDeepCopy ?? []).reduce(
          (acc, deliverableItem) => {
            const totalTaskElapsedTime = (
              deliverableItem?.task_deliverable ?? []
            ).reduce((taskAcc, task) => {
              const workTypeUsers = (task?.assignee_task ?? [])
                .filter(
                  (assignee) =>
                    assignee?.assignee_work_type?.name === workTypeName,
                )
                .map((assignee) => assignee?.assignee_user ?? null);

              const workTypeTime = calculateTotalTimeObtained(
                (task?.time_tracking ?? []).filter((tracking) =>
                  workTypeUsers.includes(tracking?.created_by?.id),
                ),
              );

              return taskAcc + workTypeTime;
            }, 0);

            return acc + totalTaskElapsedTime;
          },
          0,
        );

        const totalAssignedTime = (deliverableDeepCopy ?? []).reduce(
          (acc, deliverable) =>
            acc +
            deliverable.task_deliverable.reduce(
              (acc, task) =>
                acc +
                task.assignee_task.reduce((acc, assigneeTask) => {
                  if (workTypeName === assigneeTask.assignee_work_type.name) {
                    return acc + assigneeTask.hours;
                  }

                  return acc;
                }, 0),
              0,
            ),
          0,
        );

        return {
          title: workTypeName ?? 'Unknown Work Type',
          estimitedTime: Math.round(totalEstimatedTime),
          elapsedTime: Math.round(totalElapsedTime),
          totalAssignedTime: Math.round(totalAssignedTime),
        };
      }),
    },
    {
      def: true,
      title: 'WORK TYPES BY DELIVERABLES',
      data: (deliverableDeepCopy ?? []).map((deliverableItem) => {
        const workTypesByDeliverableDetails = (
          deliverableItem?.deliverable_worktype ?? []
        ).map((workType) => {
          // * Finds the specific elapsed time for this work type within this deliverable.
          const elapsedTime = (deliverableItem?.task_deliverable ?? []).reduce(
            (taskAcc, task) => {
              const workTypeUsers = (task?.assignee_task ?? [])
                .filter(
                  (assignee) =>
                    assignee?.assignee_work_type?.name === workType?.name,
                )
                .map((assignee) => assignee?.assignee_user ?? null);

              const workTypeTime = calculateTotalTimeObtained(
                (task?.time_tracking ?? []).filter((tracking) =>
                  workTypeUsers.includes(tracking?.created_by?.id),
                ),
              );

              return taskAcc + workTypeTime;
            },
            0,
          );

          const totalAssignedTime = (
            deliverableItem.task_deliverable ?? []
          ).reduce(
            (acc, task) =>
              acc +
              task.assignee_task.reduce((acc, assigneeTask) => {
                if (workType.name === assigneeTask.assignee_work_type.name) {
                  return acc + assigneeTask.hours;
                }

                return acc;
              }, 0),
            0,
          );

          return {
            chartTitle:
              deliverableItem?.deliverable_name ?? 'Unnamed Deliverable',
            title: workType?.name ?? 'Unnamed Work Type',
            estimitedTime: Math.round(Number(workType?.hours ?? 0)),
            elapsedTime: Math.round(elapsedTime ?? 0),
            totalAssignedTime: Math.round(totalAssignedTime ?? 0),
          };
        });

        return workTypesByDeliverableDetails;
      }),
    },
    {
      title: 'TEAM PERFORMANCE SUMMARY',
      data: (distinctUsers ?? []).map((userId) => {
        const teamMemberTotalAssignedHours = (deliverableDeepCopy ?? []).reduce(
          (acc, deliverable) => {
            (deliverable?.task_deliverable ?? []).forEach((task) => {
              (task?.assignee_task ?? []).forEach((assignment) => {
                if (assignment?.assignee_user === userId) {
                  acc[userId] = (acc[userId] || 0) + (assignment?.hours ?? 0);
                }
              });
            });
            return acc;
          },
          {},
        );

        const elapsedTime = (deliverableDeepCopy ?? []).reduce(
          (totalElapsedTime, deliverable) => {
            return (
              totalElapsedTime +
              (deliverable?.task_deliverable ?? []).reduce(
                (taskElapsedTime, task) => {
                  const timeTrackedForUser = (task?.time_tracking ?? []).filter(
                    (tracking) => tracking?.created_by?.id === userId,
                  );
                  return (
                    taskElapsedTime +
                    calculateTotalTimeObtained(timeTrackedForUser)
                  );
                },
                0,
              )
            );
          },
          0,
        );

        const teamMember = getTeamMemberByUserId(userId);

        return {
          title: `${teamMember?.first_name ?? 'Unknown'} ${teamMember?.last_name ?? 'User'}`,
          totalAssignedTime: Math.round(
            teamMemberTotalAssignedHours[userId] ?? 0,
          ),
          elapsedTime: Math.round(elapsedTime),
        };
      }),
    },
    {
      def: true,
      title: 'WORK TYPE PERFORMANCE BY TEAM',
      data: workTypeNames.map((workTypeName) => {
        const workTypeSummaryForTeam = distinctUsers.map((userId) => {
          const totalAssignedTime = (deliverableDeepCopy ?? [])
            .map((deliverableItem) => {
              return (deliverableItem?.task_deliverable ?? []).reduce(
                (acc, task) => {
                  const y = task.assignee_task.reduce((acc, assignee) => {
                    if (
                      assignee?.assignee_work_type?.name === workTypeName &&
                      assignee?.assignee_user === userId
                    ) {
                      return acc + Number(assignee?.hours ?? 0);
                    }
                    return acc;
                  }, 0);
                  return acc + y;
                },
                0,
              );
            })
            .reduce((acc, item) => acc + item, 0);

          const totalElapsedTime = (deliverableDeepCopy ?? []).reduce(
            (acc, deliverableItem) => {
              const totalTaskElapsedTime = (
                deliverableItem?.task_deliverable ?? []
              ).reduce((taskAcc, task) => {
                const workTypeUsers = (task?.assignee_task ?? [])
                  .filter(
                    (assignee) =>
                      assignee?.assignee_work_type?.name === workTypeName &&
                      assignee?.assignee_user === userId,
                  )
                  .map((assignee) => assignee?.assignee_user ?? null);

                const workTypeTime = calculateTotalTimeObtained(
                  (task?.time_tracking ?? []).filter((tracking) =>
                    workTypeUsers.includes(tracking?.created_by?.id),
                  ),
                );

                return taskAcc + workTypeTime;
              }, 0);

              return acc + totalTaskElapsedTime;
            },
            0,
          );

          const teamMember = getTeamMemberByUserId(userId);

          const firstName = teamMember.first_name ?? 'Unknown';
          const lastName = teamMember.last_name ?? 'User';

          return {
            chartTitle: workTypeName ?? 'Unknown Work Type',
            title: `${firstName} ${lastName}`,
            totalAssignedTime: Math.round(totalAssignedTime),
            elapsedTime: Math.round(totalElapsedTime),
          };
        });

        return workTypeSummaryForTeam;
      }),
    },
    {
      def: true,
      title: 'TEAM PERFORMANCE BY WORK TYPE',
      data: distinctUsers.map((userId) => {
        const workTypeSummaryForTeam = (workTypeNames ?? [])
          .map((workTypeName) => {
            const totalAssignedTime = (deliverableDeepCopy ?? [])
              .map((deliverableItem) => {
                return (deliverableItem?.task_deliverable ?? []).reduce(
                  (acc, task) => {
                    const y = task.assignee_task.reduce((acc, assignee) => {
                      if (
                        assignee.assignee_work_type?.name === workTypeName &&
                        assignee?.assignee_user === userId
                      ) {
                        return acc + Number(assignee?.hours ?? 0);
                      }
                      return acc;
                    }, 0);

                    return acc + y;
                  },
                  0,
                );
              })
              .reduce((acc, item) => acc + item, 0);

            // * Skip if no time is assigned to this work type.
            if (totalAssignedTime === 0) {
              return null;
            }

            const totalElapsedTime = (deliverableDeepCopy ?? []).reduce(
              (acc, deliverableItem) => {
                const totalTaskElapsedTime = (
                  deliverableItem?.task_deliverable ?? []
                ).reduce((taskAcc, task) => {
                  const workTypeUsers = (task?.assignee_task ?? [])
                    .filter(
                      (assignee) =>
                        assignee?.assignee_work_type?.name === workTypeName &&
                        assignee?.assignee_user === userId,
                    )
                    .map((assignee) => assignee?.assignee_user ?? null);

                  const workTypeTime = calculateTotalTimeObtained(
                    (task?.time_tracking ?? []).filter((tracking) =>
                      workTypeUsers.includes(tracking?.created_by?.id),
                    ),
                  );

                  return taskAcc + workTypeTime;
                }, 0);

                return acc + totalTaskElapsedTime;
              },
              0,
            );

            const teamMember = getTeamMemberByUserId(userId);

            return {
              chartTitle: `${teamMember.first_name ?? 'Unknown'} ${teamMember.last_name ?? 'User'}`,
              title: workTypeName ?? 'Unknown Work Type',
              totalAssignedTime: Math.round(totalAssignedTime),
              elapsedTime: Math.round(totalElapsedTime),
            };
          })
          .filter((item) => item !== null);

        return workTypeSummaryForTeam;
      }),
    },
    {
      def: true,
      title: 'DELIVERABLE PERFORMANCE BY TEAM',
      data: (deliverableDeepCopy ?? []).map((deliverableItem) => {
        return distinctUsers.map((userId) => {
          const totalAssignedTime = (
            deliverableItem?.task_deliverable ?? []
          ).reduce((acc, task) => {
            const taskTime = task.assignee_task.reduce((taskAcc, assignee) => {
              if (assignee.assignee_user === userId) {
                return taskAcc + (assignee?.hours ?? 0);
              }
              return taskAcc;
            }, 0);
            return acc + taskTime;
          }, 0);

          const totalElapsedTime = (
            deliverableItem?.task_deliverable ?? []
          ).reduce((acc, task) => {
            const taskElapsedTime = task.assignee_task.reduce(
              (taskAcc, assignee) => {
                if (assignee.assignee_user === userId) {
                  const workTypeUsers = (task?.assignee_task ?? [])
                    .filter((assignee) => assignee?.assignee_user === userId)
                    .map((assignee) => assignee?.assignee_user ?? null);

                  const elapsedTimeForUser = calculateTotalTimeObtained(
                    (task?.time_tracking ?? []).filter((tracking) =>
                      workTypeUsers.includes(tracking?.created_by?.id),
                    ),
                  );
                  return taskAcc + elapsedTimeForUser;
                }
                return taskAcc;
              },
              0,
            );
            return acc + taskElapsedTime;
          }, 0);

          const teamMember = getTeamMemberByUserId(userId);

          return {
            chartTitle:
              deliverableItem?.deliverable_name ?? 'Unknown Deliverable',
            title: `${teamMember.first_name ?? 'Unknown'} ${teamMember.last_name ?? 'User'}`,
            totalAssignedTime: Math.round(totalAssignedTime) ?? 0,
            elapsedTime: Math.round(totalElapsedTime) ?? 0,
          };
        });
      }),
    },
    {
      def: true,
      title: 'TEAM PERFORMANCE BY DELIVERABLE',
      data: distinctUsers.map((userId) => {
        return (deliverableDeepCopy ?? [])
          .map((deliverableItem) => {
            const totalAssignedTime = (
              deliverableItem?.task_deliverable ?? []
            ).reduce((acc, task) => {
              const taskTime = task.assignee_task.reduce(
                (taskAcc, assignee) => {
                  if (assignee.assignee_user === userId) {
                    return taskAcc + (assignee?.hours ?? 0);
                  }
                  return taskAcc;
                },
                0,
              );
              return acc + taskTime;
            }, 0);

            const totalElapsedTime = (
              deliverableItem?.task_deliverable ?? []
            ).reduce((acc, task) => {
              const taskElapsedTime = task.assignee_task.reduce(
                (taskAcc, assignee) => {
                  if (assignee.assignee_user === userId) {
                    const workTypeUsers = (task?.assignee_task ?? [])
                      .filter((assignee) => assignee?.assignee_user === userId)
                      .map((assignee) => assignee?.assignee_user ?? null);

                    const elapsedTimeForUser = calculateTotalTimeObtained(
                      (task?.time_tracking ?? []).filter((tracking) =>
                        workTypeUsers.includes(tracking?.created_by?.id),
                      ),
                    );
                    return taskAcc + elapsedTimeForUser;
                  }
                  return taskAcc;
                },
                0,
              );
              return acc + taskElapsedTime;
            }, 0);

            const teamMember = getTeamMemberByUserId(userId);

            return {
              chartTitle: `${teamMember.first_name ?? 'Unknown'} ${teamMember.last_name ?? 'User'}`,
              title: deliverableItem?.deliverable_name ?? 'Unknown Deliverable',
              totalAssignedTime: Math.round(totalAssignedTime) ?? 0,
              elapsedTime: Math.round(totalElapsedTime) ?? 0,
            };
          })
          .filter((item) => item.totalAssignedTime > 0);
      }),
    },
  ];

  return (
    <div
      className="main-container"
      style={{
        background: '#fff',
        borderRadius: '10px',
        padding: '21px',
        width: '100%',
      }}
    >
      <Grid container direction="column" alignItems="flex-start">
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontWeight: '600',
            fontSize: '1.3rem',
            textAlign: 'center',
            color: '#310085',
            paddingLeft: '2rem',
          }}
        >
          OVERALL JOB PERFORMANCE
        </Typography>
        <JobDetailsKPIBarChart
          data={tableData}
          deliverableDeepCopy={deliverableDeepCopy}
        />
      </Grid>

      <div className="kpi-middle-container">
        <div className="kpi-middle">
          <hr />
          <div className="kpi-barchart" />
          {tableData.map((item, nt) => {
            return (
              <Accordion
                key={item.title}
                disableGutters={true}
                elevation={0}
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id={nt + 1}
                  sx={{
                    width: '100%',
                    '&MuiAccordionSummary-content': {
                      justifyContent: 'space-between',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: '#310085',
                      fontWeight: '600',
                      fontFamily: 'Open Sans',
                      fontSize: '1.2rem',
                      textTransform: 'uppercase',
                      m: '0 1rem',
                    }}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Array.isArray(item.data) &&
                  item.data.length > 0 &&
                  !item.def ? (
                    <div>
                      <KPIBarChart
                        data={item.should ? item.data[0] : item.data}
                        isUnit
                        isLegend
                      />
                    </div>
                  ) : item.def &&
                    Array.isArray(item.data) &&
                    item.data.length > 0 ? (
                    item.data.map((data) => {
                      if (!data || !data[0] || !data[0].chartTitle) {
                        return (
                          <Box
                            key={`no-kpi-${data.title}`}
                            sx={{
                              borderBottom: '1px solid #711fff',
                              padding: '1rem 0',
                            }}
                          >
                            <Alert severity="info">
                              No KPI&apos;s insights for this item yet.
                            </Alert>
                          </Box>
                        );
                      }

                      return (
                        <Box
                          key={data[0].chartTitle ?? 'Untitled Chart'}
                          sx={{
                            borderBottom: '1px solid #711fff',
                            paddingBottom: '1rem',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: '600',
                              fontSize: '1.2rem',
                              marginTop: '1rem',
                              paddingTop: '1rem',
                            }}
                          >
                            {data[0].chartTitle}
                          </Typography>
                          <KPIBarChart data={data} isUnit isLegend />
                        </Box>
                      );
                    })
                  ) : (
                    <Alert severity="info">
                      No KPI&apos;s insights for this section yet.
                    </Alert>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default JobDetailsKPISummaries;

import { useNavigate } from 'react-router-dom';

// * Navigate using either a static path or a dynamic route function
// * - `path` can be a string for static routes or a function for dynamic routes.
// * Example:
//  ? import appRoutes from 'routing/appRoutes';
//  ? import { useRouteTo } from 'routing/routingHelper';
//
//  ?  useRouteTo(appRoutes.dashboard) // Static route.
//  ?  useRouteTo(appRoutes.routeWithParams, { userId: 123 }) // Route with params.
// eslint-disable-next-line
export function useRouteTo() {
  const navigate = useNavigate();

  const routeTo = (path, params) => {
    if (typeof path === 'function') {
      navigate(path(params)); // Dynamic route, pass params to generate the path
    } else {
      navigate(path); // Static route
    }
  };

  return routeTo;
}

import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, Box } from '@mui/material';

import Gear from '../assets/Gear.png';
import Dots from '../assets/Dots.png';
import { myCountries } from '../../../Componenets/Misc';
import { NetnetField } from '../../../Componenets/NetField';
import { ENDPOINT } from '../../Opportunity/Redux/oppor';
import { PreLoading } from '../People/EditContact';
import { getCompanies } from '../ContactRedux';
import { useRouteTo } from '../../../hooks/useRouteTo';

function EditCompany({ screenHeight }) {
  const routeTo = useRouteTo();
  const { id } = useParams();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const access_token = useSelector((state) => state.auth.user.access);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const currentCompanies = useSelector((state) => state.Contacts.companies);
  const ourCompany = currentCompanies.find((company) => company.id === id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${ENDPOINT}/api/contact/company/detail/${id}/`,
          {
            method: 'GET',
            headers: {
              Authorization: `JWT ${access_token}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (!res.ok) {
          routeTo(-1);
        } else {
          const data = await res.json();
          if (data) {
            setFormData(data);
            setIsDataLoading(false);
          }
        }
        // eslint-disable-next-line
      } catch (error) {}
    };

    fetchData();
  }, [ourCompany, id]);

  const types = ['Customer', 'Former Customer', 'Lead', 'Partner', 'Prospect'];

  const industry = [
    'Accommodation',
    'Accommodation and Food Services',
    'Administrative and Support Services',
    'Administrative and Support and Waste Management and Remediation Services',
    'Agriculture, Forestry, Fishing and Hunting',
    'Air Transportation',
    'Ambulatory Health Care Services',
    'Amusement, Gambling, and Recreation Industries',
    'Animal Production',
    'Apparel Manufacturing',
    'Arts, Entertainment, and Recreation',
    'Beverage and Tobacco Product Manufacturing',
    'Broadcasting (except Internet)',
    'Building Material and Garden Equipment and Supplies Dealers',
    'Chemical Manufacturing',
    'Clothing and Clothing Accessories Stores',
    'Computer and Electronic Product Manufacturing',
    'Construction',
    'Construction of Buildings',
    'Couriers and Messengers',
    'Credit Intermediation and Related Activities',
    'Crop Production',
    'Data Processing, Hosting, and Related Services',
    'Education and Health Services',
    'Educational Services',
    'Electrical Equipment, Appliance, and Component Manufacturing',
    'Electronics and Appliance Stores',
    'Fabricated Metal Product Manufacturing',
    'Finance and Insurance',
    'Financial Activities',
    'Fishing, Hunting and Trapping',
    'Food Manufacturing',
    'Food Services and Drinking Places',
    'Food and Beverage Stores',
    'Forestry and Logging',
    'Funds, Trusts, and Other Financial Vehicles',
    'Furniture and Home Furnishings Stores',
    'Furniture and Related Product Manufacturing',
    'Gasoline Stations',
    'General Merchandise Stores',
    'Goods-Producing Industries',
    'Health Care and Social Assistance',
    'Health and Personal Care Stores',
    'Heavy and Civil Engineering Construction',
    'Hospitals',
    'Information',
    'Insurance Carriers and Related Activities',
    'Internet Publishing and Broadcasting',
    'Leather and Allied Product Manufacturing',
    'Leisure and Hospitality',
    'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    'Machinery Manufacturing',
    'Management of Companies and Enterprises',
    'Manufacturing',
    'Merchant Wholesalers, Durable Goods',
    'Merchant Wholesalers, Nondurable Goods',
    'Mining (except Oil and Gas)',
    'Mining, Quarrying, and Oil and Gas Extraction',
    'Miscellaneous Manufacturing',
    'Miscellaneous Store Retailers',
    'Monetary Authorities - Central Bank',
    'Motor Vehicle and Parts Dealers',
    'Museums, Historical Sites, and Similar Institutions',
    'Natural Resources and Mining',
    'Nonmetallic Mineral Product Manufacturing',
    'Nonstore Retailers',
    'Nursing and Residential Care Facilities',
    'Oil and Gas Extraction',
    'Other Information Services',
    'Other Services (except Public Administration)',
    'Paper Manufacturing',
    'Performing Arts, Spectator Sports, and Related Industries',
    'Personal and Laundry Services',
    'Pipeline Transportation',
    'Plastics and Rubber Products Manufacturing',
    'Postal Service',
    'Primary Metal Manufacturing',
    'Printing and Related Support Activities',
    'Private Households',
    'Professional and Business Services',
    'Professional, Scientific, and Technical Services',
    'Publishing Industries (except Internet)',
    'Rail Transportation',
    'Real Estate',
    'Real Estate and Rental and Leasing',
    'Religious, Grantmaking, Civic, Professional, and Similar Organizations',
    'Rental and Leasing Services',
    'Repair and Maintenance',
    'Retail Trade',
    'Scenic and Sightseeing Transportation',
    'Securities, Commodity Contracts, and Other Financial Investments and Related Activities',
    'Service-Providing Industries',
    'Social Assistance',
    'Specialty Trade Contractors',
    'Sporting Goods, Hobby, Book, and Music Stores',
    'Support Activities for Agriculture and Forestry',
    'Support Activities for Mining',
    'Support Activities for Transportation',
    'Telecommunications',
    'Textile Mills',
    'Textile Product Mills',
    'Trade, Transportation, and Utilities',
    'Transit and Ground Passenger Transportation',
    'Transportation Equipment Manufacturing',
    'Transportation and Warehousing',
    'Truck Transportation',
    'Utilities',
    'Warehousing and Storage',
    'Waste Management and Remediation Services',
    'Water Transportation',
    'Wholesale Electronic Markets and Agents and Brokers',
    'Wholesale Trade',
    'Wood Product Manufacturing',
    'Others',
  ];

  const handleUpdate = async (e) => {
    e.preventDefault();
    const res = await fetch(
      `${ENDPOINT}/api/contact/company/update/${formData.id}/`,
      {
        method: 'PUT',
        headers: {
          Authorization: `JWT ${access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      },
    );
    const data = await res.json();
    if (!res.ok) {
      setError(true);
      setErrorMessage(Object.values(data));
    } else {
      dispatch(getCompanies({ access_token }));
      routeTo(-1);
    }
  };

  const persons = useSelector((item) => item.Contacts.companies).filter(
    (item) => item.tags,
  );
  const flattenedTags = persons
    .map((person) => person.tags.split(',').map((tag) => tag.trim()))
    .flat();

  const uniqueTagsArray = [];
  const seenTags = new Set();

  flattenedTags.forEach((tag) => {
    if (!seenTags.has(tag)) {
      seenTags.add(tag);
      uniqueTagsArray.push(tag);
    }
  });

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const handleDelete = (chipToDelete) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      tags: prevFormData.tags
        .split(',')
        .filter((chip) => chip.trim() !== chipToDelete.trim())
        .join(','),
    }));
  };

  return !isDataLoading ? (
    <section className="oppor-section2">
      <div className="oppor-row">
        <div className="oppor-col">
          <h3 className="fstxtPro">Edit Company</h3>
          <img className="icon-sm" src={Dots} alt="dots" />
          <img className="icon-sm" src={Gear} alt="gear" />
        </div>
      </div>
      <section
        className="grid-system"
        style={{ height: screenHeight - 180, overflow: 'auto' }}
      >
        <div className="row">
          <div className="col col-NP-NC">
            <h3 className="sub-title">Contact Information</h3>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Company Name</span>
              <NetnetField
                id="Cypress-CompanyName"
                label="Enter Name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                variant="outlined"
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Company URL</span>
              <NetnetField
                id="company"
                value={formData.company_url}
                label="Company URL"
                variant="standard"
                onChange={(e) =>
                  setFormData({ ...formData, company_url: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Office Telephone</span>

              <NetnetField
                id="Cypress-Telephone"
                label="Enter Office Telephone"
                value={formData.office_num}
                onChange={(e) =>
                  setFormData({ ...formData, office_num: e.target.value })
                }
                variant="outlined"
                color="secondary"
                style={{ width: 300 }}
                helperText="Please Enter a valid Number"
              />
            </Box>
          </div>
          <div className="col col-NP-NC">
            <h3 className="sub-title">Profile Information</h3>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Type</span>
              <Autocomplete
                id="country"
                includeInputInList
                value={formData.company_type ? formData.company_type : ''}
                onChange={(e, value) => {
                  setFormData({ ...formData, company_type: value });
                }}
                options={types}
                getOptionLabel={(option) => option}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f2f2f5 !important',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#f2f2f5',
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line
                    {...params}
                    label="Enter Type"
                    variant="outlined"
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: '#711fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#5a0bdf',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          borderColor: 'grey',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { width: '100%' },
                    }}
                  />
                )}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Industry</span>

              <Autocomplete
                id="country"
                includeInputInList
                value={formData.industry ? formData.industry : ''}
                onChange={(e, value) => {
                  setFormData({ ...formData, industry: value });
                }}
                options={industry}
                getOptionLabel={(option) => option}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f2f2f5 !important',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#f2f2f5',
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line
                    {...params}
                    label="Enter Type"
                    variant="outlined"
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: '#711fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#5a0bdf',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          borderColor: 'grey',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { width: '100%' },
                    }}
                  />
                )}
              />
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col col-NP-NC">
            <h3 className="sub-title">Address</h3>

            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Address Line 1</span>

              <NetnetField
                id="standard-basic"
                label="Enter Address"
                variant="standard"
                value={formData.street}
                onChange={(e) =>
                  setFormData({ ...formData, street: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Address Line 2</span>
              <NetnetField
                id="standard-basic"
                label="Enter Address"
                variant="standard"
                value={formData.street2}
                onChange={(e) =>
                  setFormData({ ...formData, street2: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">City</span>
              <NetnetField
                id="standard-basic"
                label="Enter City"
                variant="standard"
                value={formData.city}
                color="secondary"
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">State / Province</span>
              <NetnetField
                id="standard-basic"
                label="Enter State"
                variant="standard"
                value={formData.state}
                color="secondary"
                onChange={(e) =>
                  setFormData({ ...formData, state: e.target.value })
                }
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">
                {formData.country === 'United States'
                  ? 'Zip Code '
                  : 'Zip / Postal Code '}
              </span>
              <NetnetField
                id="standard-basic"
                label="Enter Zip Code"
                color="secondary"
                variant="standard"
                style={{ width: 300 }}
                value={formData.address_zip}
                onChange={(e) =>
                  setFormData({ ...formData, address_zip: e.target.value })
                }
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Country</span>

              <Autocomplete
                id="country"
                includeInputInList
                onChange={(e, value) => {
                  setFormData({ ...formData, country: value });
                }}
                value={formData.country ? formData.country : ''}
                options={myCountries}
                getOptionLabel={(option) => option}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f2f2f5 !important',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#f2f2f5',
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line
                    {...params}
                    label="Enter Country"
                    variant="outlined"
                    value={formData.country}
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: '#711fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#5a0bdf',
                        },

                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },

                        '&.Mui-disabled fieldset': {
                          borderColor: 'grey',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { width: '100%' },
                    }}
                  />
                )}
              />
            </Box>
          </div>
          <div className="col col-NP-NC">
            <h3 className="sub-title">Social Media</h3>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Linkedin</span>
              <NetnetField
                id="standard-basic"
                label="Enter URL"
                variant="standard"
                value={formData.linkedin_url}
                onChange={(e) =>
                  setFormData({ ...formData, linkedin_url: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">X</span>
              <NetnetField
                id="standard-basic"
                label="Enter URL"
                variant="standard"
                value={formData.twitter_url}
                onChange={(e) =>
                  setFormData({ ...formData, twitter_url: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Instagram</span>
              <NetnetField
                id="standard-basic"
                label="Enter URL"
                variant="standard"
                value={formData.instagram_url}
                onChange={(e) =>
                  setFormData({ ...formData, instagram_url: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: 'auto', maxWidth: '500px' }}
            >
              <span className="modalSpan">Facebook</span>
              <NetnetField
                id="standard-basic"
                label="Enter URL"
                variant="standard"
                value={formData.facebook_url}
                onChange={(e) =>
                  setFormData({ ...formData, facebook_url: e.target.value })
                }
                color="secondary"
                style={{ width: 300 }}
              />
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col col-NP-NC">
            <h3 className="sub-title">Tags</h3>
            <Box
              className="df-r-j-c-gp-5"
              style={{ width: '49%', maxWidth: '500px' }}
            >
              <span className="modalSpan">Tags</span>
              <Autocomplete
                id="company"
                options={uniqueTagsArray}
                defaultValue={inputValue}
                value={inputValue}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      tags: prevFormData.tags
                        ? `${prevFormData.tags}, ${e.target.value.trim()}`
                        : e.target.value.trim(),
                    }));
                    setInputValue('');
                    e.target.value = '';
                  }
                }}
                getOptionLabel={(option) => option}
                style={{ width: '100%' }}
                sx={{
                  width: '100%',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f2f2f5 !important',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#f2f2f5',
                    },
                  },
                }}
                // eslint-disable-next-line
                renderOption={(props, option) => <li {...props}>{option}</li>}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line
                    {...params}
                    label="Add Tags"
                    variant="outlined"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    inputRef={inputRef}
                    color="secondary"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white',
                        },
                        '&:hover fieldset': {
                          borderColor: '#711fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#5a0bdf',
                        },
                        '&.Mui-error fieldset': {
                          borderColor: 'red',
                        },
                      },
                      '& .MuiFormLabel-root': {
                        color: '#03071e66',
                      },
                      '& .MuiFormLabel-root.Mui-focused': {
                        color: '#5a0bdf',
                      },
                      '& .MuiFormLabel-root.Mui-error': {
                        color: 'red',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { width: '100%' },
                    }}
                  />
                )}
              />
            </Box>
            <div
              className="input-groups"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: '10px',
                marginLeft: '10px',
                height: '100px',
                overflow: 'auto',
              }}
            >
              {formData.tags &&
                formData.tags
                  .split(',')
                  .map((tag) => (
                    <Chip
                      key={tag}
                      label={tag.trim()}
                      color="secondary"
                      variant="outlined"
                      style={{ backgroundColor: '#f5f5f5' }}
                      onDelete={() => handleDelete(tag)}
                    />
                  ))}
            </div>
          </div>
        </div>
      </section>
      {error && <p className="error">{errorMessage}</p>}
      <div className="alCenter">
        <button
          type="button"
          className="cancel-btn"
          onClick={() => {
            routeTo(-1);
          }}
        >
          Cancel
        </button>
        <button type="button" className="create-btn2" onClick={handleUpdate}>
          {' '}
          Save{' '}
        </button>
      </div>
    </section>
  ) : (
    <PreLoading />
  );
}

export default EditCompany;
